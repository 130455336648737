import React from 'react';
import styled from 'styled-components';
import { HashLink } from 'widgets/MyHashLink';
import { GreenInvertRippleButton, BlueInvertRippleButton } from 'widgets/RippleContainer';
import { footerHeight, footerMax1024ScreenHeight, navBarFatHeight } from 'Config';

const AppError = () => {
    return (
        <ErrorBox><ErrorBoxOverlay>
            <ErrorMessage>{"Pagina inesistente"}</ErrorMessage>
            <ErrorLink to="/">{/* <Button iconClass="fas fa-home" textColor="white"
                bgColor="#0074cb" text={getString("error-home")} /> */}<BlueInvertRippleButton text={"Torna alla home"} icon={"fas fa-home"} /></ErrorLink>
            <ErrorLink to="https://barellolens.com">{/* <Button iconClass="fas fa-home" textColor="white"
                bgColor="#0074cb" text={getString("error-home")} /> */}<GreenInvertRippleButton text={"barellolens.com"} icon={"fas fa-shopping-cart"} /></ErrorLink>
        </ErrorBoxOverlay></ErrorBox>
    );
}

const ErrorBox = styled.div`
    position: relative;
    width: 100%;
    padding-top: ${navBarFatHeight};
    height: ${"calc(100vh - " + footerHeight + ")"};
    @media only screen and (max-width: 1024px) {
        height: ${"calc(100vh - " + footerMax1024ScreenHeight + ")"};
    }
    display: grid;
    place-items: center;
    background-image: url("/images/header/ottica-barello-negozio-lenti-a-contatto-occhiali-da-vista-da-sole-milano-duomo-ottico-1024-0-light2.jpg");
    background-size: cover;
    font-size: calc(15px + 0.5vw);
`;

const ErrorBoxOverlay = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(135deg, #ffffff88, #ffffffcc);
`;

const ErrorLink = styled(HashLink)`
    margin: calc(10px + 0.4vw) 0;
`;

const ErrorMessage = styled.h2`
    color: #0099cc;
    font-weight: 500;
    font-size: calc(20px + 1.5vw);
    text-align: center;
`;

export default AppError;
