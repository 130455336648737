import React from 'react';
import styled from 'styled-components';
import { SubHeadlineLine } from "widgets/Headlines";
import { HashLink } from "widgets/MyHashLink";
import { Expandablist2 } from "widgets/Expandablist";

const PageArticles = () => {
  return (
    <ArticlesBox>
      <Expandablist2 />
    </ArticlesBox>
  );
}
const ArticlesBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export default PageArticles;
