import React from 'react';
import { SubHeadlineLineSubdued } from "widgets/Headlines";
import { HashLink } from "widgets/MyHashLink";

export const articlesArray = [
  [
    "disturbi-cognitivi-e-riabilitazione",
    <>
      <h2>Disturbi cognitivi e riabilitazione</h2>
      <p>&nbsp;</p>
      <p>Fanno parte di questi disturbi il Delirium, la Demenza e i Disturbi Amnestici.</p>
      <p>Sono caratterizzati dalla compromissione delle funzioni cognitive, in modo particolare della memoria. E&rsquo; importante distinguere questi disturbi l&rsquo;uno dall&rsquo;altro (o riconoscere la loro concomitanza) al fine di impostare un corretto trattamento.</p>
      <p>Il delirium e la demenza sono a volte difficili da distinguere, data la compromissione della memoria e il disorientamento che si manifestano in entrambe le patologie, ma il primo &egrave; caratterizzato da un esordio brusco (ore o giorni) e vi &egrave; un&rsquo;alterazione dell&rsquo;attenzione e della capacit&agrave; di percepire lucidamente l&rsquo;ambiente circostante. Il delirium pu&ograve; essere causato da problemi epatici o renali, dalla disidratazione, da trauma cranico, da ipoglicemia, da intossicazione o astinenza da sostanze, da farmaci. Il delirium pu&ograve; risolversi in pochi giorni o settimane.&nbsp;</p>
      <p>La demenza &egrave; caratterizzata da un insieme di deficit cognitivi (compromissione della memoria, afasia, aprassia, agnosia, alterazione della capacit&agrave; d&rsquo;astrazione, alterazione del funzionamento esecutivo).</p>
      <p>I soggetti con demenza possono essere particolarmente vulnerabili agli agenti stressati fisici e psicosociali. E' importante rilevare che <strong><em>la demenza non pu&ograve; essere confusa con il normale declino delle funzioni cognitive che avviene con l&rsquo;invecchiamento.</em></strong></p>
      <p>Esistono diverse forme di demenza: la pi&ugrave; nota, da qualche anno a questa parte, &egrave; la malattia di Alzheimer; ma molto diffusa &egrave; anche la demenza vascolare, dovuta a lesioni multiple evidenti a esami come la TAC.</p>
      <p>Altre forme di demenza sono pi&ugrave; rare (demenza dovuta a malattia di Pick, a malattia di Huntigton, a malattia di Parkinson, a malattia HIV ecc.)</p>
      <p>La demenza pu&ograve; essere causata anche da traumi cranici o dall&rsquo;abuso di sostanze.</p>
      <p>I Disturbi Amnestici sono caratterizzati da deficit di memoria senza altre menomazioni cognitive importanti. Possono essere causati da malattie (es. l&rsquo;encefalite da herpes simplex) o da sostanze. E&rsquo; compromessa la capacit&agrave; di apprendere e ricordare nuove informazioni, mentre la capacit&agrave; di richiamare nozioni apprese in precedenza &egrave; maggiormente variabile e dipende dall&rsquo;entit&agrave; delle lesioni cerebrali subite. Le persone con questo disturbo possono ripetere immediatamente una serie di cifre, ma questo nuovo apprendimento non si consolida.</p>
      <p>Nelle forme pi&ugrave; gravi, la persona non ha consapevolezza del proprio disturbo. E&rsquo; possibile che le reazioni emotive si facciano pi&ugrave; scialbe e la persona si mostri apatica e indifferente, o al contrario agitata.</p>
      <p>Il disturbo pu&ograve; insorgere bruscamente (come nel caso di traumi) o insidiosamente (come nel caso dell&rsquo;abuso di alcool); pu&ograve; essere transitorio o permanente.</p>
      <p>I disturbi amnestici vanno distinti dai Disturbi Dissociativi, dove l&rsquo;amnesia &egrave; dovuta a traumi emotivi molto intensi e dove la capacit&agrave; di registrare nuove informazioni non &egrave; compromessa. Nel caso dell&rsquo;amnesia dissociativa abbiamo l&rsquo;incapacit&agrave; a ricordare quanto accaduto, ma la forza del trauma pu&ograve; agire in modo diametralmente opposto, scatenando ricordi emotivi che persistono mentre il soggetto vorrebbe soprattutto dimenticare.</p>
      <p>&nbsp;</p>
      <h3>La riabilitazione cognitiva</h3>
      <p>&nbsp;</p>
      <p>Attualmente, &egrave; noto che la deprivazione sensoriale gioca un ruolo importante nell'accentuazione dei sintomi del deterioramento mentale, ma &egrave; dalla fine dell&rsquo;Ottocento che si sa quanto l'esercizio e l'apprendimento facilitino la comunicazione tra i neuroni.</p>
      <p>Gli stimoli ambientali permettono al sistema nervoso di modificare la propria organizzazione strutturale e il proprio funzionamento.</p>
      <p>Nel cervello dell'adulto e dell'anziano si hanno in continuazione fenomeni di rimodellamento, che riguardano il collegamento di vie nervose disconnesse tramite la formazione di nuove sinapsi, oppure l'attivazione di sinapsi latenti fino a quel momento non utilizzate.</p>
      <p>Addirittura nella persona anziana le sinapsi sono maggiori che nella persona adulta, probabilmente perch&eacute; il cervello anziano cerca di compensare cos&igrave; la perdita di neuroni.</p>
      {/* <p>Durante i primi mesi dopo una lesione cerebrale, pu&ograve; avvenire un recupero spontaneo ma non &egrave; ancora chiaro se questo recupero &egrave; dovuto alla risoluzione di eventi come l'ischemia, alla non particolare gravit&agrave; della lesione stessa, o alle capacit&agrave; precedenti dell'individuo, al suo tipo di personalit&agrave; e di tono dell'umore, oppure alla durata e all'intensit&agrave; della riabilitazione.</p>
      <p>Alcuni studi hanno dimostrato la presenza di recupero durante la riabilitazione, tuttavia &egrave; ancora difficile determinare con esattezza quale sia l'entit&agrave; di questo contributo.</p>
      <p>Per quanto riguarda le demenze degenerative vascolari, la riabilitazione ha lo scopo di utilizzare le capacit&agrave; residue del paziente per mantenere il pi&ugrave; lungo possibile il massimo grado di autosufficienza cognitiva, migliorare la qualit&agrave; di vita del paziente, il tono dell'umore e il comportamento.</p> */}
      <p>Durante i primi mesi dopo una lesione cerebrale, può avvenire un recupero spontaneo. Non è ancora chiaro se questo recupero è dovuto alla risoluzione dell’evento, alla non particolare gravità della lesione stessa, o alle capacità precedenti dell&#39;individuo, al suo tipo di personalità, al tono dell&#39;umore, oppure alla durata e all&#39;intensità della riabilitazione, che ha lo scopo di utilizzare le capacità residue del paziente. Alcuni studi hanno dimostrato la presenza di recupero durante la riabilitazione, tuttavia è ancora difficile determinare con esattezza quale sia l&#39;entità di questo contributo.</p>
      <p>Esistono diversi metodi di riabilitazione cognitiva. Alcuni sono adatti nei casi di deterioramento grave, altri nel deterioramento moderato, altri nel deterioramento lieve mentre esistono interventi di stimolazione che sono utili per tutti. Vediamoli uno a uno.</p>
      <p>&nbsp;</p>
      <h3>La stimolazione psicosensoriale</h3>
      <p>&nbsp;</p>
      <p>&Egrave; rivolta ai pazienti pi&ugrave; deteriorati, quelli che hanno un risultato al Mini Mental State inferiore a 10.</p>
      <p>Deve essere preceduta da una valutazione dei cinque sensi e ha lo scopo di aiutare il paziente a cogliere gli stimoli ambientali. Si esegue attraverso sedute che di solito sono individuali e consistono nel somministrare stimoli visivi, uditivi, tattili, olfattivi e gustativi. Gli stimoli devono essere prima semplici e poi via via pi&ugrave; complessi e si possono usare i materiali pi&ugrave; vari, come fotografie, suoni e musica, parole, materiali duri e soffici, aromi, alimenti salati, dolci eccetera. Pu&ograve; essere utilizzata anche in maniera informale, aiutando il paziente a prestare attenzione ai vari stimoli che si presentano nella vita quotidiana, in modo da renderlo pi&ugrave; partecipe.</p>
      <p>La parte formale descritta sopra pu&ograve; essere inserita nella Terapia di orientamento nella realt&agrave; (ROT &ndash; Reality Orientation Therapy) formale.</p>
      <p>&nbsp;</p>
      <h3>La Terapia di orientamento nella realt&agrave; - ROT - informale</h3>
      <p>&nbsp;</p>
      <p>La ROT &egrave; stata ideata negli Stati Uniti alla fine degli anni Cinquanta. Si divide in ROT formale e informale. Quest'ultima &egrave; rivolta a persone gravemente deteriorate.</p>
      <p>Consiste nel fornire al paziente informazioni verbali su quanto sta avvenendo o dovr&agrave; avvenire. Ad esempio si pu&ograve; ricordare ripetutamente che giorno &egrave;, che ora &egrave;, che cosa si sta facendo e cos&igrave; via.</p>
      <p>Lo scopo &egrave; di aiutare l'anziano a mantenere la traccia di cosa gli accadde attorno e ridurre l'angoscia. Questa terapia pu&ograve; essere eseguita da tutte le persone che interagiscono con il paziente: familiari, ASA e OSS, infermieri eccetera.</p>
      <p>Le strutture esterne devono essere stabili (quindi l'ambiente non deve subire cambiamenti, tranne quelli necessari alla sicurezza del paziente) ed &egrave; importante correggere i deficit sensoriali, se possibile.</p>
      <p>&nbsp;</p>
      <h3>La Terapia di orientamento nella realt&agrave; - ROT - formale</h3>
      <p>&nbsp;</p>
      <p>Consiste in sedute giornaliere di quarantacinque minuti rivolte a gruppi di cinque o sei persone con deterioramento moderato o lieve, omogenee per grado di deterioramento, prive di gravi deficit sensoriali e seri disturbi comportamentali.</p>
      <p>L'operatore, professionalmente preparato, cerca di ri-orientare il paziente rispetto alla propria vita personale, all'ambiente, allo spazio. Utilizza ausili esterni come agende, calendari, orologi, che il paziente &egrave; indotto a utilizzare.</p>
      <p>Nel paziente con deterioramento lieve, l&rsquo;utilizzo pu&ograve; diventare automatico, mentre in quelli moderatamente deteriorati, &egrave; anche opportuno stimolare la motivazione.</p>
      <p>Si pu&ograve; insegnare al paziente a riportare subito sul calendario un appuntamento, a segnare su un foglio le cose che gli mancano e che deve acquistare, puntare il timer appena mette una pentola sul fuoco, riporre sempre gli oggetti al loro posto.</p>
      <p>Per l'orientamento spaziale, si possono utilizzare punti di riferimento in modo da memorizzare i percorsi.</p>
      <p>Esistono ausili esterni passivi come la predisposizione di un ambiente sicuro, ben illuminato, con colori contrastanti, con una serie di segnali efficaci, con contrassegni per cassetti o sportelli, evitando stimoli troppo forti o complicati.</p>
      <p>Chiunque abbia a che fare con questi malati deve dare messaggi semplici, uno per volta e non spazientirsi per l'eventuale ripetizione.</p>
      <p>Anche i familiari sono coinvolti per stabilire strategie ambientali che si dimostrino efficaci.</p>
      <p>Questa tecnica ha suscitato consensi e critiche. La pi&ugrave; frequente &egrave; che accanto ad un miglioramento cognitivo che riguarda ad esempio la memoria per i fatti personali o l'orientamento, non si vede un miglioramento nelle attivit&agrave; della vita quotidiana. Probabilmente perch&eacute; non si associa anche un allenamento nelle A.D.L. (Activities Daily Living, attivit&agrave; di base della vita quotidiana) e perch&eacute; molti familiari sostituiscono il paziente, anche quando potrebbe fare da solo con supervisione.</p>
      <p>Questa tecnica raramente presenta effetti collaterali negativi, per&ograve; certi pazienti reagiscono alla stimolazione cognitiva con senso di frustrazione perch&eacute; non riescono nel compito richiesto e l&rsquo;insuccesso pu&ograve; portare a confusione e depressione.</p>
      <p>Bisogna insomma stare molto attenti a non inserire un paziente in un gruppo di anziani di livello superiore al suo.</p>
      <p>&nbsp;</p>
      <h3>La Terapia di Reminiscenza</h3>
      <p>&nbsp;</p>
      <p>Anche questa &egrave; rivolta a pazienti molto compromessi e consiste nel recuperare dal vissuto del paziente le esperienze emotivamente piacevoli. Si pu&ograve; fare in piccoli gruppi e sfrutta la tendenza dell'anziano a rievocare il passato.</p>
      <p>Per i pazienti pi&ugrave; gravi la terapia &egrave; denominata Milestone Therapy ed &egrave; incentrata esclusivamente su esperienze di vita positive raccontate in una conversazione guidata da un operatore.</p>
      <p>Con il paziente meno deteriorato o anche con anziani sani potrebbe essere opportuno utilizzare la tecnica di Life Rewiev, che comprende anche il poter parlare dei lati dolorosi e non rielaborati della propria vita.&nbsp;</p>
      <p>&nbsp;</p>
      <h3>La Validation Therapy (Terapia di validazione)</h3>
      <p>&nbsp;</p>
      <p>Sviluppata da Naomi Feil, assistente sociale, tra gli anni Sessanta e Ottanta in una struttura per anziani di Cleveland, negli Stati Uniti, questo metodo trae origine dall'affermazione che ogni essere umano &eacute; prezioso indipendentemente dal disturbo che lo affligge e che esiste una causa dietro il comportamento delle persone anziane disorientate.</p>
      <p>Le cose che non siamo riusciti a fare nell'infanzia, nella giovinezza e nell'età adulta, costituiscono compiti incompleti il cui pensiero può tormentare e accompagnare anche nell’età avanzata, con emozioni e sentimenti che emergono più facilmente nella persona compromessa cognitivamente.</p>
      <p>La Validation Therapy rileva e comprende i contrasti non risolti del passato, dando un significato al presente, riducendo lo stato di tensione emotiva e restituendo al soggetto la sua dignit&agrave;.</p>
      <p>Non si pu&ograve; utilizzare l'introspezione, perch&eacute; l'anziano disorientato non &egrave; pi&ugrave; capace di pensiero logico o di autocritica, ma sa ancora esprimere, a suo modo, la realt&agrave; interiore.</p>
      <p>Non essendo in grado di assimilare nuove informazioni, ristabilisce l'equilibrio richiamando i ricordi antichi e rifugiandosi in essi. Se ad esempio, non sopporta la perdita di una persona cara, nega che sia avvenuta e si comporta di conseguenza.</p>
      <p>L'operatore non avalla queste convinzioni, ma nemmeno le contrasta, si limita a far parlare il paziente per scoprire il perch&eacute; del suo disagio senza mai chiederglielo direttamente. Per questo &egrave; importante che conosca notizie biografiche della persona con cui sta lavorando. Quando poi il paziente sar&agrave; pi&ugrave; tranquillo, potr&agrave; cercare di dirigere la sua attenzione altrove.</p>
      <p>La Validation Therapy pu&ograve; essere applicata individualmente o in gruppi di massimo dieci partecipanti. Naomi Feil prevede una sessione la settimana, l'incontro ha una durata di mezz'ora, un&rsquo;ora, e prevede quattro momenti: dedicati alla musica, al colloquio, all'esercizio motorio, al cibo. I risultati cambiano secondo la gravit&agrave; del paziente.</p>
      <p>Nei casi meno gravi, si ottengono dei miglioramenti che durano a lungo; in generale si pu&ograve; notare una diminuzione delle crisi di pianto, del vagabondaggio e una diminuzione dell'agitazione con conseguente riduzione della terapia sedativa.</p>
      <p>Non appare invece utile per ri-orientare il paziente nella realt&agrave; quotidiana.</p>
      <p>&nbsp;</p>
      <h3>Le Tecniche di rieducazione comportamentale</h3>
      <p>&nbsp;</p>
      <p>I disturbi del comportamento pregiudicano il buon esito dei metodi riabilitativi cognitivi: l'apatia, l'aggressivit&agrave;, la depressione, il delirio, la scarsa consapevolezza di malattia impediscono la collaborazione, requisito fondamentale.</p>
      <p>Tra le strategie maggiormente applicate ricordiamo la cosiddetta Terapia Contestuale o Milieu Therapy, che si propone di migliorare non tanto l'ambiente quanto l'atmosfera che si respira nell'ambiente.</p>
      <p>Si avvale del cosiddetto condizionamento operante, in altre parole premia il paziente ogni qualvolta mostri un comportamento desiderato e non lo premia quando viene prodotto un comportamento indesiderato; un'altra tecnica comportamentale &egrave; l'apprendimento imitativo, che consiste nel proporre l'osservazione dei comportamenti corretti proposti dal riabilitatore stesso in un contesto reale.</p>
      <p>&nbsp;</p>
      <h3>Il Memory Training Plus</h3>
      <p>&nbsp;</p>
      <p>&Egrave; un programma di riabilitazione cognitiva dove l'anziano &egrave; un soggetto attivo della propria riabilitazione.</p>
      <p>Questo metodo ha un aspetto ecologico perch&eacute; propone esercizi che rispecchiano le situazioni quotidiane.</p>
      <p>I partecipanti sono innanzi tutto invitati ad abolire le cattive abitudini, a evitare di ripetere di avere una cattiva memoria, a ricercare uno stato di rilassamento.</p>
      <p>Il programma proposto si basa su esercizi che potenziano la capacit&agrave; di percepire, organizzare, fissare e infine rievocare il materiale appreso.</p>
      <p>&Egrave; rivolto principalmente ad anziani che hanno un punteggio al Mini Mental da 24 a 30, si svolge in gruppo e si fanno esercizi sulla memoria verbale per i nomi (ad esempio insegnando strategie di associazione o di visualizzazione), sull'attenzione uditiva, sulla memoria per i numeri, sulla memoria per i fatti, e sulla memoria verbale di una lista di oggetti, sulla memoria di volti, sulla memoria spaziale, sulla fluenza verbale e sulla logica.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h3>Gli Interventi di stimolazione psico-fisica</h3>
      <p>&nbsp;</p>
      <h4>La terapia psicomotoria</h4>
      <p>&nbsp;</p>
      <p>Per terapia psicomotoria s&rsquo;intende un metodo che usa la mediazione del corpo e del movimento a fini terapeutici.</p>
      <p>Affinch&eacute; l'anziano si riappropri del proprio corpo, si possono mettere in atto varie stimolazioni, quali l'educazione senso-percettiva, l'attuazione di esperienze di orientamento spaziale, la realizzazione di esperienze di orientamento temporale utilizzando esercizi ritmici quali la marcia, l'esecuzione di esercizi di coordinazione oculo-manuale, e tutti quegli esercizi che servono per distinguere un tempo corto da uno lungo, per imparare il rispetto di un ordine prestabilito.{/*  nella sequenza di oggetti. */}&nbsp;</p>
      <p>&nbsp;</p>
      <h4>La terapia occupazionale</h4>
      <p>&nbsp;</p>
      <p>Utilizza l'occupazione come strumento terapeutico e il suo scopo &egrave; duplice: ridurre la disabilit&agrave; conseguente alla malattia invalidante, rieducando il gesto e aiutando a utilizzare strumenti che facilitano le attivit&agrave; della vita quotidiana, oppure ricreando un senso di utilit&agrave; in persone ormai a riposo dal lavoro, stimolando la capacit&agrave; di produrre oggetti o azioni finalizzate. Lo scopo &egrave; di promuovere l&rsquo;autonomia, per quanto possibile, e infondere la stima in se stessi.</p>
      <p>Vediamo la prima forma, vale a dire la terapia occupazionale intesa come riduzione della disabilit&agrave; e recupero.</p>
      <p>Un programma di terapia occupazionale non pu&ograve; prescindere da un'accurata valutazione del paziente, sia per quanto riguarda le attivit&agrave; della vita quotidiana sia per quanto riguarda la valutazione neuropsicologica.</p>
      <p>Il programma deve essere personalizzato.</p>
      <p>Di solito rappresenta una terapia complementare alla riabilitazione motoria individuale o di gruppo, perch&eacute; questa serve a esercitare i movimenti preparatori al gesto.</p>
      <p>Nelle sedute, i pazienti sono allenati ad alzarsi dal letto, oppure da una sedia oppure da terra, a lavarsi, vestirsi, a fare i lavori domestici, a usare i mezzi di trasporto, a scrivere, maneggiare il denaro ecc.</p>
      <p>Per ogni capacit&agrave; sar&agrave; ricercato il massimo grado di autosufficienza, anche con l'utilizzo di ausili.</p>
      <p>Se invece intendiamo la terapia occupazionale come terapia di lavoro possiamo aiutare il paziente a creare oggetti che poi verranno effettivamente utilizzati, ad esempio un grembiule con tasche porta oggetti.</p>
      <p>Si possono creare oggetti artistici, riprendendo, eventualmente con facilitazione, vecchie capacit&agrave; quali la maglia e il cucito. Si pu&ograve; incoraggiare la ripresa di attivit&agrave; artistiche come la pittura, lavorare con il cartone, preparare le decorazioni natalizie.</p>
      <p>&nbsp;</p>
      <h4>La musicoterapia</h4>
      <p>&nbsp;</p>
      <p>Da sempre l&rsquo;uomo produce e ascolta musica, la cui elaborazione coinvolge aree cerebrali piuttosto vaste.</p>
      <p>Utilizzata dalla notte dei tempi, unitamente al canto e alla danza, per entrare in contatto con la dimensione del sacro, rinforzare i legami sociali, accompagnare i momenti salienti della vita umana, era impiegata anche per curare malattie fisiche e mentali.</p>
      <p>La musica influisce sull&rsquo;attivit&agrave; neurofisiologica emotiva e cognitiva ed &egrave; in grado di modificare le connessioni sinaptiche tra diverse reti neurali.</p>
      <p>Nei pazienti con disturbi cognitivi, pu&ograve; migliorare il tono dell&rsquo;umore e ridurre l&rsquo;agitazione, favorendo il rilassamento e permettendo di ridurre o evitare il ricorso alla terapia farmacologica.</p>
      <p>L'ascolto di vecchie melodie familiari pu&ograve; stimolare la memoria remota, ad esempio durante la terapia di reminiscenza o la terapia di orientamento alla realt&agrave;.</p>
      <p>L'impiego di strumenti per la produzione di ritmi e suoni pu&ograve; far parte degli esercizi di psicomotricit&agrave; che allenano le capacit&agrave; prassiche e l'orientamento temporale.</p>
      <p>Non tutte le persone amano lo stesso tipo di musica e non a tutte la musica fa lo stesso effetto: va quindi utilizzata in modo attento e responsabile al fine di evitare effetti dannosi e controproducenti.&nbsp;</p>
      <p>&nbsp;</p>
      <h4>L'animazione</h4>
      <p>&nbsp;</p>
      <p>Il termine &ldquo;animare&rdquo; deriva da anima e si riferisce al &ldquo;soffio vitale&rdquo; che vivacizza e promuove il pensiero e l&rsquo;azione, l&rsquo;intelligenza e l&rsquo;affettivit&agrave;, la vita individuale e collettiva.</p>
      <p>L&rsquo;animazione &egrave; gioco nel suo significato profondo di attivit&agrave; ri&ndash;creativa.&nbsp;</p>
      <p>Basata sull&rsquo;utilizzo di immagini visive, esercizi linguistici, percettivo&ndash;motori e spaziali, musica e danza, laboratori artistici, ha lo scopo di stimolare le capacit&agrave; residue, favorire la socializzazione e la partecipazione attiva, e migliorare l&rsquo;autostima.</p>
      <p>Nella scelta delle attivit&agrave; ludiche, bisogna tener conto delle capacit&agrave; e delle inclinazioni del paziente, precedenti alla malattia, cercando di adattare i giochi alla menomazione avvenuta.</p>
      <p>Se l'anziano fa giochi che non &egrave; in grado di capire, lo si espone a inutili frustrazioni.</p>
      <p>A volte i giochi possono essere realizzati in squadre, stimolando di conseguenza le capacit&agrave; relazionali e il divertimento.</p>
    </>
  ],
  [
    "l-assistenza-al-malato-di-alzheimer",
    <>
      <h2>L&rsquo;assistenza al malato di Alzheimer</h2>
      <p>&nbsp;</p>
      <p>Le demenze sono <em>disturbi cognitivi</em> che colpiscono le funzioni che ci permettono di comprendere, di conoscere e di agire in modo adeguato, come la memoria, il linguaggio, l&rsquo;intelligenza, la capacit&agrave; di pianificazione e di orientamento. All&rsquo;alterazione delle funzioni cognitive possono accompagnarsi disturbi psichici (depressione, allucinazioni, deliri) e disturbi comportamentali (aggressivit&agrave;, agitazione, wandering (vagabondaggio), disinibizione sessuale), che contribuiscono a rendere stressante e faticosa l&rsquo;assistenza.</p>
      <p>Le demenze causano la morte delle cellule cerebrali, i neuroni, e non vanno confuse con i normali processi di invecchiamento, che comportano una perdita di neuroni meno massiccia e invalidante.</p>
      <p>Insorgono in modo lento e insidioso, per questo un cambiamento brusco e improvviso in un assistito che non riconosce le persone, non ricorda, appare disorientato, deve far pensare piuttosto a uno stato confusionale che pu&ograve; essere dovuto a varie cause, tra cui la disidratazione, e richiede un intervento immediato. In casi come questi, la persona pu&ograve; riprendersi e guarire.</p>
      <p>Non cos&igrave; invece nelle demenze irreversibili, la cui forma pi&ugrave; comune &egrave; la malattia di Alzheimer.</p>
      <p>Naturalmente pu&ograve; accadere che una persona colpita da demenza mostri questi improvvisi stati confusionali, non si deve pensare che tutto sia riconducibile alla demenza, e quindi esitare a chiamare il medico. Anche le persone colpite da malattia di Alzheimer, o da demenza vascolare, o da altre forme di demenza, possono disidratarsi, intossicarsi, prendere l&rsquo;influenza.</p>
      <p>Tutto ci&ograve; che si pu&ograve; guarire, deve poter essere guarito.</p>
      <p>Tuttavia, anche quando sappiamo che una malattia &egrave; <em>inguaribile</em>, non pensiamo che sia anche <em>incurabile</em>. Si possono sempre fornire cure.</p>
      <p>Per la malattia di Alzheimer esistono farmaci che rallentano il deterioramento delle funzioni cognitive e farmaci che controllano sintomi quali le allucinazioni e l&rsquo;agitazione, ma la vera cura sono le persone che assistono i dementi e sanno prendersi cura di loro.</p>
      <p>Il principio su cui poggia ogni forma di aiuto &egrave; il riconoscimento dell&rsquo;assistito come persona, prima che come malato, con il suo carattere, i suoi bisogni, la sua storia familiare e sociale. E per conoscere, e farsi conoscere, occorrono tempo e pazienza.</p>
      <p>Inoltre, anche se il comportamento del malato di Alzheimer pu&ograve; ricordarci quello di un bambino piccolo che ha bisogno di tutto, non dobbiamo dimenticare che ogni anziano, sano o malato, non &egrave; un bambino ma un adulto con alle spalle una lunga vita.</p>
      <p>Il cuore di tutte le forme di demenza &egrave; rappresentato dalla compromissione della memoria.</p>
      <p>La memoria &egrave; fondamentale non solo per orientarsi nel tempo e nello spazio. E&rsquo; la base su cui si fonda la nostra identit&agrave;, la capacit&agrave; di fare progetti, di riconoscere le persone care. Qualcuno ha affermato che il wandering delle persone colpite da Alzheimer simboleggia la ricerca della loro mente, di quanto hanno perso di importante e prezioso.</p>
      <p>E&rsquo; fondamentale non rimproverare l&rsquo;anziano se non ricorda che giorno &egrave;, in che mese dell&rsquo;anno siamo, dove si trova il bagno; perch&eacute; non ricorda il nome dell&rsquo;assistente e neppure quello dei figli; perch&eacute; confonde il sapone con gli alimenti commestibili e ripone il pesce surgelato nell&rsquo;armadio.</p>
      <p>Rimproverarlo servirebbe solo ad aumentare la sensazione di smarrimento e inadeguatezza, generando possibili reazioni aggressive.</p>
      <p>La perdita della memoria e il disorientamento vanno affrontati creando una routine, evitando i cambiamenti, utilizzando dei segnali e predisponendo un ambiente sicuro, di cui anche familiari e assistenti fanno parte costituendo un punto di riferimento. Vediamo questi aspetti in dettaglio.</p>
      <p>&nbsp;</p>
      <h3>1. Creare una routine ed evitare i cambiamenti</h3>
      <p>&nbsp;</p>
      <p>Tutti noi sappiamo che le cose fatte sempre nello stesso modo si imprimono pi&ugrave; facilmente nella memoria. Anche se per un soggetto sano tutto questo pu&ograve; essere noioso, a un malato di Alzheimer la routine fornisce un rinforzo costante alle proprie capacit&agrave; vacillanti.</p>
      <p>I cambiamenti di ambiente possono aumentare la confusione.</p>
      <p>Lo stesso pu&ograve; accadere se si rinnova l&rsquo;arredamento o si serve il pranzo in un posto diverso da quello cui la persona &egrave; abituata. In ogni modo, &egrave; molto importante osservare le sue reazioni, perch&eacute; non tutti reagiscono ai cambiamenti allo stesso modo e tanto meno allo stesso modo nelle diverse fasi della malattia. L&rsquo;invito a non portare il malato in vacanza pu&ograve; essere appropriato, ma potrebbe anche accadere che il malato portato nella sua vecchia casa al mare o in montagna reagisca bene e con piacere.</p>
      <p>&nbsp;</p>
      <h3>2. Predisporre un ambiente sicuro</h3>
      <p>&nbsp;</p>
      <p>Vanno tolti gli oggetti pericolosi, nascoste medicine e detersivi, tolte le piante le cui foglie sono velenose (es. gli oleandri).</p>
      <p>Se la persona non si riconosce allo specchio e si spaventa credendo che in casa ci sia un estraneo, &egrave; bene coprire le superfici riflettenti (anche del televisore, se necessario). L&rsquo;illuminazione deve essere buona, perch&eacute; le mezze ombre possono creare illusioni o allucinazioni.&nbsp;</p>
      <p>Si possono usare disegni o segnali colorati per distinguere la cucina dalla stanza o il water dal bidet. A causa di problemi di percezione, l&rsquo;assistito pu&ograve; non distinguere un oggetto dal suo sfondo (pensiamo a una porta tutta bianca su una parete bianca, con la maniglia bianca): se intervenire sui serramenti pu&ograve; essere complicato, si pu&ograve; farlo almeno sugli oggetti di tipo comune come salviette, saponi ecc.</p>
      <p>Naturalmente l&rsquo;ambiente non &egrave; solo quello domestico, &egrave; anche quello rappresentato dal condominio, dai giardini, dalle strade, dove il malato pu&ograve; recarsi fino a quando gli &egrave; possibile uscire da casa, e poich&eacute; questi ambienti non possono essere resi sicuri, &egrave; importante non perderlo di vista e munirlo di un foglietto con nome e indirizzo da tenere in tasca o attaccato ai vestiti, nel caso sfuggisse alla sorveglianza.</p>
      <p>&nbsp;</p>
      <h3>3. Utilizzare la forza della memoria implicita</h3>
      <p>&nbsp;</p>
      <p>Non esiste solo la memoria esplicita, cio&egrave; il ricordo consapevole, ma anche la memoria implicita, inconscia, e su questo possiamo fare leva.</p>
      <p>Per fare un esempio semplice, se chiediamo a un anziano malato di Alzheimer di segnarsi, potrebbe non essere in grado di ricordare come si fa, ma appena portato in chiesa, &egrave; possibile che il gesto gli venga immediato e spontaneo. Allo stesso modo, una malata potrebbe confondersi mentre tenta di spiegare come si fa il sugo, ma riuscire ancora a farlo.</p>
      <p>&nbsp;</p>
      <h3>4. Gestire i disturbi comportamentali e psichici</h3>
      <p>&nbsp;</p>
      <p>E&rsquo; sempre utile osservare attentamente l&rsquo;assistito. Perch&eacute; proprio in quel momento ha manifestato aggressivit&agrave;? Perch&eacute; a una certa ora comincia a camminare su e gi&ugrave; e non vuole saperne di fermarsi? Perch&eacute; si alza cos&igrave; spesso di notte?</p>
      <p>L&rsquo;aggressivit&agrave; &egrave; spesso l&rsquo;unico modo che ha per esprimere il suo disagio, la sua ansia, la sua irritazione per quello che non riesce a fare e per il modo in cui noi non riusciamo ad aiutarlo.</p>
      <p>Un atteggiamento calmo, un tono di voce rassicurate, possono tranquillizzarlo.</p>
      <p>Se necessario, si pu&ograve; uscire dalla stanza e aspettare che si sia calmato, soprattutto se si teme per la propria incolumit&agrave; fisica. Se vuole camminare, &egrave; bene assecondarlo, cercando ogni tanto di farlo sedere e riposare, perch&eacute; l&rsquo;eccessiva stanchezza, anzich&eacute; favorire il riposo notturno, pu&ograve; turbarlo.</p>
      <p>A volte il malato comincia a camminare perch&eacute; ritiene di doversi recare sul posto di lavoro, o dice di voler tornare a casa. Se si alza di notte perch&eacute; crede sia ora di pranzo, si pu&ograve; preparargli qualcosa di molto leggero, fargli compagnia e poi spiegargli che a quell&rsquo;ora tutti dormono e fuori &egrave; ancora notte.</p>
      <p>La confusione tra il giorno e la notte può avere varie cause. E&rsquo; motivo di grave stress per familiari e operatori e la prima causa di ricovero in struttura. È importante verificare la presenza di eventuali elementi di disturbo - camera rumorosa, materasso scomodo, luci inappropriate - e verificare con il medico le conseguenze dell’assunzione di farmaci come i tranquillanti, o l’eventuale presenza di stati depressivi non riconosciuti.</p>
      <p>Nel caso di comportamenti sessuali inappropriati occorre reagire in modo paziente, cercando di distrarre il malato. I familiari possono essere notevolmente imbarazzati per questi atteggiamenti, soprattutto quando un padre rivolge le sue attenzioni a una figlia, ma dobbiamo sempre ricordare che il malato pu&ograve; scambiare la figlia per la propria moglie quando era giovane, non essendo pi&ugrave; in grado di ricordare l&rsquo;aspetto attuale della moglie.</p>
      <p>In tutti i casi, occorre evitare di rimproverarlo, cosa che non farebbe altro che confonderlo e renderlo pi&ugrave; aggressivo.</p>
      <p>Nel caso di allucinazioni o deliri (soprattutto deliri di persecuzione, per i quali l&rsquo;assistito crede che gli altri lo vogliano derubare) &egrave; meglio esprimere la propria comprensione per le emozioni che prova piuttosto che contraddirlo o sgridarlo, e come sempre, cercare di distrarre la sua attenzione.</p>
      <p>Nei casi pi&ugrave; gravi, esistono farmaci che possono controllare questo tipo di fenomeni.</p>
      <p>Per quanto riguarda infine la depressione, che pu&ograve; precedere o accompagnare l&rsquo;esordio della malattia di Alzheimer, non dobbiamo sottovalutare i gravi problemi di autostima e senso di fallimento che affliggono le persone che non riescono pi&ugrave; a compiere le normali attivit&agrave; della vita quotidiana. Oltre all&rsquo;eventuale uso di farmaci antidepressivi, &egrave; importante che l&rsquo;ambiente sia il pi&ugrave; possibile semplice e accessibile, che all&rsquo;assistito sia data la possibilit&agrave; di fare quello che ancora &egrave; in grado, che non siano rilevati i suoi insuccessi ma piuttosto i suoi risultati positivi.</p>
      <p>&nbsp;</p>
      <h3>&nbsp;5. Gestire l&rsquo;alimentazione</h3>
      <p>&nbsp;</p>
      <p>Il momento della colazione e dei pasti &egrave; un momento delicato. E&rsquo; importante eliminare tutti gli stimoli che possono distrarre, evitare quindi di apparecchiare completamente la tavola ma presentare un piatto alla volta con cibi graditi all&rsquo;assistito e porgergli solo la posata che serve in quel momento.</p>
      <p>Se la persona non ricorda come si usa un cucchiaio o una forchetta, ci si pu&ograve; sedere accanto a lui (non di fronte) e mostrargli come si fa. Piuttosto che imboccarlo, &egrave; meglio lasciarlo mangiare con le mani. Ci sono anziani che hanno ripreso a mangiare autonomamente quando la sala da pranzo &egrave; stata arredata con i mobili della loro infanzia.</p>
      <p>Nella loro memoria implicita, l&rsquo;ambiente ha richiamato le esperienze di una volta.</p>
      <p>E&rsquo; importante dare da bere (tutti gli anziani, anche quelli sani, avvertono meno la sete). Un buon rimedio per la stitichezza &egrave; proprio quello di bere molta acqua.</p>
      <p>Il malato di Alzheimer pu&ograve; non sapere pi&ugrave; come ci si deve comportare quando si ha sete (prendere un bicchiere, aprire il rubinetto o la bottiglia, riempire il bicchiere) e agitarsi. Poniamo l&rsquo;accento di nuovo sull&rsquo;importanza dell&rsquo;osservazione, per comprendere il modo in cui l&rsquo;assistito comunica i suoi bisogni.</p>
      <p>&nbsp;</p>
      <h3>6. Gestire l&rsquo;incontinenza e l&rsquo;igiene personale</h3>
      <p>&nbsp;</p>
      <p>L&rsquo;incontinenza pu&ograve; essere dovuta alla malattia che progredisce, ma anche a infezioni curabili, che sarebbe gravemente sbagliato trascurare.</p>
      <p>Pu&ograve; essere utile accompagnare l&rsquo;assistito in bagno a orari fissi, e se si &egrave; bagnato, aiutarlo a lavarsi e cambiarsi senza rimproverarlo. Il bagno deve essere un posto sicuro, semplice, non freddo. Quando si lava un malato di Alzheimer, occorre usare molta attenzione, perch&eacute; l&rsquo;assistito pu&ograve; scambiare il contatto fisico per una forma di aggressione e reagire con pugni o schiaffi.</p>
      <p>E&rsquo; importante spiegare quello che si sta facendo e, se l&rsquo;anziano &egrave; in grado, farlo collaborare. Se non vuole fare il bagno, &egrave; meglio non insistere e aspettare un momento pi&ugrave; propizio. E&rsquo; importante sapere quali possono essere le paure della persona e rispettarle: c&rsquo;&egrave; chi non sopporta di sentire l&rsquo;acqua che cola sulla faccia, o non vuole entrare con la vasca piena d&rsquo;acqua, mentre &egrave; disposto a farlo se l&rsquo;acqua &egrave; aperta successivamente. L&rsquo;assistente pu&ograve; pensare a quali erano le sue reazioni quando da piccola era lavata dai genitori, magari contro la sua volont&agrave;, e comprendere quanto queste reazioni siano amplificate in un adulto che ha bisogno degli altri per un compito cos&igrave; delicato.</p>
      <p>&nbsp;</p>
      <h3>7. Il mantenimento dell&rsquo;attivit&agrave;</h3>
      <p>&nbsp;</p>
      <p>Conoscere l&rsquo;assistito ci d&agrave; la possibilit&agrave; di sapere quali sono le cose che gli piacciono e lo fanno sentire vivo. Pu&ograve; essere l&rsquo;ascolto della musica, la cura dei fiori sul balcone, una passeggiata con i figli, curare i canarini, fare qualche lavoro domestico.&nbsp;</p>
      <p>Stimolare non vuol dire aggredire, e familiari e operatori devono essere cauti e attenti. Se propongono compiti che ai loro occhi sembrano facili ma che per l&rsquo;anziano non lo sono, l&rsquo;insuccesso determiner&agrave; frustrazione e apatia.</p>
      <p>&nbsp;</p>
      <h3>8. Il rapporto tra familiari e operatori</h3>
      <p>&nbsp;</p>
      <p>La collaborazione fra familiari e operatori dell&rsquo;assistenza, la creazione di un ambiente sereno, la giusta stimolazione possono rallentare il decadimento o comunque migliorare la qualit&agrave; di vita della persona malata.</p>
      <p>Non si cura soltanto con quello che si sa o si sa fare, si cura anche con quello che si &egrave;. I familiari possono non accettare, consciamente o inconsciamente, la malattia del loro caro e reagire in modi inappropriati, scaricando il dolore, la frustrazione, i sensi di colpa, sull&rsquo;operatore o sull&rsquo;anziano stesso.</p>
      <p>Un&rsquo;assistente familiare (badante) pu&ograve; sentirsi isolata, imprigionata quasi, con la persona demente, e sentirsi frustrata nei suoi tentativi di fornire aiuto. Pu&ograve; avere conoscenze teoriche e pratiche insufficienti e scoprire a sue spese che non basta il buonsenso per fare un lavoro di cura, ma che sono necessarie buone capacit&agrave; professionali e relazionali che non si improvvisano.</p>
      <p>Gli stessi familiari si trovano a fare i conti con una serie di comportamenti che non comprendono e non riconoscono (&ldquo;Non ha mai fatto cos&igrave;, prima&rdquo;) e con la necessit&agrave; di rivedere i ruoli e ristrutturare le abitudini quotidiane.</p>
      <p>Hanno bisogno di ricevere informazioni e aiuti concreti, e avere la possibilit&agrave; di parlare con altre persone che vivono nella stessa situazione, ad esempio nei gruppi di auto mutuo aiuto</p>
      <p>La solitudine logora: enti pubblici attenti e lungimiranti possono promuovere servizi che aiutino familiari e assistenti a comunicare in modo adeguato e a collaborare, affrontando i problemi da alleati e non da antagonisti, in vista del benessere proprio e di quello dell’assistito.</p>
    </>
  ],
  [
    "il-contesto-relazionale",
    <>
      <h2>L&rsquo;anziano non autosufficiente e il contesto relazionale</h2>
      <p>&nbsp;</p>
      <p>La nostra vita &egrave; vita di relazione. Buona parte della stima in noi stessi e della gioia di vivere dipendono dalla buona qualit&agrave; dei rapporti umani e dalle soddisfazioni che ci danno.</p>
      <p>L'anziano, rispetto al giovane, tende a investire maggiormente nei rapporti affettivi e a idealizzarli, perch&eacute;, ormai lontano dal mondo produttivo, localizza l'attenzione sulle persone amate e d&agrave; significato alla sua vita grazie a queste relazioni.</p>
      <p>Il coniuge, i figli, i nipoti, rappresentano spesso per gli anziani non autosufficienti gli unici rapporti rimasti.</p>
      <p>Non sempre l'assistente entra nella casa di un anziano solo: deve essere quindi in grado di interagire con gli altri familiari.</p>
      <p>Generalmente le aspettative dell'anziano nei confronti del coniuge sono piuttosto elevate e in caso di bisogno sar&agrave; pi&ugrave; difficile per la coppia cercare aiuto al di fuori.&nbsp; Gli assistenti domiciliari sanno che per quanto le coppie anziane siano generalmente di umore migliore rispetto alle persone anziane sole, non &egrave; sempre facile lavorare con loro.</p>
      <p>Il coniuge meno bisognoso &egrave; in genere quello che crea pi&ugrave; difficolt&agrave;, probabilmente perch&eacute; si rende conto di non poter essere di pieno sostegno al partner ammalato. Ci saranno casi in cui l'assistente dovr&agrave; porre limiti precisi al coniuge che vorrebbe costringerlo a operare come il proprio alter ego.</p>
      <p>Se il malato &egrave; il marito, la moglie pu&ograve; mostrarsi scostante e gelosa.&nbsp; Non dobbiamo dimenticare che la coppia ha una lunga storia alle spalle: i comportamenti del partner, anche se insoddisfacenti, erano ormai prevedibili.&nbsp; La malattia turba questo equilibrio e obbliga a rivedere i propri ruoli.</p>
      <p>Vediamo questo esempio.</p>
      <p>&nbsp;</p>
      <p><em>&laquo;La signora D.S. si era lamentata tutta la vita del marito, un uomo dal carattere forte, direttivo, che lasciava poco spazio agli altri membri della famiglia.&nbsp; Quando l'uomo si ammal&ograve;, rimanendo gravemente invalido, la signora si sent&igrave; persa: non sapeva come amministrare le finanze della casa, aveva difficolt&agrave; perfino ad andare in banca.&nbsp; Cominci&ograve; ad assillare l'unica figlia rimasta in casa perch&eacute; assumesse le responsabilit&agrave; che aveva avuto il marito, che pur essendo autoritario e poco affettuoso le aveva sempre garantito una sicurezza cui aveva dovuto improvvisamente rinunciare&raquo;.</em></p>
      <p>&nbsp;</p>
      <p>Per la famiglia, la tentazione di trattare la malattia cronica come un'emergenza - un episodio che non &egrave; necessario integrare nella vita quotidiana - pu&ograve; essere forte.</p>
      <p>Come scrive la psicologa clinica Brigitte Camdessus nel suo ottimo libro <em>&laquo;I nostri genitori invecchiano&raquo;</em>, l'emergenza non richiede un cambiamento, ma solo un grosso impegno, una mobilitazione straordinaria ma temporanea delle risorse.</p>
      <p>La crisi, al contrario dell'emergenza, investe direttamente i rapporti familiari cos&igrave; come si sono consolidati in anni di vita, e richiede un cambiamento che senza abbandonare del tutto i vecchi modelli sappia costruire nuove risposte e nuovi comportamenti, pi&ugrave; funzionali.</p>
      <p>Quando affrontare i cambiamenti &egrave; troppo penoso, si cerca di trasformare la crisi (tipica dei sistemi viventi) in avaria (tipica dei sistemi meccanici): &laquo;E&rsquo; lui il malato.&nbsp; Lo curi.&nbsp; Lo rimetta a posto, cos&igrave; noi - sottinteso - possiamo continuare a vivere come abbiamo fatto finora&raquo;.</p>
      <p>Il momento della crisi pu&ograve; essere pesante da gestire, ma gli esseri umani non sono sistemi meccanici, dove basta cambiare un pezzo per rimediare a un guasto.</p>
      <p>La crisi fa parte della vita, &egrave; tipica dei sistemi viventi e dunque anche della famiglia, e per risolverla ogni componente dovr&agrave; cambiare qualcosa di s&eacute; o del suo rapporto con gli altri.</p>
      <p>La prima cosa da fare, in tempo di crisi, &egrave; <strong>identificare il problema</strong>, cosa non facile dato il carico di angoscia, sconvolgimento, sensi di colpa che la famiglia esprime.</p>
      <p>Anche se non riconoscere i propri sentimenti porta spesso ad ammalarsi o avere degli incidenti, negli anziani non &egrave; generalmente la trasparenza che prevale (soprattutto se si tratta di conflitti di coppia) ma la riservatezza.</p>
      <p>Quando i coniugi non parlano apertamente, i conflitti restano latenti o scoppiano in modo cos&igrave; improvviso da destare lo sconcerto dei figli e lo stupore degli estranei.</p>
      <p>Nei momenti di crisi, &egrave; difficile tener conto delle esigenze di tutti e il normale ciclo di vita della famiglia pu&ograve; esserne alterato.</p>
      <p>La famiglia non &egrave; qualcosa di statico, dato in modo definitivo, ed evolve nel tempo.</p>
      <p>Le tappe fondamentali, che richiedono cambiamenti di ruolo e nuovi equilibri, partono dalla costituzione della coppia, che dovrebbe maturare la propria identit&agrave; creando un legame basato sulla condivisione e la fiducia. Nel corso del tempo la coppia dovrebbe poter creare il proprio stile emancipandosi dalla famiglia di origine e creando qualcosa di nuovo.</p>
      <p>Se in luogo dell&rsquo;empatia (la capacit&agrave; di mettersi nei panni dell&rsquo;altro e di accettare le differenze) prevale il narcisismo, i componenti della coppia vedranno il partner in funzione di s&eacute; e dei propri bisogni e difficilmente riusciranno a mettere in gioco un&rsquo;affettivit&agrave; sana in tempo di crisi.</p>
      <p>La seconda tappa &egrave; rappresentata dalla nascita dei figli, dove al ruolo di coniuge si somma quello di genitore.</p>
      <p>La nascita di un figlio pu&ograve; portare all&rsquo;evoluzione e al rafforzamento del sistema familiare come alla sua rottura se la coppia non &egrave; in grado di svolgere adeguatamente entrambi i ruoli, oppure da una confusione generazionale per cui i figli sono investiti di ruoli parentali &ndash; in cui sono loro a dover offrire protezione e riconoscimento - perch&eacute; gli adulti non sono in grado di svolgere i compiti propri della loro et&agrave;.</p>
      <p>Se invece questa tappa &egrave; superata positivamente, i genitori possono a questo punto della vita capire meglio i propri genitori, perdonare i loro errori e porsi su un piano paritario; in caso di blocco evolutivo, possono delegare ai nonni l&rsquo;educazione dei piccoli, mantenendo un esclusivo ruolo filiale.</p>
      <p>Anche rispettare i confini posti dai figli ormai adulti e al tempo stesso aiutarli, richiede agli anziani molta sensibilit&agrave;.</p>
      <p>Una terza tappa &egrave; rappresentata dal progressivo svincolo (in pratica l'uscita dalla famiglia) dei figli grandi.</p>
      <p>Alla presenza di un grave stress, quale la malattia di un genitore, la normale evoluzione della famiglia pu&ograve; essere bloccata e le esigenze dei singoli rimosse per affrontare la nuova situazione che in qualche caso diventa drammatica.</p>
      <p>&nbsp;</p>
      <p><em>&laquo;La signora R.M., ultima di parecchie figlie, doveva continuamente rimandare il matrimonio perch&eacute; la madre subiva periodici attacchi di cuore all'approssimarsi della data delle nozze.&nbsp; Per quanto non fosse figlia unica, era considerata un po' l"'ultima fiamma" dai genitori.&nbsp; Con la sua malattia, la madre comunicava il dolore per la perdita del ruolo materno, che concepiva evidentemente come legame esclusivo. La situazione fu risolta dalle figlie maggiori, che si incaricarono dell'assistenza alla madre invitando esplicitamente la sorella a continuare i preparativi per le nozze&raquo;.</em></p>
      <p>&nbsp;</p>
      <p>Vedere i propri figli che costituiscono nuove coppie, che hanno figli, e vedere i propri genitori entrare in un&rsquo;et&agrave; in cui hanno bisogno di assistenza, essere insomma &ldquo;fra pediatria e geriatria&rdquo;, &egrave; un&rsquo;altra, delicatissima tappa.</p>
      <p>Essere genitori dei propri anziani genitori &egrave; un impegno emotivamente stressante, anche se non &egrave; detto che debba essere per forza faticoso e privo di gratificazioni.</p>
      <p>Da un lato pu&ograve; essere difficile, per i figli, accettare che i propri genitori siano diventati tanto bisognosi e dipendenti.</p>
      <p>I sentimenti che provano sono spesso un misto di tristezza, risentimento, disagio, nostalgia, senso di colpa o inadeguatezza.&nbsp;</p>
      <p>A volte, sembrano alla ricerca di un contraccambio impossibile (&laquo;Sono i miei genitori, mi hanno dato la vita&raquo;) e non accettano i propri sentimenti negativi.</p>
      <p>Dall'altro lato i genitori potrebbero non avere mai superato quella fase cruciale che &egrave; lo svincolo, l'indipendenza dei figli, e tentano, con le loro pretese, di ricreare la famiglia com'era prima.&nbsp;</p>
      <p>L'assistente domiciliare a volte si stupisce vedendo che il figlio pi&ugrave; premuroso sia anche quello meno stimato e rispettato, mentre i figli che stabiliscono chiari limiti alla loro disponibilit&agrave;, siano quelli pi&ugrave; apprezzati nei discorsi con i parenti o con gli amici.</p>
      <p>Evidentemente &egrave; pi&ugrave; facile tenere in considerazione una persona che si differenzia chiaramente, che fa pesare le proprie esigenze individuali, che non un figlio vissuto come un prolungamento di se stessi.</p>
      <p>Il figlio cui l&rsquo;anziano genitore chiede pi&ugrave; aiuto non &egrave; detto sia quello &ldquo;preferito&rdquo;. A volte si chiede aiuto a un figlio per lasciar maggiormente liberi gli altri.</p>
      <p>Una coppia madre-figlia pu&ograve; mantenere un rapporto reciproco fortissimo che esclude interventi esterni anche quando ce ne sarebbe bisogno. Niente riesce a convincere la figlia a riposarsi o a coltivare qualche suo interesse.&nbsp; In questi casi, l'autonomia e l'espressione dei propri sentimenti, soprattutto quelli negativi, sono vissute come un attentato all'unit&agrave; e alla coesione della coppia.&nbsp;</p>
      <p>In situazioni simili, &egrave; opportuno notare che se la figlia si ammala, l'anziana si trover&agrave; ben pi&ugrave; sola e triste che non se l&rsquo;avesse incoraggiata ad andare al cinema con gli amici o a fare una breve vacanza.&nbsp;</p>
      <p>Invitare a prendersi cura di s&eacute; per curare meglio l'anziano non &egrave; un trucco per costringere la figlia a staccarsi dalla madre: risponde alla realt&agrave;.</p>
      <p>Infatti, una persona sana, per quanto disponibile, non pu&ograve; vivere come una persona non autosufficiente; i suoi orizzonti non possono essere gli stessi, pena un blocco nella propria evoluzione personale.</p>
      <p>Inoltre, la figlia vivr&agrave; presumibilmente pi&ugrave; a lungo della madre: non sar&agrave; facile recuperare un altro stile di vita, dopo aver assunto per anni il ruolo di curante.</p>
      <p>Naturalmente pu&ograve; verificarsi il caso opposto, quando i familiari &laquo;scaricano&raquo; l'anziano sull'assistente domiciliare coinvolgendosi il meno possibile.</p>
      <p>Ci sono figli di cui si scopre l&rsquo;esistenza solo al momento dei funerali. Tuttavia &egrave; bene evitare qualunque giudizio. Gli operatori generalmente non sanno come si sono sviluppati i rapporti attraverso le tappe che caratterizzano il ciclo di vita della famiglia: ogni tappa potrebbe essere stata superata con successo o aver creato blocchi e regressioni.</p>
      <p>Per finire questa parte riservata ai rapporti familiari, ricordiamo che quando si parla di assistenza domiciliare si tende a pensare che in una famiglia con un anziano non autosufficiente il centro dell'attenzione debba essere l'anziano stesso.</p>
      <p>Spesso non si pensa che anche la famiglia necessiti sostegno, perch&eacute; sopporta un pesante carico fisico e psichico.</p>
      <p>Quando la coppia anziana &egrave; in buona salute ma i rapporti familiari sono cattivi, si tende a pensare che &egrave; inutile intervenire, visto che tanto l'anziano non cambier&agrave; mai e non ha neppure tanto tempo davanti a s&eacute;.</p>
      <p>Sempre Brigitte Camdessus, invece, ci ricorda nel suo libro che esistono importanti motivi per risolvere i problemi relazionali delle ultime fasi della vita, anche per il bene delle generazioni pi&ugrave; giovani.</p>
      <p>Le tensioni, soprattutto in una coppia anziana, possono tradursi in malattie o comunque in una diminuita autosufficienza e quindi in un aumento di richieste che possono stancare e irritare i figli.</p>
      <p>Le famiglie che richiedono l'assistenza domiciliare per un membro anziano portano come problema frequente la difficolt&agrave; di adattamento alla nuova situazione.</p>
      <p>Sentono la necessit&agrave; di parlarne non solo per sfogarsi, ma anche per avere indicazioni su come comportarsi con i figli adolescenti che mal sopportano la presenza &laquo;ingombrante&raquo; del nonno malato, o con il coniuge irritato perch&eacute; la moglie presta pi&ugrave; attenzione all'anziana madre che non a lui o con il giovane adulto che vorrebbe staccarsi dalla famiglia ma non riesce perch&eacute; la madre non potrebbe farcela da sola col marito allettato.</p>
      <p>Di l&agrave; dell&rsquo;ascolto specialistico, che in casi come questi pu&ograve; offrire uno psicologo, gli assistenti domiciliari si trovano spesso ad ascoltare gli sfoghi e le frustrazioni dell'anziano o dei suoi familiari.</p>
      <p>Che fare, ad esempio, quando si scambiano accuse pi&ugrave; o meno fondate? E&rsquo; essenziale non parteggiare n&eacute; per l'uno n&eacute; per l'altro.&nbsp; Le &laquo;alleanze&raquo; sono presenti generalmente contro un singolo membro (&laquo;la pecora nera&raquo;) o contro un sottogruppo familiare (i figli che solidarizzano fra loro escludendo i genitori) tuttavia l'assistente ha tutto l'interesse a mantenersi fuori da queste dinamiche: innanzitutto non ha quasi mai delle conoscenze approfondite sulla storia del suo assistito, conosce poco anche i figli o i parenti; in secondo luogo non rende un buon servizio all'anziano accentuando quelli che sono sentimenti negativi, indici di una sofferenza reale.</p>
      <p>&Egrave; importante non cadere nella trappola di un coinvolgimento eccessivo o di un'incontrollabile ansia di dare consigli e proporre soluzioni.</p>
      <p>Tutti noi sappiamo che a volte diciamo una cosa per significarne un'altra: se l'anziana si lamenta del comportamento della figlia, siamo proprio sicuri che sia quello il problema?&nbsp;</p>
      <p>Se l'anziano comincia a elencare tutti i suoi mali quando l'operatrice &egrave; pronta per andarsene, siamo certi che &egrave; veramente dei suoi mali che si preoccupa?</p>
      <p>Ecco perch&eacute; &egrave; importante non farsi prendere dalla fretta di consolare o di sdrammatizzare: meglio darsi un po' di tempo per capire meglio la persona che abbiamo davanti.</p>
      <p>Se parliamo una lingua diversa dalla sua, non riusciremo a fargli accettare il minimo cambiamento.</p>
      <p>Ognuno parte dalla convinzione che la &laquo;logica&raquo; &egrave; una sola, la propria e che tutte le persone di buonsenso non possono che essere d'accordo: l'esperienza insegna per&ograve; che questa umanissima tentazione va tenuta a freno, se non si vuole correre il rischio di &laquo;scegliere sempre se stessi&raquo;.</p>
      <p>Vediamo questi altri esempi.</p>
      <p>&nbsp;</p>
      <p><em>Il signor V.R. chiedeva ore di assistenza anche la domenica, per portar fuori il padre, e fu molto seccato di sentirsi dire dal responsabile della cooperativa che poteva occuparsene lui stesso. L'Assistente che andava tutti i giorni della settimana, rifer&igrave; che il figlio non riusciva ad assumersi questo compito, perch&eacute; non voleva interpretare un ruolo genitoriale nei confronti del proprio padre.&nbsp; Tutti i tentativi fatti per aiutarlo ad accettare l'idea che il padre era avviato a una condizione sempre pi&ugrave; grave di non autosufficienza, non riuscirono a smuoverlo.&nbsp; Trov&ograve; lui stesso la soluzione assumendo una badante che fosse presente 24 ore su 24 in casa, in modo da evitargli un coinvolgimento che sentiva come insopportabile&raquo;.</em></p>
      <p>&nbsp;</p>
      <p><em>&laquo;La nuora della signora E.F. prendeva spesso da parte l'Assistente per dirle: "Deve convincere mia suocera ad andare in casa di riposo".&nbsp; Lei, non sapendo cosa rispondere (era alla sua prima esperienza) e temendo che la nuora alla fine dicesse alla signora "Lo dice anche l'assistente che devi andare in casa di riposo!" pens&ograve; di parlarne alla signora stessa per rassicurarla circa il suo desiderio che lei restasse dov'era.&nbsp; L'anziana non disse nulla.&nbsp; Appena la nuora entr&ograve; in camera, le scagli&ograve; contro la sveglia che teneva sul comodino&raquo;.</em></p>
      <p>&nbsp;</p>
      <p>Gettare benzina sul fuoco, colpevolizzare i familiari o farsi invischiare nei problemi di rapporto tra loro, mettersi a discutere prima di aver circoscritto chiaramente il problema, pensare che occorra &laquo;cambiar loro la testa&raquo;, sono da evitare.</p>
      <p>Si ottiene di pi&ugrave; riconoscendo e accogliendo i sentimenti degli altri.&nbsp; Dire a un anziano &laquo;Capisco che lei sia triste&raquo; non significa aggiungere &laquo;perch&eacute; suo figlio si comporta da schifo&raquo;, anche se &egrave; vero.&nbsp; Evitare di parlare male degli altri e trovare loro una giustificazione, senza per questo chiudere gli occhi di fronte alla realt&agrave;, contribuisce a dare di s&eacute; un'immagine comprensiva e tollerante. &nbsp;</p>
      <p>Non serve a nulla rendere l'anziano pi&ugrave; astioso e avvilito di quanto non sia gi&agrave;.&nbsp; Oltretutto si rischia di essere zittiti, perch&eacute; l'anziano pu&ograve; sentirsi il solo autorizzato a parlar male dei figli.</p>
      <p>Lo stesso vale quando l'anziano appare insensibile e insaziabile nelle sue richieste, come nel caso che segue.</p>
      <p>&nbsp;</p>
      <p><em>&laquo;La signora F.F. si lamentava perch&eacute; la figlia sposata veniva a trovarla raramente.&nbsp; L'assistente scopr&igrave; che quel "raramente" significava "una volta al giorno".&nbsp; Anzich&eacute; far notare alla signora che le sue pretese erano eccessive, le disse che non era facile per la figlia occuparsi di lei e della sua attuale famiglia e che nessuno come lei, essendo madre, poteva sapere quanta fatica ci vuole per allevare bene i figli.&nbsp; La signora, da allora, cominci&ograve; a lamentarsi meno&raquo;.</em></p>
      <p>&nbsp;</p>
      <p>Pu&ograve; anche accadere che l&rsquo;assistente domiciliare, vedendo che l'anziano non osa chiedere certe cose ai figli, voglia aiutarlo facendosi suo portavoce presso di loro.</p>
      <p>A volte la cosa ha successo, a volte l'anziano nega tutto dicendo che sono idee dell'assistente, che non solo ci resta male, ma comincia a dubitare di quali siano gli effettivi bisogni dell'anziano.</p>
      <p>Quando l'anziano teme di comunicare le sue esigenze, bisogna in primo luogo cercare di capire che cosa lo frena.</p>
      <p>A volte sono preoccupazioni senza fondamento, a volte &egrave; la paura di mostrarsi troppo bisognoso, a volte i familiari sono davvero poco sensibili e non lo ascolterebbero.</p>
      <p>Se la cosa &egrave; importante, &egrave; meglio non dire: "Guardate che vostro padre o vostra madre, vorrebbe cos&igrave; e cos&agrave;".&nbsp; Dire piuttosto che noi riteniamo utile fare cos&igrave; e cos&agrave; e spiegare perch&eacute;.</p>
      <p>Oppure aspettare e incoraggiare l'anziano a parlare in prima persona.</p>
      <p>&nbsp;</p>
      <p>Oltre alla famiglia, l&rsquo;anziano pu&ograve; avere parenti e amici con cui &egrave; in buoni rapporti.</p>
      <p>I coetanei, a qualunque et&agrave;, sono risorse preziose. I vecchi amici rappresentano per l'anziano persone con le quali ha condiviso almeno mezzo secolo di vita e che hanno, come lui, i problemi tipici della vecchiaia.</p>
      <p>A volte, i rapporti di parentela hanno l'aspetto di rapporti amicali: l'altro &egrave; stato &laquo;scelto&raquo;, per affinit&agrave; e simpatia, di l&agrave; del fatto che esistano legami di sangue.&nbsp; Per molti anziani, che non possono pi&ugrave; uscire da casa, le visite di parenti e amici possono essere un momento importante per continuare a mantenere un contatto con il mondo esterno.</p>
      <p>In genere sono momenti significativi anche per i familiari.</p>
      <p>Stare con persone a cui si vuole bene significa ottenere sicurezza, affetto, stima, tutte cose indispensabili per la propria salute mentale.</p>
      <p>Inoltre, gli altri sono una grossa fonte di stimolazione cognitiva.</p>
      <p>Con il pensionamento e il ritiro dall'attivit&agrave; produttiva, pu&ograve; generarsi una certa caduta dei rapporti sociali.</p>
      <p>Anche se poco soddisfacente, il lavoro portava fuori, a contatto con il mondo; imponeva un certo ritmo e contribuiva essenzialmente all'immagine adulta di s&eacute;.</p>
      <p>Il pensionato si addentra in un territorio nuovo, dove al sollievo per il meritato riposo si mischiano spesso il rimpianto, la tristezza, la noia.&nbsp; Finch&eacute; l'anziano &egrave; in buona salute, ha la possibilit&agrave; di ricreare un ritmo personale che dia sapore alle sue giornate, purch&eacute; mantenga l'iniziativa (farsi aiutare va bene solo quando non spinge a invecchiare pi&ugrave; velocemente) e purch&eacute; sappia mantenere una certa elasticit&agrave;: l'anziano che annoia tutti con le sue lamentele, o parla solo di soldi, malattie o dei suoi adorati nipoti, non incoraggia gli altri a stargli vicino.</p>
      <p>Quando la salute viene a mancare, il mondo si restringe alla sola casa.</p>
      <p>Per questo accompagnare fuori l'anziano, fargli frequentare un centro ricreativo o una palestra, non &egrave; meno importante che preparargli il pasto.&nbsp; Occorre per&ograve; ricordare che l'anziano si adatta meno facilmente e che essendo maggiormente concentrato su di s&eacute;, non dimostra sempre la comprensione necessaria nei rapporti umani.</p>
      <p>L'anziano pu&ograve; percepire negativamente gli altri anziani: &egrave; tipico il caso del novantenne che dice &laquo;Non voglio andare all'ospizio, in mezzo a tutti quei vecchi&raquo;.</p>
      <p>I coetanei riflettono in qualche misura la propria immagine: se si &egrave; invecchiati insieme, sar&agrave; meno doloroso riconoscersi.</p>
      <p>Quando un anziano parla della sua solitudine, attenzione a non buttarsi immediatamente sulle soluzioni che sembrano pi&ugrave; congeniali: l'anziano pu&ograve; non avere nessuna voglia di aprire la propria casa agli estranei, per quanto animati da buone intenzioni, e quando succede pu&ograve; mostrarsi indifferente o addirittura scostante.</p>
      <p>Forse, quello che l'anziano desidera, non &egrave; la compagnia di persone sconosciute, anche se attente e sensibili come i volontari che fanno capo a varie associazioni, ma del coniuge che non c'&egrave; pi&ugrave;, dei figli che sono lontani, degli amici che se ne sono andati a uno a uno prima di lui; solitudine pu&ograve; anche voler dire vivere senza la considerazione altrui, prima di tutto quella dei familiari.</p>
      <p>Ogni persona &egrave; diversa dalle altre: anche gli anziani non autosufficienti, cos&igrave; apparentemente uguali nel loro bisogno di assistenza quotidiana, sono individui con il loro carattere e la loro personalit&agrave;.&nbsp; Vedere riconosciuta la propria unicit&agrave; e la propria dignit&agrave; &egrave; il primo rimedio alla sensazione di essere ormai &laquo;tagliati fuori&raquo;, nel momento in cui la scarsa salute e l'avvicinarsi della morte diminuiscono drammaticamente l'anziano ai suoi stessi occhi.</p>
      <p>Naturalmente, pu&ograve; accadere che un anziano fragile sia aiutato anche da volontari e vicini di casa.</p>
      <p>A volte sono i vicini o i volontari che segnalano ai servizi sociali la necessit&agrave; di un'assistenza domiciliare. Anche se l'anziano &egrave; solo, senza parenti, fa comunque parte di un sistema in cui vanno considerate tutte le parti in causa: assistente, volontari, medico di famiglia, vicino di casa, eccetera.</p>
      <p>Possiamo sentirci perplessi a usare il termine famiglia, ma &egrave; indubbio che tra i membri di questo gruppo si creino rapporti tesi o cordiali in modo non troppo diverso da come avviene tra membri di un gruppo familiare.</p>
      <p>&Egrave; possibile che vicini e volontari cerchino di controllare gli assistenti domiciliari, dicendo loro che cosa devono fare, verificando se rispettano l'orario, valutandone il lavoro, eccetera.</p>
      <p>Senza volerlo, possono compromettere il servizio stesso.</p>
      <p>Ognuno ha le sue idee su quello che serve o non serve all'anziano, su quello di cui ha bisogno e su quello che gli si deve concedere.</p>
      <p>Tutti partono dal presupposto di volere soltanto il suo bene.</p>
      <p>Quando i componenti del gruppo non concordano su quale sia davvero il &laquo;bene&raquo; dell&rsquo;anziano, si possono creare contrasti alimentati dalla convinzione che l'altro sia in cattiva fede, che non abbia capito nulla, che occorra sorvegliarlo da vicino perch&eacute; non provochi guai.</p>
      <p>E&rsquo; raro che le persone comunichino sui contenuti: pi&ugrave; i rapporti si inaspriscono, pi&ugrave; ogni argomento, ogni cosa che succede, diventano strumenti in una battaglia per decidere chi ha il potere di governare la relazione.</p>
      <p>Il rischio &egrave; di instaurare una spirale di accuse senza fine: il volontario, visto dall'assistente, &egrave; quello che &laquo;non si sporca le mani&raquo; ma sceglie di fare quello che gli &egrave; pi&ugrave; gradito; l'assistente, vista dal volontario, &egrave; una persona pagata, che non si dedica agli altri spinta dall'amore e dall'altruismo.</p>
      <p>Ognuna delle parti squalifica l'altra, e il grande dimenticato &egrave; proprio l'anziano che tutti vorrebbero al centro dell'attenzione.</p>
      <p>Lasciando per un momento in disparte le buone intenzioni, &egrave; fondamentale osservare i risultati concreti del proprio comportamento: se il volontario asseconda l'anziano nelle sue critiche all'assistente, forse pensa di rendergli un buon servizio, e non si accorge che cos&igrave; non fa altro che rendere pi&ugrave; tesi i rapporti tra i due.</p>
      <p>E&rsquo; il caso allora che rifletta sul suo atteggiamento. Se l'assistente se ne va, non sar&agrave; il volontario, ma l'anziano, a riportarne un danno.</p>
      <p>Lo stesso discorso vale per l'assistente: l'anziano non &egrave; &laquo;suo&raquo;, &egrave; libero di accogliere chi vuole nella sua casa e di accordare a chi vuole la propria confidenza.</p>
      <p>Vivere una vita ricca di affetti non toglie nulla all&rsquo;importanza del singolo, semmai lo valorizza nella sua realt&agrave; e questo &egrave; particolarmente vero quando si tratta di rapporti lavorativi e professionali, a cui nuoce sempre la confusione con il ruolo di parente e amico.</p>
    </>
  ],
  [
    "l-auto-mutuo-aiuto",
    <>
      <h2>L&rsquo;auto mutuo aiuto</h2>
      <p>&nbsp;</p>
      <p><em>Sono umano. Nulla di ci&ograve; che &egrave; umano mi &egrave; estraneo(*)</em></p>
      <p>Publio Terenzio Afro</p>
      <p>&nbsp;</p>
      <p><em>(*) La celeberrima&nbsp;frase di Terenzio, che in latino &egrave; <strong>"homo sum: humani nihil a me alienum puto"</strong> si trova nella commedia <strong>Heautontimor&uacute;menos</strong> (atto primo, scena prima,&nbsp;v.77), titolo greco translitterato in latino, che significa "Il punitore di se stesso".</em></p>
      <p><em>Si tratta di un padre che nel suo podere si sottopone a&nbsp;lavori estenuanti per punirsi di avere indotto, con la sua eccessiva severit&agrave;, il figlio ad allontanarsi da casa. Nella scena iniziale il vicino di casa gli chiede il perch&eacute; del suo comportamento e della sua angoscia, motivando il proprio interessamento con l'affermazione di cui sopra.</em></p>
      <p><em>La frase sintetizza il concetto di <strong>humanitas</strong>, cio&egrave;&nbsp;solidariet&agrave; umana, tolleranza etica e culturale, che nel II sec. a.C. si diffonde a Roma attraverso il circolo degli Scipioni (Terenzio [190-159]&nbsp;era amico di Scipione Emiliano), proponendo comportamenti e atteggiamenti di comprensione universale&nbsp;al di l&agrave; dei&nbsp;limiti allora angusti della tradizione romana.</em></p>
      <p><em>Il concetto &egrave; di matrice greca: <strong>humanitas</strong> corrisponde al termine greco&nbsp;<strong>philanthrop&iacute;a</strong>;</em> <em>ne</em>&nbsp;<em>&egrave; permeata l'opera del commediografo Menandro (342-291 a.C.), a cui Terenzio si ispira, anzi, &egrave; probabile che la frase si trovasse gi&agrave; in una commedia di Menandro non giunta fino a noi.</em></p>
      <p><em>(Prof.ssa Giuliana Boirivant)</em></p>
      <p>&nbsp;</p>
      <h3>Un po&rsquo; di storia</h3>
      <p>&nbsp;</p>
      <p>L&rsquo;uomo e la donna sono esseri sociali. Il loro cervello si &egrave; evoluto in un contesto di gruppo e la necessit&agrave; di comunicare con i membri della stessa specie ha influito sull&rsquo;evoluzione della corteccia cerebrale, non meno (e forse pi&ugrave;) dell&rsquo;utilizzo di strumenti.</p>
      <p>Il primo gruppo di riferimento &egrave; la famiglia, ma la famiglia nucleare umana non &egrave; e non &egrave; mai stata autosufficiente, essendo da sempre inserita in un contesto di rapporti pi&ugrave; vasto.</p>
      <p>Sentirsi parte di una comunit&agrave;, piccola o grande, assicura protezione e se i rapporti al suo interno sono basati sulla comunicazione ecologica - cioè su uno scambio che favorisce la crescita di tutti i componenti senza che una parte si avvantaggi a danno di altre - sono promossi il radicamento, il senso di appartenenza, la cooperazione e il benessere individuale.</p>
      <p>Nelle nostre societ&agrave; complesse, esistono molte forme di aggregazione: quello di cui parliamo &egrave; una forma particolare, <strong>il gruppo di auto mutuo aiuto</strong>, che esercita in forme nuove il sostegno, il riconoscimento e la solidariet&agrave; una volta appannaggio della famiglia allargata e del vicinato.</p>
      <p>Tutti abbiamo sentito parlare di <em>auto aiuto</em>, <em>mutuo aiuto</em>, <em>auto mutuo aiuto</em>, termini che solitamente sono considerati sinonimi.</p>
      <p>Il termine <em>self&nbsp; help</em>, molto in voga negli anni Settanta, definiti il <em>Decennio dell&rsquo;auto aiuto</em>, risale a met&agrave; dell&rsquo;Ottocento, quando lo scrittore e politico scozzese Samuel Smiles pubblic&ograve; un libro intitolato <em>Self help</em>, che ebbe un grandissimo successo, anche in Italia.</p>
      <p>Lo scopo del libro era quello di spronare i giovani di umili origine a migliorare la propria condizione, dimostrando come fosse possibile &ldquo;aiutare se stessi&rdquo; grazie all&rsquo;impegno e alla forza di volont&agrave;. Il libro fu tradotto in Italia col titolo significativo <em>Chi si aiuta, Dio l&rsquo;aiuta</em> e ispir&ograve; il libro del medico Michele Lessona <em>Volere &egrave; potere</em> che conteneva la biografia di uomini illustri italiani.</p>
      <p>Come si pu&ograve; capire, nel concetto di <em>auto aiuto</em> la lente d&rsquo;ingrandimento &egrave; puntata sul processo individuale di crescita e realizzazione personale.</p>
      <p>Nei primi del Novecento, Petr Kropotkin, filosofo anarchico russo in esilio in Inghilterra, scrisse un libro intitolato <em>Mutual Aid</em>, (mutuo appoggio)&nbsp; nel quale sosteneva che la specie umana si &egrave; evoluta in quanto specie sociale in grado di aiutarsi e sostenersi vicendevolmente.</p>
      <p>Proprio in quell&rsquo;epoca, per fronteggiare i mutamenti determinati dal processo di industrializzazione, nascono in Europa e negli Stati Uniti le Societ&agrave; di mutuo soccorso, i sindacati, le cooperative che operano in favore dei lavoratori, dei disoccupati, degli immigrati.</p>
      <p>Aiutare se stessi e aiutare gli altri diventa un processo inseparabile, e dagli anni Trenta del secolo scorso l&rsquo;auto mutuo aiuto anche un vero e proprio metodo di lavoro utilizzato nei casi di marginalit&agrave; sociale. Poco prima della Seconda Guerra mondiale,&nbsp; nascono gli Alcolisti Anonimi e da allora i gruppi di auto mutuo aiuto si sono diffusi estendendosi anche ad altre condizioni e problematiche.</p>
      <p>In Italia siamo passati dall&rsquo;avere 1603 gruppi censiti nel 1999, a oltre 8000 nel 2012.</p>
      <p>La cifra pu&ograve; colpirci, ma nel rapporto 2007 della Fondazione Andrea Devoto era riportato che in Germania esistevano pi&ugrave; di 70.00 gruppi di auto mutuo aiuto, riconosciuti e finanziati dal sistema sanitario pubblico, e a tutt&rsquo;oggi il numero supera abbondantemente il centinaio di migliaio.</p>
      <p>Ormai, le problematiche affrontate non sono pi&ugrave; solo quelle dell&rsquo;alcolismo e del disagio mentale, ma anche quelli della violenza su donne e minori, della gestione di malattie croniche come il diabete, dell&rsquo;attraversamento di particolari fasi della vita, come il pensionamento, la vedovanza o l&rsquo;adozione e l&rsquo;affido.</p>
      <p>I gruppi rivolti a familiari di anziani non autosufficienti si sono sviluppati dalla seconda met&agrave; degli anni Novanta, evidenziando i problemi legati alla presenza (o assenza) di assistenza adeguata e al bisogno da parte delle famiglie di essere supportate nel lavoro di cura.</p>
      <p>Attualmente si stanno sviluppando delle communities on line, formate da persone che scambiano le proprie esperienze e si aiutano attraverso Internet. E&rsquo; un fenomeno ancora poco studiato ma sicuramente interessante, poich&eacute; apre possibilit&agrave; di partecipazione anche a persone che per i motivi pi&ugrave; vari non possono frequentare un gruppo di auto mutuo aiuto tradizionale.</p>
      <p>&nbsp;</p>
      <h3>Che cos&rsquo;&egrave; un gruppo di auto mutuo aiuto?</h3>
      <p>&nbsp;</p>
      <p>Nel 1986 L&rsquo;OMS ha definito l&rsquo;auto mutuo aiuto come l&rsquo;insieme di &ldquo;<em>tutte le misure adottate da non professionisti per promuovere, mantenere o recuperare la salute &ndash; intesa come completo benessere fisico, psicologico e sociale &ndash; di una determinata comunit&agrave;</em>&rdquo;.</p>
      <p>Lo scopo immediato &egrave; la condivisione di problemi concreti e vissuti personali, ma poich&eacute; i gruppi sollevano questioni che investono tutta la comunit&agrave;, piccola o grande che sia, sono di stimolo per interventi di pi&ugrave; ampio respiro.</p>
      <p>Diciamo quindi che sono <em>autocentrati</em> perch&eacute; si focalizzano sui bisogni dei partecipanti ed <em>eterocentrati</em> perch&eacute; si propongono come fattore di cambiamento politico e sociale, e non solo personale.</p>
      <p>Insomma, un gruppo di auto mutuo aiuto ha il compito di proporre un approccio diverso ai problemi da parte dell&rsquo;intera comunit&agrave;, rendendo le problematiche visibili, e non invisibili e nascoste, come spesso succede, nel segreto dell&rsquo;ambiente domestico.</p>
      <p>&nbsp;</p>
      <h3>Cosa offre un gruppo di auto mutuo aiuto?</h3>
      <p>&nbsp;</p>
      <p>Nel gruppo di auto mutuo aiuto, si parla di s&eacute;, delle proprie difficolt&agrave; e dei propri progressi e si ascoltano le difficolt&agrave; e i progressi degli altri.&nbsp;</p>
      <p>Si ascolta, si &egrave; ascoltati, si offre e si riceve sostegno emotivo attraverso la rottura dell&rsquo;isolamento e la condivisione reciproca.</p>
      <p>A volte si sente dire, in questi gruppi, che finalmente ci si trova fra persone che possono capire, perch&eacute; chi non ha vissuto o non sta vivendo un&rsquo;esperienza simile, all&rsquo;inizio presta attenzione e dimostra comprensione, poi si stanca. Il gruppo favorisce la nascita di nuove amicizie e offre una protezione che non sempre si riceve nella vita di tutti i giorni.</p>
      <p>L&rsquo;essere umano &egrave; una <em>persona che narra</em>. E&rsquo; tipicamente umana l&rsquo;arte di raccontare storie, dalla pi&ugrave; semplice (che cosa ho fatto stamattina) alle grandi narrazioni epiche. Non &egrave; un caso che lo psichiatra americano esperto di terapia della Gestalt Erving Polster abbia pubblicato negli anni Ottanta un libro dal titolo <em>Ogni vita merita un romanzo</em>.</p>
      <p>Raccontare la propria esperienza significa mettere in comune qualcosa in cui anche l&rsquo;altro possa riconoscersi. Se ci&ograve; non avviene, la sensazione &egrave; quella di una comunicazione unidirezionale che alla lunga non soddisfa nessuna delle parti in causa.</p>
      <p>E&rsquo; il confronto delle esperienze che incoraggia a provare nuovi comportamenti e ad accettare i cambiamenti. L&rsquo;aiuto dato agli altri prevede la reciprocit&agrave;, cio&egrave; la possibilit&agrave; di aiutare e di essere aiutati, cosa non facile perch&eacute; non sempre le persone, pure attente e disponibili verso gli altri, accettano di ricevere a loro volta.</p>
      <p>Ma in un gruppo di auto mutuo aiuto, non &egrave; sufficiente dare: saper ricevere &egrave; una condizione imprescindibile per restare su un piano di parit&agrave;.</p>
      <p>Il gruppo aiuta l&rsquo;adattamento a condizioni percepite come stressanti e stimola la crescita personale. Chi vi partecipa &egrave; un protagonista attivo nella ricerca del benessere proprio e altrui, perch&eacute; ognuno mette a disposizione degli altri le proprie capacit&agrave;. E&rsquo; il confronto delle esperienze che incoraggia a provare nuovi comportamenti e ad accettare i cambiamenti, facendo aumentare il cosiddetto empowerment, cio&egrave; la capacit&agrave; di sentirsi gestori di una situazione difficile e non vittime impotenti.</p>
      <p>Il miglioramento dell&rsquo;autostima che ne deriva &egrave; uno dei migliori antidoti allo stress, perch&eacute; rende pi&ugrave; lucidi e di conseguenza pi&ugrave; capaci di trovare soluzioni adeguate.</p>
      <p>&nbsp;</p>
      <h3>Che cosa non &egrave; un gruppo di auto mutuo aiuto</h3>
      <p>&nbsp;</p>
      <p>Un gruppo di auto mutuo aiuto non &egrave; un gruppo terapeutico e non &egrave; un&rsquo;alternativa ai servizi sanitari di tipo professionale.</p>
      <p>Non dimentichiamo per&ograve; che in certi casi i gruppi sono nati per rimediare a un senso di sfiducia nei confronti degli operatori professionisti, accusati di non promuovere a sufficienza le capacit&agrave; che ognuno ha di aiutare se stesso.</p>
      <p>Anche la necessit&agrave; di avere un professionista come facilitatore all&rsquo;interno del gruppo &egrave; oggetto di discussione.</p>
      <p>Tuttavia, se l&rsquo;operatore professionista riconosce i limiti e le potenzialit&agrave; del suo ruolo, pu&ograve; dare un buon contributo, facilitando la comunicazione, sostenendo la motivazione, contenendo gli interventi dissonanti e promuovendo le risorse. Non &egrave; detto che un gruppo, soprattutto al suo nascere, sappia gestire il clima emotivo, le tensioni, le alleanze, i tentativi di emarginazione di qualche componente.&nbsp;</p>
      <p>D&rsquo;altro canto &egrave; bene favorire nel tempo il ruolo di partecipanti che per proprie capacit&agrave; sono definiti &ldquo;facilitatori naturali&rdquo; e che grazie all&rsquo;esperienza e ad appositi corsi possono migliorare ulteriormente le proprie capacit&agrave; e permettere ai professionisti di ritirarsi.&nbsp;</p>
      <p>&nbsp;</p>
      <h3>La gestione concreta</h3>
      <p>&nbsp;</p>
      <p>Per realizzare i propri obiettivi, il gruppo si autogestisce decidendo e condividendo</p>
      <p>&nbsp;</p>
      <ul>
        <li>i <strong>valori</strong>, quali la parit&agrave;, l&rsquo;accoglienza, la valorizzazione delle esperienze, la riservatezza, l&rsquo;apertura alla comunit&agrave; di appartenenza, l&rsquo;assenza di giudizio, la reciprocit&agrave;.<p>Particolare importanza riveste l&rsquo;assenza di giudizio.</p>
          <p>Chi partecipa ai gruppi pu&ograve; pensare di essere l&rsquo;unico &ldquo;cattivo&rdquo; o &ldquo;anormale&rdquo; ed &egrave; un sollievo ascoltare altri, che provano gli stessi sentimenti o hanno gli stessi pensieri. Non sentirsi giudicati e oggetto di richiami moralistici, diminuisce il bisogno di difendersi (anche con il silenzio) e rende pi&ugrave; sintonici verso i propri vissuti di sfiducia, rabbia, senso di abbandono, e pi&ugrave; comprensivi e disposti a trovare nuove soluzioni in situazioni che sembrano non averne.</p>
        </li>
        <li>le <strong>regole,</strong> che prevedono un luogo ed un orario stabile, una periodicit&agrave; certa e la puntualit&agrave;, nonch&eacute; momenti di convivialit&agrave;, come prendere caff&egrave;, t&egrave; e pasticcini alla fine dell&rsquo;incontro.</li>
        <li>la <strong>presenza</strong> e il <strong>ruolo del facilitatore</strong>, che ha il compito di promuovere una buona interazione fra i partecipanti, assicurare che tutti possano intervenire rispettando un dato tempo, proporre un argomento che susciti interesse, e tenere un diario di bordo per ricordare i punti emersi negli incontri precedenti, se questo non viene fatto a turno.</li>
        <li>E&rsquo; curioso notare che esistono molti modi per indicare quello che a tutti gli effetti &egrave; un facilitatore. Il &ldquo;servitore&rdquo; dei gruppi Alcolisti Anonimi, pu&ograve; diventare &ldquo;moderatore&rdquo;, &ldquo;coordinatore&rdquo;, &ldquo;capogruppo&rdquo; &ldquo;responsabile&rdquo; in altri gruppi, e come si capisce facilmente, ognuno di questi termini riflette una particolare concezione del ruolo di facilitatore.</li>
      </ul>
      <p>&nbsp;</p>
      <p>Il termine &ldquo;conduttore&rdquo; &egrave; particolarmente impegnativo e di solito &egrave; riservato ai professionisti: in loro assenza, il gruppo diventa il conduttore e ogni partecipante &egrave; invitato ad avere cura del gruppo e del suo buon andamento.&nbsp;</p>
      <p>&nbsp;</p>
      <p>Per concludere, in un gruppo di auto mutuo aiuto, si aiuta se stessi, si aiutano gli altri, si accetta di farsi aiutare.</p>
      <p>Ognuno ha qualcosa da dare e da ricevere, da imparare e da insegnare in un contesto accogliente e sicuro.</p>
      {/* <p>Per un approfondimento, &egrave; possibile consultare l&rsquo;Esperienza dei Caff&egrave; Incontro promossi dalla cooperativa Famiglie e dintorni di Sesto San Giovanni a partire dal 2009 per sostenere i familiari di anziani non autosufficienti nella scelta di assistere i propri cari a domicilio.</p> */}
    </>
  ],
  [
    "la-comunicazione-con-l-anziano-non-autosufficiente",
    <>
      <h2>La comunicazione tra anziano e operatori domiciliari (OSS e assistenti familiari)</h2>
      <p>&nbsp;</p>
      <p>Il mondo in cui viviamo &egrave; un mondo di rapporti: come insegnano le culture tradizionali (a torto chiamate &ldquo;primitive&rdquo;), non esiste qualcosa che viva separato dagli elementi che costituiscono il suo ambiente: esseri umani, animali, vegetali, minerali, formano un insieme soggetto alla continua e reciproca influenza.&nbsp;</p>
      <p>La nostra vita si svolge in un contesto dove emergono in primo piano le relazioni con le altre persone. Ecco perch&eacute; &egrave; importante conoscere le propriet&agrave; della comunicazione, che hanno un'importanza fondamentale nel comportamento e nel rapporto interpersonale.&nbsp;</p>
      <p>&nbsp;</p>
      <p style={{ textAlign: "center", alignSelf: "center" }}><em>1. Ogni comportamento &egrave; comunicazione</em></p>
      <p>&nbsp;</p>
      <p>Non si pu&ograve; non comunicare. Forse noi pensiamo che se una persona incontrata sul treno si ostina a guardare dal finestrino girando le spalle non stia comunicando nulla.&nbsp; Al contrario sta comunicando a tutti una cosa ben precisa: &laquo;Lasciatemi in pace&raquo;. Comunica cio&egrave; il suo desiderio di non comunicare con gli altri.</p>
      <p>Succede che gli operatori dell&rsquo;assistenza si lamentino dicendo: &laquo;Non riesco a comunicare!&raquo;, perch&eacute; l'anziano parla poco, evita di incrociare lo sguardo, preferisce chiudersi per conto suo in una stanza. Non &egrave; una situazione piacevole, ed &egrave; difficile accettare che l'anziano si comporti cos&igrave; verso chi vuole aiutarlo.&nbsp; Eppure la prima domanda da fare non &egrave;: &laquo;Come posso convincerlo ad aprirsi con me?&raquo;, ma piuttosto: &laquo;Cosa mi sta comunicando con quel comportamento?&raquo;. Col tempo, si arriva quasi sempre a comprenderne i motivi.&nbsp; Si pu&ograve; scoprire che l'anziano reagisce cos&igrave; quando &egrave; irritato; oppure che &egrave; il suo modo per dire agli altri quanto sia deluso o amareggiato per aver perso la propria autonomia; oppure che &egrave; il sintomo di un disturbo depressivo.</p>
      <p>Quando comprendiamo le ragioni di un comportamento inspiegabile, ci sentiamo meno frustrati, e proprio perch&eacute; ci vuole tempo per riuscirci, bisogna avere la costanza e la delicatezza di continuare a svolgere al meglio il proprio servizio.</p>
      <p>Dire infatti che ogni comportamento &egrave; comunicazione &egrave; solo il primo passo: non &egrave; ancora chiaro <em>che cosa </em>si vuole comunicare.</p>
      <p>&nbsp;</p>
      <p style={{ textAlign: "center", alignSelf: "center" }}><em>2. L'uomo comunica con le parole, ma anche con i gesti,  gli atteggiamenti e la mimica</em></p>
      <p>&nbsp;</p>
      <p>Spesso viene trascurato l'aspetto non verbale della comunicazione, anche se tutti noi sottolineiamo abitualmente i nostri discorsi con certe posizioni del corpo, con i gesti delle mani, con il tono della voce. A volte, senza bisogno di dire nulla, corrughiamo la fronte, stringiamo i pugni, induriamo le labbra; oppure apriamo le braccia, sorridiamo, ci facciamo pi&ugrave; vicini. Pu&ograve; accadere che tra il messaggio che arriva attraverso le parole e quello che passa attraverso i gesti ci sia una contraddizione. E&rsquo; impossibile barare con il corpo: se siamo arrabbiati, qualcosa della nostra rabbia si insinuer&agrave; perfino nel nostro modo di guardare, anche se a parole diremo che no, non siamo arrabbiati per niente. E&rsquo; pi&ugrave; opportuno, se ne siamo consapevoli, ammettere i nostri sentimenti senza attaccare l&rsquo;altra persona ( meglio dire: &rdquo;Mi sento cos&igrave;&rdquo; che non &ldquo;Tu mi fai sentire cos&igrave;&rdquo;).</p>
      <p>&nbsp;</p>
      <p style={{ textAlign: "center", alignSelf: "center" }}><em>3. La comunicazione trasmette contenuti e informazioni ma un osservatore attento può cogliere attraverso di essa il tipo di relazione che c'è fra chi comunica</em></p>
      <p>&nbsp;</p>
      <p>Anche se apparentemente molte discussioni tra familiari, anziani e operatori vertono sui contenuti (cosa fare, cosa non fare, come fare ecc.), dobbiamo ammettere che spesso prendono l'aspetto della lotta per il potere (&laquo;vediamo chi la vince&raquo;). Quando la discussione prende questa piega, i contenuti reali non interessano pi&ugrave; a nessuno: sono solo il pretesto per affermare il predominio nella relazione. E&rsquo; un rischio che si corre facilmente quando i rapporti sono conflittuali: anzich&eacute; attenersi al contenuto della comunicazione, si comincia a discutere su chi ha il diritto di decidere . &nbsp;</p>
      <p>Gli operatori hanno spesso modo di osservare come gli anziani reagiscano in modo diverso se certe cose vengono dette dal coniuge o da un figlio o da un&rsquo;altra persona.&nbsp;</p>
      <p>Certi anziani accettano volentieri il consiglio di un vicino, mentre sono pronti a respingerlo quando lo sentono sulla bocca di un familiare, o viceversa. La ragione di questo comportamento non sta certo nella validit&agrave; o meno del consiglio, ma nel tipo di relazione che lega i soggetti.</p>
      <p>Pi&ugrave; il rapporto &egrave; patologico (cio&egrave; fonte di sofferenza), pi&ugrave; la comunicazione trascura i contenuti: una comunicazione sana, invece, &egrave; in grado di mettere fra parentesi gli aspetti relazionali per concentrarsi sulla validit&agrave; o meno dei contenuti stessi.</p>
      <p>&nbsp;</p>
      <p style={{ textAlign: "center", alignSelf: "center" }}><em>4. Ognuno interpreta il proprio comportamento come causato dall'altro e non lo vede come causa dei comportamenti altrui</em></p>
      <p>&nbsp;</p>
      <p>In altri termini, siamo sempre convinti che sia stato l'altro a cominciare.&nbsp; Ad esempio, l'anziano si pone subito in un atteggiamento conflittuale perch&eacute; l&rsquo;assistente familiare &laquo;parla male l&rsquo;italiano&raquo; oppure perch&eacute; non gli piace come si veste. Dal canto suo, lei non se ne rende conto ed &egrave; convinta di essere stata costretta ad alzare la voce, visto che l'anziano l'ha fatto per primo. Ricordiamo che, nella maggior parte dei casi, &egrave; impossibile (e inutile) cercare di capire chi ha iniziato. Meglio focalizzare l'attenzione sul presente e pensare che i nostri comportamenti sono in grado di influenzare quelli degli altri come quelli degli altri influenzano i nostri, anche inconsciamente.</p>
      <p>&nbsp;</p>
      <p style={{ textAlign: "center", alignSelf: "center" }}><em>5. La comunicazione può essere di tipo simmetrico o complementare a seconda che sia basata sull'uguaglianza o sulla differenza tra i soggetti</em></p>
      <p>&nbsp;</p>
      <p>Il rapporto tra anziano non autosufficiente e assistente &egrave; fatto di scambi complementari (grossolanamente, come quelli tra madre e figlio) e di scambi simmetrici (come quelli tra adulti).&nbsp; Lo scambio simmetrico, paritario, pu&ograve; emergere nel rispetto reciproco, quando entrambi riconoscono di essere persone con propri bisogni e un proprio stile di vita. A volte emerge invece in modo doloroso e conflittuale, quando entrambi difendono strenuamente il proprio punto di vista.</p>
      <p>Negli scambi complementari, i due soggetti funzionano un po' come i piatti di una bilancia: se uno sale, l'altro scende.&nbsp; Lo si vede in modo particolare quando l'assistente &egrave; molto attiva&nbsp; o direttiva e l'anziano, per contro, passivo e accondiscendente, oppure, abbastanza tipico, nel caso dell'aiuto domestico prestato a una anziana che per tutta la vita ha lavorato come colf e alla quale non sembra vero poter riequilibrare la propria condizione di dipendenza sottolineando tutti gli errori dell&rsquo;assistente nella cura dell&rsquo;ambiente.</p>
      <p>La patologia negli scambi simmetrici si manifesta come ostilit&agrave;, la patologia negli scambi complementari come abuso di potere.</p>
      <p>In una relazione sana, gli scambi sono sia simmetrici che complementari a seconda della situazione.&nbsp;</p>
      <p>&nbsp;</p>
      {/* <p style={{ textAlign: "center", alignSelf: "center" }}><em>Cosa fare</em></p>
      <p>&nbsp;</p>
      <p><strong>Una premessa importante.</strong></p>
      <p>L&rsquo;anziano ha bisogno di condividere la propria esperienza di vita, di raccontarsi per meglio confermare anche a s&eacute; stesso la propria identit&agrave;, ha bisogno di non sentirsi isolato, soprattutto se un&rsquo;eventuale compromissione psico-fisica lo rende non autosufficiente.</p>
      <p>La comunicazione passa attraverso gli organi di senso e la parola ed &egrave; condizionata dalle abilit&agrave; intellettive: quindi uno scambio che sia qualitativamente accettabile richiede contemporaneamente l&rsquo;integrit&agrave; delle funzioni sensoriali, neurologiche e psichiche, condizione non frequentemente riscontrabile nell&rsquo;anziano.</p>
      <p>Infatti spesso la vista, l&rsquo;udito, il tatto, il gusto e l&rsquo;olfatto tendono a peggiorare per un fisiologico processo d&rsquo;invecchiamento degli organi; altre volte invece il problema &egrave; pi&ugrave; radicale e complesso perch&eacute; origina dal cervello e pu&ograve; compromettere, oltre al linguaggio, la capacit&agrave; di comprensione, di attenzione, il comportamento e il tono dell&rsquo;umore.</p>
      <p>Un&rsquo;analisi approfondita delle cause che alterano o limitano la comunicazione rappresenta la tappa essenziale per distinguere le afasie dalle demenze, le depressioni dai disturbi della memoria, del linguaggio e dell&rsquo;apprendimento, la sordit&agrave; dai deficit di comprensione. Solo su questa base,&nbsp; gli specialisti potranno scegliere l&rsquo;approccio terapeutico e riabilitativo, pi&ugrave; idoneo.</p>
      <p>&nbsp;</p>
      <ol style={{ listStyleType: "decimal" }}>
        <li>Innanzitutto verifichiamo se l&rsquo;anziano di cui ci prendiamo cura ha problemi di vista, udito o se soffre di disturbi del linguaggio (afasia).</li>
        <li>Se siamo con un anziano che sente poco (ipoacusico) poniamoci sempre di fronte a lui quando parliamo, scandendo bene le parole a voce alta. Aiutiamoci con la gestualit&agrave; per mimare azioni e indicare oggetti.</li>
        <li>Con un anziano che ha problemi di vista (ipovedente), la comunicazione verbale normalmente non &egrave; compromessa, a scapito per&ograve; di una stentata comunicazione non verbale, quella che veicola meglio l&rsquo;aspetto affettivo ed emozionale del discorso; cerchiamo quindi con discrezione di stabilire un contatto fisico, moduliamo il tono della voce e l&rsquo;intensit&agrave;, ed utilizziamo un linguaggio che sia il pi&ugrave; possibile appropriato e preciso.</li>
        <li>Le afasie meritano un&rsquo;attenzione in pi&ugrave; perch&eacute; si accompagnano, in vario modo, a caratteristiche modificazioni del comportamento e ad altri disturbi
        <ul>
            <li>La persona con problemi di linguaggio pu&ograve; essere rigida ed introversa. Ha bisogno di ordine perch&eacute; fa fatica ad adattarsi ai cambiamenti dell&rsquo;ambiente, cos&igrave; come ad assumere il punto di vista degli altri. Rispettiamo il pi&ugrave; possibile l&rsquo;ordine mentale dell&rsquo;anziano, e non cataloghiamo come ostinata contrariet&agrave; la sua incapacit&agrave; di interpretare in altri modi, che non gli sono propri, una situazione.</li>
            <li>La persona afasica nei primi tempi, pu&ograve; non rendersi conto della propria malattia; in seguito, insieme alla presa di coscienza, subentra l&rsquo;angoscia di non poter comunicare. In questi momenti l&rsquo;anziano acquisisce sicurezza se si trova in un ambiente noto, se pu&ograve; far conto sulla presenza e vicinanza dei parenti e se non pretendiamo da lui lunghe prestazioni verbali.</li>
            <li>Teniamo presente che la persona afasica ha perso anche le capacit&agrave; di comprensione e di apprendimento, di lettura e di scrittura.</li>
            <li>Stimoliamo l&rsquo;anziano afasico a concludere autonomamente le frasi e non sostituiamoci a lui se non &egrave; strettamente necessario.</li>
            <li>L&rsquo;anziano afasico spesso viene considerato incapace di interagire e improvvisamente diventa spettatore muto degli eventi quotidiani. Non isoliamolo e sosteniamolo nei suoi progressi piuttosto che criticarlo continuamente a ogni errore di dizione o a ogni parola scorretta</li>
          </ul>
        </li>
        <p>&nbsp;</p>
        <li>Pi&ugrave; comunemente, dove non vi siano deficit noti, nella comunicazione con un anziano dovremmo osservare alcuni accorgimenti che ci permettano di stabilire una sintonia
      <ol>
            <ul>
              <li>Cerchiamo di capire come l&rsquo;anziano percepisce la realt&agrave;, la sua condizione psico-fisica e la nostra presenza in casa sua in una forma di sostanziale convivenza stretta. Non giudichiamo le convinzioni e gli atteggiamenti dell&rsquo;anziano perch&eacute; sarebbe infruttuoso per entrambi: egli pensa e agisce sulla base dell&rsquo;esperienza intellettiva e pratica di una vita intera e difficilmente sar&agrave; disposto a modificare il proprio punto di vista o il proprio comportamento.</li>
              <li>L&rsquo;anziano ha bisogno di essere ascoltato, ed &egrave; proprio ascoltandolo che meglio comprendiamo i nodi critici, le reali difficolt&agrave; che incontra rispetto ai suoi disturbi o alla vita con un &ldquo;estraneo&rdquo; che ha il compito di aiutarlo. Cerchiamo innanzitutto di capire se l&rsquo;anziano &egrave; consapevole della sua non autosufficienza, o se nega di essere in difficolt&agrave;; quindi affrontiamo con pazienza, spiegando e rassicurando, eventuali suoi atteggiamenti di rifiuto nei nostri confronti.</li>
              <li>Uno dei momenti privilegiati per &ldquo;incontrare&rdquo; un anziano si ha quando egli racconta la propria vita e ribadisce le proprie convinzioni, fa emergere preoccupazioni vecchie e nuove, stati d&rsquo;animo ed emozioni. Ascoltiamolo con attenzione, senza fretta; l&rsquo;anziano ha bisogno di tempo per potersi fidare di noi.</li>
              <li>Quando avvertiamo che il livello di empatia (di profonda intesa) &egrave; buono, concediamoci di stabilire con l&rsquo;anziano un contatto fisico rispettoso, rassicurante e sincero; questo sar&agrave; di stimolo e qualificante per la nostra relazione con lui.</li>
            </ul>
          </ol></li>
      </ol>
      <p>&nbsp;</p>
      <p style={{ textAlign: "center", alignSelf: "center" }}><em>Cosa non fare</em></p>
      <p>&nbsp;</p>
      <ol style={{ listStyleType: "decimal" }}>
        <li>Non denigriamo mai l&rsquo;anziano per le sue difficolt&agrave; di espressione e di comprensione, qualsiasi sia la causa dei problemi che manifesta.</li>
        <li>Non giudichiamone gli atteggiamenti ed i comportamenti, ma facciamo lo sforzo di capire.</li>
        <li>Non perdiamo le occasioni di dialogo con l&rsquo;anziano a vantaggio di una relazione che pu&ograve; essere arricchente per entrambi. Ricordiamo che sono momenti privilegiati di comunicazione quelli in cui si stabilisce un rapporto di condivisione e di intimit&agrave;: il momento del pasto, dell&rsquo;igiene e del saluto della buona notte.</li>
        <li>Non sottovalutiamo l&rsquo;importanza della comunicazione non verbale: nel determinare la buona disposizione dell&rsquo;anziano nei nostri confronti, di solito hanno maggior peso le sensazioni e le percezioni che non le parole.</li>
      </ol>
      <p>&nbsp;</p>
      <p style={{ textAlign: "center", alignSelf: "center" }}><em>Altri consigli utili</em></p>
      <p>&nbsp;</p>
      <ol style={{ listStyleType: "decimal" }}>
        <li>Uno dei principali motivi che rendono difficoltosa la comunicazione &egrave; il problema linguistico. Se l&rsquo;assistente parla un italiano stentato e l&rsquo;anziano si esprime utilizzando un gergo dialettale, la comprensione reciproca &egrave; difficile. Un suggerimento a questo proposito &egrave; quello di approfittare dei numerosi corsi di italiano per stranieri organizzati in particolare dai Comuni nei quali la concentrazione di cittadini extracomunitari &egrave; alta. Questo va a vantaggio non solo della relazione con il nostro assistito, ma anche dell&rsquo;integrazione nella comunit&agrave; locale.</li>
        <li>Non dimentichiamo i parenti dell&rsquo;anziano, che possono aiutarci a valutare i comportamenti dell&rsquo;anziano, le chiusure, la diffidenza. Se ci si mostra ben disposti e desiderosi di una buona comunicazione e relazione con l&rsquo;assistito, i parenti non negheranno il loro sostegno.</li>
      </ol>
      <p>&nbsp;</p>
      <p>( redatto in collaborazione con Elisa Santoni, infermiera professionale)</p>*/}
    </>
  ],
  [
    "la-relazione-di-aiuto",
    <>
      <h2>La relazione di aiuto tra operatori domiciliari&nbsp; e anziani non autosufficienti</h2>
      <p>&nbsp;</p>
      <p>Relazionarsi con persone in stato di bisogno, richiede due qualit&agrave; essenziali: disponibilit&agrave; umana e competenza. Le persone che vogliono lavorare nel campo dell&rsquo;assistenza ritengono fondamentali la pazienza, la sensibilit&agrave;, la dedizione: in latino, <em>humanita</em>s significa solidariet&agrave;, cio&egrave; partecipazione profonda alle vicende del prossimo.&nbsp;</p>
      <p>Tuttavia la disponibilit&agrave; umana non basta. Occorrono competenze che riguardano l&rsquo;assistenza diretta alla persona e la cura del suo ambiente di vita, e buone capacit&agrave;&nbsp; relazionali, fatte di ascolto, spirito di osservazione, riconoscimento e valorizzazione dell&rsquo;altro, accettazione dei propri limiti.&nbsp;</p>
      <p>La propria professionalit&agrave; e le proprie doti umane si incontrano con i bisogni dell&rsquo;assistito quando non si pensa pi&ugrave; a s&eacute; <em>e</em> a lui, ma a s&eacute; <em>con</em> lui.</p>
      <p>Qualunque siano le operazioni concrete da svolgere, lo scopo &egrave; di promuovere il benessere psicofisico dell&rsquo;assistito e, per quanto possibile, la sua autonomia, mantenendolo nel proprio ambiente di vita o supportandolo se ricoverato in ospedale.</p>
      <p>Spostare una persona allettata, prevenire le cadute e gli incidenti domestici, correggere i deficit nutrizionali attraverso una dieta opportuna, incoraggiare la socializzazione, stimolare intellettualmente ed emotivamente al fine di rallentare il declino, non sono cose che si imparano dall'oggi al domani. L&rsquo;impegno richiesto &egrave; molto elevato, e la qualit&agrave; della relazione &egrave; determinante per affrontarlo. Purtroppo, sommersi dalle esigenze quotidiane, i familiari possono mettere in secondo piano questo aspetto, quando si rivolgono a un operatore dell&rsquo;assistenza domiciliare. Pu&ograve; prevalere allora l&rsquo;aspetto puramente custodialistico, dove ci si accontenta di soddisfare i bisogni fisiologici fondamentali.</p>
      <p>Le nostre abilit&agrave; relazionali si formano a partire dai primi anni di vita e dipendono dal modo in cui gli altri (in primo luogo i genitori o i loro sostituti) si sono relazionati con noi. I genitori attenti, affettuosi e sensibili, forniscono buoni modelli per sviluppare sicurezza in se stessi e fiducia negli altri, mentre quelli distaccati, autoritari, assenti o maltrattanti&nbsp; forniscono modelli poco adeguati o dannosi. Tuttavia, mentre si cresce e aumenta la possibilit&agrave; di nuovi rapporti, &egrave; possibile acquisire&nbsp; modelli che possono riparare, almeno in parte, i danni causati da quelli disfunzionali.</p>
      <p>Gli operatori portano se stessi e la propria storia nella relazione con l'assistito. Portano la loro carica di ottimismo e fiducia cos&igrave; come la loro instabilit&agrave; e insicurezza, i loro desideri di vicinanza ed affetto, la facilit&agrave; o la difficolt&agrave; a staccarsi dai propri bisogni per comprendere quelli dell'altro.&nbsp;</p>
      <p>Alcuni servizi diventano impossibili quando tra assistito e assistente non c'&egrave; un buon rapporto, mentre altri continuano ma costano enormemente in termini di frustrazione e di stress.</p>
      <p>Ricordiamo che qualunque situazione di mancanza di autonomia comporta ansia e timore: anche se l'anziano &egrave; favorevole all'assistenza che gli viene proposta, pu&ograve; mostrare atteggiamenti critici e aggressivi. Pu&ograve; comportarsi come un padrone con la servit&ugrave;; oppure controllare ogni gesto trovando qualcosa da ridire. L&rsquo;operatore, con la sua sola presenza, &egrave; la prova che l&rsquo;anziano non &egrave; pi&ugrave; in grado di fare da s&eacute;, che dipende da qualcuno che fino al giorno prima era del tutto estraneo e che ora si prende cura di lui nelle funzioni basilari della vita come una madre con il bambino. Ma l&rsquo;anziano non &egrave; un bambino e l&rsquo;assistente non &egrave; sua madre. L&rsquo;adattamento non &egrave; facile e a volte non avviene.</p>
      <p>Offendersi per una critica dell&rsquo;anziano, scoraggiarsi davanti a un rifiuto, considerare i suoi atteggiamenti scostanti come attacchi personali, pu&ograve; portare l&rsquo;operatore a una valutazione affrettata del carattere dell&rsquo;assistito e alla rinuncia a impegnarsi per far funzionare meglio il rapporto. Anche esaltarsi per aver avuto una buona accoglienza pu&ograve; essere fuori luogo.&nbsp; L'anziano respira di sollievo perch&eacute; finalmente c'&egrave; qualcuno che si prende cura di lui, ma pu&ograve; avere delle aspettative esagerate, che non possono essere soddisfatte, e la cordialit&agrave; e l'attaccamento possono trasformarsi in astio e in rifiuto. &nbsp;</p>
      <p>&nbsp;</p>
      <p><em>Per conoscere e per farsi conoscere ci vuole tempo, tempo da utilizzare bene, mettendo a frutto alcune competenze.&nbsp;</em></p>
      <p>&nbsp;</p>
      <p>In campo assistenziale, <strong>la capacit&agrave; di osservazione</strong> &egrave; una delle competenze fondamentali da acquisire o da perfezionare. Osservare significa prestare attenzione, tra gli innumerevoli stimoli che ci colpiscono, a quelli veramente importanti. E&rsquo; un lavoro di selezione che l&rsquo;esperienza pu&ograve; affinare rendendolo quasi automatico. Diciamo &ldquo;quasi&rdquo; perch&eacute; l&rsquo;attenzione subisce l&rsquo;influenza di stimoli esterni e interni. Il continuo squillare del telefono, cos&igrave; come le proprie preoccupazioni, possono distogliere l&rsquo;attenzione dall&rsquo;assistito. La mancanza di preparazione teorica pu&ograve; portare alla sottovalutazione o al non riconoscimento di segnali importanti.</p>
      <p>Quando si entra per la prima volta in casa d&rsquo;altri, si mette piede in un mondo sconosciuto e affettivamente estraneo per chi entra, tanto quanto conosciuto e amato (o talvolta odiato, ma non affettivamente estraneo) da chi vi abita.</p>
      <p>L&rsquo;operatore potr&agrave; prestare attenzione in un primo momento agli aspetti pi&ugrave; evidenti (presenza di barriere architettoniche, numero dei locali, adeguatezza dei servizi e degli elettrodomestici ecc.), ma lentamente dovr&agrave; prendere confidenza con gli arredi, le suppellettili, gli oggetti che per l&rsquo;assistito sono pieni di storia e di significato. Quando li tocca o li sposta, &egrave; come se prendesse in mano qualcosa dell&rsquo;anziano stesso, ed &egrave; importante osservare le sue reazioni e rispettarle.</p>
      <p>Osservare l&rsquo;assistito significa rilevare la funzionalit&agrave; dei suoi organi di senso, la capacit&agrave; di deambulare, il grado di autonomia nell&rsquo;igiene personale, la capacit&agrave; di vestirsi e alimentarsi, ma anche le abilit&agrave; residue nelle attivit&agrave; strumentali (maneggiare il denaro, usare il telefono ecc.). Occorre conoscere le patologie di cui soffre e quali medicine assume, con i possibili effetti collaterali. E&rsquo; altres&igrave; fondamentale osservare la sua efficienza psichica, che si esprime nella capacit&agrave; di stare attento, di comprendere, di fare discorsi logici, di ricordare; il tono dell&rsquo;umore, che pu&ograve; essere normale, labile, depresso o euforico; la presenza di ansia, di convinzioni deliranti e di altri segni insoliti che occorre segnalare prontamente ai familiari e al medico.</p>
      <p>Per quanto riguarda la rete relazionale e assistenziale, &egrave; significativo osservare la presenza (o l&rsquo;assenza) di familiari, parenti, amici e vicini di casa, volontari, sacerdoti, figure professionali significative (medico di base, fisioterapista ecc.): con ognuno di essi l&rsquo;anziano avr&agrave; instaurato rapporti buoni o cattivi, intensi o superficiali. Buone relazioni affettive promuovono la serenit&agrave; e diminuiscono lo stress dell&rsquo;assistito ma anche dell&rsquo;operatore, soprattutto se si tratta di un&rsquo;assistente familiare (badante) che lavora in convivenza e che pu&ograve; temere di essere lasciata sola a gestire in toto la situazione.</p>
      <p>Infine ricordiamo che non basta osservare: bisogna anche saper utilizzare le informazioni che si ricavano da quanto osservato. Se un&rsquo;assistente familiare ha capito che l&rsquo;anziano demente non tollera i cambiamenti ma lei continua a farli perch&egrave; le sembra che la casa vada meglio cos&igrave;, vanifica il proprio lavoro e inasprisce la sua relazione con l&rsquo;assistito. Se sono i familiari a volere questi cambiamenti, &egrave; importante informarli sulle conseguenze. Prima del proprio personale commento, per&ograve;, &egrave; bene presentare i fatti: si perderebbe altrimenti di credibilit&agrave;. &ldquo;Non bisogna spostare niente, a lui non piace&rdquo;, non &egrave; un&rsquo;affermazione convincente. Meglio spiegare quanto accaduto le volte in cui si &egrave; cercato di modificare qualcosa</p>
      <p>&nbsp;</p>
      <p>Un&rsquo;altra competenza fondamentale, ritenuta sovente un&rsquo;arte, &egrave; <strong>la capacit&agrave; di ascolto</strong>. Per certi versi &egrave; molto simile all&rsquo;osservazione, perch&egrave; si basa sulla capacit&agrave; di filtrare le informazioni importanti e far emergere quelle nascoste.</p>
      <p>Ascoltare &egrave; <strong>un&rsquo;attivit&agrave;</strong> che richiede sforzo. Anche se ad alcune persone riesce pi&ugrave; facile che ad altre, tutti possiamo diventare maggiormente abili con l&rsquo;esercizio, la correzione di abitudini sbagliate e la conoscenza di come avviene il processo di ascolto.&nbsp;</p>
      <p>Saper ascoltare non dipende solo da noi: se l&rsquo;emissione di messaggi da parte del nostro interlocutore &egrave; troppo rapida o confusa, se il tono della voce &egrave; troppo basso, se le parole sono articolate male o ci sono sconosciute, dopo un tempo pi&ugrave; o meno lungo ci arrendiamo e smettiamo di ascoltare. In questo caso &egrave; importante parlare francamente delle difficolt&agrave; incontrate. Se il nostro interlocutore non pu&ograve; rimediare (pensiamo a un anziano afasico) bisogner&agrave; trovare altri modi per comprendere e comunicare.</p>
      <p>L&rsquo;ascolto pu&ograve; essere disturbato da interferenze esterne, ma anche dall&rsquo;afflusso di idee e di emozioni che distraggono. Per questi motivi pu&ograve; essere difficile ascoltare (o pretendere di essere ascoltati) se si parla con il frullatore in funzione oppure quando non si &egrave; in grado di staccarsi dai pensieri e dalle preoccupazioni personali.</p>
      <p>Per migliorare la nostra capacit&agrave; di ascolto dobbiamo prima capire <em>come</em> ascoltiamo. Ecco alcuni atteggiamenti molto comuni che possono rappresentare un ostacolo:</p>
      <p>&nbsp;</p>
      <ul>
        <li><em>Fingere di ascoltare quando l&rsquo;altro parla</em></li>
      </ul>
      <p>&nbsp;</p>
      <p>Se ci accorgiamo che troppo spesso, parlando con gli altri, non ricordiamo le cosa che ci hanno detto, chiediamoci come mai.</p>
      <p>Ascoltare sembra una modalit&agrave; passiva di comportamento, mentre &egrave; un processo attivo, faticoso e coinvolgente. A volte pu&ograve; essere necessario proteggersi fingendo di ascoltare, ma alla lunga diventa controproducente. E&rsquo; meglio stabilire limiti chiari e riservarsi spazi di solitudine e tranquillit&agrave; per recuperare energie.</p>
      <p>&nbsp;</p>
      <ul>
        <li><em>Smettere di ascoltare quando non si &egrave; d&rsquo;accordo con l&rsquo;interlocutore, interromperlo, oppure cercare di elaborare una nostra risposta mentre sta ancora parlando.&nbsp;</em></li>
      </ul>
      <p>&nbsp;</p>
      <p>Agire in questo modo, soprattutto nel corso di una discussione, significa perdere informazioni o messaggi che potrebbero essere importanti e chiarificatori, e rischiare un logoramento dei rapporti. Pu&ograve; essere utile ripetere quello che ha detto l&rsquo;altro per essere sicuri di aver capito bene e per mandargli un segnale di ascolto e di interesse. (&ldquo;Aspetti, mi faccia capire bene. Lei dice che i pavimenti cos&igrave; non vanno bene. Cosa intende esattamente? Sono ancora sporchi? Dovevo usare un altro prodotto?&rdquo;)</p>
      <p>&nbsp;</p>
      <ul>
        <li><em>Farsi turbare da parole ad alto contenuto emotivo o non rendersi conto che gli altri possono essere turbati da queste parole</em></li>
      </ul>
      <p>&nbsp;</p>
      <p>Ci sono persone, anziane o meno, che interrompono il dialogo, magari con un pretesto, quando vengono toccati certi argomenti o usate determinate parole. Possono allontanarsi o cambiare bruscamente discorso o intimare all&rsquo;altro di non parlarne pi&ugrave;. È necessario esserne consapevoli, e mostrare rispetto e discrezione. Con il tempo, sarà forse possibile comprenderne i motivi.</p>
      <p>&nbsp;</p>
      <ul>
        <li><em>Sottovalutare l&rsquo;importanza dei segnali non verbali inviati e ricevuti</em></li>
      </ul>
      <p>&nbsp;</p>
      <p>La mimica e la gestualit&agrave; hanno un&rsquo;influenza maggiore delle parole sullo stato emotivo. Alcune persone&nbsp; possiedono una notevole abilit&agrave; comunicativa e la loro mimica e gestualit&agrave; sembrano fatte apposta per catturare e mantenere l&rsquo;attenzione altrui. Alcuni invece hanno bisogno di esercitarsi a lungo per evitare gli errori pi&ugrave; comuni, come quello di <strong>distogliere lo sguardo dalla persona che sta parlando senza inviare altri segnali che rassicurino</strong> sul fatto che lo si sta ascoltando.</p>
      <p>&nbsp;</p>
      <ul>
        <li><em>Rispondere in modo inadeguato</em></li>
      </ul>
      <p>&nbsp;</p>
      <p>Una buona risposta &egrave;&nbsp; frutto di un buon ascolto.</p>
      <p>Se si ascolta solo quello che coincide con le nostre idee preconcette, la risposta sar&agrave; buona per noi e inutile per l&rsquo;altro. A volte siamo assillati dal desiderio di risolvere problemi quando magari l&rsquo;altro non vuole da noi soluzioni, ma semplicemente ascolto e riconoscimento.</p>
      <p>C&rsquo;&egrave; il momento per dare coraggio, per consigliare, per fornire spiegazioni, per fare domande, per trovare soluzioni, per riconoscere i sentimenti, per condividere pensieri, e anche per stare zitti. Un buon ascoltatore non presume di saper rispondere correttamente prima di avere ascoltato, e per questo, all&rsquo;occorrenza, &egrave; capace di tacere.</p>
      <p>&nbsp;</p>
      <p>Grazie allo spirito di osservazione e alla capacit&agrave; di ascolto e di dialogo, l&rsquo;operatore ha modo di comprendere meglio i bisogni dell&rsquo;anziano, anche quelli di cui non parla. Oltre alla soddisfazione delle esigenze primarie (igiene, nutrimento, sonno), gli esseri umani hanno bisogno di sicurezza, di affetto, di stima, di conoscenza, di espressione delle proprie qualit&agrave; e capacit&agrave;.</p>
      <p>Bambini, giovani, adulti, anziani, esprimeranno questi bisogni in modo diverso, ma quando li sentono accolti e compresi, sentono aumentare la considerazione e il rispetto di sé.&nbsp;</p>
    </>
  ],
  [
    "l-arte-di-invecchiare",
    <>
      <h2>L&rsquo;arte di invecchiare</h2>
      <p>&nbsp;</p>
      <p>Il vocabolario spiega che la vecchiaia &egrave; "la fase pi&ugrave; avanzata del ciclo biologico, nella quale si manifestano fenomeni di decadimento fisico e un generale indebolimento dell'organismo".&nbsp;</p>
      <p>Non pu&ograve; dirci per&ograve; a quale et&agrave; si &egrave; definiti &ldquo;vecchi&rdquo;.</p>
      <p>Oggi come oggi, si entra ufficialmente nella Terza Et&agrave; a sessantacinque anni.</p>
      <p>Questo dato non ha valore assoluto ovunque, perch&eacute; l&rsquo;et&agrave; media e la speranza di vita dipendono dalle condizioni di vita (disponibilit&agrave; di cibo, acqua, medicine, ecc.) presenti in una dato momento storico.</p>
      <p>Anche nei Paesi occidentali, dove appunto l&rsquo;inizio della Terza Et&agrave; &egrave; <em>per il momento</em> fissato intorno ai sessantacinque anni, osserviamo che gli anziani non sono tutti uguali.</p>
      <p>La vecchiaia &egrave; caratterizzata da cambiamenti fisici, psicologici, sociali, ma il modo in cui sono vissuti questi eventi non &egrave; uguale per tutti e molto dipende da come la persona ha impostato in precedenza la sua vita e dal tipo di ambiente in cui vive.</p>
      <p>Ai tempi dell'uomo di Neanderthal la maggior parte degli uomini moriva prima dei venti anni.</p>
      <p>Nel mondo antico la vecchiaia era un evento eccezionale, come succede ancora oggi presso alcuni popoli.</p>
      <p>La vita media non superava i trenta anni e il destino dei vecchi poteva essere molto diverso secondo la societ&agrave; in cui erano inseriti: quando le risorse alimentari erano troppo scarse, il vecchio inabile, che non poteva seguire il gruppo nei suoi spostamenti, era abbandonato in favore della sopravvivenza altrui; mentre in situazioni di maggior benessere riceveva considerazione come esperto di tecniche di coltivazione e di caccia e detentore della memoria storica del suo clan.</p>
      <p>Nell'antica Grecia, Aristotele voleva escludere gli anziani del governo della <em>polis</em> e affidarlo alle classi pi&ugrave; giovani, d&rsquo;et&agrave; media. Nell'Atene di Pericle era prevista l'assistenza per gli orfani, ma gli anziani non erano citati e non sappiamo se godessero di una qualche forma di aiuto al di fuori della famiglia.&nbsp;</p>
      <p>La civilt&agrave; e la cultura di Atene erano imperniate sul concetto di bellezza e di conseguenza la vecchiaia e la decadenza erano considerate sciagure.&nbsp;</p>
      <p>A Sparta invece il vecchio riceveva pi&ugrave; considerazione: era un guerriero sopravvissuto a molte guerre, dunque doveva essere molto abile o gradito agli dei. Se era anche di buoni costumi, poteva ottenere importanti incarichi pubblici. Il consiglio degli anziani di Sparta aveva un notevole potere sulla cittadinanza.</p>
      <p>Nell'antica Roma, scriveva Varrone, i vecchi poveri e inutili erano gettati nel Tevere e sia nella Roma dei primordi che nella Roma repubblicana o imperiale molto dipendeva dalle risorse economiche. Le oligarchie conservatrici e il senato erano composti da persone anziane e ricche.</p>
      <p>Durante il Medioevo e il Rinascimento, gli anziani godevano di notevole rispetto. Addirittura la Controriforma prefer&igrave; i papi anziani ai giovani, perch&eacute; ritenuti pi&ugrave; affidabili.</p>
      <p>Tra il Seicento e il Settecento, le condizioni sociali subirono un notevole cambiamento.</p>
      <p>Il fenomeno dell'urbanizzazione modific&ograve; profondamente il tessuto familiare e gli anziani assunsero un&rsquo;importanza minore. Furono fondati ospizi e cronicari la cui funzione non era soltanto quella di garantire un'assistenza ai vecchi, ma anche quella di proteggere la societ&agrave;: non dimentichiamo che nella legislazione italiana, dall'Unit&agrave; alla seconda guerra mondiale, i mendicanti erano considerati un problema di ordine pubblico, e tra questi i mendicanti anziani erano la maggior parte.</p>
      <p>La vecchiaia cominci&ograve; a essere istituzionalizzata nel Novecento grazie alle leggi sul pensionamento, che permisero agli anziani di non dipendere pi&ugrave; economicamente dai figli.&nbsp;</p>
      <p>Il nostro tempo &egrave; caratterizzato da un fenomeno assolutamente nuovo: la presenza massiccia di persone anziane come mai si era visto prima sul nostro pianeta.</p>
      <p>In futuro avremo una societ&agrave; sempre pi&ugrave; anziana e con sempre meno figli. Questo comporter&agrave; la necessit&agrave; di rivedere i meccanismi delle pensioni e di investire pi&ugrave; adeguatamente sulla non autosufficienza, per evitare il rischio di emarginazione degli anziani e la contrapposizione tra le generazioni.</p>
      <p>&nbsp;</p>
      <h3>Un progetto di vita per l&rsquo;et&agrave; anziana</h3>
      <p>&nbsp;</p>
      <p>Nonostante i progressi fatti nel campo della prevenzione e della cura, l&rsquo;invecchiamento &egrave; un processo inarrestabile caratterizzato da mutamenti fisiologici e psicologici.</p>
      <p>Diminuisce l&rsquo;acutezza dei cinque sensi, si abbassa il tono muscolare, le ossa diventano pi&ugrave; fragili, le pareti delle arterie meno elastiche. Tutto ci&ograve; va affrontato anche con nuovi accorgimenti nella gestione della casa, perch&eacute; l&rsquo;anziano possa continuare a viverci in sicurezza.</p>
      <p>L&rsquo;invecchiamento cerebrale porta a un rallentamento dei processi psichici e le funzioni cognitive ne risentono: vi &egrave; una maggiore difficolt&agrave; a ricordare i vari elementi di un&rsquo;informazione e a metterli in relazione tra loro.</p>
      <p>La velocit&agrave; di elaborazione dei dati rallenta, la capacit&agrave; di prestare attenzione accusa dei limiti.</p>
      <p>L&rsquo;anziano ha bisogno di concentrare l&rsquo;attenzione su uno stimolo alla volta e richiede pi&ugrave; tempo per elaborare risposte complesse, anche a causa della mole di dati immagazzinata in tanti anni cui pu&ograve; attingere.</p>
      <p>Non &egrave; un caso che gli anziani tendono a essere abitudinari e a rifiutare, pur essendo in grado, di apprendere nuovi modi di fare le cose: quando si compiono i soliti gesti, le azioni si svolgono in modo automatico e non &egrave; necessario prestare grande attenzione a quello che si fa, mentre quando s&rsquo;impara qualcosa di nuovo, molta energia va spesa solo per prestare attenzione.</p>
      <p>Occorre per&ograve; che tutto questo non si trasformi in un rassegnato: &ldquo;<em>Alla mia et&agrave;&hellip;&rdquo;.</em></p>
      <p>Gli anziani a volte sottovalutano la loro capacit&agrave; di imparare, ritenendo di essere &ldquo;troppo vecchi&rdquo;.</p>
      <p>In realt&agrave;, non dobbiamo in alcun modo confondere le patologie che possono danneggiare gravemente le funzioni cognitive (pensiamo alle demenze) con quello che &egrave; il normale invecchiamento fisiologico.</p>
      <p>Le difficolt&agrave; di memoria, la necessit&agrave; di pi&ugrave; tempo per imparare le cose, l&rsquo;utilizzo di un linguaggio pi&ugrave; semplice non significano necessariamente una compromissione dell&rsquo;intelligenza. Purtroppo, nella nostra societ&agrave; &egrave; apprezzata la velocit&agrave; delle risposte e una persona anziana non pu&ograve; competere con una giovane su questo terreno. Tuttavia questo non significa che, avuto il tempo necessario, la persona anziana non arrivi a dare risposte efficaci, e pi&ugrave; efficaci di quelle date da un giovane, vista l&rsquo;esperienza accumulata in un&rsquo;intera vita.</p>
      <p>E&rsquo; fuori di dubbio che la vecchiaia, come l&rsquo;adolescenza, sia un periodo caratterizzato da una maggiore attenzione a s&eacute; e da parecchie preoccupazioni. Il corpo, in entrambe le et&agrave;, cambia, &ldquo;sfugge di mano&rdquo;, e anche se pare esserci un abisso tra il bambino che si trasforma in adulto e l&rsquo;anziano che ha davanti a s&eacute; la prospettiva della morte, non dobbiamo dimenticare che queste due fasi della vita sono caratterizzate da incertezza, paure e dubbi su di s&eacute;.</p>
      <p>Per questo l&rsquo;anziano, come l&rsquo;adolescente, &egrave; spesso rimproverato di essere egoista, di pensare solo a se stesso, mentre il soggetto pu&ograve; essere semplicemente impegnato a elaborare i cambiamenti che avvengono indipendentemente dai suoi desideri e dalla sua volont&agrave; e a trovare le risorse per adattarsi alla nuova situazione e accettarla (o sopportarla).</p>
      <p>Non &egrave; detto che i risultati siano quelli sperati. La medicina e la psicologia puntano proprio a promuovere il benessere e a ridurre lo stress, cercando di assicurare quello che era espresso qualche anno fa con uno slogan efficace: <em>&ldquo;Dare pi&ugrave; anni alla vita, dare pi&ugrave; vita agli anni&rdquo;</em>.</p>
      <p>Erik Erikson, psicanalista, ritiene che la vita individuale sia caratterizzata da una serie di fasi, a ognuna delle quali &egrave; affidato un compito evolutivo che pu&ograve; riuscire o fallire.</p>
      <p>La vecchiaia pu&ograve; essere caratterizzata dall'autorealizzazione, quindi da un senso di compiutezza e di soddisfazione per la vita vissuta, per le cose fatte, o al contrario da una sensazione d&rsquo;incompiutezza e disperazione per ci&ograve; che non si &egrave; riuscito a realizzare, e dalla convinzione di avere sprecato la propria esistenza.</p>
      <p>Fa parte della compiutezza, secondo Erikson, la capacit&agrave; dell'anziano di essere ci&ograve; che ha dato, cos&igrave; come i bambini sono ci&ograve; che ricevono e gli adulti sono ci&ograve; che danno. &Egrave; una concezione per&ograve; troppo schematica: anche la persona anziana &egrave; ci&ograve; che riceve e ci&ograve; che ancora pu&ograve; dare.</p>
      <p>Carl Gustav Jung riteneva che la sintesi piena della propria personalit&agrave;, rappresentata dall'istanza psichica del Sé - un'istanza psichica superiore all&rsquo;Io e all'Inconscio - non si realizzava prima dei cinquanta anni: si sarebbe dunque trovato d'accordo con quel proverbio cinese che afferma <em>"Occorrono sessanta anni di vita per conoscere la propria anima, poi si resta giovani in eterno"</em></p>
      <p>La storia della vecchiaia non deve essere una storia di malattie e di medici.</p>
      <p>E&rsquo; una storia individuale ed &egrave; una storia sociale.</p>
      <p>Come ricorda la scrittrice francese Simone de Beauvoir: <em>&rdquo;Un uomo pu&ograve; rimanere uomo se &egrave; stato trattato da uomo per tutto il corso della sua vita&rdquo;</em></p>
      <p>&Egrave; questo ci&ograve; cui punta la <em>geragogia</em>.</p>
      <p>Il termine &egrave; stato coniato nel 1973 da Angelo Sordi, assistente presso l'istituto di geriatria e gerontologia del professor Francesco Maria Antonini dell'universit&agrave; di Firenze.&nbsp;</p>
      <p>Geragogia &egrave; un neologismo scarsamente conosciuto, anche in ambiente medico. E&rsquo; una branca della gerontologia e presenta delle analogie con la pedagogia.</p>
      <p>Possiamo definirla un insieme di insegnamenti per poter vivere fino all'ultimo una vecchiaia sufficientemente serena ed attiva.</p>
      <p>I professionisti &ndash; medici, psicologi, fisioterapisti ecc. &ndash; possono trovarsi in imbarazzo davanti a persone pi&ugrave; anziane di loro. Il segreto sta proprio nel rinunciare a quel &ldquo;<em>mettersi davanti&rdquo;</em> all'anziano in favore dell&rsquo;<em>&ldquo;essere con&rdquo;</em> l'anziano.</p>
      <p>Soprattutto quando si devono gestire malattie croniche e l'alleanza terapeutica con il paziente &egrave; indispensabile, i professionisti devono saper coltivare un buon rapporto con i loro assistiti anziani. Il principio base &egrave; che l&rsquo;educazione non &egrave; mai a senso unico: mentre si educano gli altri, si educa anche se stessi.</p>
      <p>Gli studiosi ritengono che la geragogia possa operare in tre settori: la <strong>geragogia biologica</strong>, attraverso l'educazione e la prevenzione in campo sanitario (l'intento &egrave; di insegnare abitudini corrette che prevengano il decadimento precoce); la <strong>geragogia psicologica</strong>, attraverso l'educazione all'assunzione di nuovi ruoli e lo sviluppo dell&rsquo;adattamento alla nuova condizione esistenziale; la <strong>geragogia sociale</strong>, attraverso la messa in discussione dell&rsquo;immagine culturale negativa dell&rsquo;anziano e la sviluppo del collegamento e della solidariet&agrave; tra le generazioni.</p>
      {/* <p>Lo scopo della geragogia &egrave; insegnare a gestire nel modo migliore possibile la propria vecchiaia.</p> */}
      <p>E&rsquo; importante, per stare bene, conoscere se stessi, i propri limiti e le proprie risorse, e conoscere i cambiamenti cui si va incontro. E trovare un ambiente favorevole, che sollevi il morale anzich&eacute; distruggerlo.</p>
      <p>La pubblicit&agrave; martellante di prodotti di bellezza fa apparire le rughe e i capelli bianchi una calamit&agrave;.</p>
      <p>Un tempo la perdita del fascino giovanile era compensata dal rispetto ottenuto per l&rsquo;et&agrave; e dalla necessit&agrave; per i giovani di contare sull&rsquo;esperienza degli anziani.</p>
      <p>In alcune culture, gli anziani esagerano a bella posta l&rsquo;et&agrave;, in quanto pi&ugrave; &egrave; avanzata, pi&ugrave; l&rsquo;ambiente in cui vivono li onora.</p>
      <p>Non cos&igrave; da noi, dove i giovani studiano pi&ugrave; dei loro genitori e dove, una volta lasciato il lavoro, ci si sente tagliati fuori dalla societ&agrave; circostante.</p>
      <p>Persone ancora in buona salute fisica e mentale non sanno come occupare le proprie giornate. Oltre alla perdita di un introito economico pi&ugrave; elevato, &egrave; l&rsquo;immagine di s&eacute; come persona utile e produttiva a essere messa fortemente in crisi.</p>
      <p>Non sarebbe cos&igrave; se si smettesse di circondare il lavoro di sacralit&agrave;, riportandolo alle giuste proporzioni in un contesto che apprezzi soprattutto la qualit&agrave; dei rapporti umani.</p>
      <p>Una volta lasciato il lavoro non si pu&ograve; improvvisare un nuovo modo di vivere. Come si diceva prima, molto dipende dalle scelte precedenti.</p>
      <p>Il periodo del pensionamento pu&ograve; essere un momento difficile oppure ricco e completo.</p>
      <p>Anche dal punto di vista familiare possono verificarsi cambiamenti consistenti. Quando i figli lasciano la casa, i coniugi rimangono uno di fronte all&rsquo;altro. Se non hanno coltivato il loro rapporto, possono scoprire, proprio nel momento in cui avrebbero pi&ugrave; tempo per parlare, che non hanno niente da dirsi. Oppure potrebbero finalmente avere pi&ugrave; tempo per stare insieme.</p>
      <p>A questo proposito c&rsquo;&egrave; da aggiungere che gli anziani possono avere una vita sessuale soddisfacente fino alla pi&ugrave; tarda et&agrave;. Gli anziani, uomini e donne, che conducono una vita sessualmente attiva, hanno maggiore stima di s&eacute;, a patto che riescano a non farsi condizionale dai tab&ugrave; ancora presenti, che creano solo inutile incertezza, angoscia e sensi di colpa.</p>
      <p>L&rsquo;ideale, certo non facile, &egrave; rappresentato dalle parole del famoso violoncellista e direttore d&rsquo;orchestra Pablo Casals, quando scriveva:</p>
      <p>&nbsp;</p>
      <p><em>&laquo;Ho pi&ugrave; di 93 anni, non sono proprio giovane, perlomeno non cos&igrave; giovane come quando avevo 90 anni. La vecchiaia &egrave;, d&rsquo;altra parte, qualcosa di relativo. Quando si continua a lavorare e si resta sensibili alla bellezza del mondo che ci circonda, si scopre che la vecchiaia non significa necessariamente invecchiare, o perlomeno, non l&rsquo;invecchiare nel senso comune. Oggi sento, pi&ugrave; intensamente di prima, molte cose, e la vita mi affascina sempre di pi&ugrave;&raquo;</em></p>
      <p>&nbsp;</p>
      <p>La geragogia non &egrave; rivolta solo alle persone anziane ma anche a chi si vuole preparare alla vecchiaia o agli &ldquo;anziani giovani&rdquo;.</p>
      <p>In maniera pi&ugrave; estesa, dovrebbe diventare una sorta di educazione permanente in tutte le fasi della vita perch&eacute; ci si prepari per tempo ai cambiamenti che sono causati dal trascorrere del tempo. Questo perch&eacute; anche da anziani si possa vivere bene, affinando la propria personalit&agrave;, coltivando la spiritualit&agrave; e continuando a imparare, esprimersi, scegliere e donare.&nbsp;</p>
      <p>L&rsquo;accettazione realistica del tempo che passa fa il resto, creando una maggiore capacit&agrave; di adattamento.</p>
      <p>Non serve negare la fragilit&agrave; fisica n&eacute; il rallentamento cognitivo n&eacute; le difficolt&agrave; a tenere il passo in una societ&agrave; che cambia il modo vorticoso.</p>
      <p>Sono fattori disturbanti anche il rifiuto di ammettere i limiti legati all'et&agrave;, con conseguente incapacit&agrave; di gestire la frustrazione e l'invidia perch&egrave; gli altri sono pi&ugrave; veloci ed efficienti, la chiusura in s&eacute; e il narcisismo, la rinuncia e la tendenza eccessiva a criticare.</p>
      <p>La sensazione di aver sprecato la propria vita e di aver fallito &egrave; all&rsquo;origine della disperazione spesso nascosta dietro la rabbia, l&rsquo;aggressivit&agrave;, il disprezzo per gli altri, che spesso significa solo disprezzo per se stessi.</p>
      <p>Ripensare ai fallimenti, alle cose non fatte, alle perdite subite serve solo ad amareggiarsi. Nel ripassare la propria vita occorrerebbe anche valutare gli aspetti positivi che certamente non sono mancati.</p>
      <p>Una visione pi&ugrave; obiettiva pu&ograve; incrementare il senso di compiutezza di cui parla Erikson, alla base della serenit&agrave; e della pace interiore, e della convinzione che a conti fatti la propria vita &egrave; stata ed &egrave; ancora degna della fatica fatta.</p>
    </>
  ],
  [
    "l-autostima",
    <>
      <h2>L'autostima nella Terza Et&agrave;</h2>
      <p>&nbsp;</p>
      <p>L'autostima &egrave; l'opinione che l'individuo ha di se stesso organizzata sulla base delle proprie esperienze.</p>
      <p>Non &egrave; necessariamente cosciente, ma influenza il comportamento.</p>
      <p>Un basso livello di autostima si manifesta con timidezza, insicurezza, chiusura in se stessi, ansia, pessimismo, tendenza alla depressione e all'autocritica.</p>
      <p>Influisce sui rapporti sociali ed ha anche un effetto inibitorio sulla curiosit&agrave; e le capacit&agrave; cognitive: nei test d'intelligenza, i soggetti con bassa autostima ottengono spesso un punteggio inferiore a quello che potrebbero effettivamente ottenere.</p>
      <p>Nel linguaggio quotidiano siamo soliti affermare che queste persone hanno un "complesso d'inferiorit&agrave;". Su questo tema, dobbiamo molto ad Alfred Adler, psicologo austriaco, prima allievo e in seguito grande critico di Sigmund Freud.</p>
      <p>Adler sosteneva che i "sentimenti d'inferiorit&agrave;" sono normali nel bambino. Non &egrave; detto che debbano per forza strutturarsi in un "complesso d'inferiorit&agrave;", che come dice il nome stesso, &egrave; costituito da una costellazione di fattori psichici rigida, poco adattiva, che influenza negativamente il comportamento perch&eacute; basata sulla scarsa considerazione di s&eacute;. Il bambino cercher&agrave; di superare i sentimenti di inferiorit&agrave; lottando per il raggiungimento delle mete che gli sono poste (autonomia, relazione con gli altri, apprendimento scolastico ecc ). Il successo in questi campi gli permetter&agrave; di costruire un'immagine positiva di s&eacute;.</p>
      <p>I sentimenti di inferiorit&agrave;, secondo Adler, non riguardano solo il bambino, perch&eacute; fanno parte della condizione umana. Anche l'uomo adulto deve misurarsi con essi, cercando di realizzarsi essenzialmente in tre campi: lavoro, amicizia, e amore.</p>
      <p>Per sviluppare l'autostima, &egrave; indispensabile sapersi relazionare con gli altri. L'evoluzione dei sentimenti di inferiorit&agrave; dipende infatti dal grado di sentimento sociale dell'individuo.</p>
      <p>Adler sostiene che una carenza di sentimento sociale &egrave; all'origine di tutte le condotte umane inadeguate, siano esse nevrotiche, psicotiche o delinquenziali.</p>
      <p>Nella vita di tutti i giorni, si pu&ograve; notare che le persone a volte contrastano in modo non appropriato i sentimenti di inferiorit&agrave;, per esempio sfuggendo ed evitando le situazioni che ritengono di non saper gestire, oppure continuando a criticare, mettere in difficolt&agrave;, minacciare, dominare gli altri, oppure ancora rifugiandosi in fantasie consolatorie che al momento soddisfano ma in concreto allontanano la possibilit&agrave; di evoluzione personale.</p>
      <p>I bambini che sentono di potersi fidare dei genitori concepiscono se stessi come persone degne di amore e vedono il mondo come un luogo fondamentalmente non ostile. Hanno quindi la possibilit&agrave; di sviluppare una sana stima di s&eacute; (che non considera i limiti come catastrofi) e sono ben disposti verso gli altri, pi&ugrave; dei bambini allevati da genitori che preferiscono il potere e la disciplina.</p>
      <p>Un bambino che ha trovato orecchie sensibili ai suoi bisogni, avr&agrave; buone probabilit&agrave; di fare altrettanto con gli altri per tutto il resto della vita ed essere ritenuto quindi un buon amico e un buon partner.</p>
      <p>Relazioni umane positive sono certamente il primo motore dell&rsquo;autostima, tuttavia ci sono altri aspetti da considerare.</p>
      <p>Uno &egrave; il rapporto con il proprio corpo. Adolescenti e anziani condividono una caratteristica insoddisfazione per il proprio aspetto, esacerbata dalla proposta di modelli estetici inarrivabili e dall&rsquo;attenzione ossessiva per l&rsquo;aspetto esteriore.</p>
      <p>Comincia a non essere pi&ugrave; vera l&rsquo;opinione secondo cui nella Terza Et&agrave; ci si libera dagli obblighi imposti dalla moda e dall&rsquo;apparire, e secondo lo psichiatra britannico Alex Yellowlees aumenta anche per le donne anziane il rischio di sviluppare disturbi dell&rsquo;alimentazione come risposta a un&rsquo;immagine di s&eacute; non accettata.</p>
      <p>Ci vogliono molta maturit&agrave; e molto senso critico per non lasciarsi schiacciare dal continuo bombardamento di messaggi che dipingono il naturale trascorrere del tempo come una tragedia.</p>
      <p>La soddisfazione di s&eacute; come persone che non si limitano al viso, al seno, alla pancia e alle cosce, e la gioia di sentirsi persone intere e integre, sono il miglior antidoto alla disistima, ma &egrave; una meta che si alimenta giorno per giorno con la cura dei rapporti umani e il raggiungimento di nuovi obiettivi.</p>
      <p>Pu&ograve; sembrare strano, dato che l&rsquo;anziano si &egrave; ritirato dalla vita produttiva e ha legami affettivi consolidati.</p>
      <p>In realt&agrave; non &egrave; cos&igrave;.</p>
      <p>L&rsquo;anziano si trova necessariamente a ridefinire progetti e rapporti, per esempio quando nella famiglia entrano nuovi membri come i nipoti, o quando purtroppo perde il coniuge, altri parenti o i vecchi amici.</p>
      <p>Affrontare la solitudine continuando a sviluppare la propria capacit&agrave; di amare &egrave; la strada migliore. Il peso dell'et&agrave; non &egrave; dato solo dalla cattiva salute e dalla tristezza per quanto si &egrave; perduto, ma anche, e forse soprattutto, dalla passivit&agrave; e dalla rinuncia.</p>
      <p>Di solito tendiamo a porre l&rsquo;accento sulle tendenze egoistiche che dominano l'uomo, soprattutto nella societ&agrave; contemporanea; al contrario, numerose ricerche provano che prendersi cura degli altri fa parte della natura umana, non meno dello stimolo a badare a se stessi.</p>
      <p>Che queste tendenze altruistiche si mantengano vive o si assopiscano dipende in gran parte dall'educazione ricevuta, e che siano veramente altruistiche e dunque efficaci, dipendono in gran parte dalla stima di s&eacute;. Segnali rivelatori di una dedizione inappropriata e dunque motivata pi&ugrave; dalla propria fragilit&agrave; che da un sano desiderio di aiutare gli altri, sono la mancanza di rispetto per se stessi, l&rsquo;incapacit&agrave; di porre limiti, la gelosia e l&rsquo;incapacit&agrave; di collaborare, l&rsquo;aggressivit&agrave; e la delusione quando la persona aiutata si rivolge anche ad altri, la creazione di un rapporto di dipendenza anzich&eacute; l&rsquo;aiuto all&rsquo;autonomia.</p>
      <p>Un'ultima cosa da rilevare, riguardo al piacere dell'attivit&agrave;, del porsi nuove mete, dell'aiutare gli altri, riguarda il coraggio di provare.</p>
      <p>La difficolt&agrave;, a volte, sta proprio nel decidere di salire il primo gradino combattendo con la convinzione che non ce la si pu&ograve; fare, che la fatica &egrave; troppa, che tanto ormai&hellip; dimenticando che l&rsquo;attivit&agrave; ha una ricaduta positiva sull&rsquo;umore e che spesso siamo insoddisfatti dei risultati perch&eacute; automaticamente ed inconsapevolmente li denigriamo ritenendoli ovvi e normali.</p>
      <p>Rimproverandoci insomma perch&eacute; dovremmo &ldquo;fare di pi&ugrave;&rdquo;.</p>
      <p>Questo atteggiamento non porta a fare di pi&ugrave;, semmai a fare di meno e a essere poco soddisfatti di ci&ograve; che si fa, con un ulteriore peggioramento dell&rsquo;umore. E&rsquo; importante rendersi conto di questi &ldquo;giochi&rdquo; della mente e, se necessario, chiedere aiuto e sostegno alle persone pi&ugrave; vicine o, nei casi di maggiore sofferenza, a un professionista.</p>
    </>
  ],
  [
    "la-gerascofobia",
    <>
      <h2>La Gerascofobia&nbsp;</h2>
      <p>&nbsp;</p>
      <p>Con questo termine, s&rsquo;intende una paura eccessiva e continuata riguardante l&rsquo;invecchiamento, che si manifesta con attacchi di panico, vertigini o altri sintomi somatici. Studiata a partire dagli anni Sessanta, &egrave; classificata come fobia specifica e rientra dunque nei disturbi d&rsquo;ansia, tuttavia pu&ograve; nascondere altri tipi di disturbi, depressivi o di personalit&agrave;, anche gravi.</p>
      <p>Da sempre l&rsquo;umanit&agrave; si &egrave; confrontata con l&rsquo;invecchiamento, che richiama l&rsquo;idea della perdita (degli affetti, della salute, della bellezza, delle risorse, dell&rsquo;autonomia)&nbsp; e della finitezza umana, ma al tempo stesso quella della sapienza, della contemplazione, della profondit&agrave; spirituale.</p>
      <p>Omero circonda di venerazione la tarda et&agrave;, sinonimo di saggezza. Solone, giurista e poeta greco vissuto nel VI secolo a.C., aveva una visione positiva della vecchiaia. Plutarco, filosofo e biografo vissuto nel I secolo d.C., riporta un suo verso</p>
      <p><em>&ldquo;Invecchio molte cose sempre imparando</em></p>
      <p><em>or mi sono care le opere di Cipride e Dioniso</em></p>
      <p><em>e delle Muse, che agli uomini danno gioia&rdquo;&nbsp;</em></p>
      <p><em>(Plutarco, Solone, 31.8)</em></p>
      <p>Sottolinea quindi come anche in tarda et&agrave; si possano apprezzare Venere (dea dell&rsquo;amore, venerata a Cipro), l&rsquo;istintivit&agrave; sfrenata di Dioniso e la bellezza della poesia e delle arti, in contrasto con il poeta Minmermo, che vedeva la vecchiaia solo come decadimento e sofferenza e che rendeva l&rsquo;uomo odioso agli altri e a se stesso.</p>
      <p>L&rsquo;umanit&agrave; ha sempre favoleggiato sulla terra dell&rsquo;Eterna Giovinezza.</p>
      <p>E&rsquo; un mito che troviamo anche in Paesi molto lontani da noi, come il Giappone, dove un pescatore sottrae una tartaruga alle sevizie di alcuni bambini e, come ricompensa, riceve l’invito a recarsi nel Palazzo del Re del Mare, un regno subacqueo dove vive per qualche tempo.</p>
      <p>Quando spinto dalla nostalgia torna a casa sua, si accorge che sono passati trecento anni, e non tre giorni come pensava, e vinto dalla tristezza perch&eacute; nessuno pi&ugrave; lo conosce, viola il divieto di aprire una scatola tempestata di gioielli che la regina del palazzo gli ha donato. Scopre cos&igrave; che contiene la sua vera et&agrave;, e muore consumato dalla improvvisa vecchiaia.</p>
      <p>Un tema simile si trova nei miti dell&rsquo;Irlanda pre-cristiana, dove l&rsquo;eroe guerriero Oisin (l&rsquo;Ossian scozzese dello scrittore James Macpherson) sposa la regina della Terra dei Giovani e ha dei figli, di cui uno di nome Oscar, e vive felice con lei fino a quando non viene ripreso dalla nostalgia per l&rsquo;Irlanda e vi torna. Sopraffatto dalla nostalgia alla vista di una fontana dove si bagnava un tempo con il padre e gli amici, dimentica la raccomandazione di non scendere mai da cavallo e appena toccata la terra, cadono su di lui cento e pi&ugrave; anni di vita.</p>
      <p>Lo scrittore irlandese Oscar Wilde ha incarnato questo mito considerando la vecchiaia un&rsquo;autentica tragedia. Come Minmermo, riteneva che solo ci&ograve; che &egrave; esteticamente bello ha valore e che quindi la vecchiaia con il suo corteo di mali &egrave; inutile e gravosa. Il suo destino sembra essere scritto nel nome, scelto dalla madre: Oscar, come il figlio di Oisin, nato nella Terra dei Giovani.</p>
      <p>Ne <em>Il Ritratto di Dorian Gray</em>, Oscar Wilde narra la vicenda del giovane Dorian che a seguito di quello che si potrebbe considerare un patto col diavolo ottiene di non essere lui a invecchiare, ma il proprio ritratto. Continua cos&igrave; ad essere un giovane bellissimo, ma senza scrupoli, in modo che il suo ritratto non solo invecchia ma imbruttisce in modo inquietante, fino a quando Dorian lo colpisce con un pugnale, restando vittima del suo stesso colpo, e in quel momento lui si trasforma in un orribile vecchio, mentre il ritratto torna ad essere quello di un bellissimo giovane.</p>
      <p>Nella nostra epoca caratterizzata dall&rsquo;estrema attenzione a fattori quali la bellezza e la forma fisica, il prestigio sociale, l&rsquo;indipendenza, la negazione del limite, la riduzione della morte a fatto privato, la vecchiaia pu&ograve; apparire nella sua forma pi&ugrave; temibile.</p>
      <p>Il ricorso alla chirurgia estetica pu&ograve; essere uno dei modi per affrontare il terrore di invecchiare, per questo motivo &egrave; fondamentale esaminare le motivazioni che stanno alla base di questa scelta. La Societ&agrave; Americana dei Chirurghi Plastici ha rilevato un incremento notevole delle richieste negli ultimi vent&rsquo;anni. Persone che si sottopongono a interventi di chirurgia estetica possono soffrire di disturbi di personalit&agrave;, quali il disturbo narcisistico e istrionico (47%, sempre secondo la Societ&agrave; Americana, che ha valutato come il rischio suicidario possa aumentare in donne con pregressi disturbi mentali che abbiano chiesto l&rsquo;intervento per l&rsquo;aumento del seno) e il disturbo di dismorfismo corporeo, dove tutta l&rsquo;attenzione del soggetto &egrave; diretta verso un presunto o reale difetto fisico.</p>
      <p>La presenza di questi disturbi pu&ograve; comportare l&rsquo;insoddisfazione per i risultati dell&rsquo;intervento, con la ricerca di ulteriori interventi, oppure una reazione depressiva perch&eacute; l&rsquo;intervento, per quanto riuscito, non ha portato a una sensazione di sollievo e soddisfazione.</p>
      <p>Il terrore di invecchiare pu&ograve; generare altri tipi di comportamento, come la ricerca di un partner pi&ugrave; giovane, che rassicuri sulla propria desiderabilit&agrave;. E&rsquo; stato un atteggiamento culturalmente approvato o almeno tollerato quando si trattava di un uomo anziano nei confronti di una donna pi&ugrave; giovane, mentre veniva severamente biasimato quando si verificava la situazione opposta. Oggi questo comportamento appare meno censurato. E&rsquo; stato coniato il termine <em>cougar</em>, che significa puma o coguaro, per indicare le donne che intrecciano relazioni con uomini pi&ugrave; giovani, che per&ograve;, da varie statistiche, sembra un comportamento pi&ugrave; frequente nelle donne legate al mondo dello spettacolo che non della popolazione femminile in generale.</p>
      <p>Un altro comportamento utilizzato per fuggire l&rsquo;idea di una vecchiaia cupa e invalidante &egrave; l&rsquo;eccesso di attenzione alla forma fisica, che si esprime come una cura compulsiva del corpo attraverso lo sport o gli esercizi in palestra; l&rsquo;eccessiva attenzione alla dieta e alla ricerca di cibi &ldquo;sani&rdquo;; l&rsquo;assunzione a tutti i costi di comportamenti giovanili nel vestire, nei gusti, nella ricerca di novit&agrave;; la sopravvalutazione dell&rsquo;indipendenza e dell&rsquo;autonomia.</p>
      <p>Anche quando non ci troviamo in presenza di gerascofobia, non possiamo sottovalutare i sentimenti di paura per il futuro o di frustrazione per la nuova condizione legata al pensionamento, che non sempre si traduce in sollievo per la maggiore libert&agrave;. Infatti, ritrovarsi privi di un&rsquo;attivit&agrave; che scandiva il ritmo delle giornate, che teneva impegnati e assicurava un ruolo all&rsquo;interno della societ&agrave;, pu&ograve; creare un notevole disagio: sopravviene la necessit&agrave; di&nbsp; organizzare in modo diverso il tempo e il bisogno di trovare una diversa collocazione sociale.</p>
      <p>Inoltre, il mondo esterno, con le sue continue richieste, facilita la distrazione, il rimandare, l&rsquo;inconsapevolezza; la diminuzione degli stimoli esterni porta all&rsquo;emersione della propria interiorit&agrave;, con emozioni, pensieri, domande irrisolte, che pu&ograve; essere riscoperta con soddisfazione o temuta.</p>
      <p>Lo psicanalista Erik Erikson riteneva che il compito della Terza Et&agrave; consistesse nella revisione di quanto vissuto per trarne la giusta soddisfazione e per farne dono alle nuove generazioni. In realt&agrave;, come sosteneva lo psichiatra Gene Cohen, se &egrave; vero che quando si &egrave; avanti negli anni si sente il bisogno di fare un bilancio della propria vita, &egrave; anche vero che la persona anziana pu&ograve; continuare a dedicarsi a ci&ograve; che ha sempre amato fare, o a fare cose nuove alla sua portata, e comunque ad approfondire il senso della vita, con le sue delusioni e le sue soddisfazioni.</p>
      <p>Perfino la dipendenza pi&ugrave; accentuata nei confronti degli altri pu&ograve; gettare un fascio di luce su come si &egrave; vissuta l&rsquo;autonomia, l&rsquo;aiuto dato agli altri e la capacit&agrave; di ricevere aiuto.</p>
      <p>Pu&ograve; accadere anche a una persona giovane di essere vittima del terrore di invecchiare. Quello che spaventa pu&ograve; basarsi su una serie di idee distorte della vecchiaia e sulla sopravvalutazione di alcuni aspetti, quali la bellezza fisica o la prestazione intellettuale, ma soprattutto sulla difficolt&agrave; a gestire i sentimenti di perdita della propria infanzia e il profondo mutamento dell&rsquo;immagine corporea. Vivere in un corpo che cambia indipendentemente dalla propria volont&agrave; e dai propri desideri &egrave; un dato che accomuna adolescenti e anziani e che spiega, almeno in parte, l&rsquo;insicurezza, il bisogno di conferme e la maggiore attenzione a se stessi.</p>
      {/* <p>&nbsp;</p>
      <h3>La Nomofobia</h3>
      <p>&nbsp;</p>
      <p>E&rsquo; un termine coniato nel 2008 a seguito di uno studio commissionato dal settore telefonia mobile delle Poste Reali inglesi. Su un campione di 2163 persone intervistate, poco pi&ugrave; della met&agrave;, il 53%, ha dichiarato si sentirsi in ansia quando teme di aver perso il cellulare, o non c&rsquo;&egrave; segnale, o resta senza caricatore o senza credito. Le persone portano come giustificazione la necessit&agrave; di restare in contatto con familiari e amici, o di essere sempre connessi per motivi di lavoro.</p>
      <p>Quando la preoccupazione e il malessere portano a irrequietezza e aggressivit&agrave; fino a sfociare in forti vissuti di ansia e depressione, parliamo di nomofobia (= no mobile phobia), che indica appunto la paura di rimanere disconnessi dalla rete dei dispositivi mobili (smartphone, tablet, computer portatili, palmari ecc.).</p>
      <p>Non &egrave; sempre facile distinguere una cattiva abitudine da una forma di dipendenza nei confronti di questi dispositivi che tutti utilizziamo quotidianamente, tuttavia alcuni comportamenti possono metterci in allarme: non riuscire a separarsi fisicamente dal dispositivo anche quando stiamo dormendo, alzarsi di notte per controllarlo, guardarlo come prima cosa il mattino, passare troppo tempo a chattare o leggere i messaggi o navigare su internet trascurando lavoro, studio, relazioni sociali, sentirsi molto tesi, nervosi e impulsivi quando non li si pu&ograve; utilizzare, utilizzarli dove non &egrave; opportuno o &egrave; vietato, come in certe aree negli ospedali, non resistere al bisogno di utilizzarli quando si &egrave; alla guida o quando si attraversa la strada, sottovalutando il rischio per la propria vita e quella degli altri.</p>
      <p>I dispositivi mobili non hanno solo un utilizzo pratico, ma adempiono anche&nbsp; una funzione sociale, tuttavia, paradossalmente, i contatti virtuali possono allontanare dalle relazioni concrete, fisicamente presenti.</p>
      <p>Se ci accorgiamo che non siamo noi a usare i dispositivi mobili ma che sono loro a usarci attirandoci in un gorgo di SMS, mail, whatsapp e link che agganciano un sito all&rsquo;altro, possiamo cominciare a chiederci se si tratta solo di un&rsquo;abitudine che ha lentamente preso forma in modo inconsapevole, oppure se dietro questi comportamenti ci sono problemi pi&ugrave; profondi.</p>
      <p>Se si tratta solo di un&rsquo;abitudine, possiamo stabilire regole di utilizzo e usare la nostra volont&agrave; per rispettarle: ad esempio, possiamo decidere che non controlleremo il cellulare fino a dopo colazione, che saremo sintetici nei nostri messaggi, che elimineremo le notifiche partendo dal presupposto che per le urgenze si telefona, che controlleremo se sono arrivati messaggi solo in pochi momenti stabiliti, che non controlleremo il cellulare mentre si pranza con gli amici e non risponderemo se non per ragioni davvero serie.</p>
      <p>Sperimentare nuovi modi di fare le cose permette di imparare molto su di noi. Potremmo scoprire di non riuscire ad applicare queste regole perch&eacute; i dispositivi mobili sedano l&rsquo;ansia di non essere cercati, di non avere persone vicine con cui comunicare ecc.</p>
      <p>In certi casi, i contatti virtuali sostituiscono quelli reali e la persona si trova a comunicare con gli altri attraverso uno schermo dove ci si pu&ograve; anche inventare un&rsquo;identit&agrave; fasulla e proteggersi dagli sguardi reali. Difficolt&agrave; di questo genere sotto intendono problemi di ansia sociale e di evitamento, paura del giudizio altrui, convinzione di valere meno degli altri, terrore del fallimento.</p>
      <p>E&rsquo; il caso dei cosiddetti ragazzi hikikomori (parola giapponese che significa &ldquo;stare in disparte, isolarsi&rdquo;), che sentendosi costretti a raggiungere il successo e la realizzazione personale e avvertendo questa pressione come insostenibile, abbandonano la scuola e si chiudono nella propria camera rifiutando spesso qualunque forma di aiuto. Il fenomeno &egrave; stato osservato e studiato in Giappone, ma &egrave; presente anche in altri paesi occidentali, tra cui l&rsquo;Italia. Questi ragazzi non sempre utilizzano i dispositivi mobili per tenersi in contatto con il mondo, perch&eacute; a volte il loro bisogno d&rsquo;isolamento &egrave; estremo, tuttavia una percentuale di circa il 10% li usa ed &egrave; l&rsquo;unica modalit&agrave; che non li spaventi. E&rsquo; l&rsquo;isolamento che porta eventualmente alla dipendenza dalla rete, anche se di solito si pensa il contrario. Esistono delle associazioni che aiutano i familiari a fronteggiare situazioni di questo tipo, perch&eacute; la famiglia per prima non si senta isolata e sola.</p> */}
    </>
  ],
  [
    "la-solitudine",
    <>
      <h2>Anziani e solitudine</h2>
      <p>&nbsp;</p>
      <p>"Pu&ograve; intendersi come il termine o lo scopo di un desideroso perseguimento di pace, di vita appartata e raccolta nell'intimit&agrave; (ricercare, amare la solitudine) o come l'aspetto doloroso di una vita totalmente priva del dono degli affetti, di ogni sostegno e conforto (sento tutto il peso della mia solitudine, vive nella pi&ugrave; squallida solitudine)"</p>
      <p>Questa definizione, tratta dal vocabolario illustrato della lingua italiana Devoto-Oli, ci fa capire che non &egrave; possibile trovare un significato univoco al termine "solitudine".</p>
      <p>Per certi versi ci pu&ograve; sembrare una realt&agrave; determinata da fattori esterni e allora parliamo di isolamento, dovuto alla mancanza di una rete di rapporti sociali validi. Tuttavia la solitudine ci sembra pi&ugrave; spesso legata a uno stato d'animo.</p>
      <p>La solitudine come sentimento &eacute; alla base della differenza tra essere soli e sentirsi soli.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <h3 style={{ alignSelf: "center" }}>QUANDO LA SOLITUDINE E' UN PROBLEMA</h3>
      <p style={{ alignSelf: "center" }}>Un percorso di riflessioni per metterlo a fuoco</p>
      <p>&nbsp;</p>
      <p style={{ alignSelf: "center" }}>PREMESSA</p>
      <p>&nbsp;</p>
      <p>Durante tutto l'arco della vita, gli esseri umani devono fare i conti con la solitudine e ogni volta devono trovare delle soluzioni, degli aggiustamenti pi&ugrave; o meno provvisori, pi&ugrave; o meno riusciti. In questa riflessione non troverete ricette magi&shy;che ma solo suggerimenti.</p>
      <p>Ricordate che ognuno di noi, quan&shy;do ha dei problemi, cerca soluzioni coerenti con la propria visione del mondo e resiste, a volte anche tenacemente, alle soluzioni proposte da parenti e amici.</p>
      <p>Naturalmente una convinzione pu&ograve; generare soluzioni ("il mondo &egrave; brutto e pericoloso dunque meglio chiudersi in casa") che pur essendo coerenti sono portatrici di grandi sofferenze.</p>
      <p>Ma per cambiare qualcosa non si possono ricevere o proporre soluzioni concrete come si proporrebbe (o imporrebbe) l'assunzione di una medicina: &egrave; la visione del mondo che deve essere modifica&shy;ta, sono le convinzioni disadattive che vanno corrette.</p>
      <p>Qualche volta ci si riesce da soli, riflettendo, mettendosi in discussione; qualche volta confrontandosi con altre persone; qualche volta rivolgendosi all&rsquo; &ldquo;interlocutore di professione", cio&egrave; allo psicologo.</p>
      <p>&nbsp;</p>
      <p style={{ alignSelf: "center" }}>PARTE I</p>
      <p>&nbsp;</p>
      <p>Il primo percorso da compiere &egrave; la messa a fuoco del &ldquo;pro&shy;blema solitudine". Tutti noi, quando abbiamo qualche guaio, rischiamo di non riuscire a risolverlo perch&egrave; non ne abbiamo una percezione chiara.</p>
      <p>Proviamo a rispondere a queste domande, utilizzando, come confronto, alcune possibili risposte.</p>
      <p>&nbsp;</p>
      <p>1. Che cosa vuol dire per me, in modo concreto, la solitudine?</p>
      <p>&nbsp;</p>
      <ul>
        <li>Trascorrere solo gran parte della giornata</li>
        <li>Avere rappor&shy;ti umani superficiali</li>
        <li>Avere rapporti poco soddisfacenti con le persone care (per scarsa frequentazione, per diver&shy;sit&agrave; di carattere, per difficolt&agrave; a parlare con loro del&shy;le cose importanti)</li>
        <li>Non avere nessuno che aiuti in caso di bisogno</li>
        <li>Non ricevere sufficiente stima</li>
        <li>Non avere abbastanza stimoli</li>
        <li>non riuscire a mantenere gli interessi e le attivit&agrave; di un tempo&nbsp;</li>
        <li>Altro................................</li>
      </ul>
      <p>&nbsp;</p>
      <p>2. In quali momenti della giornata mi sento pi&ugrave; solo?</p>
      <p>&nbsp;</p>
      <ul>
        <li>Che tipo di emozioni provo?</li>
        <li>Perch&egrave; mi succede proprio in questi momenti e non in altri?</li>
        <li>Quali sono i miei pensieri?</li>
        <li>Quanto dura questo stato d&rsquo;animo?</li>
        <li>Come mi comporto?</li>
        <li>Faccio qualcosa per superarlo?</li>
      </ul>
      <p>&nbsp;</p>
      <p>3. Ho gi&agrave; provato, in altri momenti della vita, a sentirmi solo?</p>
      <p>&nbsp;</p>
      <ul>
        <li>A che et&agrave;?</li>
        <li>Per quali motivi?</li>
        <li>Come reagivo a queste situazioni?</li>
        <li>Riuscivo a migliorarle?</li>
        <li>Riuscivo a sentirmi meglio?</li>
      </ul>
      <p>&nbsp;</p>
      <p>4. In questo periodo che cosa riuscirebbe a farmi sentire meno solo?</p>
      <p>&nbsp;</p>
      <ul>
        <li>La presenza di chi non c'&egrave; pi&ugrave; o di chi &egrave; andato via&nbsp;</li>
        <li>La presenza di qualcuno che mi dia una mano nelle incom&shy;benze di tutti i giorni</li>
        <li>La possibilit&agrave; di intraprende&shy;re nuove attivit&agrave; e coltivare nuovi interessi</li>
        <li>Sapere che c'&egrave; qualcuno cui importa se mi alzo o no la mattina</li>
        <li>La possibilit&agrave; di parlare con altri, di avere uno scambio</li>
        <li>La possibilit&agrave; di rendermi utile</li>
        <li>Un nuovo amore&nbsp;</li>
        <li>Pi&ugrave; contatti familiari</li>
        <li>Altro.......</li>
      </ul>
      <p>&nbsp;</p>
      <p>5. E infine...........</p>
      <p>&nbsp;</p>
      <ul>
        <li>Cosa potrei fare per sentirmi meno solo?</li>
        <li>Riuscirei a farlo?</li>
        <li>Qual &egrave; la prima cosa che direi a una persona che si lamen&shy;tasse con me perch&eacute; si sente sola?</li>
        <li>Riuscirei a fare quello che consiglio?</li>
      </ul>
      <p>&nbsp;</p>
      <p style={{ alignSelf: "center" }}>PARTE II</p>
      <p>&nbsp;</p>
      <p>Il secondo percorso da compiere riguarda il rapporto con gli altri. Pensate alla giornata o alla settimana appena trascorsa e provate a vedere qual &egrave; stato il vostro effettivo comporta&shy;mento, nel contatto con gli altri, siano essi familiari, paren&shy;ti, amici o sconosciuti.</p>
      <p>&nbsp;</p>
      <p>1. Che cosa vorrei ricevere dagli altri?</p>
      <p>2. Che cosa effettivamente ricevo?</p>
      <p>3. Che cosa do agli altri, in termini di tempo e attenzione?&nbsp;</p>
      <p>4. Come mi comporto durante l'interazione con l'altro/altri?&nbsp;</p>
      <p>5. Sono in grado di cogliere i bisogni altrui?&nbsp;</p>
      <p>6. Aspetto sempre che sia l'altro a chiedere, quando ha bisogno?</p>
      <p>7. Ricordo le cose a cui l'altro tiene? Ricordo le cose che dice?&nbsp;</p>
      <p>8. Difendo a qualunque costo le mie convinzioni?</p>
      <p>9.&nbsp; Come reagisco, quando qualcuno mi racconta un suo problema?</p>
      <p>10. Come mi comporto, quando faccio delle domande?</p>
      <p>11. Assumo atteggiamenti, senza volerlo, che infastidiscono? (es. mi faccio troppo vicino, non guardo mai negli occhi, interrompo gli altri quando parlano, sono imprevedibile ed incoerente nelle manifestazioni, a volte do la mano a volte non la do, giro la testa mentre l'altro parla, volto le spalle, parlo a voce troppo bassa ecc. ecc.)</p>
      <p>12. Quando conosco qualche persona nuova, cerco di "accaparrar&shy;mela"?&nbsp;</p>
      <p>13. Ho delle aspettative molto elevate nei confronti delle nuove conoscenze?</p>
      <p>14. Ho molto bisogno di essere stimato e considerato?</p>
      <p>15. Tendo a lasciar andare alcune amicizie, quando ne trovo altre?</p>
      <p>16. Sono costante nei miei rapporti interpersonali?</p>
      <p>17. Sono molto preoccupato di fare bella figura?</p>
      <p>18. Sono convinto che gli altri siano pi&ugrave; felici di me?</p>
      <p>19. Sono convinto che gli altri siano pi&ugrave; bravi di me?</p>
      <p>20. Penso che una critica valga pi&ugrave; di cento complimenti?</p>
      <p>21. Penso che in amicizia non si debba selezionare e che a tutti vadano fatte le proprie confidenze?</p>
      <p>22. Mi capita di parlare male di qualcuno con altre persone? Quan&shy;te volte mi capita?</p>
      <p>23. Mentre una persona mi parla, penso che fondamentalmente la gente sia tutta uguale, e pensi solo a fregarti o cerchi solo l'occasione per parlare male?</p>
      <p>24. Riferisco ad altri le confidenze fatte a me?</p>
      <p>25. Sono convinto che "la prima impressione &egrave; quella che conta"?</p>
      <p>&nbsp;</p>
      <p>Nella sua durezza, la solitudine pu&ograve; diventare un'occasione di crescita e maturazione. Pu&ograve; servire per capire meglio chi siamo, che cosa vogliamo, di che cosa abbiamo bisogno.</p>
      <p>Pu&ograve; offrire la possibilit&agrave; di mettere a fuoco le proprie esigenze e debolezze, i propri timori e le proprie capacit&agrave;.</p>
      <p>A volte essere soli significa capire meglio cosa vuol dire stare con gli altri e imparare a farlo senza essere di peso, senza cercare di dominare o usare le persone con cui si creano nuovi rapporti.</p>
      <p>La solitudine insomma non &egrave; sempre quel tempo vuoto, fatto di assenze, come di solito si pensa.</p>
      <p>Se diventa un problema serio, causa di sofferenza psicologica e aumentata vulnerabilit&agrave; alle malattie, &egrave; opportuno affrontarlo con seriet&agrave; e chiarezza, individuando obiettivi ben definiti che portino a modificare la situazione o almeno a renderla meno dolorosa.</p>
    </>
  ],
  [
    "la-motivazione",
    <>
      <h2>La motivazione</h2>
      <p>&nbsp;</p>
      <p>&Egrave; un fattore dinamico, che determina il comportamento individuale e collettivo in vista di uno scopo.</p>
      <p>Si sente spesso dire nel linguaggio colloquiale che quel tale &egrave; "molto motivato", oppure che "gli manca una motivazione adeguata". Questo ci dice che la motivazione pu&ograve; esserci o non esserci, pu&ograve; esserci e venire meno, pu&ograve; non esserci inizialmente e crescere pian piano, e che anche se siamo persone tendenti all'ottimismo, non possiamo dare per scontato di essere sempre sostenuti, in tutte le contingenze della vita, da una forte motivazione.</p>
      <p>La motivazione si basa su alcuni fattori essenziali, e se questi sono mancanti &egrave; fondamentale attuare una sorta di lavoro preparatorio che coinvolga l'intera personalit&agrave; o perlomeno alcuni aspetti importanti di essa. La motivazione nasce su un terreno di coltura costituito essenzialmente dalla fiducia.</p>
      <p>Fiducia in s&eacute;, fiducia negli altri, fiducia nel futuro.</p>
      <p>Avere fiducia non significa sottovalutare i problemi, le difficolt&agrave;, il rischio di fallimento, ma significa credere a sufficienza in s&eacute;, negli altri e nella possibilit&agrave; di crescere, di cambiare, di raggiungere una meta.</p>
      <p>Quando le persone vivono costantemente in un clima dominato dal pessimismo, dall&rsquo;insoddisfazione, dalla disistima, dal criticismo, dalla paura di perdere le proprie sicurezze, dalla colpevolizzazione, difficilmente si dimostrano motivate, e tendono piuttosto ad arrendersi o defilarsi rifiutando di assumere il rischio di una scelta e la fatica di portarla avanti.</p>
      <p>Infatti, il raggiungimento di un obiettivo presuppone la perseveranza (= per severus, cio&egrave; oltremodo duro) e dunque l&rsquo;accettazione di un impegno.</p>
      <p>In genere, le persone amano avere a che fare con chi apprezza, loda, riconosce e se &egrave; il caso critica, ma in modo rispettoso e costruttivo, ed evita invece chi si lamenta in continuazione, rimprovera, trova solo difetti e mancanze. Ci&ograve; non significa che si possano sempre evitare i giudizi negativi. Se sono fondati, pensiamo allora a come modificare i nostri comportamenti, anzich&eacute; demotivarci per quelle che riteniamo caratteristiche immutabili e negative della nostra personalit&agrave;.</p>
      <p>Se non sono fondati, difendiamoci con il senso dello humor e ampliando le nostre relazioni sociali.</p>
      <p>La frase &ldquo;&egrave; normale cos&igrave;, non c&rsquo;&egrave; nulla di speciale&rdquo; nasconde il germe che pu&ograve; uccidere qualunque motivazione, ma porta a compiere anche un altro errore: credere che il successo delle proprie azioni sia causale.</p>
      <p>Il successo ha sempre delle ragioni, e riconoscerle aiuta a replicarle in futuro.&nbsp;</p>
      <p>Non a caso &ldquo;motivazione&rdquo; deriva da <em>motus</em>, cio&egrave; movimento. La motivazione parte dal presente ma parla al futuro, il che richiede una buona capacit&agrave; di porsi degli obiettivi.</p>
      <p>Dice il proverbio che non esiste porto per il marinaio che non sa dove condurre la nave, ma il marinaio che non sente sua la nave e dunque non se ne sente responsabile, o che non crede esistano porti o che dispera di arrivarci, non pu&ograve; promuovere dentro di s&eacute; la giusta motivazione per afferrare il timone e mettere al vento le vele.</p>
      <p>Non possiamo negare l&rsquo;influenza delle generazioni passate sulla nostra vita sociale e personale.</p>
      <p>Nelle terapie familiari, &egrave; evidente, anche quando non sono presenti nella famiglia perch&eacute; mancati da tempo, l&rsquo;influenza dei nonni o addirittura dei bisnonni.</p>
      <p>Nelle terapie individuali, anche in quelle pi&ugrave; concentrate sul qui e ora, emergono inevitabilmente episodi e considerazioni legati all&rsquo;infanzia. Essere consapevoli del grado di importanza di queste influenze pu&ograve; essere di aiuto, per ritrovare fiducia se positive o per lasciarle alle spalle se negative.</p>
      <p>Il passato non pu&ograve; essere cambiato, ma &egrave; possibile agire sul presente cominciando a rinunciare all&rsquo;idea nefasta che chi &egrave; stato vittima in passato lo sar&agrave; per sempre.</p>
      <p>La mancanza di autostima pu&ograve; portare a non premiarsi per i successi ottenuti, mentre &egrave; fondamentale gratificarsi (e gratificare gli altri) quando si realizza qualcosa di buono.</p>
      <p>Non &egrave; facile, perch&eacute; significa fare i conti con la necessit&agrave; di assumersi responsabilit&agrave; e anche con l&rsquo;inevitabile conseguenza di ogni esperienza di successo: l&rsquo;aumento dell&rsquo;autostima. Per quanto appaia strano, sentirsi in gamba e capaci pu&ograve; scatenare il panico, il terrore di dover essere sempre all&rsquo;altezza, di essere manipolati da chi non vuole prendere impegni, o pu&ograve; suscitare sensi di colpa per essere stati bravi, pi&ugrave; dei genitori o dei fratelli.</p>
      <p>Il gruppo &egrave; fondamentale per stimolare la speranza e condividere i momenti duri della fatica e dell&rsquo;attesa dei risultati.</p>
      <p>Motivare gli altri presuppone innanzitutto la motivazione personale: pi&ugrave; delle parole, &egrave; il linguaggio non verbale che dice se la persona che abbiamo davanti &egrave; positiva, prova entusiasmo e voglia di fare; se mostra la capacit&agrave; di essere attenta e di valorizzare quello che c&rsquo;&egrave; di buono, sulla base di un sano realismo che ammette la validit&agrave; delle soluzioni parziali e dei piccoli passi.</p>
      <p>Ogni esperienza ha un forte valore informativo, pi&ugrave; di quello che siamo abituati a pensare. Dice molte cose sul mondo circostante ma anche su di noi.&nbsp;</p>
      <p>Chi ha il difficile compito della leadership &ndash; pu&ograve; essere un genitore che si chiede come motivare i figli a collaborare nelle faccende domestiche, un insegnante che vorrebbe gli alunni interessati allo studio, un rappresentante politico o sindacale che punta a stimolare le persone alla partecipazione, un educatore che vuole spingere un ragazzo &ldquo;difficile&rdquo; ad abbandonare comportamenti antisociali, un dirigente d&rsquo;azienda che spera di coinvolgere i dipendenti in un certo progetto, un allenatore che prepara la squadra in vista di una trasferta difficile e cos&igrave; via &ndash; deve essere motivato in prima persona e comportarsi in modo coerente.</p>
      <p><em>Fate come dico, non fate come faccio</em> &egrave; un atteggiamento che non funziona. L&rsquo;ostilit&agrave;, la paura degli altri, l&rsquo;indifferenza, l&rsquo;invidia, sono sentimenti che una guida deve considerare con molta attenzione ed essere disposto ad affrontare e rielaborare.</p>
      <p>Dimostrare stima e ascolto, valorizzare i contributi e le qualit&agrave; degli altri, cogliere in tempo i segnali di demotivazione, sono comportamenti difficili da mettere in pratica, quando si &egrave; condizionati da stati d&rsquo;animo negativi.</p>
      <p>Si ottiene fiducia quando gli altri capiscono che il leader mantiene la parola e gli impegni presi, che &egrave; riservato, presente nel momento in cui si ha bisogno di lui, che &egrave; chiaro e trasparente nelle comunicazioni, che &egrave; pronto a riconoscere i meriti altrui, che non nasconde i propri errori e soprattutto non li scarica sugli altri, che si comporta in modo giusto e coerente, che incoraggia nelle difficolt&agrave;.</p>
      <p>Se non riscuote fiducia, se non s&rsquo;interessa a quello che pensa l&rsquo;altro, se dimentica quanto il silenzio possa essere demotivante, se non condivide le proprie idee, perde autorevolezza e manca gli obiettivi.</p>
      <p>Possiamo domandarci se l&rsquo;autorit&agrave; permette di risolvere il problema.</p>
      <p>Si pu&ograve; ottenere l&rsquo;adesione degli altri per obbedienza, non per adesione convinta, ma questo significa fondamentalmente che l&rsquo;altro vive in una condizione infantile, dove sono altri a guidarlo e a decidere per lui.</p>
      <p>A volte le persone possono aspettare gli ordini perch&eacute; non si credono capaci di prendere iniziative, e allora il leader capace utilizza il suo carisma per compiere un lavoro preparatorio che porti le persone a crescere, a diventare adulte e responsabili.</p>
      <p>Questo significa anche preparare il terreno per una propria uscita, prospettiva ansiogena per chi ha bisogno che gli altri non possano operare senza di lui.</p>
      <p>Quando non si parla della motivazione di una singola persona, ma di un gruppo (una classe, un&rsquo;associazione, un&rsquo;azienda), il successo &egrave; facilitato dalla chiarezza degli obiettivi, dei rispettivi ruoli e dei compiti che ognuno pu&ograve; portare avanti.</p>
      <p>Sono indispensabili la franchezza nell&rsquo;affrontare dissapori e conflitti, la capacit&agrave; di affrontare le difficolt&agrave; come occasioni di crescita e non come fattori demotivanti, la capacit&agrave; di non farsi abbattere da una critica, la volont&agrave; di dare il meglio di s&eacute; e di favorire negli altri lo stesso desiderio.</p>
      <p>Le grandi organizzazioni (aziende, partiti politici, associazioni, parrocchie, sindacati ecc.) funzionano bene quando i vari componenti comunicano fra loro. La gelosia, la diffidenza, l&rsquo;andare ognuno per proprio conto, alla lunga, sono fatali.</p>
      <p>&nbsp;</p>
      <h3>Motivazione e obiettivi</h3>
      <p>&nbsp;</p>
      <p>Ognuno di noi, anche senza parole, sa quando si sente motivato e quando no. La motivazione &egrave; un atteggiamento complesso fatto di cognizioni e di emozioni, e sono proprio le emozioni, le nostre reazioni viscerali, a darci la &ldquo;temperatura&rdquo; della nostra motivazione.</p>
      <p>Quando ci sentiamo entusiasti, sicuri di noi, possiamo dire che la nostra motivazione a raggiungere quel dato obiettivo &egrave; alta; quando siamo stanchi, delusi o tristi, sentiamo invece che la meta &egrave; lontana o che non abbiamo le forze per arrivarci.</p>
      <p>Ma la motivazione si alimenta anche dei nostri pensieri, atteggiamenti, valutazioni.</p>
      <p>Una prima domanda cui rispondere &egrave;: l&rsquo;obiettivo che mi pongo &egrave; davvero importante per me? Se non lo &egrave;, inutile cercare di motivarsi facendo finta che lo sia. Meglio valutarlo onestamente e trarne le conseguenze, che non portano necessariamente all&rsquo;abbandono di quell&rsquo;obiettivo: a volte facciamo cose che non ci interessano perch&eacute; questo fa piacere a un&rsquo;altra persona, e a volte anche questa &egrave; una motivazione sufficiente per farle. L&rsquo;importante &egrave; diventarne consapevoli.</p>
      <p>Qualche volta siamo noi stessi a demotivarci continuando a rimuginare sulle nostre incapacit&agrave; e difetti, o a perseguire obiettivi che non sono alla nostra portata, o a seguire strategie inadeguate per raggiungere obiettivi che invece sono alla nostra portata, o a volere troppo in fretta o volere tutto e subito.</p>
      <p>Saper attendere, avere la pazienza necessaria, pu&ograve; volgere le cose a nostro favore.</p>
      <p>Gli obiettivi si raggiungono individuando mete vicine (le cosiddette mete operative) e mete lontane ma pi&ugrave; importanti (le cosiddette mete strategiche) e i tempi vanno stabiliti con chiarezza.</p>
      <p>Se si fatica a raggiungere una meta o ci si trova sempre nella necessit&agrave; di rimediare ai ritardi, occorre fare una valutazione sul fattore tempo.</p>
      <p>Forse siamo stati troppo ottimisti nelle previsioni e dobbiamo concedere al nostro progetto qualche giorno o qualche settimana in pi&ugrave;.</p>
      <p>Oppure non dobbiamo farci distrarre lungo il cammino o abbandonarci alla pigrizia.</p>
      <p>Qualche volta ci troviamo in difficolt&agrave; perch&eacute; l&rsquo;obiettivo non &egrave; chiaro o cambia in continuazione: ci troviamo nella condizione del leone disorientato dalle strisce bianche e nere nei mantelli di un branco di zebre in fuga, che fallisce la preda.</p>
      <p>Un&rsquo;altra funzione cognitiva si rivela indispensabile per raggiungere gli obiettivi e dunque per vedere gratificata la propria motivazione: la capacit&agrave; di concentrazione.</p>
      <p>Questa pu&ograve; essere esercitata visualizzando i passi che dobbiamo compiere per raggiungere un obiettivo, le difficolt&agrave; che potremmo incontrare, le opportunit&agrave; che potrebbero aprirsi.</p>
      <p>Una sorta di film di cui siamo registi, attori, sceneggiatori.</p>
      <p>E&rsquo; una tecnica utilizzata spontaneamente anche da persone che devono prepararsi a una conferenza con contraddittorio, a una partita di calcio, a una dichiarazione d&rsquo;amore.</p>
      <p>L&rsquo;importante &egrave; non essere dominati esageratamente dalle emozioni, perch&eacute; in quel caso il film non risponderebbe a criteri sufficientemente oggettivi e non sarebbe di aiuto.</p>
    </>
  ],
  [
    "emozioni-e-motivazioni",
    <>
      <h2>Emozioni e motivazioni</h2>
      <p>&nbsp;</p>
      <p>Le emozioni sono reazioni, pi&ugrave; o meno intense, che rivelano la tendenza all'avvicinamento o all'allontanamento da un determinato stimolo.&nbsp;</p>
      <p>Le emozioni possono essere osservate perch&eacute; si esprimono nel comportamento e in certi mutamenti fisiologici: attraverso i gesti, le espressioni del volto, la modulazione della voce, la postura, il pallore, il rossore, le mani sudate ecc., i nostri interlocutori riescono a cogliere le nostre emozioni, mentre noi possiamo non esserne consapevoli. Soggettivamente, le emozioni sono vissute come esperienze piacevoli o meno.</p>
      <p>La variet&agrave; delle emozioni nell'uomo &egrave; straordinaria: abbracciano qualunque tipo di comportamento motivato.</p>
      <p>In condizioni normali, l'emozione d&agrave; luogo a comportamenti appropriati, consoni alla situazione che l'ha provocata.</p>
      <p>Diventa invece una risposta disorganizzata, che impedisce all'organismo il giusto adattamento, quando &egrave; eccessiva. Questi aspetti negativi dell&rsquo;emozione sono particolarmente visibili nei disordini mentali o somatici.&nbsp;</p>
      <p>Solo di rado le emozioni eccessive sono la giusta risposta a stati di emergenza, cui bisogna far fronte con tutta la propria energia: spesso sono reazioni a pericoli immaginati, presunti, anticipati, e il loro continuo ripetersi pu&ograve; avere un effetto nocivo.</p>
      <p>Si pensa, con Darwin, che l'espressione delle emozioni sia stata fondamentale per la nostra sopravvivenza.</p>
      <p>Anche negli animali notiamo reazioni funzionali alla sopravvivenza: in quelli pi&ugrave; semplici, come i molluschi, si tratta di reazioni piuttosto primitive, che si scatenano con il contatto.</p>
      <p>In presenza di un sistema nervoso pi&ugrave; evoluto, la presenza dell'udito, della vista, dell'olfatto, permette di localizzare in anticipo le possibili minacce.</p>
      <p>Senza bisogno di un contatto diretto che dice "fuggi/combatti/immobilizzati/resta mimetizzato", l'individuo aumenta la consapevolezza e il controllo dell'ambiente circostante.</p>
      <p>Le emozioni sono date geneticamente ma si modificano e diventano pi&ugrave; raffinate e complesse nel corso dello sviluppo individuale a causa dell'apprendimento.</p>
      <p>In conformit&agrave; con le esigenze sociali, sono espresse o contenute.</p>
      <p>Affettivit&agrave; e intelligenza sono indissociabili e costituiscono i due aspetti fondamentali di ogni condotta umana.&nbsp;</p>
      <p>I processi cognitivi ci permettono di analizzare la realt&agrave; in vista delle realizzazioni che ci proponiamo. Pu&ograve; succedere che questo equilibrio si spezzi: le emozioni diventano cos&igrave; intense da disorganizzare la percezione corretta, la memoria, la capacit&agrave; di apprendere e di progettare.</p>
      <p>I pensieri, gli atteggiamenti, i comportamenti diventano inadeguati e controproducenti.</p>
      <p>Di solito diciamo di aver perso la capacit&agrave; di controllo e per recuperarla possiamo cercare di &ldquo;mettere il coperchio&rdquo; alle emozioni, cio&egrave; silenziarle, negarle. Questo per&ograve; non significa ridare il primato ai processi cognitivi, perch&eacute; l'inibizione affettiva inibisce anche l'intelligenza.&nbsp;</p>
      <p>Se una parte importante del mondo interiore perde visibilit&agrave; e non pu&ograve; essere percepita perch&eacute; causerebbe troppa paura e dolore, anche ci&ograve; che appartiene al mondo esterno e che richiama la parte nascosta di noi, pu&ograve; essere negato o distorto.&nbsp;</p>
      <p>Si compromettono cos&igrave; il buon adattamento, l'interpretazione corretta del significato degli eventi e diminuiscono la curiosit&agrave;, la capacit&agrave; di apprendere e di dare risposte nuove e creative.</p>
      <p>Rimettere in moto i processi cognitivi vuol dire innanzitutto utilizzarli per recuperare pensieri ed emozioni e per valutarli in modo adeguato: le emozioni in particolare possono riprendere la loro visibilit&agrave; parallelamente all'aumento della sensazione di padronanza.&nbsp;</p>
      <p>Aumenta la conoscenza di s&eacute;, si elaborano risposte flessibili e migliorano le capacit&agrave; sociali.</p>
      <p>Si diventa pi&ugrave; abili nella valutazione delle situazioni e nelle decisioni da prendere in proposito: ad esempio, tutti noi riteniamo opportuno e utile essere autonomi, ma se ci rompiamo una mano e rifiutiamo ostinatamente qualunque aiuto, dimostriamo di essere cos&igrave; rigidi da non saperci adattare alla nuova situazione, con notevole danno per noi e probabilmente anche per i nostri rapporti con gli altri.</p>
      <p>A parit&agrave; di quoziente d'intelligenza, le persone pi&ugrave; competenti da un punto di vista emotivo e sociale sono pi&ugrave; stabili, cio&egrave; si turbano quando &egrave; opportuno, e sono maggiormente in grado di recuperare la stabilit&agrave; quando questa &egrave; stata turbata.&nbsp;</p>
      <p>Questa competenza non si acquisisce dal nulla: il suo apprendimento comincia presto nella vita, molto prima dell'ingresso a scuola.</p>
      <p>Il cervello del bambino, cos&igrave; plastico e in grado di apprendere rapidamente, assorbe dall'ambiente circostante ci&ograve; che poi si riveler&agrave; decisivo nei rapporti con gli altri: la fiducia, la paura, l'aggressivit&agrave;, la rassicurazione, la freddezza, la comprensione.</p>
      <p>Oltre alla famiglia, la scuola dovrebbe essere un luogo privilegiato per l'acquisizione delle competenze emotive e sociali.&nbsp;</p>
      <p>Gli insegnanti sono generalmente preoccupati di non riuscire a terminare il programma, ma sanno che le lacune pi&ugrave; pericolose sono quelle che riguardano la gestione delle emozioni, in primo luogo dell'aggressivit&agrave;.</p>
      <p>I bambini irascibili sono spesso isolati dagli altri che non tollerano il loro modo di fare. Questi bambini tendono a interpretare in modo automatico i comportamenti altrui in termini di attacco e reagiscono in modo altrettanto automatico, con l'attacco.&nbsp;</p>
      <p>Il loro problema pi&ugrave; grosso &egrave; l'impulsivit&agrave;, che &egrave; all'origine non solo dei problemi con i coetanei ma anche del loro insuccesso scolastico.&nbsp;</p>
      <p>Anche se hanno quoziente di intelligenza normale, gli altri tendono a considerati poco intelligenti.</p>
      <p>Questi bambini hanno bisogno di imparare strategie alternative: fermarsi a pensare prima di reagire, non reagire con l'attacco fisico o uno sfogo di rabbia ma in altri modi pi&ugrave; produttivi, comprendere i propri segnali corporei che dicono "attenzione, se vai avanti esplodi", comprendere che cosa in particolare fa scattare la rabbia.&nbsp;</p>
      <p>Queste strategie possono essere insegnate esplicitamente, ma un vero educatore le trasmette anche implicitamente, col proprio modo di fare.&nbsp;</p>
      <p>I bambini imparano molte cose vedendo come i genitori, gli insegnanti, gli adulti in genere si comportano nei loro confronti e imparano a rispondere alle domande fondamentali</p>
      <p>&nbsp;</p>
      <ul>
        <li>Cosa devo pensare di me stesso: sono buono, sono cattivo, sono capace, sono inetto, sono di troppo.......</li>
        <li>come reagiranno gli altri alle mie emozioni: le ignorano, le accolgono, le minimizzano, le stravolgono....</li>
        <li>come si fa a esprimere sentimenti: urlando, parlando......</li>
        <li>come si distinguono le emozioni: sono arrabbiato, impaurito, triste o tutte e tre.....</li>
      </ul>
      <p>&nbsp;</p>
      <p>Saper controllare gli impulsi, ridurre lo stress, riconoscere la differenza tra sentimento e azione permette di ottenere dei successi che alimenteranno l'immagine positiva di s&eacute; e la capacit&agrave; di relazionarsi con gli altri, tutte cose che sono alla base della realizzazione personale.</p>
      <p>&nbsp;</p>
      <p>Considerando le nostre azioni, molte volte sappiamo che abbiamo desiderato fare una certa cosa, senza per&ograve; essere in grado di spiegare bene perch&eacute;, e ci accontentiamo di dire: &laquo;Perch&eacute; mi andava&raquo;, oppure, ancora pi&ugrave; semplicemente: &laquo;Perch&eacute;? Perch&eacute; s&igrave;!&raquo;.</p>
      <p>I bisogni che ci spingono ad agire sono definiti "motivazioni", che &egrave; quanto d&agrave; l'avvio a comportamenti nuovi oppure ripetitivi.</p>
      <p>Poich&eacute; l'uomo &egrave; un essere molto complesso, anche le sue motivazioni non sono sempre di facile comprensione.</p>
      <p>Perch&eacute;, ad esempio, una persona ha comprato un certo oggetto? Forse perch&eacute; era necessario per la casa o il lavoro, forse perch&eacute; gli piaceva esteticamente, o era legato a un desiderio dell'infanzia; oppure perch&eacute; era un buon investimento, un simbolo di successo... oppure, perch&eacute; mi andava!</p>
      <p>Il problema sta nel fatto che la motivazione &egrave; un fattore interno, non direttamente osservabile: si deduce solo dal comportamento.</p>
      <p>Sono state formulate molte teorie psicologiche con lo scopo di descrivere, raggruppare, spiegare le motivazioni. Una delle pi&ugrave; convincenti &egrave; quella dello psicologo americano Abraham Maslow, illustrata nel suo libro "Motivazione e personalit&agrave;".</p>
      <p>Maslow ha individuato cinque bisogni fondamentali che spingono l'uomo all'azione, bisogni che appaiono ordinati gerarchicamente: al primo posto, ci sono i bisogni fisiologici, quali la fame, la sete, il sonno, il movimento, il sesso, necessari per la sopravvivenza e la continuazione della specie.</p>
      <p>Hanno bisogno di essere sempre soddisfatti (se facciamo eccezione per il sesso, la cui rinuncia non comporta un pericolo di vita per l'organismo) e anche se nella nostra societ&agrave; opulenta la maggior parte delle persone ha dimenticato cosa significhi lottare per il cibo, basta un inconveniente durante una gita (un ristorante chiuso, lo smarrimento dei cestini) per sentirsi come un predatore della savana in preda ai morsi della fame.</p>
      <p>Oltre a mangiare e bere, abbiamo bisogno di sentirci liberi dall'ansia e sufficientemente protetti, al riparo da eventi minacciosi e imprevedibili.</p>
      <p>In situazioni di disgregazione sociale, di ondate di criminalit&agrave;, o durante le guerre o le gravi crisi economiche, le persone possono manifestare il bisogno di sicurezza in modi che comportano la restrizione della libert&agrave; personale: per esempio, possono invocare &ldquo;l'uomo forte&rdquo;, &ldquo;l&rsquo;uomo solo al comando&rdquo; al quale delegare tutte le decisioni, compresa quella di gestire i diritti fondamentali.</p>
      <p>Oppure possono cercare un capro espiatorio (un gruppo emarginato, una minoranza etnica, in ogni caso qualcuno di fragile) sul quale sfogare la paura e la frustrazione.</p>
      <p>Il desiderio di sicurezza &egrave; talmente radicato che solo l'adesione convinta a certi valori pu&ograve; proteggerci dal ritorno a comportamenti infantili di ricerca di qualcuno cui delegare la facolt&agrave; di pensare e decidere per noi.</p>
      <p>Oltre al bisogno di sicurezza, gli esseri umani cercano amore e affetto: hanno bisogno di amare e di essere amati, di vivere 1'appartenenza a una famiglia, a un gruppo, a una comunit&agrave;.</p>
      <p>Sono noti gli effetti devastanti della divisione delle famiglie a causa delle guerre, dell'abbandono delle proprie case, del proprio territorio, della propria nazione. La persona si sente "sradicata", anche se non &egrave; detto che comprenda appieno le ragioni del proprio disagio, espresse frequentemente sotto forma di disturbi fisici.</p>
      <p>Oltre all'amore, gli esseri umani hanno bisogno di stima, cio&egrave; di rassicurazione circa il proprio valore: chi non &egrave; stimato potrebbe facilmente cadere in uno stato di inferiorit&agrave;, di depressione, di rinuncia o al contrario reagire in modo negativo e aggressivo.</p>
      <p>Al culmine, troviamo il bisogno di autorealizzazione, che viene spiegato cos&igrave; da Maslow: &laquo;Ci&ograve; che uno pu&ograve; essere, deve esserlo&raquo;.</p>
      <p>Realizzarsi non significa necessariamente diventare famosi e occupare posizioni di prestigio, quanto piuttosto poter esprimere le proprie qualit&agrave;, in qualunque campo si vogliano esprimere.</p>
      <p>Attorno a questi bisogni fondamentali, ne ruotano molti altri: il bisogno di conoscere, la curiosit&agrave;, il bisogno di stimoli nuovi ecc.</p>
      <p>Secondo Maslow, tutti questi bisogni sono tipici dell'uomo, proprio come se fossero istinti.</p>
      <p>Proviamo ora a paragonare il concetto di istinto a quello di motivazione.</p>
      <p>In passato si tendeva spesso a spiegare il comportamento umano e animale con gli istinti, cio&egrave; con modelli di attivit&agrave; innati e immutabili.</p>
      <p>Con l'avvento della scuola comportamentista che dava importanza esclusivamente all'apprendimento, la teoria degli istinti cadde in disuso e fu ripresa solo negli anni Cinquanta, quando etologi come Konrad Lorenz affermarono che anche nell'uomo, come negli animali, esistono comportamenti che non sono appresi e che sono di natura ereditaria.</p>
      <p>La controversia fattori innati/fattori ambientali si &egrave; per&ograve; lentamente stemperata in una visione meno unilaterale e pi&ugrave; completa. Qualsiasi forma di comportamento &egrave; plasmata da elementi congeniti e acquisiti.</p>
      <p>Se &egrave; vero che talune motivazioni umane hanno un'origine innata, &egrave; anche vero che &egrave; impossibile trovare queste motivazioni nella loro forma pura: nell'uomo interviene sempre l'esperienza, intervengono i valori culturali del gruppo di cui fa parte.</p>
      <p>I comportamenti umani non sono rigidi e immutabili ed essendo il bambino molto immaturo al momento della nascita, &egrave; decisiva l'influenza dell'ambiente.</p>
      <p>E' stato detto che l'uomo &egrave; un animale pieno di bisogni. Infatti, appena soddisfatto un desiderio, subito se ne affaccia un altro.</p>
      <p>Ci&ograve; non significa che l'uomo sia sempre consapevole delle proprie motivazioni (la cui etimologia ci riporta al termine latino <em>motus</em>, movimento, indicando di qualcosa di mobile, vitale e dinamico).</p>
      <p>Esistono motivazioni inconsce che possono determinare problemi seri.</p>
      <p>Perch&eacute; in certi casi, la persona persegue una condotta autodistruttiva e rifiuta di adottare comportamenti pi&ugrave; adeguati? Evidentemente sono presenti motivi che la stessa persona pu&ograve; ignorare, oppure, pur comprendendoli, non riesce a sottrarsi alla loro forza.</p>
      <p>La ricerca di sicurezza, come abbiamo visto, o la mancanza di stima, possono confondere le idee al punto da spingere la persona ad affidarsi a chi obiettivamente le fa e le far&agrave; del male.</p>
      <p>Che rapporto c'&egrave; tra motivazioni ed emozioni?</p>
      <p>Le nostre necessit&agrave;, i nostri bisogni, i nostri desideri, sono accompagnati dalle emozioni, che sono un po' il colore di ogni azione, di ogni comportamento. Le emozioni possono facilitarci l'azione o comprometterla, migliorare o peggiorare ogni nostra prestazione.</p>
      <p>Una persona pu&ograve; essere fortemente motivata a voler finire l'universit&agrave;, perch&eacute; ama studiare, vuole svolgere proprio quella professione, spera di ricavarne vantaggi economici: eppure, l'ansia di esprimersi davanti a un professore durante gli esami pu&ograve; diventare addirittura paralizzante e compromettere la buona riuscita degli studi.</p>
      <p>L&rsquo;individuo in preda a un&rsquo;emozione si comporta in modo osservabile: piange, ride, trema, impallidisce.</p>
      <p>Anche quando non c'&egrave; una comunicazione verbale diretta, &egrave; possibile farsi un'idea di che cosa sta provando l'altra persona (attenzione per&ograve; a non cadere nell'errore di chi insiste perch&eacute; l'altro confermi le sue geniali intuizioni! Nessuno &egrave; in grado d&igrave; leggere nella mente di un altro, e anche se abbiamo colto correttamente il tipo di emozione, non significa che sappiamo automaticamente a cosa sia dovuta).</p>
      <p>La gestione delle emozioni &egrave; fondamentale e non va confusa con la negazione o la repressione. Possiamo anzi dire che &egrave; l&rsquo;esatto contrario.</p>
      <p>Le emozioni negate o represse mantengono tutta la loro forza dirompente e tanta energia psichica si consuma nello sforzo di tenerle fuori dal campo della coscienza.</p>
      <p>E&rsquo; importante invece che le emozioni abbiano <em>diritto di parola</em>, nel senso pieno del termine, cio&egrave; possano esprimersi attraverso il linguaggio.</p>
      <p>Parlare vuol dire frapporre uno spazio di riflessione tra l&rsquo;emozione e l&rsquo;impulso ad agire, vuol dire assegnare un nome allo sconvolgimento interiore rendendolo meno minaccioso e utilizzandolo come importante occasione per conoscersi meglio, per aumentare il proprio senso di adeguatezza e capacit&agrave;, e per assumere comportamenti pi&ugrave; soddisfacenti.</p>
    </>
  ],
  [
    "disturbi-d-ansia",
    <>
      <h2>I disturbi d'ansia</h2>
      <p>&nbsp;</p>
      <p>Il termine <em>paura</em> deriva dalla radice indoeuropea <em>pat,</em> con il significato di <em>percuotere, </em>ed &egrave; passata al latino <em>pavero</em> con il significato di temere, sbigottire, essere atterriti.</p>
      <p>La paura &egrave; un&rsquo;emozione che si &egrave; sviluppata nel mondo animale e umano nel corso dell&rsquo;evoluzione, con lo scopo principale di proteggere la vita innescando pronte reazioni di attacco, fuga, o immobilizzazione (freezing).</p>
      <p>Quando non svolge pi&ugrave; il suo naturale compito di metterci in guardia di fronte a pericoli reali, pu&ograve; diventare ansia rivolta a eventi possibili ma non certi o evolvere in forme che chiamiamo disturbi.</p>
      <p>Sono caratterizzati da sintomi, vissuti e comportamenti sufficientemente costanti e strutturati da limitare la vita quotidiana e provocare sofferenza a livello fisiologico, cognitivo, emotivo e interpersonale.</p>
      <p>Il termine <em>ansia </em>deriva dal latino medievale <em>anxus</em>, con il significato di <em>stretto</em>, sensazione che chi soffre di un disturbo d&rsquo;ansia conosce molto bene, sia dal punto di vista dei sintomi fisici (difficolt&agrave; respiratorie, tachicardia che fa pensare a morte imminente, chiusura dello stomaco, ecc.) sia di quelli psicologici (impossibilit&agrave; di recarsi in certi luoghi, paura di impazzire, paura di non poter allontanarsi e fuggire, necessit&agrave; di un accompagnatore ecc.).</p>
      <p>Chi soffre per un disturbo d&rsquo;ansia considera gli eventi difficili da affrontare e quello che per la maggior parte delle persone sembra di scarsa importanza emotiva, suscita risonanze tanto forti da sembrare a volte insopportabili.</p>
      <p>Anche sollecitazioni minime possono diventare fonte d&rsquo;inquietudine, angoscia e dolore, e pesare negativamente sulle attivit&agrave; lavorative, familiari e sociali.</p>
      <p>Di solito, si decide di chiedere aiuto quando la situazione non &egrave; pi&ugrave; gestibile e la sofferenza rende difficile la vita a sé e agli altri.</p>
      <p>Naturalmente, sarebbe pi&ugrave; opportuno non aspettare di essere arrivati a questo punto.</p>
      <p>L&rsquo;intervento professionale pu&ograve; essere psicoterapeutico, farmacologico, oppure una combinazione di entrambi.</p>
      <p>La psicoterapia cognitiva dell&rsquo;ansia rimanda alla frase del filosofo greco Epitteto: &laquo;Non sono i fatti in s&eacute; che turbano gli uomini, ma i giudizi che gli uomini formulano sui fatti&raquo;</p>
      <p>In altri termini, &egrave; importante portare alla luce i pensieri, le convinzioni, gli schemi mentali e le modalit&agrave; profonde che ci guidano e condizionano il comportamento, spesso a nostra insaputa, e che l&rsquo;ansia nasconde dietro la sua forza dirompente.</p>
    </>
  ],
  [
    "disturbi-dell-umore",
    <>
      <h2>I disturbi dell'umore</h2>
      <p>&nbsp;</p>
      <p>L&rsquo;umore &egrave; la disposizione a provare sentimenti piacevoli o spiacevoli.</p>
      <p>Si usano comunemente frasi del tipo <em>il mio collega ha la luna</em>, oppure<em> oggi sono di cattivo umore</em>, oppure, <em>come fai a essere sempre di buonumore</em>?</p>
      <p>Tutti sperimentiamo oscillazioni dell&rsquo;umore, soprattutto come risposta a eventi che ci accadono, ma quando queste si strutturano in disturbi che compromettono la normale vita sociale e lavorativa, creano una sofferenza che pu&ograve; richiedere un intervento sia psicoterapico che farmacologico.&nbsp;</p>
      <p>II termine "depressione" &egrave; piuttosto generico e a volte viene usato impropriamente per indicare momenti di tristezza del tutto normali.</p>
      <p>Non &egrave; sempre facile distinguere depressione da dolore e anche nell'ambito della patologia, distinguere le varie forme di depressione, con le loro cause, i loro sintomi, la loro possibile evoluzione.</p>
      <p>Nei disturbi bipolari, le oscillazioni dell&rsquo;umore sono spesso appariscenti e invalidanti, passando dai sintomi della depressione a quelli della maniacalit&agrave;, con un tono dell&rsquo;umore elevato in modo anormale.</p>
      <p>La persona pu&ograve; non essere consapevole della gravit&agrave; del disturbo, oppure, nei periodi in cui l&rsquo;umore rientra in un intervallo di normalit&agrave;, pu&ograve; esprimersi con frasi del tipo: &laquo;Ti senti all&rsquo;improvviso una persona diversa&raquo;.</p>
      <p>Ci sono poi disturbi dell&rsquo;umore dovuti all&rsquo;utilizzo di farmaci (a volte, dei salvavita cui non &egrave; possibile rinunciare e che richiedono dunque un&rsquo;attenta supervisione da parte del medico curante) o all&rsquo;abuso di sostanze, che possono insorgere nella fase di intossicazione o di astinenza, e disturbi dell&rsquo;umore dovuti agli effetti fisiologici diretti di una malattia, che possono essere distinti con difficolt&agrave; da un'alterazione dell'umore come risposta al fatto di essere malati.</p>
      <p>I disturbi dell&rsquo;umore, a causa della loro complessit&agrave; e del fatto che possono essere associati ad altri disturbi mentali, richiedono interventi diversificati e flessibilit&agrave; da parte dello psicoterapeuta: ad esempio, nei momenti pi&ugrave; gravi della Depressione Maggiore e nella fase maniacale del Disturbo Bipolare, la persona &egrave; poco o per nulla recettiva e disponibile alla psicoterapia, mentre in altri momenti &egrave; pi&ugrave; disposta a&nbsp; chiedere aiuto, sia di tipo terapeutico che educazionale per ci&ograve; che concerne il riconoscimento precoce dei sintomi e la loro gestione.</p>
      <p>Anche la famiglia ha bisogno di aiuto e sostegno, e la sua collaborazione &egrave; importante per la prevenzione delle ricadute.</p>
      <p>Attualmente, l&rsquo;utilizzo della Mindfulness in psicoterapia cognitiva si sta rivelando un approccio utile per le persone che hanno alle spalle gravi episodi depressivi ricorrenti.</p>
      <p>Nei bambini e negli adolescenti, la tristezza e la mancanza di piacere nello svolgimento delle attivit&agrave; &ndash; cuore dei disturbi depressivi &ndash; possono essere nascosti dall&rsquo;irritabilit&agrave;, mentre nell&rsquo;anziano lo stato depressivo pu&ograve; simulare una demenza, rendendo difficile una diagnosi corretta.</p>
      <p>Per questo motivo, i disturbi dell&rsquo;umore richiedono una diagnosi nel senso pieno del termine, <em>conoscere</em> <em>attraverso</em>, attraverso l&rsquo;osservazione prolungata nel tempo, attraverso la valutazione costante dell&rsquo;efficacia delle cure, attraverso un accompagnamento sollecito e attento.</p>
    </>
  ],
  [
    "disturbi-di-personalita",
    <>
      <h2>I disturbi di personalità</h2>
      <p>&nbsp;</p>
      <p>La <HashLink to="/articoli/la-personalita">personalità</HashLink> è ci&ograve; che ci caratterizza come individui unici, diversi da tutti gli altri.</p>
      <p>Ci permette di muoverci nel mondo in modo prevedibile e adattivo, di conoscere e farci conoscere, di instaurare relazioni e di esprimere le nostre capacit&agrave;.</p>
      <p>Alcune modalit&agrave; di pensiero e di comportamento possono per&ograve; diventare rigide e inflessibili: reazioni emotive impulsive e sproporzionate, mancanza di empatia, ripetute distorsioni cognitive, difficolt&agrave; ad ammettere che qualcosa non funziona nel modo di relazionarsi con gli altri&hellip; Parliamo allora di un possibile Disturbo di Personalit&agrave;.</p>
      <p>Questo termine indica un <strong>modello di esperienza interiore</strong>, riferita all&rsquo;area cognitiva e affettiva, e <strong>un modello di comportamento</strong> problematici e pervasivi, cio&egrave; non limitati a poche situazioni, in grado di creare un serio disagio.</p>
      <p>Pu&ograve; esordire in adolescenza o nella prima et&agrave; adulta, sebbene occorra molta prudenza nell&rsquo;effettuare questa diagnosi quando il soggetto &egrave; ancora nella fase adolescenziale e quindi in piena crescita e cambiamento.</p>
      <p>Nel DSM IV (manuale diagnostico e statistico dei disturbi mentali, quarta edizione) sono identificati dieci disturbi di personalit&agrave;, riuniti in tre gruppi:</p>
      <p>&nbsp;</p>
      <ul>
        <li>Gruppo A - caratterizzato da stranezza, eccentricit&agrave;, isolamento (disturbi di personalit&agrave; paranoide, schizoide, schizotipico)</li>
        <li>Gruppo B - caratterizzato da emotivit&agrave;, drammaticit&agrave;, mancanza di empatia (disturbi di personalit&agrave; borderline, istrionico, antisociale, narcisistico)</li>
        <li>Gruppo C - caratterizzato da un notevole livello di paura (disturbi di personalit&agrave; dipendente, evitante, ossessivo compulsivo)</li>
      </ul>
      <p>&nbsp;</p>
      <p>Spesso, la diagnosi &egrave; resa difficoltosa perch&eacute; una persona pu&ograve; presentare caratteristiche miste, livelli pi&ugrave; o meno buoni di funzionamento identitario e interpersonale, tratti che evolvono nel tempo e altri pi&ugrave; stabili, problematiche di tipo depressivo o ansioso che sconfinano in altri disturbi.</p>
      <p>I disturbi di personalit&agrave; sono da questo punto di vista una sfida per il clinico, tuttavia ci&ograve; non deve stupire data la complessit&agrave; degli esseri umani, difficile da costringere in categorie diagnostiche rigidamente separate e caratterizzate da un elenco di sintomi che non possono descrivere pienamente il mondo interiore della persona e il suo modo di manifestarsi al mondo.</p>
      <p>La psicoterapia cognitivo&ndash;comportamentale lavora per modificare i comportamenti non adatti e socialmente indesiderati, che portano spesso la persona a isolarsi o a essere emarginata, e i costrutti mentali basati su schemi che distorcono sistematicamente la realt&agrave;.</p>
      <p>Il paziente legge la terapia e vive la persona stessa del terapista utilizzando queste distorsioni. Ci vuole tempo perch&eacute; le comprenda e viva un&rsquo;esperienza di apprendimento in grado di metterlo in contatto con la sofferenza che ha prodotto e mantiene gli stessi schemi.</p>
      <p>L&rsquo;accoglienza, l&rsquo;empatia e al tempo stesso la fermezza del terapista sono atteggiamenti indispensabili per consentire questa esperienza di evoluzione e crescita.</p>
    </>
  ],
  [
    "la-personalita",
    <>
      <h2>La personalità</h2>
      <p>&nbsp;</p>
      <p>Con questo termine, si indica l&rsquo;insieme delle caratteristiche individuali che mantengono nel tempo una certa costanza.</p>
      <p>Possiamo dire, con una bella immagine, che la personalit&agrave; &egrave; il ritratto psicologico relativamente stabile di ogni essere umano. Un ritratto unico come l&rsquo;aspetto fisico. Personalit&agrave; e carattere sono solitamente considerati sinonimi, altre volte, per&ograve;, si preferisce considerare il carattere come il modo abituale di comportarsi in rapporto alle regole sociali. Una persona che ha &ldquo;buon carattere&rdquo; potrebbe essere una persona sottomessa oppure semplicemente gentile, secondo ci&ograve; che si intende per &ldquo;buon carattere&rdquo; in un certo contesto sociale.</p>
      <p>La personalit&agrave; sarebbe invece qualcosa di pi&ugrave; complesso, perch&eacute; oltre ai tratti che si esprimono nel comportamento, comprenderebbe la visione della vita, gli interessi profondi, il modo di approcciarsi alla realt&agrave;. Dunque, lo studio della personalit&agrave; &egrave; forse il campo di studio pi&ugrave; vasto e interessante per uno psicologo.</p>
      <p>Personalit&agrave; deriva dal latino &ldquo;persona&rdquo;, la maschera portata dagli attori dell&rsquo;antica Roma, ma con il tempo, il termine non ha indicato pi&ugrave; il modo di apparire, l&rsquo;esteriorit&agrave;, di un essere umano, ma il suo modo profondo di essere.</p>
      <p>Dalla maschera, si &egrave; passati all&rsquo;attore.</p>
      <p>Gi&agrave; gli antichi greci si interessavano al problema e avevano privilegiato i fattori biologici e costituzionali per descrivere la personalit&agrave;.</p>
      <p>Ippocrate, considerato il padre della medicina, defin&igrave; quattro tipi (melanconico, collerico, flemmatico, sanguigno) governati da quattro umori presenti nel corpo. L&rsquo;equilibrio tra gli umori garantiva la salute, il disequilibrio causava la malattia.</p>
      <p>La prevalenza di un umore sull&rsquo;altro generava determinate caratteristiche di personalit&agrave;: ad esempio, nel collerico, l&rsquo;eccesso di bile gialla era considerato alla base della superbia, della generosit&agrave;, dell&rsquo;irritabilit&agrave; proprie di quel temperamento.</p>
      <p>Questo indirizzo toglieva potere a ogni interpretazione magica o religiosa legata allo studio dell&rsquo;uomo, e creava un ponte tra biologia e psicologia, che &egrave; stato ripreso pi&ugrave; volte nella nostra epoca, come nella teoria costituzionalista di Sheldon, che metteva in relazione il tipo fisico con certe caratteristiche di personalit&agrave; e che &egrave; stata oggetto di grosse controversie, anche perch&eacute; i biotipi puri sono rari e la maggior parte delle persone presenta caratteristiche miste, come gi&agrave; lo stesso Sheldon aveva compreso.</p>
      <p>Per lungo tempo, la teoria freudiana, dove la componente biologica rappresentata dalla libido occupa un posto privilegiato e mantiene la sua preminenza pur nell&rsquo;adattamento all&rsquo;ambiente sociale, &egrave; stata quella pi&ugrave; seguita.</p>
      <p>Freud riteneva che lo sviluppo della personalit&agrave; terminasse nell&rsquo;infanzia e che quanto accadeva dopo, poteva essere solo una riedizione.</p>
      <p>Rompendo con Freud sul concetto di libido come unico motore dello sviluppo umano, Carl Gustav Jung propose una teoria della personalit&agrave; complessa e articolata,&nbsp; descrivendo una psiche ricca di sfaccettature e istanze (Io, inconscio, ombra, persona&hellip;) che pu&ograve; integrarsi ed equilibrarsi nel S&eacute; solo intorno ai cinquanta anni. Per tutta la vita, gli esseri umani si prefiggono dei fini da raggiungere sviluppando le proprie potenzialit&agrave;.</p>
      <p>Alfred Adler, prima allievo di Freud e poi sempre pi&ugrave; in disaccordo con il maestro, pose l&rsquo;accento sui fattori psicologici e sociali, considerando centrale il concetto di sentimento d&rsquo;inferiorit&agrave;: nello sforzo di superare le proprie limitazioni, l’essere umano non è più imprigionato nello spazio ristretto delle esperienze infantili, e forgia la sua personalità e il suo stile di vita con successo, se crescendo sviluppa un forte sentimento sociale e di appartenenza.</p>
      <p>I neopsicanalisti come Erich Fromm consideravano la dimensione esistenziale e relazionale come il vero fondamento della personalit&agrave;: gli esseri umani impiegano le proprie energie per soddisfare i bisogni interni &ndash; identit&agrave;, relazione, realizzazione, trascendenza e altri &ndash; tenendo conto dell&rsquo;ambiente sociale in cui vivono&nbsp; e delle sue richieste e aspettative.</p>
      <p>I comportamentisti come Watson e Skinner ritenevano che i tratti di personalit&agrave;, come ogni comportamento, fossero frutto di apprendimento e che la persona fosse quindi facilmente modificabile grazie a un sistema di ricompense e punizioni.</p>
      <p>Gli psicologi umanisti come Maslow, Rogers, Assagioli, ripresero alcuni concetti di Jung, considerando centrale il concetto di equilibrio e realizzazione di s&eacute;.</p>
      <p>Concludiamo questa velocissima carrellata, con le teorie fattoriali della personalit&agrave;, come quelle elaborate da Cattell, Eysenck, MacCrae e Costa.</p>
      <p>Lo psichiatra anglo-tedesco Hans Eysenck, nella sua opera "<em>The structure of Human Personality</em>" afferm&ograve; che:</p>
      <p>&laquo;La personalit&agrave; &egrave; la pi&ugrave; o meno stabile e durevole organizzazione del&nbsp;carattere, del&nbsp;temperamento, dell'intelletto&nbsp;e del&nbsp;fisico&nbsp;di una persona: organizzazione che determina il suo adattamento totale all'ambiente&raquo;.</p>
      <p>Se la definizione &egrave; chiara, gli ostacoli non sono pochi. Ci sono, infatti, quasi 5000 aggettivi che indicano tratti di personalit&agrave;, perci&ograve; lo sforzo di questi studiosi &egrave; stato di ridurli per arrivare a descrizioni utilizzabili.&nbsp;</p>
      <p>Eysenck li ha addirittura sintetizzati in due dimensioni fondamentali:&nbsp;</p>
      <p><strong>estroversione/introversione</strong> e <strong>labilit&agrave; emotiva/adattamento</strong>.</p>
      <p>Il suo scopo era creare un profilo di personalit&agrave; (es. personalit&agrave; ossessiva, personalit&agrave; autoritaria ecc.), che permettesse di cogliere in modo rapido e sintetico la maniera di essere di una persona e di prevedere con ragionevole sicurezza il suo comportamento.</p>
      <p>Eysenck non elabor&ograve; una propria teoria della personalit&agrave; ma utilizzò i concetti introdotti da altri studiosi, come Carl Gustav Jung, cercando di misurarli con test e profili basati su numeri. Le sue misurazioni non coprivano tutti i tratti di personalit&agrave;, ma solo alcuni. Per questo, a partire dagli studi di Cattell, fu elaborato e poi perfezionato da MacCrae e Costa, il Big Five, un test che misura cinque grandi dimensioni della personalit&agrave;: Estroversione, Amicalit&agrave;, Coscienziosit&agrave;, Nevroticismo, Apertura all&rsquo;Esperienza.</p>
      <p>&nbsp;</p>
      <p>Come abbiamo visto, non c&rsquo;&egrave; una sola teoria della personalit&agrave; e in ognuno dei contributi che questi studiosi hanno dato per fare un po&rsquo; di luce, possiamo trovare argomentazioni e ipotesi credibili.</p>
      <p>In questo campo, lo sforzo di dare ragione dell&rsquo;unit&agrave; e della molteplicit&agrave; non sar&agrave; probabilmente mai esaurito.</p>
    </>
  ],
  [
    "lo-psicologo-questo-sconosciuto",
    <>
      <h2>Lo psicologo, questo sconosciuto</h2>
      <p>&nbsp;</p>
      <p>La psicologia si &egrave; costituita ultima fra le discipline scientifiche, ma sta assumendo una cos&igrave; grande importanza che come notava lo psicologo comportamentista americano J.B. Watson, lo psicologo si trova nella posizione di chi, dopo essere stato lungamente preoccupato per far tollerare la propria presenza, deve sforzarsi per convincere che non pu&ograve; svolgere tutti quei compiti che si vorrebbero affidargli.</p>
      <p>Lo psicologo non si occupa solo di disturbi mentali, ma promuove il benessere individuale e collettivo grazie alle sue conoscenze sul funzionamento della mente, del comportamento, delle dinamiche relazionali, in vari contesti.</p>
      <p>In effetti la psicologia copre un'area di interessi molto varia, tra cui l'orientamento scolastico e professionale, la formazione e la ricerca, la selezione e la gestione delle risorse umane all'interno delle aziende, la pubblicit&agrave; e il marketing, lo sport, il turismo, lo studio della personalit&agrave;, il supporto in caso di calamit&agrave; naturali e altre emergenze, la costruzione di test, il sostegno nella gestione di patologie croniche, la consulenza in campo giuridico e naturalmente tutta la vastissima area della psicopatologia.</p>
      <p>La psicologia, scienze recente, ha per&ograve; radici profonde: il termine <em>psiche</em>, in greco antico, significa soffio, respiro e riconduce all'idea di anima in quanto elemento vivificatore del corpo. Aristotele nel quarto secolo a.C. tratt&ograve; nella sua opera "Dell'anima" il problema delle funzioni dello spirito umano.</p>
      <p>Il legame fra psicologia e filosofia, da Aristotele a Sant'Agostino, da Cartesio a Kant, era inscindibile.</p>
      <p>Solo nell&rsquo;Ottocento, sotto l'influenza della filosofia positivista, la psicologia entr&ograve; a far parte delle discipline scientifiche, mutuandone i metodi.&nbsp;</p>
      <p>In quel periodo la fisiologia, cio&egrave; la disciplina che studia i processi vitali degli organismi, aveva compiuto grandi scoperte sull'attivit&agrave; del sistema nervoso: erano state localizzate le zone del cervello che regolano il linguaggio e le funzioni motorie e si studiavano gli organi di senso, i nervi, i muscoli, cercando un rapporto tra il mondo fisico e obiettivo, e quello psichico e soggettivo.&nbsp;</p>
      <p>La psicologia nacque ufficialmente a Lipsia nel 1879, con la fondazione del primo laboratorio di psicologia sperimentale a opera del professor Wilhelm Wundt.</p>
      <p>Con i suoi studi sulla percezione, si proponeva di dimostrare che la psicologia poteva distinguersi dalla filosofia, contestando la convinzione che gli eventi legati alla psiche umana non fossero misurabili e osservabili come tutti gli altri fenomeni.</p>
      <p>Nel corso della sua carriera dovette superare molti ostacoli: riusc&igrave; a farsi assegnare una cattedra di filosofia e la convert&igrave; alle pratiche sperimentali, e fond&ograve; anche un giornale, che si chiamava "Studi filosofici", per pubblicare i risultati degli esperimenti stessi.&nbsp;</p>
      <p>Nel suo laboratorio utilizzava apparecchiature per studiare le percezioni e i tempi di reazione: la loro presenza testimoniava il distacco dall'approccio puramente speculativo e razionale ai problemi della mente umana in favore dell'osservazione e dell'esperimento, che permettevano di basare la teoria su dati controllabili e verificabili.</p>
      <p>Al tempo stesso, continuava la discussione sulla possibilit&agrave; di misurare lo spirito umano, che non poteva ridursi alle sole facolt&agrave; di percezione e attenzione, e soprattutto si discuteva sull&rsquo;origine e la cura dei disturbi mentali, la cui spiegazione non poteva pi&ugrave; basarsi sul concetto di possessione diabolica.</p>
      <p>Particolarmente interessanti apparivano i fenomeni legati all&rsquo;isteria, e ci&ograve; che si verificava praticando la tecnica dell&rsquo;ipnosi, che affascin&ograve; un neurologo austriaco, Sigmund Freud, fino a quando l&rsquo;abbandon&ograve; in favore delle libere associazioni e dell&rsquo;analisi dei sogni per entrare nel mondo opaco e problematico dei pazienti.</p>
      <p>Lo sviluppo della psicologia non &egrave; quindi semplice e lineare, non solo per la vastit&agrave; dei suoi campi di interesse.</p>
      <p>Pur essendo una disciplina scientifica, non &egrave; ancora arrivata a condividere un'unica teoria sulla quale fondare lo studio della mente e del comportamento umano: esistono diverse teorie e diverse scuole, nessuna delle quali ha per&ograve; una netta prevalenza.</p>
      <p>Neppure la psicanalisi, a lungo la pi&ugrave; nota teoria della mente, &egrave; riuscita a diventare il fondamento della psicologia generale, nonostante Freud lo sperasse.</p>
      <p>&Egrave; indubbio che le grandi domande su chi sia l&rsquo;uomo, quanto sia importante l&rsquo;ambiente in cui vive, quanto pesino condizionamenti e se esista il libero arbitrio, quale sia il senso della sua esistenza, che cosa sia la normalit&agrave;, come integrare la consapevolezza della fragilit&agrave; e finitezza con l&rsquo;aspirazione alla realizzazione personale, insomma, &egrave; indubbio che tutte queste grandi questioni filosofiche influenzino anche la psicologia, per quanto emancipata da un approccio puramente teorico.</p>
      <p>Probabilmente &egrave; un bene che non si arrivi a una teoria unica della mente se questo significa sacrificarne la complessit&agrave; e le sfaccettature, rendendo l&rsquo;essere umano definibile ed esauribile come un oggetto.</p>
      <p>Al grande pubblico per&ograve; questo importa relativamente: per la gente comune lo psicologo &egrave; colui che si dedica allo studio e alla cura della sofferenza psichica e dei comportamenti che ostacolano la realizzazione di s&eacute; e di buone relazioni con gli altri, e l&rsquo;adattamento positivo e creativo alle esigenze sociali.</p>
      <p>Difficilmente si domanda a quale teoria faccia riferimento e si rivolge allo specialista grazie alle indicazioni di persone di cui si fida.</p>
      <p>Tuttavia, &egrave; importante che lo psicologo, comunichi al paziente informazioni &ndash; utili e comprensibili &ndash; sul suo metodo di lavoro e sui suoi fondamenti, spieghi perch&eacute; ha bisogno di un certo tempo per formulare una diagnosi e quali sono gli strumenti che utilizza (oltre ai colloqui, per esempio i test, oppure gli homework, i &ldquo;compiti a casa&rdquo;) e perch&eacute; sono importanti.</p>
      <p>Se grazie alla trasparenza ed alla competenza si crea un buon apporto di fiducia, il paziente si sentir&agrave; motivato a collaborare e gli sar&agrave; pi&ugrave; facile accettare di intraprendere una psicoterapia, se necessaria.</p>
      <p>Altro argomento di interesse &egrave; la distinzione tra psicologo, psicoterapista o psicoterapeuta, psicanalista, psichiatria, neurologo.&nbsp;</p>
      <p>Lo psicologo studia l'uomo nel suo comportamento quotidiano e nelle sue attivit&agrave;: quando si dedica alla cura della sofferenza psichica, parliamo di lui come psicoterapista (o psicoterapeuta, &egrave; la stessa cosa).</p>
      <p>Per&ograve; non basta essere laureati in psicologia per essere psicoterapisti: occorre un&rsquo;ulteriore specializzazione, di almeno quattro anni.</p>
      <p>Infatti, se lo psicologo pu&ograve; fare formazione, ricerca, prevenzione, riabilitazione, diagnosi, counseling e sostegno psicologico, pu&ograve; dedicarsi alla psicoterapia solo dopo una preparazione adeguata.</p>
      <p>A volte ci si chiede che differenza c&rsquo;&egrave; tra sostegno psicologico e psicoterapia.</p>
      <p>Il primo &egrave; un intervento focalizzato su problemi specifici, rivolto a persone che hanno bisogno di un aiuto per mobilitare le proprie risorse personali in un momento di difficolt&agrave;; la psicoterapia &egrave; un intervento pi&ugrave; complesso e profondo, che ha la finalit&agrave; di guarire o almeno migliorare una forma importante di disagio psichico, che blocca la persona nelle sue aspirazioni, nel suo progetto di vita, nella sua capacit&agrave; di avere buone relazioni con gli altri e di provare gioia e gratificazione nelle sue attivit&agrave;.</p>
      <p>Lo psicoterapista pu&ograve; appartenere a varie scuole (psicanalitica, cognitivista, comportamentale, sistemica ecc.), perch&eacute;, come abbiamo visto, non esiste un&rsquo;unica teoria della mente e ognuno aderisce a quella che ritiene affine alla sua sensibilit&agrave; e visione personale.</p>
      <p>In Italia, l'Albo degli Psicologi prevede una sezione apposita per coloro che sono anche psicoterapisti.</p>
      <p>Psicologi e psicoterapisti non hanno necessariamente una laurea in medicina: lo stesso Freud, che pure era medico, non la considerava indispensabile per essere psicanalisti.</p>
      <p>La psicologo, anche se psicoterapeuta, non pu&ograve; prescrivere farmaci n&eacute; applicare cure mediche.</p>
      <p>Lo psichiatra, che a sua volta si occupa di disturbi mentali, &egrave; un medico e come tale pu&ograve; prescrivere farmaci e utilizzare tutte le forme di terapia che rientrano nelle sue competenze: se ha una formazione psicoterapeutica pu&ograve; integrare il suo orientamento con la pratica strettamente medica, anche se a volte &egrave; meglio che la figura dello psichiatra e dello psicoterapista non coincidano, in modo che eventuali problemi che insorgessero durante la psicoterapia non compromettano l&rsquo;adesione al trattamento farmacologico e viceversa.</p>
      <p>La collaborazione tra psichiatra e psicologo psicoterapeuta pu&ograve; essere molto fruttuosa.</p>
      <p>Non &egrave; vero, come a volte si sente dire, che lo psicologo si occupa delle persone che hanno disturbi lievi mentre lo psichiatra di quelle che hanno disturbi gravi.</p>
      <p>&Egrave; vero invece che in presenza di disturbi gravi pu&ograve; essere indispensabile il trattamento farmacologico, e dunque la presenza dello psichiatra, senza che questo escluda la possibilit&agrave; dell&rsquo;aiuto psicologico.</p>
      <p>Al tempo stesso, anche persone che hanno disturbi lievi possono non desiderare una psicoterapia e limitarsi ad affrontare il problema con un sostegno farmacologico e quindi rivolgersi allo psichiatra o semplicemente al proprio medico di base.</p>
      <p>C&rsquo;&egrave; poi il caso di chi rifiuta qualunque trattamento farmacologico e si rivolge allo psicologo qualunque sia la natura dei suoi disturbi.</p>
      <p>Lo psichiatra o lo psicologo, in scienza e coscienza, valuteranno con il paziente queste sue scelte.</p>
      <p>Infine, ricordiamo che il neurologo &egrave; un medico che cura malattie organiche proprie del sistema nervoso come encefaliti, tumori cerebrali, morbo di Parkinson, ictus, demenze ecc.</p>
      <p>A volte per&ograve; anche persone con problemi squisitamente psicologici preferiscono rivolgersi a questo specialista, quando ritengono "vergognoso" soffrire di un disturbo mentale, evitando sia psicologi che psichiatri.</p>
      <p>Non &egrave; un caso che nel linguaggio comune si usino termini vaghi ma considerati meno stigmatizzanti, come esaurimento nervoso o nervi scossi.</p>
      <p>&Egrave; curioso ma comprensibile, dato l&rsquo;ostracismo che segnava le persone ricoverate in manicomio, che ancora oggi alcuni provino angoscia sentendosi dire che soffrono di un disturbo mentale, come se ogni sofferenza di questo tipo indicasse una cosa sola: la pazzia, la perdita completa del controllo di s&eacute;, mentre, quando si tratta di malattie organiche, le stesse persone distinguano e attribuiscano il giusto peso a una laringite, piuttosto che all&rsquo;ipertensione o alla metastasi ossea.</p>
      <p>E&rsquo; importante sensibilizzare il grande pubblico alle tematiche legate alla salute mentale, ricordando, come abbiamo detto all&rsquo;inizio, che lo psicologo non se ne occupa solo in termini di cura, ma anche di promozione e prevenzione.</p>
      <p>Troppo spesso lo psicologo &egrave; interpellato dai mass media solo a causa di gravi fatti di cronaca e dimenticato subito dopo, come se il suo contributo dovesse limitarsi ad assicurare una spiegazione ad azioni estreme che hanno spaventato l&rsquo;opinione pubblica e rispondere alla domanda: &laquo;Che cosa aveva nella testa per fare una cosa del genere?&raquo;.</p>
      <p>La psicologia ha finalit&agrave; pi&ugrave; ampie: aiutare la comprensione del comportamento umano per aumentare il benessere dell&rsquo;intera collettivit&agrave; e modificare la mentalit&agrave; che ancora oggi ritiene poco accettabile il disturbo mentale.</p>
      <p>Anche se esistono diverse forme di psicoterapia, tutte insegnano che qualunque forma di sofferenza psichica ha il suo significato e la sua dignit&agrave;.</p>
    </>
  ],
  [
    "il-significato-psicologico-della-fiaba",
    <>
      <h2>Il significato psicologico della fiaba</h2>
      <p>&nbsp;</p>
      <p><em>Un popolo che non ha pi&ugrave; racconti &egrave; condannato a morire di freddo</em></p>
      <p>(detto delle steppe)</p>
      <p>&nbsp;</p>
      <p>I racconti accanto al fuoco e gli onori resi a chi narra sono fenomeni universali, presenti in ogni cultura. Per millenni la voce e la memoria sono state alla base del patrimonio narrativo e le fiabe hanno viaggiato e circolato nel mondo incessantemente.</p>
      <p>I temi fondamentali sono gli stessi e il filo che li lega &egrave; rappresentato dal MUTAMENTO, cio&egrave; dalla capacit&agrave; e dalla necessit&agrave; di trasformarsi e trasformare a proprio vantaggio le esperienze vissute.</p>
      <p>La fiaba non deve rappresentare la realt&agrave; esterna ma quella interiore nei suoi aspetti pi&ugrave; importanti e densi di significato. Il narratore la colloca dalla prima battuta in un mondo che non &egrave; quello che percepiamo attraverso i cinque sensi: &laquo;C&rsquo;era una volta&hellip; Molto tempo fa&hellip; Al tempo dei tempi&hellip;&raquo;, oppure come nelle fiabe turche e arabe: &laquo;C&rsquo;era e non c&rsquo;era&hellip; Non qui e non altrove&hellip;&raquo;.</p>
      <p>Sono frasi di apertura utilizzate per avvertire l&rsquo;ascoltatore che non si tratta di una narrazione con un fondo di verit&agrave; storica, come nelle leggende, o di un racconto intorno alle origini del mondo e alle gesta di eroi, divinit&agrave; e popoli, come nel mito e nelle epopee, anche se non &egrave; sempre facile distinguere nettamente tra i vari generi narrativi.&nbsp;</p>
      <p>La fiaba non &egrave; neppure la favola, i cui personaggi sono animali umanizzati con intenti moralistici ed educativi espressi in modo fin troppo scoperto.</p>
      <p>Per essere ancora pi&ugrave; certi che il pubblico non prender&agrave; per rappresentazioni reali quelli che sono elementi fantastici e per attirare l&rsquo;attenzione, le fiabe sono talvolta precedute da filastrocche o &ldquo;racconti alla rovescia&rdquo; dove pu&ograve; succedere di tutto, anche dondolare la culla del proprio padre o caricare con la pelle di una mosca ottantotto cammelli.</p>
      <p>Sono prive di un legame con la storia che verr&agrave; dopo, ma introducono nel mondo del meraviglioso. Pur utilizzando immagini simboliche che rappresentano emozioni e vissuti, come nei sogni, la fiaba non le mischia alla rinfusa seguendo unicamente la logica dell&rsquo;inconscio e ha una struttura coerente: si rispettano formalmente le categorie di spazio e tempo, anche se la magia permette il loro superamento collocando il racconto in una dimensione dove non vigono i rigidi principi del mondo reale esterno al soggetto.</p>
      <p>Le fiabe sono nate prima della scrittura e il narratore doveva essere tanto abile da spingere il suo pubblico a immedesimarsi nella storia, soffrendo con il protagonista, gioendo per le sue vittorie, insomma spingendolo a ridere e a piangere come succede a noi quando andiamo al cinema o guardiamo un telefilm.</p>
      <p>La fiaba, come hanno scritto Chesterton e Lewis, &egrave; &ldquo;un&rsquo;esplorazione spirituale&rdquo;, per questo non ha senso chiedere ai bambini se credono alle fiabe: in una fiaba, i processi interiori sono esteriorizzati attraverso i personaggi e le loro avventure, allo scopo di chiarire che cosa avviene nell&rsquo;intimo e trovare una soluzione a paure, tensioni, conflitti. Il fatto che siano i bambini a essere i maggiori utilizzatori delle fiabe ci dice che le battaglie che sostengono interiormente sono particolarmente dure. Gli elementi sono piuttosto generici (la fata, il cavaliere, il lupo, i benevoli aiutanti animali, il re e la regina&hellip;) e permettono una facile identificazione.</p>
      <p>Il contenuto manifesto pu&ograve; non essere pi&ugrave; attuale, riferendosi per lo pi&ugrave; a un ambiente agro&ndash;silvo&ndash;pastorale, ma il contenuto latente delle fiabe lo &egrave; sempre.</p>
      <p>Basti pensare al tema del bosco.</p>
      <p>&Egrave; il luogo pieno di pericoli per eccellenza, oscuro e abitato da personaggi magici che possono aiutare oppure nuocere e dove l&rsquo;eroe/eroina della fiaba deve cavarsela come pu&ograve;.&nbsp;</p>
      <p>Gli antropologi propongono un rimando reale all&rsquo;immagine del bosco come luogo dove ci si perde e ci si ritrova: il luogo lontano dalla sicurezza della casa, del villaggio, dove gli adolescenti erano portati per i riti di iniziazione attraverso i quali diventavano membri adulti del loro gruppo dimostrando di saper sopportare la fame, la paura, la solitudine, e di saper apprendere tutto ci&ograve; che poteva servire per sfamare se stessi e il loro clan. L&rsquo;albero simboleggia l&rsquo;uomo stesso, ben radicato a terra e al tempo stesso elevato verso il cielo, soggetto alla nascita, alla crescita, alla morte, e di nuovo alla rinascita grazie ai semi o a fenomeni di rigenerazione come nel bosco ceduo.</p>
      <p>Da un punto di vista concreto, il bosco e la foresta sono luoghi fondamentali per la sopravvivenza: forniscono legname per costruire abitazioni e manufatti, per cucinare e scaldarsi, per raccogliere frutti ed erbe e per cacciare.</p>
      <p>Anche se le fiabe si sono arricchite e modificate con il passare dei secoli, il loro nucleo fondamentale &egrave; rimasto lo stesso: nel bosco e nella foresta gli esseri umani devono innanzitutto affrontare se stessi, mutare status sociale, inserirsi nella vita del gruppo.</p>
      <p>&nbsp;</p>
      <p>La fiaba deve avere un lieto fine, deve rassicurare gli ascoltatori che un giorno, dopo aver sostenuto lotte e fatiche (inevitabili se si vuole costruire una vera identit&agrave;), gli sforzi fatti saranno premiati. Il lieto fine infonde forza e speranza, sostiene la ricerca di una soluzione ai problemi in cui ci si dibatte, non perch&eacute; la fiaba consigli sul da farsi ma perch&eacute; mobilita le energie dell&rsquo;individuo in modo che possa farsi carico delle questioni irrisolte e trovare una soluzione.</p>
      <p>In modo particolare il bambino ha bisogno di sentire che la sofferenza &egrave; solo un passaggio per raggiungere una vita felice &ndash; vale a dire la crescita, la conoscenza, la realizzazione e integrazione della sua personalit&agrave;, il senso della vita.</p>
      <p>Gli adulti si chiedono spesso se &egrave; opportuno raccontare al bambino storie truculente come se ne trovano spesso nella letteratura nordica o anche semplicemente storie dove i figli sono abbandonati dai genitori (la paura pi&ugrave; grande dei bambini) o maltrattati da matrigne cattive e ignorati da padri imbelli o dove i fratelli maggiori fanno il possibile per danneggiare il minore.</p>
      <p>Secondo la psicanalisi &egrave; inutile &ldquo;depurare" le fiabe da questi elementi terrorizzanti, anzi, sarebbe un male, perch&eacute; il bambino stesso si sente sconvolto da pulsioni distruttive e ha bisogno di sentirsi dire che il bene trionfer&agrave; sul male e che i personaggi cattivi saranno puniti in modo esemplare. &Egrave; come dire che le sue &ldquo;parti cattive&rdquo; non avranno il sopravvento sulle &ldquo;parti buone&rdquo; &ndash; per quanto riguarda il suo mondo interiore &ndash; e che l&rsquo;adulto ingiusto e prepotente non prevarr&agrave; sull&rsquo;adulto buono e protettivo che si prende cura di lui &ndash; per quanto riguarda i rapporti con il mondo esterno.</p>
      <p>Tutta l&rsquo;ambivalenza dei rapporti umani &egrave; rappresentata dallo sdoppiamento (la madre buona e la matrigna cattiva, perch&eacute; nella fiaba non esistono le sfumature, &egrave; tutto bianco o tutto nero, tutto buono o tutto cattivo), che nei bambini &egrave; normale non essendo ancora capaci di stabilire quale di questi aspetti prevalga in modo prevedibile e costante e non essendo ancora in grado di gestire la complessit&agrave; dell&rsquo;essere umano, che &egrave; fatta di luci e di ombre.</p>
      <p>Tuttavia le fiabe devono essere scelte in base all&rsquo;et&agrave;: i bambini sotto i cinque anni non sono ancora in grado di pensare per simboli e vivono le cose raccontate come conformi alla realt&agrave;, quindi potrebbero spaventarsi davanti a descrizioni di personaggi crudeli e malvagi.</p>
      <p>L&rsquo;instabilit&agrave; &egrave; tipica delle fiabe: il ferro diventa oro, gli esseri umani sono trasformati in animali e gli animali in esseri umani. Alla fine per&ograve; il mondo acquista la sua stabilit&agrave; in quel &ldquo;&hellip;e vissero felici e contenti&rdquo; che non &egrave; mai raccontato ma solo fatto intuire: il legame con un altro essere umano amato profondamente, l&rsquo;emancipazione dalla propria famiglia di origine, il radicamento in un luogo proprio.</p>
      <p>A volte ci si chiede se &egrave; opportuno leggere le fiabe o se non sarebbe meglio raccontarle, ma pi&ugrave; del mezzo usato conta la capacit&agrave; del narratore di interessare e coinvolgere, proprio come hanno fatto per millenni i narratori di professione.</p>
      <p>I bambini tendono a essere conservatori: soprattutto se sono piccoli&nbsp; non vogliono sentir modificare alcun dato delle fiabe che gi&agrave; conoscono.</p>
      <p>Gli adulti, oltre ad attingere al patrimonio delle fiabe popolari, possono inventare storie, anche con il contributo dei bambini. Gianni Rodari, nel suo libro "Grammatica della fantasia&rdquo; ha insegnato alcune tecniche per inventare storie nuove. Non si tratta di far concorrenza o di soppiantare le fiabe popolari, che sono sopravvissute al logorio dei secoli in ragione della capacit&agrave; di rappresentare le lotte interiori dell&rsquo;uomo, ma di lasciar libero corso alla fantasia e alla creativit&agrave;, che pu&ograve; sfociare in un momento di gioco, di socializzazione, di allegria, e chiss&agrave;, entrare a far parte dell&rsquo;immenso repertorio di fiabe che hanno superato la prova del tempo.</p>
      <p>Un adulto sensibile e attento pu&ograve; notare problemi o difficolt&agrave; che il bambino traduce nelle immagini delle storie che inventa o che vuole introdurre nelle storie raccontate da altri ma &egrave; bene che non renda consce le problematiche che ha intuito e rimanga sul piano del racconto e del gioco per indicare una possibile soluzione.</p>
      <p>La mancata speranza nel futuro determina un arresto dello sviluppo, per cui le fiabe sono terapeutiche quando la persona vi si riconosce e attinge alle risorse che propone (la perseveranza, il coraggio, la lealt&agrave;, la bont&agrave; d&rsquo;animo).</p>
      <p>Sono impiegate nella psicoterapia dei bambini ma in certi casi utilizzate anche con gli adulti, possono essere create dal terapista stesso o tratte dal patrimonio esistente.</p>
      <p>In fondo la fiaba e la psicoterapia hanno un punto in comune molto importante: mostrano che vale la pena di sperare mentre si cerca una via d&rsquo;uscita.</p>
    </>
  ],
  [
    "il-disturbo-da-accumulo",
    <>
      <h2>Il disturbo da accumulo&nbsp;</h2>
      <p>&nbsp;</p>
      <p>Presso gli antichi Romani, il termine <em>casa</em> indicava le capanne delle popolazioni galliche, semplici abitazioni di legno con il tetto di paglia e il termine deriverebbe a sua volta dal sanscrito <em>ska </em>che significa &ldquo;coprire&rdquo;.</p>
      <p>Designava insomma un riparo molto umile e primordiale (ricordiamo che le case degli antichi romani erano le <em>domus</em> - per i ricchi &ndash; e le <em>insulae</em> - per i poveri).</p>
      <p>Il termine per&ograve; &egrave; passato nella nostra lingua perdendo la connotazione negativa che lo contraddistingueva.</p>
      <p>Che cos&rsquo;&egrave; la casa? Uno spazio fisico, certamente, ma soprattutto uno spazio mentale, intimo, che segna di fatto un territorio separato dall&rsquo;esterno: diciamo comunemente &ldquo;dentro casa&rdquo;, e "fuori casa&rdquo;. Nel modo di organizzare gli spazi, nella divisione e utilizzo di questi spazi e nell&rsquo;arredamento, diciamo chi siamo noi.</p>
      <p>La casa ci riflette. E&rsquo; nata come luogo protettivo, e al tempo stesso &egrave; la nostra base di partenza per uscire all&rsquo;esterno e per dare a chi viene dall&rsquo;esterno un&rsquo;impressione piuttosto che un&rsquo;altra.</p>
      <p>Non &egrave; un caso che siano eseguiti cambiamenti o lavori di ristrutturazione in coincidenza con particolari momenti della propria vita, in genere quando si verificano svolte o eventi molto importanti dal punto di vista affettivo.</p>
      <p>Come sappiamo, questo pu&ograve; accadere anche per quanto riguarda l&rsquo;abbigliamento: cambiare stile al modo in cui ci si veste dice che proiettiamo in modo rinnovato la nostra personalit&agrave;, i nostri desideri e i nostri bisogni.</p>
      <p>Gli antropologi hanno studiato come l&rsquo;abitazione rispecchi le diversit&agrave; culturali, al di l&agrave; della semplice necessit&agrave; di ripararsi dal caldo, dal freddo, e dagli sguardi indiscreti. Anche i nostri remoti antenati che utilizzavano le caverne come ripari, organizzavano gli spazi in funzione della propria vita quotidiana, e davano dunque un&rsquo;impronta personale a un luogo naturale. Gli antropologi hanno osservato con curiosit&agrave; il rapporto fra esterno e interno e tutti noi sappiamo che esistono popolazioni per le quali lo spazio esterno non &egrave; cos&igrave; rigidamente separato da quello interno come il nostro.</p>
      <p>Il test della casa &egrave; molto utilizzato con i bambini, e i bambini stessi tendono spontaneamente a disegnare la casa, che pu&ograve; rappresentare la famiglia, se stessi, ma soprattutto le emozioni e l&rsquo;affettivit&agrave;.</p>
      <p>Il primo aspetto che colpisce nel Disturbo da Accumulo &egrave; l&rsquo;impossibilit&agrave; di fruire di una casa vivibile e dalla negazione di questa impossibilit&agrave;. Affrontiamo dunque questo problema, che ha raggiunto una certa notoriet&agrave; presso il grande pubblico grazie ad alcune trasmissioni televisive, con intenti documentaristici basati su scene di vita reali.</p>
      <p>&nbsp;</p>
      <p><em>&ldquo;Non lo butto, potrebbe servirmi...&rdquo;.</em></p>
      <p>Chi non l&rsquo;ha mai detto, almeno una volta? E chi, almeno una volta, ha scoperto che qualche oggetto conservato si &egrave; rivelato utile? Probabilmente ognuno di noi.</p>
      <p>Questo ci fa vedere come l&rsquo;insidia sia dietro l&rsquo;angolo, quando la casa lentamente, un po&rsquo; per volta, si riempie di oggetti e quando gli spazi liberi per passare si riducono ai cosiddetti &ldquo;sentieri delle capre&rdquo;.</p>
      <p>Che cosa accade a chi rifiuta di considerare questo comportamento un problema e non capisce il disagio dei familiari conviventi? Che cosa accade nella mente di chi inizia a vergognarsi di come la casa appaia disordinata, sporca, caotica, ed evita di invitare amici e conoscenti, rinunciando alla socialit&agrave; e mettendo in pericolo &ndash; a causa del rischio di incendi, infortuni o per scarsa igiene &ndash; la vita e la salute propria e altrui?</p>
      <p>Il disturbo da accumulo &egrave; diffuso in tutto il mondo, con un&rsquo;incidenza del 4-5% e tra chi ne soffre non sembrano esservi distinzione di cultura o classe sociale.</p>
      <p>E&rsquo; stato chiamato in vari modi: disposofobia, hoarding disorder, accaparramento compulsivo, mentalit&agrave; Messie, sindrome di Collyer (dal nome di due fratelli che dopo la morte dei genitori si chiusero nella loro casa accumulando un numero impressionante di oggetti, tra cui quattordici pianoforti).</p>
      <p>Ognuna di queste definizioni mette l&rsquo;accento su un aspetto particolare del disturbo: la fase iniziale di accaparramento, la fase di accumulo, la fase in cui non si vuole buttare via nulla. In realt&agrave;, il disturbo &egrave; complesso: la persona che ne soffre non riesce a fare a meno di comprare, accaparrare, a volte rubare oggetti di ogni tipo, molto spesso inutili, che iniziano ad accumularsi impedendo un utilizzo razionale dei vari ambienti domestici.</p>
      <p>Il livello di gravit&agrave; non &egrave; sempre uguale, cos&igrave; come non &egrave; detto che il problema sia cronico. Gli studiosi distinguono cinque livelli di gravit&agrave;: il primo, indicato con il colore verde, non richiede particolare attenzione; il secondo, blu, necessita di attenzione e consiglio su come organizzare gli spazi della casa; il terzo, di colore giallo, &egrave; la linea di demarcazione che richiede un&rsquo;attenzione pi&ugrave; elevata, soprattutto da parte dei professionisti della salute mentale; il quarto livello, arancione, &egrave; considerato ad alto rischio e prevede l&rsquo;intervento di molti professionisti, tra cui quelli che fanno riferimento all&rsquo;Ufficio di Igiene; il quinto livello, rosso, &egrave; il pi&ugrave; grave, e pu&ograve; prevedere l&rsquo;intervento dei vigili del fuoco per mettere in sicurezza l&rsquo;ambiente.</p>
      <p>All&rsquo;interno di ogni livello di gravit&agrave;, si prendono in considerazione quattro categorie: presenza di animali, zonizzazione, igiene, funzione del nucleo familiare.</p>
      <p>Solitamente associato al Disturbo ossessivo compulsivo (DOC), il DSM V lo colloca dal 2013 nella macro categoria dei Disturbi ossessivo compulsivi e Disturbi correlati, ma come disturbo a s&eacute; stante, e non &egrave; detto che in futuro le cose non possano cambiare nuovamente, in quanto gli studiosi hanno notato come questo disturbo si presenti spesso associato a condizioni di ansia sociale o generalizzata, depressione maggiore o disturbi di personalit&agrave;, nonch&eacute; a condizioni mediche generali come il diabete o problemi cardio vascolari.</p>
      <p>L&rsquo;accumulo &egrave; presente in una piccola percentuale di persone che soffrono di Disturbo ossessivo compulsivo e soprattutto si manifesta con modalit&agrave; diverse. Nel DOC l&rsquo;ansia &egrave; l&rsquo;aspetto pi&ugrave; importante, mentre nel Disturbo da accumulo emerge solo se il soggetto &egrave; costretto a eliminare gli oggetti che ha accumulato. Inoltre, la persona affetta da DOC soffre per la sua condizione, mentre la persona che accumula mostra soddisfazione nell&rsquo;accumulo e pu&ograve; avere una scarsa o nulla consapevolezza del problema, percependo la sofferenza solo quando la casa &egrave; diventata del tutto invivibile o quando familiari e autorit&agrave; intervengono.</p>
      <p>Gli accumulatori non rispondono agli stessi trattamenti usati con i pazienti ossessivo compulsivi, tendono ad aggravarsi con l&rsquo;avanzare dell&rsquo;et&agrave;, e le stesse scansioni cerebrali hanno rilevato il coinvolgimento e l&rsquo;attivazione di differenti aree cerebrali. Naturalmente &egrave; possibile che entrambi i disturbi siano presenti.</p>
      <p>Fare una statistica pu&ograve; essere complicato, perch&eacute; raramente chi soffre di disturbo da accumulo chiede aiuto e quindi la quantit&agrave; di persone con questo disturbo pu&ograve; essere sottostimata. A ogni modo, non si pone questa diagnosi quando l&rsquo;accumulo compare come comportamento nel caso di altre sindromi psichiatriche come la schizofrenia, o disturbi dello spettro autistico, o lesioni cerebrali dovute a traumi, o sindromi neurodegenerative. Pu&ograve; essere complicata la diagnosi differenziale tra demenza frontotemporale e disturbo da accumulo, quando il sintomo inziale &egrave; proprio l&rsquo;accaparramento o il furto di oggetti privi d&rsquo;importanza, perch&eacute; questo tipo di demenza pu&ograve; insorgere prima della terza et&agrave; ed &egrave; caratterizzata da cambiamenti della personalit&agrave;, ossessioni, comportamenti bizzarri piuttosto che da un chiaro declino cognitivo.</p>
      <p>Che cosa &egrave; accumulato? Solitamente oggetti come scatole, scontrini, libri, giornali o vestiti. Ma &egrave; anche possibile che vengano accumulati avanzi di cibo e sacchi della spazzatura.</p>
      <p>Chi soffre di questo disturbo presenta alcune caratteristiche: solitudine, profonda paura di sbagliare ed eccessivo senso di responsabilit&agrave;, relazioni familiari difficili, infanzia trascurata, esperienza di gravi ristrettezze economiche, umore depresso. E&rsquo; possibile che il disturbo insorga dopo gravi stress ed eventi traumatici, rottura di un legame simbiotico con profondo senso di perdita.&nbsp;</p>
      <p>Le ragioni addotte per non sbarazzarsi di oggetti inutili o dannosi possono essere affettive, o strumentali, o legate a quello che si ritiene il valore di un oggetto.</p>
      <p>Anche il collezionista pu&ograve; riempire la casa in modo esagerato, ma solitamente ha molta cura dei propri oggetti ed &egrave; orgoglioso di mostrarli alle persone in visita. L&rsquo;accumulatore seriale invece non ha cura degli oggetti che ha accaparrato, non li usa e li valorizza solo se un intervento esterno lo costringe a fare ordine e a liberarsi delle cose superflue, il che genera resistenze e una profonda angoscia.</p>
      <p>Nel disturbo da accumulo emerge un rapporto di profonda sofferenza con se stessi. L&rsquo;intervento terapeutico viene di solito chiesto dai familiari, che non reggono pi&ugrave; la situazione e quindi&nbsp; a loro in primo luogo va offerto il sostegno.</p>
      <p>In secondo luogo, &egrave; indispensabile una visita domiciliare, ma &egrave; chiaro che prima si dovr&agrave; ottenere il consenso della persona che soffre del disturbo e che generalmente nega che esista un problema. E&rsquo; necessario un lavoro di &eacute;quipe, che preveda la figura dello psichiatra e dello psicologo, del medico di base, dell&rsquo;assistente sociale se ci sono anche problemi di tipo lavorativo o finanziario, e dell&rsquo;organizzatore professionale, una figura nota soprattutto nel mondo anglosassone.</p>
      <p>Il medico di base pu&ograve; intervenire per tutto ci&ograve; che riguarda la salute generale del soggetto; lo psichiatra pu&ograve; impostare un trattamento farmacologico che incida su condizioni trattabili quali l&rsquo;ansia, la depressione, o il DOC, perch&eacute; al momento non esiste una terapia specifica per il Disturbo da Accumulo.</p>
      <p>La psicoterapia di elezione &egrave; di tipo cognitivo comportamentale, adattata a questo particolare disturbo.</p>
      <p>Da un punto di vista cognitivo &egrave; importante capire che cosa pensa il soggetto del comportamento di accumulo e tutte le idee disfunzionali legate al fare ordine; vanno valutate la capacit&agrave; di decidere che cosa &egrave; importante tenere e cosa buttare; di pianificare mentalmente il riordino; di controllare &ndash; se presente &ndash; l&rsquo;acquisto compulsivo; di effettuare esercizi di esposizione, prima solo immaginati, per valutare il grado di ansiet&agrave; che comportano, e stilare una graduatoria delle situazioni ansiogene.</p>
      <p>Da un punto di vista comportamentale, la persona pu&ograve; apprendere tecniche di rilassamento, pu&ograve; iniziare a riordinare grazie alle tecniche di esposizione prima immaginative e ora reali, affrontando l&rsquo;ansia che questo comporta e parlandone nelle sedute di psicoterapia, pu&ograve; seguire le indicazioni di una collaboratrice domestica preparata che lo aiuti a riorganizzare la casa. Molto utili si sono rivelati i gruppi di auto mutuo aiuto tra persone con gli stessi problemi di accumulo o lo stimolo alla socializzazione.</p>
      <p>Non bisogna nascondersi che la cura &egrave; lunga e spesso interrotta da soggetti che non intendono proseguire nel trattamento perch&eacute; mantengono una scarsa consapevolezza del problema o non intendono elaborare i propri vissuti. Per questo &egrave; importante che ogni terapia consideri la persona, e non solo il comportamento disturbato, e faccia leva sulle risorse, sui desideri, sugli obiettivi che possono motivare a un cambiamento, lavorando anche con la rete familiare e sociale.</p>
      <p>Infine, anche un lavoro terapeutico andato a buon fine non pu&ograve; dirsi completo se non prevede la possibilit&agrave; di una ricaduta, e dunque non affronta il problema prima della fine della terapia.</p>
      <p>Al terapista sono richieste doti di grande pazienza e tolleranza delle frustrazioni, date le obiettive difficolt&agrave; del trattamento.</p>
    </>
  ],
  [
    "le-peak-experiences",
    <>
      <h2>Le Peak Experiences</h2>
      <p>&nbsp;</p>
      <p><em>Le cime innevate sembravano molto pi&ugrave; basse e lontane sotto il chiaro di luna, strisce di stelle scintillavano qua e l&agrave; sul ghiaccio nero e lucido. Quel viaggio era come una poesia, con rime e parole magnifiche che restavano nel sangue (&hellip;) Benedikt si sent&igrave; invadere da una pace assoluta. Una fiducia sgorgata dal profondo si diffondeva in lui, totale e infallibile: l&igrave; camminava. Camminava l&igrave;.</em></p>
      <p><em>(Gunnar Gunnarsson, Il pastore d&rsquo;Islanda)</em></p>
      <p>&nbsp;</p>
      <p><em>Tante volte era rimasto in ammirazione dinanzi a un paesaggio, a un monumento, a una piazza, a uno scorcio di strada, a un giardino, a un interno di chiesa, a una rupe, a un viottolo, a un deserto. Solo adesso, finalmente, si rendeva conto del segreto.</em></p>
      <p><em>Un segreto molto semplice: l&rsquo;amore.(&hellip;)</em></p>
      <p><em>Quanto meschina sarebbe di fronte a un grande spettacolo della natura, la nostra esaltazione spirituale se riguardasse soltanto noi e non potesse espandersi verso un&rsquo;altra creatura.</em></p>
      <p><em>(Dino Buzzati, Un amore)</em></p>
      <p>&nbsp;</p>
      <p>Il termine &ldquo;peak experiences&rdquo;, cio&egrave; &ldquo;esperienze picco, esperienze culminanti o massime&rdquo; &egrave; stato coniato nel 1964 dallo psicologo americano Abraham Maslow, esponente della Psicologia umanistica, noto soprattutto per aver elaborato la &ldquo;piramide dei bisogni&rdquo;, che vanno dai bisogni fisiologici ai bisogni di autorealizzazione, di natura psicologica, morale e spirituale. La mancata soddisfazione dei bisogni basilari (fisiologici, di sicurezza e di affetto), rende difficile per non dire impossibile la soddisfazione dei bisogni pi&ugrave; elevati, considerati comunque insiti negli esseri umani come gli istinti lo sono nelle varie specie animali.</p>
      <p>Secondo Maslow ci sono alcuni momenti della vita in cui avvertiamo una sensazione di intenso benessere e gioia di vivere, e percepiamo la realt&agrave; in modo diverso dall&rsquo;ordinario, in un modo che si avvicina o si identifica con il misticismo e l&rsquo;estasi. Sono momenti rari in cui la persona ha la sensazione di vivere se stessa, gli altri, il mondo, in modo pieno e creativo, ricco di significato e di energia, al di fuori delle categorie del tempo e dello spazio, privo di conflitti e senza sforzi, fatiche o giudizi negativi, concentrato solo sul presente.</p>
      <p>Quando la persona vive momenti simili, sente di aver realizzato se stessa, di aver espresso pienamente le sue potenzialit&agrave;, e quindi, in parole povere, di aver raggiunto la sommit&agrave; della piramide.</p>
      <p>Sono esperienze involontarie e molto difficili da descrivere con il linguaggio corrente. Le persone dicono di &ldquo;aver raggiunto l&rsquo;obiettivo della loro vita&rdquo;, o di sentirsi &ldquo;rapite&rdquo; o &ldquo;in estasi&rdquo;, ricalcando una terminologia poetica e religiosa, in quanto&nbsp; queste esperienze sono tipiche dei mistici. Ma possono scaturire anche dalla creativit&agrave; artistica, dalla scoperta scientifica, dalla pratica di uno sport, dall&rsquo;innamoramento, dall&rsquo;ascolto della musica, da semplici momenti della vita quotidiana.</p>
      <p>Maslow distingueva le peak experiences dalle plateau experiences, o esperienze altopiano, che sono costituite da un modo ottimista di vedere la vita, dalla pace interiore, dalla convinzione di avere un posto nel mondo e di esserne degni, senza per&ograve; la travolgente sensazione di gioia che caratterizza le prime. Possiamo definirle come la &ldquo;base&rdquo;, pi&ugrave; stabile e continua nel tempo, da cui in momenti inaspettati possono nascere le esperienze di picco, che ripetiamo, non dipendono dalla volont&agrave;.</p>
      <p>Nella cultura cinese, il libro dell&rsquo;I Ching, utilizzato popolarmente per la divinazione, &egrave; in realt&agrave; un testo filosofico classico, al cui centro c&rsquo;&egrave; il concetto di cambiamento, perch&eacute; tutto muta, tutto scorre, ma le basi della vita restano identiche: una forza stabile, che accoglie e contiene, corrispondente alla terra e all&rsquo;elemento femminile, materno, e una forze dirompente e creativa, che corrisponde al cielo e all&rsquo;elemento maschile, paterno. Queste due forze corrisponderebbero alle esperienze di cui parla Maslow, che si completano a vicenda e non potrebbero esistere l&rsquo;una senza l&rsquo;altra. Entrambe concorrono a dare alla vita il suo significato, permettendo alla persona di crescere sicura, affettivamente ricca, flessibile e&nbsp; aperta all&rsquo;esperienza, desiderosa di esprimere le proprie potenzialit&agrave;.</p>
      <p>Jung studi&ograve; l&rsquo;I Ching, ritenendo che le risposte ai quesiti posti durante la divinazione fossero utili alla persona per scoprire in se stessa quello che gi&agrave; c&rsquo;&egrave; ma di cui non ha consapevolezza. Un&rsquo;occasione per conoscere se stessi, e conoscendo se stessi per realizzare il S&eacute;, il centro della personalit&agrave;, che integra le diverse istanze psichiche, tra cui l&rsquo;Io, che &egrave; solo una parte di noi, anche se tendiamo a indentificarci con essa e quindi a considerarla la totalit&agrave;, il nucleo della nostra identit&agrave;. Passare dall&rsquo;Io al S&eacute; significa accettare la spinta evolutiva che ci porta a realizzare quello che siamo, senza rigidit&agrave;, chiusure, regressioni, e a raggiungere quindi uno stato di sicurezza e pace interiore da cui possono scaturire le peak experiences.</p>
    </>
  ],
  [
    "psicologia-e-yoga",
    <>
      <h2>Psicologia e Yoga</h2>
      <p>&nbsp;</p>
      <p>Il termine yoga deriverebbe dal sanscrito yuj, che significa &ldquo;unire, soggiogare, legare&rdquo; e indica un insieme di pratiche che hanno lo scopo di conoscere se stessi equilibrando corpo e mente e aprendo l&rsquo;essere umano al contatto con le realt&agrave; spirituali pi&ugrave; elevate.&nbsp;</p>
      <p>Originatosi millenni fa, si &egrave; sviluppato in modi diversi, secondo l&rsquo;interpretazione delle numerose scuole diffuse nel sub continente indiano e le re-interpretazioni diffuse in Occidente, che spesso lasciano perplesso e confondono chi vuole accostarsi a questa disciplina.</p>
      <p>Solitamente, quando si parla di yoga si fa riferimento al testo del filosofo indiano Patanjali, Yoga Sutra, gli Aforismi dello Yoga, che lo descrive come una forma di autodisciplina individuale (ma non individualista), molto rigorosa, che va ben oltre il &ldquo;faccio un po&rsquo; di yoga&rdquo; come si farebbe una qualunque forma di ginnastica, o di esercizio sul respiro, o un po&rsquo; di meditazione. Un vero praticante yoga segue una precisa condotta morale nei confronti di se stesso (cura della propria salute, sobriet&agrave;, studio di s&eacute;, seriet&agrave; nel lavoro, capacit&agrave; di porsi obiettivi, scelta di un modello divino a cui ispirarsi, perseveranza) e degli altri (non violenza, onest&agrave;, ascolto, condivisione dei beni): su questa base, pu&ograve; praticare con successo le ulteriori tappe, il controllo e la conoscenza del corpo e della mente con l&rsquo;assunzione di particolare posture, di differenti modalit&agrave; di respiro, di tecniche di concentrazione e meditazione fino a giungere allo stato di Samadhy, che pu&ograve; essere tradotto come illuminazione, estasi, consapevolezza ecc.</p>
      <p>Come si vede, anche se conosciuto da noi soprattutto come un insieme di posture fisiche utili al benessere del corpo, lo yoga ha una certa visione della mente umana e una dimensione speculativa nata in un preciso contesto culturale: coerentemente con il pensiero induista e buddista, chi raggiunge il Samadhy, questo supremo grado di consapevolezza, ha soggiogato i sensi, svuotato la mente dal pensiero, rinunciato a qualunque identificazione con aspetti della vita terrena, e non &egrave; destinato a rinascere nel mondo, con tutto il suo corollario di sofferenze e dolori.</p>
      <p>Lo yoga in realt&agrave; non richiede l&rsquo;adesione a una fede e pu&ograve; essere integrato in qualunque tradizione religiosa, bench&eacute; le istituzioni religiose possano nutrire una certa diffidenza nei suoi confronti ( a meno che non sia inteso come pura pratica fisica e salutista) dato il carattere nettamente individuale dell&rsquo;approccio del praticante con il Divino, che non necessita di intermediari per la propria &ldquo;salvezza&rdquo;, con l&rsquo;eccezione di un maestro (guru) che aiuti nel percorso.</p>
      <p>Agendo su diversi livelli, chi &egrave; interessato alla pratica yoga, pu&ograve; scegliere il tipo di percorso e dove e come fermarsi, o proseguire. Sia la medicina che la psicologia (particolarmente la psicologia della salute) si sono interessate a questa pratica millenaria allo scopo di aiutare le persone a mantenere o recuperare un ragionevole stato di salute. Spesso le persone scelgono di praticare yoga per raggiungere uno stato di calma interiore, arrivando a padroneggiare ansia, irritabilit&agrave;, momenti di sconforto; oppure per migliorare l&rsquo;efficienza fisica, attraverso il raggiungimento di una buona flessibilit&agrave; corporea, una migliore respirazione e digestione, oppure per migliorare l&rsquo;efficienza cognitiva grazie alle capacit&agrave; di concentrazione richieste dalla pratica; infine, lo scopo potrebbe essere quello di accrescere la fiducia in se stessi, diventare pi&ugrave; ottimisti verso la vita e tolleranti nei confronti degli altri, e pi&ugrave; autonomi e determinati. La riduzione dello stress e l&rsquo;aumento della lucidit&agrave; nell&rsquo;affrontare i problemi quotidiani instaurano un circolo virtuoso da cui traggono beneficio sia il praticante sia quanti gli sono vicini.</p>
      <p>Questo non significa che praticare yoga sia facile e valido in tutte le circostanze. Abbiamo gi&agrave; detto che l&rsquo;esercizio richiede un atteggiamento perseverante e rigoroso: superficialit&agrave; e impulsivit&agrave; impediscono il raggiungimento di qualsivoglia risultato. Inoltre, la pratica potrebbe non essere adatta a persone che vivono l&rsquo;attenzione a se stesse come continuo rimuginio sui propri problemi o carenze, o che potrebbero vivere esperienze di dissociazione psicotica (sia nella meditazione che nella psicosi vi &egrave; un&rsquo;alterazione della coscienza ordinaria, con la differenza che nella psicosi lo stato alterato porta a sofferenza, angoscia, disorganizzazione mentale, mentre nella meditazione l&rsquo;alterazione non pregiudica il contatto con la realt&agrave; e porta anzi a vissuti di profondo benessere che permangono pi&ugrave; o meno a lungo anche dopo la fine della pratica).</p>
      <p>Come qualunque pratica non basata sull&rsquo;utilizzo del linguaggio (tecniche di rilassamento, musicoterapia, massaggio ecc.) pu&ograve; portare sul piano fisico, senza alcuna mediazione, i contenuti angoscianti che vorticano nella mente a livello pi&ugrave; o meno conscio, scatenando mal di testa o attacchi di panico.</p>
      <p>Lo yoga potrebbe essere controindicato in presenza di particolari problemi a livello muscolare o scheletrico e va usato con discernimento durante il ciclo mestruale, in gravidanza, menopausa, e secondo le diverse et&agrave; della vita.</p>
      <p>Utilizzare lo yoga come terapia richiede una supervisione accurata e la collaborazione di diversi specialisti.</p>
      <p>Spesso ci si chiede se lo yoga &egrave; una cosa e la meditazione un&rsquo;altra. In realt&agrave;, anche la &ldquo;semplice&rdquo; pratica delle asana (posture yoga) se non vissuta con il corpo da una parte e la testa dall&rsquo;altra, &egrave; una pratica meditativa. Ricordiamo che la meditazione non &egrave; la riflessione, l&rsquo;ordinato susseguirsi dei pensieri che ci aiutano a capire meglio i problemi e la realt&agrave; circostante; la meditazione &egrave; <strong>l&rsquo;attenzione al momento presente</strong>, che di volta in volta pu&ograve; riguardare il corpo, il respiro, le proprie emozioni, i pensieri, e nelle fasi pi&ugrave; profonde e complesse (non facilmente realizzabili) &egrave; la pura contemplazione della propria mente, che esiste anche se in quel momento non &egrave; soggetta a nessun pensiero o emozione. Mente che gli orientali paragonano al cielo limpido, di per se stesso vuoto, attraversato dalle nuvole bianche dei contenuti mentali soggetti al continuo cambiamento e con i quali non ci si pu&ograve; identificare. Questi sono concetti difficilmente accettabili per la cultura occidentale, che vede in questo &ldquo;vuoto&rdquo; anche la dissoluzione della propria identit&agrave; individuale (gi&agrave; messa in discussione dal credo induista e buddista nella reincarnazione) e non solo di pensieri ed emozioni, ma qui il discorso si farebbe troppo lungo e complesso.</p>
      <p>E&rsquo; vero per&ograve; che nella cosiddetta <em>mindfulness</em> (presenza mentale consapevole, attenzione piena al momento presente) di cui si sente parlare sempre pi&ugrave; spesso e che deriva dallo yoga, le persone imparano a non identificarsi con i propri pensieri &ndash; solitamente basati sull&rsquo;autocritica e la rilevazione continua di difetti, carenze e colpe &ndash; assumendo un atteggiamento pi&ugrave; benevolo verso se stessi, gli altri e la vita.</p>
      <p>Questo non significa che la mindfulness sia la panacea per tutti i mali.</p>
      <p>La stessa psicoterapia cognitiva, che da alcuni anni a questa parte propone l&rsquo;utilizzo della mindfulness nella terapia della depressione, afferma per&ograve; con giusta cautela che i risultati ottenuti, per il momento, riguardano solo questo tipo di disturbo (soprattutto se soggetto a continue ricadute) e che per altri tipi di disturbi sono necessari ancora studi e ricerche.</p>
      <p>La psicologia, e soprattutto la psicologia della salute (salute intesa come benessere bio-psico-sociale e non solo come assenza di malattia), hanno in comune con lo yoga la ricerca di un equilibrio interiore che permetta la realizzazione di s&eacute;, attraverso una consapevolezza piena e presente.</p>
      <p>Le strade per arrivarci sono per&ograve; diverse. La psicologia usa come strumento principale il colloquio, quindi il linguaggio verbale, attraverso il quale portare alla luce modalit&agrave; profonde che in caso di disturbo sono disfunzionali e causa di sofferenza, e spesso non percepibili in modo consapevole dalla persona. Compito dello psicologo &egrave; aiutarla a &ldquo;mettere insieme i pezzi&rdquo;, dando senso e dignit&agrave; ai sintomi e al disagio, collocandoli nel contesto pi&ugrave; ampio della storia individuale e del contesto relazionale.</p>
      <p>Per fare un esempio, una persona cresciuta in un ambiente poco gratificante e attento, pu&ograve; sviluppare l&rsquo;idea di non essere abbastanza valida o interessante per gli altri, e reagire cercando continuamente l&rsquo;attenzione degli altri o ritirandosi facendosi notare il meno possibile. Succede nella maggior parte dei casi che una volta adulta non ricordi di essere cresciuta in un ambiente di questo tipo, parlando solo di un vago senso di disagio e facendo molta fatica a ricordare episodi specifici; nel comportamento attuale, per&ograve;, nelle scelte lavorative o del partner, si riscontrano atteggiamenti che rimandano a una storia di difficolt&agrave; affettive, mancanza di accoglienza e di empatia, scarsa attenzione.</p>
      <p>Lo psicologo porta quindi all&rsquo;attenzione del paziente quanto queste esperienze, per quanto remote, siano ancora in grado di influenzare la visione di s&eacute; e i rapporti con gli altri, impedendo la naturale evoluzione interiore e l&rsquo;espressione delle proprie potenzialit&agrave;.</p>
      <p>E&rsquo; possibile che nel corso di questo lavoro si consigli al paziente di praticare yoga per aumentare la consapevolezza del corpo e dei segnali che manda per denunciare tensioni, sofferenza o soddisfazione, in modo che possa rendersene conto anche nella vita quotidiana e durante il lavoro psicoterapeutico; oppure per lavorare sulla respirazione, migliorando l&rsquo;apporto di ossigeno e consentendo di alleviare disturbi psicosomatici, anche senza arrivare necessariamente al controllo del respiro come controllo della mente, che apre a stati di coscienza particolari e dunque da considerare, come gi&agrave; detto, con molta attenzione a cautela.</p>
      <p>Un vero maestro yoga, dal canto suo, sa che anche le posture fisiche non hanno il solo fine di rendere il corpo pi&ugrave; flessibile e agile, ma agiscono sulla psiche e dunque vanno insegnate con cognizione di causa. La correzione di posture scorrette porta certamente a un beneficio fisico che per&ograve; si dovrebbe riflettere positivamente anche sullo stato d&rsquo;animo, e non sempre &egrave; cos&igrave;. Sollevare le spalle curve pu&ograve; significare meno dolori al collo o alla schiena, ma significa anche diventare pi&ugrave; assertivi e decisi nella vita, il che non &egrave; semplice per chi non ha strumenti sufficienti per vivere questi cambiamenti nelle sue relazioni quotidiane.</p>
      <p>La cosa fondamentale &egrave; <em>sapere che cosa si sta facendo e perch&eacute;</em>, al fine di non nuocere a chi &egrave; gi&agrave; di suo in difficolt&agrave;. Per quanto a nessuno piaccia soffrire di ansia, depressione o disturbi psicosomatici, il miglioramento deve poter essere integrato in modo da non causare scompensi ulteriori: una persona molto ansiosa potrebbe vivere come un pericolo anzich&eacute; un beneficio la riduzione della tensione a cui &egrave; abituata e che tenendola costantemente in guardia la fa sentire inconsciamente protetta.</p>
      <p>Solitamente, lo psicologo considera concluso il suo lavoro nel momento in cui il paziente sente di aver raggiunto un buon grado di benessere, padronanza (intesa come conoscenza e competenza, non solo come dominio e controllo) e resilienza di fronte alle difficolt&agrave;, lasciando a lui il compito di proseguire come ritiene pi&ugrave; opportuno il proprio itinerario di crescita personale.</p>
      <p>A questo punto, la psicologia umanistica e la psicologia transpersonale sono gli approcci che si avvicinano maggiormente allo yoga nella cura della persona che possiede un buon equilibrio, non pi&ugrave; continuamente soggetta ai mulinelli cognitivi ed emotivi che condizionano la mente, pronta ad aprirsi alle realt&agrave; spirituali pi&ugrave; elevate basate sull&rsquo;amore universale, convinta che si pu&ograve; diventare pi&ugrave; di quello che si &egrave;, perch&eacute; l&rsquo;apprendimento, l&rsquo;evoluzione e l&rsquo;integrazione non hanno et&agrave; anagrafica n&eacute; fine.</p>
    </>
  ],
  [
    "psicologia-e-spiriitualita",
    <>
      <h2>Psicologia e spiritualità</h2>
      <p>&nbsp;</p>
      <p>Il significato del termine &ldquo;spirito&rdquo; &egrave; &ldquo;soffio vitale, respiro&rdquo; e la spiritualit&agrave; &egrave; un tema che interessa la teologia, la storia delle religioni, la filosofia, l&rsquo;antropologia e la psicologia.</p>
      <p>Nelle tradizioni sciamaniche, tutto &egrave; spirito perch&eacute; ogni cosa &egrave; diretta e governata da spiriti, benevoli o malevoli, che solamente lo sciamano &egrave; in grado di conoscere e interpellare fungendo da tramite tra il mondo terreno e quello ultraterreno. Lo spirito umano, dopo la morte, acquisisce un rango pi&ugrave; elevato non essendo pi&ugrave; limitato dalla materia. (1)</p>
      <p>A partire dalle pi&ugrave; antiche civilt&agrave;, la filosofia ha dibattuto sullo spirito, su che cosa &egrave;, su come si manifesta, se &egrave; identico o diverso dall&rsquo;anima, se &egrave; un semplice sinonimo di coscienza, se &egrave; solo una delle tante qualit&agrave; della materia vivente oppure un&rsquo;entit&agrave; diversa dalla &ldquo;carne&rdquo;, un&rsquo;entit&agrave; immateriale e per questo immortale.</p>
      <p>Nel linguaggio comune delle persone religiose, anima e spirito sono sinonimi, anche se la prima riveste una connotazione pi&ugrave; individuale: l&rsquo;anima sarebbe spirito individualizzato, quindi una scheggia, una parte, dello Spirito di Dio, che con il suo &ldquo;soffio&rdquo; crea tutto e con il quale si pu&ograve; dialogare nella preghiera.</p>
      <p>Nell&rsquo;antica Grecia, i filosofi propongono visioni differenti del problema. A volte considerano psiche e anima come sinonimi, a volte la psiche &egrave; descritta in un modo che noi tradurremmo come &ldquo;insieme di fenomeni mentali&rdquo;, basati essenzialmente sul funzionamento della materia cerebrale, mentre l&rsquo;anima &egrave; l&rsquo;essenza vitale pi&ugrave; profonda della persona. Platone sosteneva un dualismo corpo &ndash; anima che Aristotele non accettava, ritenendo l&rsquo;anima un&rsquo;entit&agrave; non separabile dal corpo, tuttavia, parlando di &ldquo;anima intellettiva eterna&rdquo;, che presiede alle funzioni pi&ugrave; elevate della persona umana e che &egrave; priva di sostanza, non chiarisce se questa abbia caratteristiche di individualit&agrave; e quindi sopravviva alla morte del corpo.</p>
      <p>Il filosofo Plotino riteneva che esistesse un&rsquo;anima superiore o universale, incorruttibile e non soggetta a errore, che genera un&rsquo;anima inferiore, che si manifesta nel cosmo e in tutti gli esseri viventi guidando e governando le loro espressioni, ma che essendo a contatto con la materia, pu&ograve; corrompersi e peccare. Scopo della vita &egrave; cercare il contatto con l&rsquo;Uno da cui derivano tutte le cose, non lasciandosi ingannare dalla loro grande variet&agrave; (&ldquo;fuggi il molteplice&rdquo;) e ben sapendo che non si pu&ograve; entrare in contatto con la dimensione del sacro grazie alla logica e alla speculazione filosofica, ma con l&rsquo;intuizione e con l&rsquo;esperienza diretta. L&rsquo;Uno &egrave; identico all&rsquo;anima individuale, che si riunisce a questo principio supremo attraverso l&rsquo;estasi, condizione a cui la maggior parte delle persone non ha accesso perch&eacute; troppo ardua.</p>
      <p>Le idee di Plotino, che pure non concepiva l&rsquo;Uno come un Dio personale, hanno influenzato filosofi e teologi appartenenti alle religioni monoteiste e sono alla base&nbsp; delle correnti mistiche che nel corso della storia emergono quando materialismo, meccanicismo e razionalismo appaiono incapaci di dare una risposta soddisfacente alle domande fondamentali della vita: chi sono? perch&eacute; sono qui? da dove vengo e dove vado? che senso ha l&rsquo;universo?</p>
      <p>Domande che sono alla base di ogni ricerca spirituale.</p>
      <p>Il termine <em>psicologia</em> significa &ldquo;studio, scienza dell&rsquo;anima&rdquo;, quindi parrebbe la via maestra per parlare di spiritualit&agrave;. Tuttavia sappiamo che il vocabolo <em>psiche</em> &egrave; usato oggi per definire le facolt&agrave; mentali superiori, come l&rsquo;intelligenza, la memoria, la capacit&agrave; di scegliere e pianificare, e le attivit&agrave; basilari rappresentate dagli istinti o pulsioni, dai sentimenti e dalle emozioni, senza apparenti implicazioni di carattere filosofico o religioso.&nbsp;</p>
      <p>Ricordiamo che la psicologia, volendo ottenere riconoscimento come disciplina scientifica basata sull&rsquo;osservazione e l&rsquo;esperimento, nasce nella seconda met&agrave; dell&rsquo;Ottocento studiando e misurando in laboratorio alcuni particolari processi cognitivi, quali la percezione, l&rsquo;apprendimento e la memoria.</p>
      <p>Anche uno studioso come Sigmund Freud, che pure si era occupato di fenomeni molto pi&ugrave; sfuggenti e non replicabili in laboratorio quali il sogno e le produzioni dell&rsquo;inconscio, basava le proprie teorie su un razionalismo materialista che negava valore (ed esistenza) ad altre dimensioni della realt&agrave; umana.</p>
      <p>Carl Gustava Jung, al contrario, mostr&ograve; sempre molto interesse per il &ldquo;trascendente&rdquo;, per quello che va oltre la realt&agrave; individuale percepita dai cinque sensi, e questo spiega il suo interesse per le religioni, il misticismo e l&rsquo;occultismo.</p>
      <p>La <em>psicologia transpersonale</em>, il cui fondatore &egrave; considerato Roberto Assagioli, &egrave; quella che pi&ugrave; si &egrave; occupata del rapporto tra psicologia e spiritualit&agrave;.</p>
      <p>Anche Jung utilizz&ograve; il termine di &ldquo; inconscio transpersonale&rdquo; (che poi diventer&agrave; inconscio collettivo) per indicare una dimensione appartenente al singolo individuo e al tempo stesso all&rsquo;intera umanit&agrave;. Assagioli riprende il concetto junghiano di molteplicit&agrave; interiore: l&rsquo;essere umano possiede varie facolt&agrave; e agisce nel mondo grazie a molteplici processi intellettivi; mostra impulsi, emozioni, desideri; possiede convinzioni, valori, scopi, e caratteristiche riassunte nel termine &ldquo;personalit&agrave;&rdquo;.</p>
      <p>L&rsquo;eccessiva identificazione con uno o pochi aspetti di s&eacute;, pu&ograve; comportare rigidit&agrave; e impoverimento, ma al tempo stesso la variet&agrave; interiore pu&ograve; portare a confusione, angoscia, sensazione di frammentazione e di perdita di identit&agrave;. Secondo Assagioli, per non perdersi e far s&igrave; che questa frammentazione diventi ricchezza, &egrave; importante scoprire il proprio centro interiore, equidistante da tutte le espressioni mutevoli della nostra psiche, caratterizzato dalla consapevolezza e in grado di farci prendere coscienza dei talenti non espressi e di governare le nostre zone d&rsquo;ombra.</p>
      <p>Il risultato di questa scoperta &egrave; un&rsquo;esperienza di pienezza, di gioia e di completezza. Un&rsquo;esperienza che pu&ograve; essere definita mistica, dove ogni cosa ha un senso e la persona sperimenta un profondo legame con tutto ci&ograve; che esiste (peak experience, &ldquo;esperienza culmine&rdquo;), identica alle esperienze estatiche presenti nelle diverse religioni sia orientali che occidentali.</p>
      <p>William James, filosofo e psicologo americano (1842&ndash;1910), riteneva che le esperienze mistiche fossero sane e naturali, connesse a ogni forma di vera religiosit&agrave;. Freud invece le riteneva una regressione allo stato uterino e i comportamentisti americani, all&rsquo;inizio del Novecento, cercarono addirittura di abolire definitivamente l&rsquo;interesse scientifico verso ogni forma di interiorit&agrave;, compresa la coscienza, spostandolo sul comportamento osservabile.</p>
      <p>In ambito psicologico, prevalse l&rsquo;idea che queste esperienze fossero frutto di fantasie o, peggio, conseguenza di una qualche patologia mentale o del malfunzionamento neurale, ma l&rsquo;interesse per la dimensione spirituale dell&rsquo;uomo si mantenne grazie a studiosi come Jung, Assagioli, Horney, Maslow, Frankl e altri, che ne fecero oggetto della propria ricerca rifiutando di omologarsi alle tendenze dominanti e considerando gli interessi spirituali fondamentali per la piena realizzazione di s&eacute;, in quanto aiutano la persona a cercare il significato della propria vita e il proprio posto in un mondo di cui sentirsi parte. Superando i limiti delle preoccupazioni personali e sviluppando solidariet&agrave; e interesse per gli altri esseri umani e per ogni forma di vita, secondo questi autori, si possono sperimentare quelle &ldquo;esperienze culmine&rdquo; fatte di pace, gioia, benevolenza verso tutti che sono all&rsquo;opposto del conflitto, della separazione e del fanatismo.</p>
      <p>Il mistico ind&ugrave; Paramahansa Yogananda descriveva la mente come un lago: se le sue acque sono lisce e tranquille, possono specchiare la luna, se sono flagellate dalla tempesta non possono specchiare nulla. E&rsquo; necessario quindi acquisire la calma interiore, indispensabile per il contatto con la realt&agrave; spirituale.</p>
      <p>Lo studio della mente e del comportamento, e l&rsquo;elaborazione di teorie e pratiche cliniche che portano a un contenimento o a un superamento delle inquietudini e dei disturbi che li affliggono, &egrave; il campo privilegiato di azione della psicologia, che storicamente si &egrave; interessata pi&ugrave; alla &ldquo;malattia&rdquo; che alla &ldquo;salute&rdquo;, tuttavia grazie agli studiosi menzionati prima, la cui presenza &egrave; minoritaria ma significativa, si &egrave; compiuto un passo ulteriore, in direzione di un interesse e di una &ldquo;presa in carico&rdquo; della ricerca spirituale.&nbsp;</p>
      <p>&nbsp;</p>
      <ol>
        <li>Il termine &ldquo;sciamano&rdquo; deriva dal tunguso saman (e forse prima ancora dal sanscrito sramana = monaco). Fu introdotto in Europa dal mercante tedesco Adam Brand nel 1698, al ritorno da un suo viaggio da Mosca a Pechino.</li>
      </ol>
      <p>Il termine indicherebbe in senso stretto una figura tipica delle culture siberiane, in realt&agrave; &egrave; stato poi impiegato per indicare tutte quelle figure che nelle culture tradizionali hanno compiti di mediazione tra il visibile e l&rsquo;invisibile.</p>
    </>
  ],
  [
    "la-gratitudine",
    <>
      <h2>La gratitudine</h2>
      <p>&nbsp;</p>
      <p><em>&ldquo;Il sentimento di gratitudine &egrave; una delle espressioni pi&ugrave; evidenti della capacit&agrave; di amare&rdquo; (M. Klein)</em></p>
      <p>&nbsp;</p>
      <p>La gratitudine &egrave; uno dei campi pi&ugrave; studiati dalla psicologia positiva, cio&egrave; da quella parte della psicologia che considera la salute mentale come uno stato di equilibrio e di benessere, e non come semplice assenza di sintomi ansiosi o depressivi (d&rsquo;altra parte, anche la salute fisica non &egrave; pi&ugrave; considerata solo come assenza di sintomi o malattie).</p>
      <p>Il termine <em>gratitudine</em> deriva dal latino <em>gratus</em>, parallelo al greco <em>chair&ocirc;</em>, e da una pi&ugrave; antica radice indoeuropea <em>ghar </em>(<em>har</em>), presente nel sanscrito <em>h&agrave;rya-mi</em>, &ldquo;<em>amo, desidero, mi diletto</em>&rdquo;.</p>
      <p>Il vocabolario la definisce &ldquo;memoria di un beneficio ricevuto&rdquo;, e anche &ldquo;sentimento e disposizione d&rsquo;animo che comporta affetto verso chi ci ha fatto del bene&rdquo;, dove &egrave; esplicitamente sottolineato l&rsquo;aspetto emotivo e relazionale che nel primo caso resta maggiormente sullo sfondo, a meno che non siamo in grado di riconoscere tutta la ricchezza insita nei termine &ldquo;ricordare&rdquo; (tornare al cuore).</p>
      <p>Suoi sinonimi sono <em>riconoscenza, ringraziamento, riconoscimento, debito, obbligo</em>, e questo ci suggerisce che la gratitudine pu&ograve; presentare varie sfumature.</p>
      <p>Nella <em>riconoscenza</em> &egrave; posto l&rsquo;accento sull'apprezzamento per quanto ricevuto, derivato al <em>riconoscimento</em>, cio&egrave; dalla consapevolezza che questo &egrave; avvenuto (e non sempre le due cose vanno di pari passo. Infatti, riconoscere di aver ricevuto un dono non comporta automaticamente l&rsquo;apprezzamento, anche se di solito &egrave; cos&igrave; che succede).</p>
      <p>I termini <em>debito</em> e <em>obbligo </em>rilevano invece l&rsquo;aspetto morale, legato al senso del dovere e dell&rsquo;onore, o alla necessit&agrave; di ubbidire a un&rsquo;autorit&agrave; (<em>debito</em> deriva dal latino <em>debere, dovere</em>, e <em>obbligo</em> dal latino <em>ob-ligare</em>, cio&egrave; <em>legare a vantaggio di o legare per</em>). Da questo punto di vista, l&rsquo;aspetto emotivo-affettivo non &egrave; preponderante, e prevale quello normativo, legato alle convenzioni sociali.</p>
      <p><em>Ringraziamento</em> indica l&rsquo;atto esplicito, la dimostrazione della propria gratitudine con i fatti o con le parole. Deriva da <em>grazia</em>, che ha tra i suoi significati quello di liberazione, gentilezza, cosa piacevole, favore, benevolenza.</p>
      <p>La gratitudine nasce come risposta a un beneficio ricevuto, qualunque esso sia. Provarla e saperla esprimere &egrave; un ottimo mezzo per migliorare le relazioni sociali e per stare bene dal punto di vista psicologico, perch&eacute; crea in noi un senso di compiutezza, di pienezza.</p>
      <p>Si pu&ograve; imparare la gratitudine? S&igrave;, &egrave; possibile, e questo &ldquo;apprendistato&rdquo; si accompagna inevitabilmente alla capacit&agrave; di imparare a essere felici, o almeno pi&ugrave; sereni.</p>
      <p>Il primo passo &egrave; riconoscere gli eventi positivi e saper cogliere&nbsp; gli aspetti favorevoli anche in una situazione neutra o addirittura negativa (senza negare la realt&agrave;). Infatti, anche le esperienze di dolore, delusione, frustrazione contengono un potenziale di insegnamento, che se colto ci fa crescere e migliora il nostro equilibrio personale. Qualcuno riesce pi&ugrave; facilmente di altri, per cui possiamo pensare che la capacit&agrave; di essere grati poggi su basi innate o si formi comunque molto presto nel corso della vita, grazie a modelli adulti adeguati.</p>
      <p>Tuttavia possiamo allenarci a modificare la percezione della realt&agrave; diventando pi&ugrave; equanimi, cio&egrave; pi&ugrave; capaci, come si diceva prima, di cogliere gli aspetti positivi senza negare quelli negativi. Questo &egrave; impossibile se non si sviluppa un&rsquo;attenzione consapevole (per inciso la consapevolezza produce un effetto benefico pi&ugrave; generale, perch&eacute; affina le capacit&agrave; cognitive e quindi le capacit&agrave; di valutare lucidamente una situazione, di trovare soluzioni ai problemi ecc. anche in altre circostanze della vita).</p>
      <p>&nbsp;</p>
      <p>Di seguito, suggerisco alcuni esercizi.</p>
      <p>&nbsp;</p>
      <ol>
        <li>Accordatevi con familiari o amici disposti a esercitarsi insieme a voi. Dovete condividere con loro almeno una giornata, meglio se un tempo pi&ugrave; lungo. Al termine della giornata, o della settimana, o del mese, stilate individualmente l&rsquo;elenco delle cose accadute, negative, positive o neutre, date loro un punteggio da 1 a 10 per valutarne l&rsquo;importanza, e poi confrontate le liste. Questo pu&ograve; darvi un&rsquo;idea di come selezionate gli avvenimenti (avete ricordato pi&ugrave; quelli negativi che quelli positivi? Gli altri che cos&rsquo;hanno fatto?).</li>
      </ol>
      <p>In seconda battuta, confrontate il peso dato agli eventi. Potreste aver segnato due eventi positivi dando loro il valore di 3 e un solo evento negativo che per voi vale 10. Il confronto con gli altri pu&ograve; aiutarvi a valutare meglio la vostra percezione, come le dimensioni di un oggetto che possono essere grandi o piccole solo se inserite in un contesto (pensiamo ad Alice nel Paese delle meraviglie quando rimpicciolisce o ingrandisce).</p>
      <p>Se non trovate nessuno disposto a fare questo esercizio con voi, fatelo da soli. Ne uscir&agrave; comunque qualche informazione interessante.</p>
      <ol start="2">
        <li>Fate un elenco di cose positive e valutate quante volte vi capita di pensarci. Infatti a volte siamo talmente abituati alle cose positive da non notarle pi&ugrave;, a meno che improvvisamente ci vengano a mancare.</li>
        <li>monitorate in una giornata o in un tempo pi&ugrave; lungo quante volte vi capita di ringraziare e con quale stato d&rsquo;animo lo dite (grazie di cuore o grazie di circostanza?); al tempo stesso, notate quante volte vi capita di essere ringraziati da qualcuno.</li>
        <li>Infine, un quarto esercizio consiste nell&rsquo;osservare quante volte in una giornata o in un tempo pi&ugrave; lungo vi capita di meravigliarvi, si stupirvi, di essere quindi poco propensi all&rsquo;assuefazione e all&rsquo;abitudine.</li>
      </ol>
      <p>&nbsp;</p>
      <p>E&rsquo; importante avere la possibilit&agrave; di esprimere la gratitudine: chi prova questo sentimento sente la necessit&agrave; di comunicarlo e di vederlo accolto. Frasi del tipo &ldquo;Non ho fatto che il mio dovere&rdquo; oppure &ldquo;Non ho poi fatto molto&rdquo; sono frasi di circostanza che non devono in alcun modo sminuire la gioia di chi ha ricevuto un beneficio. Purtroppo un malinteso senso di modestia rischia di produrre questo effetto. La gratitudine &egrave; legata a un sentimento di felicit&agrave; e la felicit&agrave; per sua stessa natura porta alla condivisione.</p>
      <p>E&rsquo; importante sostenere la felicit&agrave; altrui. Giustamente, si dice che i veri amici si riconoscono nel momento del bisogno ma soprattutto si riconoscono nel momento della gioia. Riuscire a farlo, &egrave; il segnale che ci stiamo liberando da uno dei sentimenti pi&ugrave; nocivi per la nostra e altrui salute: l&rsquo;invidia.</p>
      <p>Siamo grati se riconosciamo che l&rsquo;evento positivo &egrave; dovuto all&rsquo;intenzione di&nbsp; qualcuno che voleva farci del bene, ma possiamo essere grati anche se questa intenzione non c&rsquo;&egrave;. Ad esempio, possiamo provare gratitudine verso il creato pur non essendo credenti, oppure essere riconoscenti a uno scrittore, a un filosofo vissuto secoli fa. Questo significa avere una visione molto matura della realt&agrave;, una visione in cui &egrave; preminente il concetto di relazione: se stessi come parte di un tutto, un tutto verso cui mostrarsi solidali per diminuirne la sofferenza e per potenziarne al massimo la realizzazione.</p>
      <p>L&rsquo;evento positivo &egrave; essenzialmente qualcosa che &egrave; percepito come un dono, non come qualcosa che dipende in modo particolare da un nostro merito, da un nostro comportamento, anche se possiamo essere grati alle persone che riconoscono i nostri meriti. Si tratta di un dono rivolto pi&ugrave; all&rsquo;<em>essere</em> che al <em>fare</em>, e riguarda la dimensione dell&rsquo;amore, sia per chi gratifica sia per chi &egrave; gratificato. C&rsquo;&egrave; in entrambi i casi, il desiderio di esprimersi pienamente, di stare bene e di fare star bene.</p>
      <p>Non si tratta solo di comunicare un sentimento positivo, si tratta di qualcosa che aumenta il nostro benessere. Le persone capaci di empatia e gioiose esprimono pi&ugrave; facilmente di altre la loro riconoscenza, e nella gratitudine &egrave; sempre presente un sentimento di felicit&agrave;.</p>
      <p>Si dice che quando si fa qualcosa di buono per gli altri non bisogna sentirsi frustrati se non si riceve gratitudine. Una reazione di frustrazione e rabbia nasce pi&ugrave; da un bisogno di essere riconosciuti come persone valide, quindi nasce da un sentimento d&rsquo;insicurezza. Se non si riceve gratitudine per quanto fatto, &egrave; pi&ugrave; opportuno un sentimento di compassione (nel senso di &ldquo;patire insieme&rdquo;, non nel senso di una sprezzante commiserazione) perch&eacute; la persona o le persone interessate non hanno condiviso la gioia, non si sono accorte di aver perso un&rsquo;occasione per sperimentarla (hanno perso il dono, l&rsquo;evento positivo). In questo caso, chi ha donato non vede annullata la gioia data nel donare.</p>
      <p>Esprimere gratitudine ha un forte impatto sulle relazioni con gli altri.</p>
      <p>Abbiamo visto che si pu&ograve; essere riconoscenti per obbligo, e in questo caso potremmo dire che si curano i rapporti da un punto di vista della correttezza e della formalit&agrave;, forse anche di una certa simpatia, soggetta in ogni caso al &ldquo;vivere civile&rdquo;. Siamo quindi ancora lontani dagli effetti prodotti dalla sincera gratitudine, che creano un clima affettivo caldo, empatico, e aumentano la capacit&agrave; di stare vicini. Per una specie sociale come la nostra, sperimentare la vicinanza &egrave; uno dei fattori di benessere pi&ugrave; importanti, che influisce direttamente sul sistema ormonale e sul sistema immunitario, e quindi anche sulla salute fisica, oltre che psicologica.&nbsp;</p>
    </>
  ],
  [
    "la-resilienza",
    <>
      <h2>La resilienza</h2>
      <p>&nbsp;</p>
      <p>Il termine<em> resilienza</em> deriva dal latino e significa saltare o rimbalzare. &Egrave; un termine utilizzato in fisica per descrivere l'attitudine di un corpo a resistere ad un urto di tipo meccanico senza rompersi. E&rsquo; un termine utilizzato anche in informatica quando si parla di un sistema che funziona malgrado alcuni difetti.&nbsp;</p>
      <p>Nelle scienze sociali fa riferimento alla capacit&agrave; di resistere, senza spezzarsi, in situazioni molto gravi, quali traumi dovuti ad abusi sessuali, gravi malattie, guerre, catastrofi naturali. Potremmo dunque definire questo fenomeno come la capacit&agrave; di "restare in piedi", sia che si parli dello sviluppo normale di un bambino malgrado la presenza di un ambiente sfavorevole oppure la rinascita dopo aver subito un trauma.&nbsp;</p>
      <p>&Egrave; curioso come la resilienza sia sempre esistita ma che nessuno prima degli anni Ottanta se ne sia mai occupato in termini scientifici: da sempre si sa che ci sono persone che riescono a sopravvivere malgrado esperienze molto gravi, tuttavia non si &egrave; cercato di comprendere come mai alcuni soccombono, altri restano pesantemente segnati, altri riescono, pur nel dolore e nella difficolt&agrave;, a ricostruirsi una vita. Avere dato un nome a quest&rsquo;esperienza permette di concettualizzarla, dunque di descriverla e di approfondirla.</p>
      <p>Resilienza non significa invulnerabilit&agrave;, non &egrave; una capacit&agrave; data in modo definitivo, prevede tempi lunghi e convinzione che nonostante quanto accaduto &egrave; possibile resistere, fronteggiare, trasformare ed infine integrare l'esperienza negativa per poter andare avanti. In altri termini, la resilienza permette di utilizzare anche le esperienze pi&ugrave; negative per apprendere le competenze utili a migliorare la propria vita e a provare di nuovo soddisfazione.</p>
      <p>Una bella metafora sul tema della guarigione &egrave; quella dell'albero ferito da giovane, che crescendo riduce la sua ferita in confronto alle proprie dimensioni e dove anche i nodi e i rami contorti testimoniano una storia originale.</p>
      <p><em>La resilienza non nega in alcun modo l'esperienza del dolore ma non riduce la</em> <em>persona a quest&rsquo;esperienza</em>, perch&eacute; valuta anche le sue risorse e le risorse presenti nel suo ambiente, e considera le determinanti che spingono a continuare a vivere, in primo luogo la capacit&agrave; di avere rapporti umani, di possedere iniziativa, autonomia, creativit&agrave;, senso dell'umorismo, etica, capacit&agrave; di arrivare al cuore dei problemi. Un buon livello di intelligenza, un temperamento attivo, sapere che &egrave; possibile controllare dall'interno e non essere quindi in balia degli eventi, essere amabili per gli altri, sono fattori di protezione individuale molto importanti.&nbsp;</p>
      <p>I traumi hanno effetti diversi non solo perch&eacute; colpiscono persone diverse ma anche perch&eacute; avvengono in momenti diversi della vita in ambienti diversi: questo fa s&igrave; che la resilienza non sia soltanto un carattere squisitamente personale ma si possa esprimere solo in un contesto che in qualche modo lo permette. Il trauma pu&ograve; essere talmente grave da superare le capacit&agrave; della persona, ecco perch&eacute; un ambiente favorevole si dimostra veramente importante.&nbsp;</p>
      <p>Non sempre la persona mostra reazioni traumatiche dopo un fatto grave, perch&eacute; i sintomi possono restare nascosti, anche per molti anni, e poi, magari a seguito di un altro evento traumatico, manifestarsi con violenza. Lo stress post-traumatico pu&ograve; manifestarsi anche a distanza di tempo ed &egrave; caratterizzato da forti reazioni emotive. Non mostrare particolari reazioni psicopatologiche dopo un trauma non significa necessariamente averlo superato.&nbsp;</p>
      <p>Teniamo presente che a volte il racconto del trauma avviene dopo molto tempo l'evento, eppure la resilienza pu&ograve; essersi gi&agrave; espressa: &egrave; solo necessario mettere un periodo di tempo in mezzo perch&eacute; la persona possa comprendere che cosa l'ha tenuta in piedi nel periodo della sofferenza e in quello successivo. Di solito si dice che il tempo &egrave; un gran medico ma non &egrave; il semplice passare del tempo che guarisce la persona, &egrave; quello che accade nel frattempo, anche se la persona non ha piena consapevolezza del modo in cui sta recuperando le forze.&nbsp;</p>
      <p>La ferita inferta, per quanto cicatrizzata, condiziona lo sviluppo successivo, che non sar&agrave; come avrebbe potuto essere se questo trauma non ci fosse stato. Tuttavia l'esperienza negativa pu&ograve; essere trasformata in apprendimento e la persona non&nbsp; rester&agrave; congelata nel suo dolore.&nbsp;</p>
      <p>Per comprendere meglio la resilienza bisogna comprendere i termini <em>fragilit&agrave;</em> e <em>vulnerabilit&agrave;</em>. La fragilit&agrave;, che richiama il termine "frammento", il termine "frangere" cio&egrave; rompere, sottolinea il rischio connesso ad ogni esistenza umana, ma anche un qualcosa di strettamente personale, legato al bagaglio genetico o a seri problemi ambientali che hanno determinato una particolare condizione.&nbsp;</p>
      <p>Il termine vulnerabilit&agrave;, dal latino <em>vulnus</em>, che significa ferita, &egrave; una condizione che pu&ograve; essere transitoria e pi&ugrave; o meno grave secondo il grado della fragilit&agrave; iniziale. Dopo che questa ferita &egrave; stata inferta non si pu&ograve; essere pi&ugrave; quelli di prima, la persona si sente priva dei consueti punti di riferimento, "perde la bussola", e non sa ancora che cosa sar&agrave; di lei. La fuga pu&ograve; essere una reazione comprensibile, purch&eacute; non diventi uno stile e porti la persona a ritirarsi per evitare di soffrire ancora. E&rsquo; molto importante che abbia la possibilit&agrave; di comprendere e di comunicare sentimenti di rabbia, di tristezza, di delusione, di smarrimento connessi alla crisi, e ricordare che crisi significa scelta, e quindi capacit&agrave; e volont&agrave; di assumersi la responsabilit&agrave; di scegliere tra possibilit&agrave; diverse.&nbsp;</p>
      <p>Una di queste consiste nel lasciare che il dolore condizioni permanentemente la propria vita bloccando le capacit&agrave; evolutive, ma questo &egrave; il contrario della resilienza. Dal punto di vista culturale, la nostra societ&agrave; non favorisce l'accoglienza e la comprensione della fragilit&agrave; e della vulnerabilit&agrave;. Ognuno di noi &egrave; spaventato da tutto ci&ograve; che sfugge al suo controllo e cerca di cancellare l'idea che le proprie sicurezze possono essere perse. La nostra societ&agrave; ha paura proprio perch&eacute; cerca di tenere lontani la debolezza, il rischio, le incognite. Invece la vita chiede di essere adattabili, cio&egrave; capaci di sopravvivere malgrado il dolore e non solo di sopravvivere, ma di riprendere a vivere con capacit&agrave; e consapevolezza diverse.&nbsp;</p>
      <p>E&rsquo; molto importante avere un progetto, ottenere risultati soddisfacenti, avere la possibilit&agrave; di ricevere stima e sapere che gli altri possono essere stimati.&nbsp;</p>
      <p>E&rsquo; molto importante, se si vuole costruire il processo di resilienza, prestare attenzione a come agisce il gruppo (familiare, amicale, sociale ecc.): &egrave; nel gruppo che la persona pu&ograve; ricevere sostegno e fornirlo, tuttavia i conflitti e le differenze presenti nei gruppi non devono essere negati. Insegnare strategie di compromesso e di negoziazione &egrave; utile per gestire gli stress legati alle relazioni umane e renderle pi&ugrave; soddisfacenti.&nbsp;</p>
      <p>Possiamo terminare rilevando che nel processo di resilienza entrambi i termini, quello di limite e quello di capacit&agrave;, vanno integrati tra loro. Infatti, non vanno esaminati separatamente, ma vanno compresi nella reciproca interazione. Operare secondo la prospettiva della resilienza, cio&egrave; della ristrutturazione dopo lo stress, non significa semplicemente eliminare un danno, a volte il danno &egrave; ineliminabile, ci&ograve; su cui dunque occorre agire &egrave; come continuare la propria strada pur essendo stati segnati.&nbsp;</p>
      <p>Esistono certo delle tecniche per realizzare tutto questo, ma la resilienza va ben al di l&agrave; delle tecniche, perch&egrave; &egrave; un modo di vivere, di essere vivi.</p>
    </>
  ],
  [
    "il-modello-transteorico-di-cambiamento",
    <>
      <h2>Il modello transteorico di cambiamento</h2>
      <p>&nbsp;</p>
      <p>E&rsquo; un modello che viene elaborato nei primi anni Ottanta da due professori americani, James Prochaska, Direttore di un Centro di Ricerca per la prevenzione del cancro, e Carlo Di Clemente, insegnante di psicologia clinica a Baltimora.</p>
      <p>Entrambi erano interessati a comprendere quali fattori motivazionali spingono al cambiamento, con quali modalit&agrave; il cambiamento si realizza e che cosa pu&ograve; determinare il mantenimento nel tempo dei risultati oppure il fallimento con il ritorno al modello precedente di comportamento.</p>
      <p>I termini &ldquo;cambiare, cambiamento&rdquo; derivano dal greco k&agrave;mpsis, curva, con tutti i suoi derivati (giravolta, cammino tortuoso, ed anche piegare, girare intorno).</p>
      <p>Che cosa produce un cambiamento nelle persone? Come avviene questo cambiamento?</p>
      <p>Gli studiosi hanno concentrato la loro attenzione su quello che avviene durante la psicoterapia, indipendentemente dall&rsquo;orientamento seguito dai terapisti. Non solo. Le persone possono cambiare anche in assenza di trattamento, o con trattamenti molto brevi, o frequentando gruppi di auto aiuto. L&rsquo;ambizione di questi studiosi &egrave; stata quella di elaborare un modello solido ma sufficientemente elastico da adattarsi a diverse condizioni ed aperto a nuove scoperte.</p>
      <p>Come scritto sopra, cambiare &egrave; &ldquo;infilare una curva&rdquo;, prendere un&rsquo;altra strada. Tutto questo per&ograve; non avviene in modo repentino. Il modello transteorico sostiene che i cambiamenti sono progressivi, che possono subire un arresto, che devono attraversare diverse fasi. Il tempo e la motivazione della persona (ricordiamo che motivazione deriva da motus, movimento) scandiscono queste fasi, rallentandole, accellerandole, tenendole in stand by. In ognuna delle fasi, la persona pone le basi per passare alla fase successiva.</p>
      <p>Il primo passo &egrave; dato dal riconoscimento del problema. Se la persona non lo riconosce, non avvier&agrave; alcuna azione di cambiamento. A volte sorprende come si possa essere inconsapevoli di un problema che ad occhi estranei appare chiarissimo. Il fatto &egrave; che riconoscere qualcosa come un problema significa mettere in crisi il proprio universo di significati. Pi&ugrave; l&rsquo;identit&agrave; individuale &egrave; fragile, pi&ugrave; l&rsquo;autostima &egrave; bassa, pi&ugrave; la persona &egrave; incapace di mettere in atto adeguati strumenti protettivi, pi&ugrave; tender&agrave; a difendere tenacemente comportamenti, idee, relazioni disfunzionali o addirittura pericolosi. A volte le cose non sono tanto drammatiche e certi comportamenti vengono mantenuti solo per abitudine.</p>
      <p>Le persone che non riconoscono l&rsquo;esistenza di un problema mostrano alcuni comportamenti caratteristici: non elaborano tutte le informazioni utili al riconoscimento del problema, sono affettivamente poco coinvolte sugli aspetti negativi, non si impegnano nel tentativo di cambiare.</p>
      <p>Nel momento in cui &ndash; spesso a seguito di ripetuti insuccessi, di un intervento esterno o a seguito di gravi conseguenze personali &ndash; il soggetto si rende conto che c&rsquo;&egrave; qualcosa che non va, pu&ograve; iniziare a mostrare qualche dubbio sulla bont&agrave; dei propri atteggiamenti. Le persone molto rigide, limitate o abituate a non prendersi le proprie responsabilit&agrave; potrebbero non arrivare a questa prima fase e restare chiuse nel proprio schema di vita. Se invece accettano di considerare le contraddizioni, le sconfitte, gli esiti negativi come un qualcosa che non pu&ograve; essere fatto rientrare forzatamente nella propria lettura della realt&agrave;, si preparano alla fase della decisione, quella in cui si comincia a riflettere su che cosa fare e come farlo per risolvere i problemi e passare quindi all&rsquo;azione.</p>
      <p>Ci sono persone che pur avendo compreso molto, non passano alla fase dell&rsquo;azione. In ultima analisi, &egrave; invece il cambiamento del comportamento che porta ad una ricchezza di esperienze nuove per non dire ad un modo di vivere, di pensare e di sentire veramente nuovi. Le persone tenaci e perseveranti, quelle che hanno bisogno di appoggio ma lo accettano volentieri, quelle che vogliono controllare il loro problema anzich&eacute; essere controllate dal problema, hanno pi&ugrave; possibilit&agrave; di affrontare questa fase senza spaventarsi e ricadere nel modello precedente. L&rsquo;assunzione di nuovi comportamenti e nuovi modi di vedere le cose determina l&rsquo;uscita del problema.</p>
      <p>Gli ostacoli pi&ugrave; comuni al successo dell&rsquo;azione intrapresa riguardano il sentimento di una scarsa autoefficacia, di vergogna, di colpa. La persona pu&ograve; allora, come si diceva prima, ritornare sui propri passi.</p>
      <p>Per evitare che questo succeda, &egrave; importante che la persona elabori comportamenti alternativi a quelli inadeguati e li applichi nel momento in cui si ripresentano le situazioni problematiche. Questo processo sar&agrave; pi&ugrave; facile se contemporaneamente migliora l&rsquo;immagine di s&eacute;, ai propri occhi e a quelli delle persone significative.</p>
      <p>Possiamo domandarci giustamente in che cosa consista il processo di cambiamento. Diciamo innanzitutto che non si tratta di un processo singolo, ma di un insieme di processi.</p>
      <p>Alcuni riguardano l&rsquo;area cognitiva, come l&rsquo;aumento della consapevolezza, l&rsquo;aumento dell&rsquo;autostima e il miglioramento dell&rsquo;immagine di s&eacute;.</p>
      <p>Aumentare la consapevolezza significa aiutarsi/aiutare ad elaborare meglio le informazioni dando loro un significato che vada al di l&agrave; delle apparenze e ad allargare la propria visione delle cose. Le persone curiose sono favorite in questo perch&eacute; non temono di provare nuove esperienze, ma questo servirebbe a poco se poi mancasse la rielaborazione delle esperienze stesse. Inoltre non &egrave; facile accettare che esistano altri modi di vedere le cose che non siano i propri, perch&eacute; questo mette in crisi la &ldquo;mappa cognitiva&rdquo; di cui ognuno di noi dispone e sulla quale fonda la propria identit&agrave;.</p>
      <p>Aumentare l&rsquo;autostima e migliorare l&rsquo;immagine di s&eacute; sembra un desiderio comune a tutti, ma &egrave; incredibile notare quanti bastoni tra le ruote ci si pone da soli. Un&rsquo;immagine di s&eacute; pi&ugrave; piacevole ed interessante pu&ograve; essere vissuta inconsciamente come una minaccia, e dal canto suo l&rsquo;aumento dell&rsquo;autostima (che comporta inevitabilmente una maggiore autonomia e fiducia in s&eacute;) pu&ograve; far temere di essere abbandonati e lasciati soli.</p>
      <p>Altri processi di cambiamento riguardano l&rsquo;area delle esperienze. Partecipare a gruppi di auto muto aiuto, trovare un nuovo lavoro, partecipare a gruppi teatrali, intraprendere una terapia familiare, significa mettersi in gioco dal punto di vista relazionale ed emotivo. In genere &egrave; il primo passo ad essere difficile. La persona indugia o rinuncia perch&eacute; teme di non essere all&rsquo;altezza, perch&eacute; non si sente socialmente desiderabile, perch&eacute; teme di essere travolto dalle sue stesse emozioni. Non &egrave; un caso che alcune persone, pur partecipando ad una psicoterapia di gruppo, sentano la necessit&agrave; di uno spazio di terapia individuale, pi&ugrave; rassicurante.</p>
      <p>Il terzo gruppo di processi di cambiamento riguarda gli aspetti comportamentali. La persona si adopera perch&eacute; un certo comportamento diventi stabile (es., chi vuole smettere di fumare fa attenzione a sospendere o eliminare quelle abitudini che inevitabilmente erano seguite dall&rsquo;accensione della sigaretta, come prendere il caff&egrave; al bar), oppure impara certe strategie per aumentare la propria autoefficacia, oppure affronta un contro condizionamento che lo liberi da paure e fobie. E&rsquo; importante che la persona si senta premiata o si premi da sola quando ha raggiunto il risultato desiderato. Le persone che sono affidabili e che ci tengono a portare avanti gli impegni presi, e le persone che hanno una ragionevole fiducia in se stesse sono favorite. Se mancano queste premesse, &egrave; necessario costruirle prima di affrontare i cambiamenti desiderati.</p>
      <p>Nei casi pi&ugrave; complicati, pi&ugrave; che agire sulla persona si agisce sul suo ambiente di vita, inteso come ambiente fisico e come ambiente relazionale.</p>
      <p>Come si pu&ograve; intuire, quando decidiamo che &ldquo;&egrave; ora di cambiare&rdquo;, mettiamo in atto la maggior parte di questi processi.</p>
      <p>C&rsquo;&egrave; qualcosa che pu&ograve; aiutarci a predire il successo o l&rsquo;insuccesso del cambiamento? Dato per scontato che nessuno ha la sfera di cristallo, possiamo per&ograve; stilare una sorta di bilancio dei fattori positivi e di quelli negativi legati ad un certo comportamento. Se i fattori negativi sono di meno o meno importanti di quelli positivi, la persona non vede ragioni valide per cambiare; man mano che i valori negativi aumentano e quindi cambia il modo di considerare il problema, la persona pu&ograve; decidere di fare qualcosa. A volte, un solo fattore positivo ha la meglio su dieci fattori negativi, per cui non conta tanto il numero dei fattori in gioco ma la loro forza, sia cognitiva che affettiva.</p>
      <p>Una domanda che si pongono le persone desiderose di aiutare chi ha un problema &egrave;: come posso persuaderlo a cambiare?</p>
      <p>Intanto ricordiamo che<em> persuadere</em> ha la stessa radice latina e prima ancora sanscrita di <em>soave</em> (dal sanscrito <em>svadus</em>, che significa dolce)</p>
      <p>In realt&agrave; chi si fa persuadere, fondamentalmente era gi&agrave; persuaso, cio&egrave; convinto che possa essere piacevole o quantomeno utile fare quella data cosa.</p>
      <p>Pi&ugrave; spesso nella persona che dovrebbe cambiare e non cambia, &egrave; presente una certa dose di ambivalenza, che si esprime a livello interiore come conflitto tra due forze opposte. Quante volte abbiamo sentito dire o abbiamo detto: &ldquo;Non volevo farlo ma poi l&rsquo;ho fatto&rdquo;, oppure &ldquo;Ero deciso a farlo, poi mi sono tirato indietro&rdquo;, oppure ancora &ldquo;S&igrave;, ho fatto questa cosa ma non avrei dovuto&rdquo;?</p>
      <p>Se non si tratta di un comportamento isolato ma la persona continua a mettere in atto qualcosa che sa non essere opportuna per s&eacute; o per gli altri (quindi non si verifica alcun cambiamento), si pu&ograve; essere tentati di adottare l&rsquo;arma della persuasione sottolineando tutti gli svantaggi che crea quel dato comportamento. Si ignora cos&igrave; il significato positivo che quel comportamento pu&ograve; avere per la persona, che in genere reagisce portando il proprio conflitto interiore nella relazione, diventando oppositivo ed aggressivo. Non &egrave; un caso che nella psicoterapia, il terapista parta dagli aspetti positivi di un dato comportamento. Questo fa sentire il paziente compreso ad abbassa le sue difese, il che permette al terapista di elencare altri fattori a cui il paziente non ha pensato e che aumentano la sua consapevolezza. In genere, chi adotta un comportamento decisamente inadeguato riconosce pi&ugrave; facilmente i buoni motivi per smettere che non i buoni motivi per continuare: eppure non smette!</p>
      <p>E&rsquo; proprio il confronto con &ldquo;i buoni motivi per continuare&rdquo; che mette in contatto il paziente con valori, credenze, immagine di s&eacute;, fragilit&agrave; di cui non ha spesso coscienza.</p>
      <p>&nbsp;</p>
    </>
  ],
  [
    "la-psicologia-della-salute-1",
    <>
      <h2>La Psicologia della Salute - parte I</h2>
      <p>&nbsp;</p>
      <p><em>Definizione</em></p>
      <p>&nbsp;</p>
      <p>E&rsquo; una branca della psicologia che si occupa della salute intesa come benessere bio-psico-sociale, diritto dell&rsquo;individuo e interesse della collettivit&agrave;. Come si pu&ograve; capire gi&agrave; da queste poche righe, la salute non &egrave; intesa solo come salute fisica o come assenza di malattie organiche, e la cura non &egrave; intesa solo come adesione alle terapie farmacologiche, chirurgiche o riabilitative.</p>
      <p>La psicologia della salute considera l&rsquo;essere umano in modo olistico, completo, e si basa sull&rsquo;assunto che lo stato di salute debba essere inteso in modo globale, certamente nella sua espressione biologica, ma anche in quella psicologica e relazionale. Inoltre studia come i processi psicologici influenzano la salute fisica, l&rsquo;adesione alla prevenzione, alla diagnosi precoce, alle terapie, alla riabilitazione, e come il sostegno sociale, i sistemi sanitari, la cultura, le convinzioni e le opinioni in merito alla salute influenzano l&rsquo;individuo nelle sue scelte di cura.&nbsp;</p>
      <p>Si pone quindi in una zona di frontiera, promuovendo l&rsquo;interdisciplinariet&agrave; e la collaborazione tra le professioni.</p>
      <p>Negli anni Cinquanta e Sessanta, nacque l&rsquo;interesse scientifico per i fattori psichici e gli stili di vita che potevano incidere sulla salute fisica; la psicologia della salute nasce ufficialmente negli USA nel 1976 e da allora hanno visto la luce riviste, pubblicazioni, convegni, master e corsi universitari dedicati al tema. In Italia, la materia &egrave; trattata nel corso di laurea in psicologia dal 1996. La convinzione che la mente avesse un ruolo di primo piano era gi&agrave; sostenuta nel V secolo avanti Cristo da alcuni filosofi dell&rsquo;antica Grecia, convinti che fosse la mente a dirigere il corpo verso la salute o verso la malattia.&nbsp;</p>
      <p>E&rsquo; indubbio che questo interesse abbia trovato un terreno favorevole nella sempre minore incidenza delle malattie infettive in Occidente, contro uno sviluppo emergenziale delle malattie metaboliche, tumorali e cardiovascolari. L&rsquo;analisi dei fattori di rischio e le possibili strategie per il miglioramento degli stili di vita sono diventate fondamentali per ridurre il tasso di mortalit&agrave; e invalidit&agrave;.&nbsp;</p>
      <p>Vediamo ora in modo pi&ugrave; specifico quali contributi pu&ograve; offrire la psicologia della salute per promuovere e sostenere il benessere individuale, familiare e sociale, che come riconosciuto dall&rsquo;Organizzazione Mondiale della Sanit&agrave; ha modificato il concetto di salute come semplice assenza di malattie organiche.</p>
      <p>&nbsp;</p>
      <p><em>I comportamenti di salute (health behaviours)</em></p>
      <p>&nbsp;</p>
      <p>Si riferisce a tutti quei comportamenti, a quelle azioni, che sono messe in atto per conservare e/o migliorare il proprio stato di salute. Consistono nella capacit&agrave; di anticipare i problemi attraverso uno stile di vita sano o correggerli rinunciando ad abitudini notoriamente dannose (fumo, alcol, sedentariet&agrave; ecc.).</p>
      <p>La psicologia della salute si occupa del perch&eacute; i comportamenti corretti non sono messi in atto e soprattutto della domanda pi&ugrave; difficile: come mai le persone, anche quando sono bene informate e consapevoli, non abbandonano i loro comportamenti poco salutari?</p>
      <p>Per rispondere, dobbiamo riprendere il concetto di stile di vita, cio&egrave; di un insieme di abitudini consolidate nel tempo in modo automatico. Queste abitudini possono essere nate a seguito dell&rsquo;imitazione di modelli (es. un genitore fumatore) oppure da valutazioni puramente teoriche prive di un vero impatto emotivo (&ldquo;So che il fumo fa male&rdquo;), dalla convinzione di poter gestire pienamente il comportamento (&ldquo;Smetto quando voglio&rdquo;), dalla sottovalutazione della fatica necessaria per rinunciare per sempre a un dato comportamento, dalla poca stima di s&eacute; o da processi inconsci autopunitivi (presenti ad esempio in certi disturbi del comportamento alimentare).</p>
      <p>Modificare certe abitudini o introdurne di nuove (&ldquo;Dovrei prendere un po&rsquo; di tempo per me&rdquo;) &egrave; difficile. Il primo passo &egrave; riflettere sul perch&eacute; dovremmo cambiare le cose, su quali motivi abbiamo per farlo e su quanto questi motivi sono veramente importanti per noi; il secondo &egrave; sperimentare che cosa succede quando non mettiamo in atto il solito comportamento e &ldquo;guardare in faccia&rdquo; la difficolt&agrave;, il malessere, il fastidio che ne deriva, restando in loro compagnia fino a quando ne siamo in grado. Tutto questo pu&ograve; fornirci molte informazioni sfuggite alla nostra consapevolezza e aiutarci a prendere una decisione. Per le persone che praticano la meditazione e sono quindi abituate a prestare un&rsquo;attenzione consapevole al presente, pu&ograve; essere molto utile concentrarsi sull&rsquo;abitudine che desiderano perdere mentre la mettono in atto, quindi ad esempio, non lasciare vagare la mente mentre si fuma ma prestare coscientemente attenzione a ogni boccata, all&rsquo;espressione del volto, ai movimenti delle mani, al sapore del tabacco ecc. Anche questo modo di vivere l&rsquo;esperienza pu&ograve; fornire molte informazioni utili.</p>
      <p>&nbsp;</p>
      <p><em>La rappresentazione mentale della malattia</em></p>
      <p>&nbsp;</p>
      <p>Si riferisce al modo in cui la persona valuta il rischio di ammalarsi, la malattia stessa e le strategie messe in atto. Uno degli aspetti pi&ugrave; importanti &egrave; quello che la persona ritiene la causa della malattia, perch&eacute; questo pu&ograve; condizionare la scelta della cura o il rifiuto delle cure stesse. Anche l&rsquo;aspetto valoriale (rifiuto di amputazioni, di trasfusioni ecc.) ha un&rsquo;estrema importanza. I processi psichici e quelli somatici si intrecciano in modo profondo, per cui la sofferenza legata ad esempio alla depressione o alle crisi d&rsquo;ansia pu&ograve; avere ripercussioni importanti sulla salute in generale e su alcuni organi in particolare, ma anche un problema cardiovascolare, tumorale, neurodegenerativo o metabolico possono avere un&rsquo;influenza diretta sui processi psichici (pensiamo a forme di depressione o di ansia dovute a una condizione medica) o indiretta, come difficolt&agrave; a gestire le terapie o facilit&agrave; a trascurarsi e/o isolarsi socialmente. Un intervento psicologico pu&ograve; essere molto utile per aiutare la persona a scoprire in s&eacute; o a costruire le necessarie risorse per far fronte a un simile evento.</p>
      <p>La malattia organica, se non affrontata con i dovuti supporti, pu&ograve; incrementare la possibilit&agrave; di sviluppo di un disturbo psichico o comunque di scarsa compliance (conformit&agrave;) e aderenza al programma terapeutico. La conformit&agrave; si basa su un&rsquo;asimmetria: il paziente si adegua a quanto prescritto dal medico, dunque segue le sue indicazioni. E&rsquo; un termine che sottolinea la responsabilit&agrave; del paziente. L&rsquo;aderenza sottolinea la volont&agrave; di seguire le prescrizioni, il convincimento personale che le indicazioni del medico sono corrette. La scarsa aderenza alle prescrizioni dei medici pu&ograve; diventare un serio problema. L&rsquo;OMS, gi&agrave; nel 2003, ha indicato nell&rsquo;aderenza alla terapia un fattore fondamentale per ridurre la probabilit&agrave; di ospedalizzazione, di aggravamento, e per ridurre di costi sanitari. L&rsquo;AIFA, l&rsquo;agenzia del farmaco, nell&rsquo;ultimo rapporto del 2014 ha evidenziato come il 45% dei pazienti ipertesi, il 62% dei pazienti depressi, il 38% dei pazienti diabetici, non segua regolarmente il trattamento prescritto. I medici trovano molto frustrante questa difficolt&agrave; a convincere i pazienti che seguire le terapie &egrave; nel loro stesso interesse. Alcuni ritengono che la comunicazione corretta e comprensibile tra medico e paziente possa risolvere il problema, tuttavia &egrave; vero che l&rsquo;aderenza non si ottiene solo perch&eacute; il paziente ha capito il vantaggio che pu&ograve; ricavare dall&rsquo;utilizzo del farmaco. La psicologia della salute ritiene che, data l&rsquo;indubbia importanza di una buona comunicazione, sia fondamentale capire come il paziente valuti il vantaggio che pu&ograve; procurargli la terapia, quale sia la sua idea di malattia e di guarigione, quanto si fidi del medico, di quanto supporto abbia bisogno.</p>
      <p>Pu&ograve; accadere anche il contrario, cio&egrave; che il paziente abusi di farmaci che si procura autonomamente e che non informi il proprio medico di queste scelte. A volte i pazienti si lamentano di non poter parlare francamente col proprio medico per timore di severe reprimende, o per vergogna, o perch&eacute; pensano che da quel momento il medico effettuer&agrave; un controllo pi&ugrave; stringente. I medici, dal canto loro, non sempre tengono nella dovuta considerazione il mondo interiore del paziente e di quanto sia importante quello che si agita dietro uno sguardo sfuggente o a un&rsquo;apparente accondiscendenza.</p>
    </>
  ],
  [
    "la-psicologia-della-salute-2",
    <>
      <h2>La Psicologia della Salute - parte II</h2>
      <p>&nbsp;</p>
      <p><em>La vulnerabilit&agrave; allo stress</em></p>
      <p>&nbsp;</p>
      <p>Sullo stress si &egrave; detto moltissimo e si conosce quel &egrave; il suo ruolo nell&rsquo;insorgenza di determinate patologie o quantomeno nella diminuzione della qualit&agrave; della vita.</p>
      <p>Il primo uso scientifico del termine si deve al fisiologo americano Walter Cannon, professore alla Harvard Medical School, che coni&ograve; il termine nella prima met&agrave; del Novecento nei suoi studi sull&rsquo;omeostasi e sulla risposta &ldquo;combattimento o fuga&rdquo; che l&rsquo;organismo mette in atto quando deve affrontare una minaccia reale o solo immaginata.</p>
      <p>Fu per&ograve; Hans Seyle, nel 1971, a definirlo come una risposta aspecifica dell&rsquo;organismo a qualunque richiesta di adattamento.</p>
      <p>Lo stress non &egrave; dunque una risposta abnorme e indesiderata per se stessa, perch&eacute; nessuno potrebbe vivere senza una ragionevole quantit&agrave; di stimoli e novit&agrave;, per&ograve; pu&ograve; diventarlo quando la persona sente che le richieste che le sono poste sono superiori alle sue capacit&agrave; di farvi fronte, o, in altre parole, che l&rsquo;evento stressante si perpetua nel tempo rendendo cronica quella che doveva essere solo una risposta adattiva a breve termine.</p>
      <p>E&rsquo; possibile che l&rsquo;evento stressante sia tale anche se oggettivamente piacevole e gratificante, come una promozione, perch&eacute; pu&ograve; rinfocolare vissuti d&rsquo;inadeguatezza e timore di non essere all&rsquo;altezza oppure perch&eacute; pu&ograve; promuovere, ad un livello pi&ugrave; profondo, vissuti d&rsquo;indegnit&agrave; collegati a vissuti depressivi.</p>
      <p>Lo stress comporta disturbi del sonno, difficolt&agrave; di concentrazione e di memoria, difficolt&agrave; nello svolgimento delle attivit&agrave; quotidiane, isolamento sociale, difficolt&agrave; a prendersi cura di s&eacute;, ansia, depressione.</p>
      <p>La psicologia della salute lavora sull&rsquo;insegnamento di strategie per riconoscere e gestire lo stress, attraverso un&rsquo;analisi della situazione presente e delle possibili soluzioni concrete, attraverso una valutazione dei convincimenti e dei valori delle persone interessate (che possono frapporsi inconsciamente come ostacoli alle soluzioni), attraverso l&rsquo;insegnamento di tecniche di rilassamento e di &ldquo;buone pratiche&rdquo; utili nelle pi&ugrave; varie circostanze.</p>
      <p>Per una buona gestione dello stress &egrave; importante acquisire una buona competenza emotiva, cognitiva e sociale.</p>
      <p>&nbsp;</p>
      <p><em>La competenza emotiva - </em>E&rsquo; definita come la capacit&agrave; di relazionarsi con l'ambiente in modo efficace e adeguato grazie all'abilit&agrave; di gestire le proprie e altrui emozioni nelle situazioni di vita quotidiana. Tuttavia vi &egrave; anche un aspetto soggettivo, legato alla capacit&agrave; di provare benessere e serenit&agrave;, di saper godere delle esperienze positive e di non lasciarsi distruggere da quelle dolorose.</p>
      <p>Pi&ugrave; che di autocontrollo, oggi si preferisce parlare di autoregolazione. Infatti, il termine &ldquo;controllo&rdquo; evoca facilmente altri termini quali repressione o padronanza, che sotto intendono la necessit&agrave; di utilizzare la forza per gestire la parte affettiva ed emotiva della nostra psiche. E&rsquo; indubbio che, in determinate circostanze, sapersi padroneggiare o controllare evita lo sfogo immediato e spesso controproducente del proprio stato d&rsquo;animo. Tuttavia, in un&rsquo;ottica evolutiva, di crescita personale, il termine autoregolazione appare pi&ugrave; calzante, perch&eacute; indica l&rsquo;apprendimento di competenze emotive ed affettive che formeranno il bagaglio comportamentale ma anche caratteriale, della persona.</p>
      <p>Questo tipo di competenza, basata sullo sviluppo delle aree prefrontali della corteccia cerebrale, richiede molto tempo per arrivare a maturazione, almeno fino all&rsquo;adolescenza. Gli eventi che accadono in questo periodo sono dunque molto importanti per ostacolare o favorire i processi biologici riguardanti questa parte &ndash; la pi&ugrave; evoluta - del nostro cervello. Continui cambiamenti delle figure di accudimento, abusi, trascuratezza, incoerenza educativa, disagio economico, sono fattori che influiscono negativamente sull&rsquo;instaurarsi di buone capacit&agrave; di autoregolazione.</p>
      <p>Al contrario, un&rsquo;atmosfera di fiducia e sicurezza fornita da figure familiari accudenti ed accoglienti permette al bambino di imparare, poco a poco, la comprensione e l&rsquo;espressione appropriata delle emozioni.</p>
      <p>La comprensione delle emozioni si basa anzitutto sul possesso di un vocabolario appropriato: le emozioni fondamentali sono poche, tuttavia basta prendere uno dei termini che le riguardano (gioia, rabbia, paura&hellip;) e procurarsi un dizionario dei sinonimi e dei contrari per stupirci della ricchezza di sfumature che caratterizza ciascuno di questi termini.</p>
      <p>Sia <em>irritazione</em> sia <em>collera </em>sono espressioni dell&rsquo;emozione fondamentale <em>rabbia</em>, tuttavia se sappiamo coglierne la differenza, riusciamo a distinguerle, elaborandole cognitivamente ed esprimendole a livello comportamentale in modo opportuno e non distruttivo. E&rsquo; quella che si chiama modulazione e che fa s&igrave; che le emozioni non ci travolgano bloccandoci o esprimendosi in modo esplosivo, obnubilandoci o creando problemi a livello relazionale e sociale.</p>
      <p>La competenza emotiva si acquisisce nel corso del tempo, grazie alle esperienze e ai modelli forniti dagli educatori e dal gruppo dei pari, e grazie all&rsquo;attitudine all&rsquo;introspezione, che ci permette di comprendere che cosa proviamo e come funzionano le emozioni (sia a livello fisico sia comportamentale, e non solo mentale), qual &egrave; la loro intensit&agrave;, a quali bisogni, attese, motivazioni, interessi sono legate, quali reazioni provocano negli altri, quali reazioni provocano in noi le emozioni altrui e cos&igrave; via.</p>
      <p>&nbsp;</p>
      <p><em>La competenza cognitiva - </em>Il termine deriva dal greco antico gnosco (io so). La competenza cognitiva si riferisce all&rsquo;atto del conoscere (conoscenza = gnosis), del sapere.</p>
      <p>Ha come basi i processi attentivi e mnemonici, la comprensione, il linguaggio, l&rsquo;intelligenza, la consapevolezza. E&rsquo; intimamente legata alla competenza emotiva: gli affetti possono favorire o ostacolare i processi intellettivi necessari all&rsquo;apprendimento, alla fissazione e alla rievocazione dei ricordi, al ragionamento logico e deduttivo, alla soluzione dei problemi attraverso decisioni e strategie adeguate, alla pianificazione delle decisioni, alla gestione dell&rsquo;incertezza, alla valutazione delle esperienze e dei fatti sociali, all&rsquo;orientamento spaziale e temporale, alla cognizione dei numeri e delle quantit&agrave;, alla comunicazione efficace.</p>
      <p>La competenza cognitiva riguarda anche la conoscenza dei processi mentali e di come i nostri pensieri influenzano le nostre emozioni, il nostro umore, i nostri comportamenti.&nbsp; In ognuno di noi agiscono dei set cognitivi, cio&egrave; dei pensieri organizzati sulla base di percezioni che possono essere abbastanza fedeli alla realt&agrave; oppure distorti. Se ad esempio siamo convinti che gli altri ce l&rsquo;hanno con noi, tenderemo a selezionare, tra tutte le nostre percezioni, quelle che confermano la nostra convinzione e perfino a distorcere le prove contrarie.</p>
      <p>&nbsp;</p>
      <p><em>La competenza sociale - </em>Per competenza sociale s&rsquo;intende un insieme di abilit&agrave;, in parte innate e in parte acquisite, che si consolidano nel tempo al punto da essere utilizzate in modo spontaneo e continuativo per gestire le interazioni con gli altri.</p>
      <p>Le competenze emotive e cognitive facilitano e sostengono la competenza sociale, e viceversa.</p>
      <p>Le persone socialmente competenti sanno adattare la comunicazione ai diversi ambienti in cui si trovano a operare, sanno che la comunicazione non verbale non &egrave; meno importante di quella verbale, cercano di capire il punto di vista dell&rsquo;altro, sanno prevedere le conseguenze del proprio comportamento, sono autonome ma hanno spirito cooperativo e sono capaci di ricevere aiuto, osservano le regole, evitano di colpevolizzare cercando piuttosto di trovare la soluzione ai problemi, sanno gestire i conflitti, cambiare i comportamenti inefficaci ed essere assertivi, mantengono le relazioni nel tempo e non credono che le relazioni siano pi&ugrave; intime di quanto siano davvero, sono affidabili, flessibili e comunicano in modo chiaro, e sono attente ai bisogni altrui.</p>
    </>
  ]
];
