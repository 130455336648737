import React from 'react';
import styled from 'styled-components';

export const Bigp = styled.p`
  width: 90%;
  padding: 5vh 0;
  text-align: center;
  font-size: calc(26px + 2vw);
  color: #00394d;
  &::first-letter {color: #0099cc;}
`;

export const SubHeadlineLineSubdued = ({ title = "Subtitle line", align = "center", color = "#0099cc" }) =>
  <SHLSBox align={align}><SHLSLine color={color} align={align} />
    <SHLSTitle color={color}>{title}</SHLSTitle>
  </SHLSBox>;

const SHLSBox = styled.div`
  position: relative;
  width: 96%;
  overflow: hidden;
  padding: 0 15%;
  display: grid;
  place-items: ${props => ((props.align === "left") ? "flex-start" : (
    (props.align === "right") ? "flex-end" : "center"
  ))};
  @media only screen and (max-width: 600px) {
    place-items: center;
  }
`;

const SHLSLine = styled.div`
  position: absolute;
  height: 5px;
  background-image: ${props => ((props.align === "left") ?
    "linear-gradient(to right, " + props.color + "00 0%, " + props.color + "a0 10%, " + props.color + "a0 50%, " + props.color + "00 100%)" : (
      (props.align === "right") ?
        "linear-gradient(to left, " + props.color + "00 0%, " + props.color + "a0 10%, " + props.color + "a0 50%, " + props.color + "00 100%)" :
        "linear-gradient(to right, " + props.color + "00, " + props.color + "a0 35%, " + props.color + "a0 65%, " + props.color + "00)"
    ))};

  left: 0;
  right: 0;
  top: calc(50% - 3px);
  opacity: 0.8;
  @media only screen and (max-width: 600px) {
    background-image: ${props => "linear-gradient(to right, " + props.color + "00, " + props.color + "a0 35%, " + props.color + "a0 65%, " + props.color + "00)"};
  }
`;

const SHLSTitle = styled.h3`
  position: relative;
  font-size: calc(16px + 1.2vw);
  line-height: calc(16px + 1.2vw);
  padding: calc(8px + 1.6vw);
  font-weight: 500;
  color: #b0b0b0;
  &::first-letter { color: ${props => props.color}; }
  background-color: white;
`;

export const SubHeadlineLine = ({ title = "Subtitle line", align = "center", color = "#0099cc", ...moreProps }) =>
  <SHLBox {...moreProps} align={align}><SHLLine color={color} align={align} />
    <SHLTitle color={color}>{title}</SHLTitle>
  </SHLBox>;

const SHLBox = styled.div`
  position: relative;
  width: 96%;
  overflow: hidden;
  padding: 0 15%;
  display: grid;
  place-items: ${props => ((props.align === "left") ? "flex-start" : (
    (props.align === "right") ? "flex-end" : "center"
  ))};
  @media only screen and (max-width: 600px) {
    place-items: center;
  }
`;

const SHLLine = styled.div`
  position: absolute;
  height: 5px;
  background-image: ${props => ((props.align === "left") ?
    "linear-gradient(to right, " + props.color + "00 0%, " + props.color + "a0 10%, " + props.color + "a0 50%, " + props.color + "00 100%)" : (
      (props.align === "right") ?
        "linear-gradient(to left, " + props.color + "00 0%, " + props.color + "a0 10%, " + props.color + "a0 50%, " + props.color + "00 100%)" :
        "linear-gradient(to right, " + props.color + "00, " + props.color + "a0 35%, " + props.color + "a0 65%, " + props.color + "00)"
    ))};

  left: 0;
  right: 0;
  top: calc(50% - 3px);
  opacity: 0.8;
  @media only screen and (max-width: 600px) {
    background-image: ${props => "linear-gradient(to right, " + props.color + "00, " + props.color + "a0 35%, " + props.color + "a0 65%, " + props.color + "00)"};
  }
`;

const SHLTitle = styled.h3`
  position: relative;
  font-size: calc(16px + 1.2vw);
  line-height: calc(16px + 1.2vw);
  padding: calc(8px + 1.6vw);
  font-weight: 500;
  color: #b0b0b0;
  &::first-letter { color: ${props => props.color}; }
  background-color: white;
`;

export const HeadlineLine = ({ title = "Title line", align = "center", color = "#0099cc" }) =>
  <HLBox align={align}>
    <HLTitle color={color}>{title}</HLTitle>
    <HLTop align={align} /><HLBottom align={align} />
  </HLBox>;

const HLBox = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background-image: ${props => ((props.align === "left") ? "linear-gradient(to right, #0099cc00 50%, #0099cc10)" : (
    (props.align === "right") ? "linear-gradient(to left, #0099cc00 50%, #0099cc10)" : "linear-gradient(to right, #0099cc10, #0099cc00, #0099cc00, #0099cc00, #0099cc10)"
  ))};
  padding: 0 11.5%;
  display: grid;
  place-items: ${props => ((props.align === "left") ? "flex-start" : (
    (props.align === "right") ? "flex-end" : "center"
  ))};
  @media only screen and (max-width: 768px) {
    place-items: center;
    padding: 0;
  }
`;

const HLBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: calc(4px + 0.3vw);
  background-image: ${props => ((props.align === "left") ? "linear-gradient(to right, #0099cc, #99E5FF, #004D66)" : "linear-gradient(to left, #0099cc, #99E5FF, #004D66)"
  )};
`;

const HLTop = styled(HLBottom)`
  bottom: unset;
  top: 0;
  height: calc(2px + 0.1vw);
  background-image: ${props => ((props.align === "left") ? "linear-gradient(to left, #0099cc, #99E5FF00)" : (
    (props.align === "right") ? "linear-gradient(to right, #0099cc, #99E5FF00)" : "linear-gradient(to right, #0099cc, #99E5FF, #004D66)"
  ))};
`;

const HLTitle = styled.h1`
  position: relative;
  font-size: calc(18px + 2.5vw);
  line-height: calc(16px + 2vw);
  padding: calc(16px + 2vw) 0;
  font-weight: 500;
  text-transform: uppercase;
  color: #989898;
  &::first-letter { color: ${props => props.color}; }
  @media only screen and (max-width: 350px) {
    font-size: 20px;
  }
`;
