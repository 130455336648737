export const menuItems00 = [
  { title: 'HOME', icon: "fas fa-home", parent: false, link: '/#panoramica' },
  {
    title: 'NADIA', icon: "fas fa-info-circle", parent: true, children: [
      { title: 'CHI SONO', parent: false, link: '/#nadia' },
      { title: 'PERCORSO', parent: false, link: '/nadia' },
      { title: 'CONTATTAMI', parent: false, link: '/#contatti' }]
  },
  {
    title: 'CONTENUTI', icon: "fas fa-book", parent: true, children: [
      { title: 'AREE DI INTERVENTO', parent: false, link: '/#psicoterapia-cognitiva' },
      { title: 'DOMANDE', parent: false, link: '/#approfondisci' },
      { title: 'ARTICOLI', parent: false, link: '/articoli' }/* ,
      { title: 'RISORSE', parent: false, link: '/risorse-utili' } */]
  },
  { title: 'CONTATTI', icon: "fas fa-phone", parent: false, link: '/#contatti' }
];

export const items00 = {
  title: 'CLINICA', parent: true, children: [
    { title: 'HOME', parent: false, link: '/' },
    { title: 'NADIA', parent: false, link: '/nadia' },
    { title: 'AREE', parent: false, link: '/aree-di-intervento' },
    { title: 'TERAPIE', parent: false, link: '/trattamenti-e-terapie' },
    { title: 'FAQ', parent: false, link: '/domande-frequenti' }]
};

/* const menuItems00_old = [
  { title: 'HOME', icon: "fas fa-home", parent: false, link: '/' },
  {
    title: 'STUDIO', icon: "fas fa-user-friends", parent: true, children: [
      { title: 'ACCADEMIA', parent: false, link: '/nadia' },
      { title: 'LIBRI', parent: false, link: '/nadia' }]
  },
  {
    title: 'SERVIZI', icon: "fas fa-tools", parent: true, children: [
      { title: 'PANORAMICA', parent: false, link: '/nadia' },
      { title: 'TRATTAMENTI', parent: false, link: '/nadia#first' },
      { title: 'TARIFFE', parent: false, link: '/nadia#second' },
      { title: 'FAQ', parent: false, link: '/nadia#third' }]
  },
  {
    title: 'NEWS', icon: "fas fa-newspaper", parent: true, children: [
      { title: 'PANORAMICA', parent: false, link: '/' },
      { title: 'INTERVENTI', parent: false, link: '/#nadia' },
      { title: 'FAQ', parent: false, link: '/#approfondisci' }]
  },
  { title: 'RISORSE', icon: "fas fa-question-circle", parent: false, link: '/nadia' },
  { title: 'CONTATTI', icon: "fas fa-paper-plane", parent: false, link: 'https://google.com' }
]; */
