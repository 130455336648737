import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, useLocation } from "react-router-dom";
import './index.css';
import App from './App';
/* import * as serviceWorker from './serviceWorker'; */
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

/* scrolls to top when changing route, except on hash change only. disabled as long as we have a single route */
function ScrollToTop() {
  const { hash, pathname } = useLocation();
  /* snaps to page top with no smooth scroll on route change with no hash */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { if (hash && hash !== "") { return; } window.scrollTo(0, 0); }, [pathname]);

  /* smooth scrolls to page top on route change even with no hash */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  /* useEffect(() => { if (hash && hash !== "") { return; } window.scrollTo({ top: 0, behavior: 'smooth' }); }, [pathname]); */
  return null;
}
ReactDOM.render(<BrowserRouter><ScrollToTop /><App /></BrowserRouter>, document.getElementById('app-root'));
/* ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('app-root')); */


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
/* serviceWorker.unregister(); */
