import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { HashLink } from "widgets/MyHashLink";
import { menuItems00, items00 } from 'MenuItems';

const SpreadMenu = ({ shrinked = false, menuItems = menuItems00 }) => {
  const [expandedItemIndex, setExpandedItemIndex] = useState(-1);
  const handleClick = (e) => { setExpandedItemIndex(-1); };
  useEffect(() => {
    /* useCapture true to allow items clickswitch */
    if (expandedItemIndex !== -1) { document.addEventListener('click', handleClick, true); }
    return () => { if (expandedItemIndex !== -1) { document.removeEventListener('click', handleClick, true); } };
  }, [expandedItemIndex]);
  return (
    <SMBox>
      {
        menuItems.map((item, index) =>
          <SMItem shrinked={shrinked} icon={item.icon} title={item.title} expandCallback={setExpandedItemIndex} key={"menuItem" + index} itemIndex={index} expandedItemIndex={expandedItemIndex} items0={item} />)
      }
    </SMBox>
  );
}

const SMBox = styled.div`
/* background-color: yellow; */
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  align-items: center;
  z-index: 1;
  &>div {
    padding: 0 calc(10px + 0.7vw);
  }
  &>div:first-child {
    padding: 0 calc(10px + 0.7vw) 0 0;
  }
  &>div:last-child {
    padding: 0 0 0 calc(10px + 0.7vw);
  }
`;

const SMItem = ({ shrinked, icon = "fas fa-clock", title = "ORARI", items0 = items00, itemIndex, expandCallback, expandedItemIndex }) => {
  const toggle = () => expandCallback(expandedItemIndex !== itemIndex ? itemIndex : -1);
  const expanded = (expandedItemIndex === itemIndex);

  return (
    items0.parent === true ?
      <SMIBox shrinked={shrinked} onClick={toggle} expanded={expanded}>
        <SMIIcon className={icon}></SMIIcon>
        <SMITitle>{title}</SMITitle>
        <SMIOverlay expanded={expanded}>
          {
            items0.children.map((item, index) =>
              <HashLink className="default-link" key={items0.title + "menuSubItem" + index} smooth to={item.link}>
                <SMISubtitle count={index} expanded={expanded}>{item.title}</SMISubtitle>
              </HashLink>)
          }
        </SMIOverlay>
      </SMIBox> :
      <SMIBoxDirectLink shrinked={shrinked} onClick={toggle} expanded={expanded}>
        <HashLink smooth to={items0.link}>
          <SMIIcon className={icon}></SMIIcon>
          <SMITitle>{title}</SMITitle>
        </HashLink>
      </SMIBoxDirectLink>
  );
}

const SMIBoxBase = styled.div`
/* background-color: red; */
  position: relative;
  min-width: calc(60px + 4.6vw);
  padding: 0 calc(10px + 0.7vw);
  height: 100%;
  display: flex;
  flex-flow: ${props => props.shrinked === true ? "row wrap" : "column nowrap"};
  justify-content: center;
  align-items: center;
  &>a {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: ${props => props.shrinked === true ? "row wrap" : "column nowrap"};
    justify-content: center;
    align-items: center;
  }
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  i {transition: all 0.2s; margin: 0 8px;}
  p {transition: all 0.2s;}
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.1);
      i {opacity: 1;}
      p {opacity: 1;}
    }
  }
`;

const SMIBox = styled(SMIBoxBase)`
  &>i {opacity: ${props => props.expanded === true ? "1.0" : "0.5"};}
  &>p {opacity: ${props => props.expanded === true ? "1.0" : "0.9"};}
  &>div {opacity: ${props => props.expanded === true ? "1.0" : "0.0"};
  visibility: ${props => props.expanded === true ? "visible" : "hidden"};}
`;

const SMIBoxDirectLink = styled(SMIBoxBase)`
  i {opacity: 0.5;}
  p {opacity: 0.9;}
`;

const SMIIcon = styled.i`
  /* width: auto; */
  color: #0099cc;
  /* opacity: 0.4; */
  font-size: calc(24px + 0.6vw);
  line-height: 1.4;
  text-align: center;
`;

const SMITitle = styled.p`
  /* width: auto; */
  /* font-size: calc(12px + 0.4vw); */
  font-size: calc(12px + 0.3vw);
  line-height: 1.4;
  font-weight: 500;
  color: #0099cc;
  /* opacity: 0.8; */
  text-transform: uppercase;
  text-align: center;
`;

const SMIOverlay = styled.div`
  position: absolute;
  width: calc(150px + 5vw);
  padding: 0.75vw 2vw;
  height: auto;
  background-color: #00171Fe0;
  border-radius: 25px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s;
`;

const SMISubtitle = styled.p`
  position: relative;
  /* width: auto; */
  font-size: calc(10px + 0.25vw);
  font-weight: 400;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  margin: 0.7vh 0;
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      color: #0099cc;
    }
  }
  transition: all ${props => ((props.count + 1) * 0.2)}s;
  opacity: ${props => props.expanded === true ? "1.0" : "0.0"};
  transform: ${props => props.expanded === true ? "translateY(0px)" : "translateY(100px)"};
`;

export default SpreadMenu;
