import React, { useState } from 'react';
/* import debounce from 'lodash.debounce'; */
import './CookieBanner.css';
import styled from 'styled-components';

const caniuseCookiesName = "accept-nadiasilistrini-cookies";
const caniuseCookiesValueOk = "nadiasilistrinicookiesok";
const caniuseCookiesValueNo = "nadiasilistrinicookiesno";

const CookieBanner = () => {
   // state: 0 cookies unset, 1 just set, animating and leading to: 2 cookies already set
   const [state, setState] = useState((getCaniuseCookies() === undefined) ? 0 : 2);
   if (state === 2) {
      return null;
   }
   const onAccept = /* debounce( */() => {
      setCaniuseCookies(true);
      setState(1);
      setTimeout(() => {
         setState(2);
      }, 300);
   }/* , 50) */;

   const stopPropagationCallback = (e) => e.stopPropagation();

   return (
      <CookieBox onClick={stopPropagationCallback} className={((state === 0) ? "cb-new" : "cb-die")}>
         <CookieText>Questo sito impiega dei <strong>cookie</strong> per migliorare la navigazione</CookieText>
         <CookieButton onClick={onAccept}>
            <p>Accetto</p>
         </CookieButton>
      </CookieBox>
   );
}

const CookieText = styled.p`
   font-size: 20px;
   max-width: 75%;
   text-align: center;
   @media only screen and (max-width: 768px) {
      font-size: 14px;
   }
`;

const CookieButton = styled.div`
   margin-left: 2vw;
   padding: 10px;
   display: grid;
   place-items: center;
   border: 2px solid white;
   border-radius: 20px;
   text-transform: uppercase;
   font-weight: 500;
   background: #00000055;
   color: white;
   cursor: pointer;
   transition: all 0.2s;
   @media(hover: hover) and (pointer: fine) {
      &:hover {
         background: #ffffffdd;
         color: black;
      }
   }
`;

const CookieBox = styled.div`
   padding: 0 8vw;
   font-size: 16px;
   width: 100%;
   height: 100px;
   position: fixed;
   left: 0;
   bottom: 0;
   background: #000000d0;
   transform: translate(0, 100%);
   z-index: 13;
   display: flex;
   flex-flow: row nowrap;
   justify-content: center;
   align-items: center;
   color: white;
   border-top: 4px solid #0099cc;
`;

export const getCaniuseCookies = () => {
   /* TODO: remove this return in production // return undefined; */
   const decDocCookies = decodeURIComponent(document.cookie);
   if ((decDocCookies.indexOf(caniuseCookiesName) > -1) && (decDocCookies.indexOf(caniuseCookiesValueOk) > -1)) { /* console.log("cookies ok"); */ return true; }
   else if (decDocCookies.indexOf(caniuseCookiesValueNo) > -1) { /* console.log("cookies not ok"); */ return false; }
   return undefined;
}

const setCaniuseCookies = (caniuseCookiesValue) => {
   const expiresDateString = (new Date(Date.now() + 864e9)).toUTCString();
   document.cookie = encodeURIComponent(caniuseCookiesName) + "=" + encodeURIComponent(caniuseCookiesValue ? caniuseCookiesValueOk : caniuseCookiesValueNo) + ";" + "expires=" + expiresDateString + ";path=/";
}

export default CookieBanner;
