import React from 'react';
import styled from 'styled-components';

export const MultiSnippet5 = ({ titles = ["LOL ECCE", "LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-clock", "fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], texts = ["sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada.", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."], color = "#0099cc" }) => {
  return (
    <MS5Box>
      <MS5Item><MS4Icon color={color} className={icons[0]} /><MS5Title color={color}>{titles[0]}</MS5Title><MS5Text>{texts[0]}</MS5Text></MS5Item>
      <MS5Item><MS4Icon color={color} className={icons[1]} /><MS5Title color={color}>{titles[1]}</MS5Title><MS5Text>{texts[1]}</MS5Text></MS5Item>
      <MS5Item><MS4Icon color={color} className={icons[2]} /><MS5Title color={color}>{titles[2]}</MS5Title><MS5Text>{texts[2]}</MS5Text></MS5Item>
      <MS5Item><MS4Icon color={color} className={icons[3]} /><MS5Title color={color}>{titles[3]}</MS5Title><MS5Text>{texts[3]}</MS5Text></MS5Item>
      <MS5Item><MS4Icon color={color} className={icons[4]} /><MS5Title color={color}>{titles[4]}</MS5Title><MS5Text>{texts[4]}</MS5Text></MS5Item>
    </MS5Box>
  );
}

const MS5Box = styled.div`
  position: relative;
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;
  margin: 2.5vmax 0;
`;

const MS5Item = styled.div`
  position: relative;
  height: auto;
  width: 19%;
  padding: 1.25%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  /* border-radius: 30px;
  box-shadow: 2px 2px 20px #40404020; */
  @media only screen and (max-width: 1300px) {
    width: 31%;
    max-width: 420px;
    margin-bottom: 2.5vmax;
  }
  @media only screen and (max-width: 1100px) {
    width: 45%;
    max-width: 420px;
    margin-bottom: 2.5vmax;
  }
  @media only screen and (max-width: 800px) {
    width: 80%;
    max-width: 500px;
    margin-bottom: 2.5vmax;
  }
`;

const MS5Icon = styled.i`
  width: 100%;
  font-size: calc(32px + 2vw);
  color: ${props => props.color + "a0"};
`;

const MS5Title = styled.h3`
  width: 100%;
  font-size: calc(16px + 0.25vw);
  font-weight: 600;
  color: #909090;
  &::first-letter { color: ${props => props.color}; }
  margin: 8px 0;
`;

const MS5Text = styled.p`
  width: 100%;
  font-size: calc(12px + 0.25vw);
  font-weight: 400;
  color: #000000b0;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  .more-link {
    position: relative;
    text-transform: uppercase;
    font-size: calc(11px + 0.1vw);
    font-weight: 600;
    align-self: flex-end;
    text-align: end;
    margin-right: calc(10px + 0.6vw);
    margin-top: 10px;
    color: ${props => (props.linkColor && props.accentColor !== "") ? props.accentColor : "#0099cc"};
    text-decoration: none;
    transition: color .25s ease-out;
    &::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      background-color: #0099cc;
      bottom: -2px;
      left: 50%;
      transition: all .25s ease-out;
    }
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        &::after {
          left: 0%;
          width: 100%;
        }
      }
    }
  }
`;

export const MultiSnippet4 = ({ five = false, titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], texts = ["sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."], color = "#0099cc" }) => {
  return (
    <MS4Box>
      <MS4Item>{/* <MS4BgIcon1 className={icons[0]} /> */}<MS4Icon color={color} className={icons[0]} /><MS4Title color={color}>{titles[0]}</MS4Title><MS4Text>{texts[0]}</MS4Text></MS4Item>
      <MS4Item>{/* <MS4BgIcon2 className={icons[1]} /> */}<MS4Icon color={color} className={icons[1]} /><MS4Title color={color}>{titles[1]}</MS4Title><MS4Text>{texts[1]}</MS4Text></MS4Item>
      <MS4Item>{/* <MS4BgIcon3 className={icons[2]} /> */}<MS4Icon color={color} className={icons[2]} /><MS4Title color={color}>{titles[2]}</MS4Title><MS4Text>{texts[2]}</MS4Text></MS4Item>
      <MS4Item>{/* <MS4BgIcon4 className={icons[3]} /> */}<MS4Icon color={color} className={icons[3]} /><MS4Title color={color}>{titles[3]}</MS4Title><MS4Text>{texts[3]}</MS4Text></MS4Item>
      {five && <MS4Item>{/* <MS4BgIcon4 className={icons[3]} /> */}<MS4Icon color={color} className={icons[3]} /><MS4Title color={color}>{titles[3]}</MS4Title><MS4Text>{texts[3]}</MS4Text></MS4Item>}
    </MS4Box>
  );
}

const MS4Box = styled.div`
  position: relative;
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;
  margin: 2.5vmax 0;
`;

const MS4Item = styled.div`
  position: relative;
  height: auto;
  width: 24%;
  padding: 1.25%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  /* border-radius: 30px;
  box-shadow: 2px 2px 20px #40404020; */
  @media only screen and (max-width: 1100px) {
    width: 45%;
    max-width: 420px;
    margin-bottom: 2.5vmax;
  }
  @media only screen and (max-width: 800px) {
    width: 80%;
    max-width: 500px;
    margin-bottom: 2.5vmax;
  }
`;

const MS4Icon = styled.i`
  width: 100%;
  font-size: calc(32px + 2vw);
  color: ${props => props.color + "a0"};
`;

const MS4Title = styled.h3`
  width: 100%;
  font-size: calc(16px + 0.25vw);
  font-weight: 600;
  color: #909090;
  &::first-letter { color: ${props => props.color}; }
  margin: 8px 0;
`;

/* const MS4Text = styled.p`
  width: 100%;
  font-size: calc(12px + 0.25vw);
  font-weight: 400;
  color: #000000a0;
`; */
const MS4Text = styled.p`
  width: 100%;
  font-size: calc(12px + 0.25vw);
  font-weight: 400;
  color: #000000b0;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  .more-link {
    position: relative;
    text-transform: uppercase;
    font-size: calc(11px + 0.1vw);
    font-weight: 600;
    align-self: flex-end;
    text-align: end;
    margin-right: calc(10px + 0.6vw);
    margin-top: 10px;
    color: ${props => (props.linkColor && props.accentColor !== "") ? props.accentColor : "#0099cc"};
    text-decoration: none;
    transition: color .25s ease-out;
    &::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      background-color: #0099cc;
      bottom: -2px;
      left: 50%;
      transition: all .25s ease-out;
    }
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        &::after {
          left: 0%;
          width: 100%;
        }
      }
    }
  }
`;

const MS4BgIconBase = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20%;
  font-size: calc(3 * (32px + 2vw));
  color: #3E56AA10;
  pointer-events: none; /* otherwise meddles with potentially overlapping clickable stuff */
`;

const MS4BgIcon1 = styled(MS4BgIconBase)``;
const MS4BgIcon2 = styled(MS4BgIconBase)``;
const MS4BgIcon3 = styled(MS4BgIconBase)``;
const MS4BgIcon4 = styled(MS4BgIconBase)``;

export const MultiSnippet3 = ({ bgicons = true, titles = ["LOL ECCE", "PORPOSYCHY", "ECCE HOMO"], icons = ["fas fa-clock", "fas fa-handshake", "fas fa-paper-plane"], texts = ["sit amet, consectetur adipiscing elit. Donec id finibus tellus, at finibus sem. Morbi eleifend malesuada nunc.", "consectetur mauris, vitae molestie tortor sollicitudin eu. Sed elementum arcu mollis lacus tincidunt", "eget diam in arcu ullamcorper molestie. Praesent rhoncus varius lacus, porta rutrum tellus finibus malesuada."] }) => {
  return (
    <MSBox>
      <MSItem>{bgicons && <MSBgIcon1 className={icons[0]} />}<MSIcon className={icons[0]} /><MSTitle>{titles[0]}</MSTitle><MSText>{texts[0]}</MSText></MSItem>
      <MSItem>{bgicons && <MSBgIcon2 className={icons[1]} />}<MSIcon className={icons[1]} /><MSTitle>{titles[1]}</MSTitle><MSText>{texts[1]}</MSText></MSItem>
      <MSItem>{bgicons && <MSBgIcon3 className={icons[2]} />}<MSIcon className={icons[2]} /><MSTitle>{titles[2]}</MSTitle><MSText>{texts[2]}</MSText></MSItem>
    </MSBox>
  );
}

const MSBox = styled.div`
  position: relative;
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: stretch;
  padding: calc(10px + 1vw) 0 calc(20px + 2vw) 0;
`;

const MSItem = styled.div`
  position: relative;
  height: auto;
  width: 30%;
  padding: 1.25%;
  @media only screen and (max-width: 1000px) {
    width: 80%;
    max-width: 600px;
    margin-bottom: 2.5vmax;
  }
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  /* border-radius: 30px;
  box-shadow: 2px 2px 20px #40404020; */
`;

const MSIcon = styled.i`
  width: 100%;
  font-size: calc(32px + 2vw);
  color: #3E56AAd0;
`;

const MSTitle = styled.h3`
  width: 100%;
  font-size: calc(16px + 0.25vw);
  font-weight: 500;
  color: #3E56AAa0;
  margin: 8px 0;
`;

const MSText = styled.div`
  width: 100%;
  font-size: calc(12px + 0.25vw);
  font-weight: 400;
  color: #000000b0;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  .more-link {
    position: relative;
    text-transform: uppercase;
    font-size: calc(11px + 0.1vw);
    font-weight: 600;
    align-self: flex-end;
    text-align: end;
    margin-right: calc(10px + 0.6vw);
    margin-top: 10px;
    color: ${props => (props.linkColor && props.accentColor !== "") ? props.accentColor : "#0099cc"};
    text-decoration: none;
    transition: color .25s ease-out;
    &::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      background-color: #0099cc;
      bottom: -2px;
      left: 50%;
      transition: all .25s ease-out;
    }
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        &::after {
          left: 0%;
          width: 100%;
        }
      }
    }
  }
`;

const MSBgIconBase = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: -32%;
  font-size: calc(4 * (32px + 2vw));
  color: #3E56AA10;
  pointer-events: none; /* otherwise meddles with potentially overlapping clickable stuff */
`;

const MSBgIcon1 = styled(MSBgIconBase)`right: unset;`;
const MSBgIcon2 = styled(MSBgIconBase)``;
const MSBgIcon3 = styled(MSBgIconBase)`left: unset;`;
