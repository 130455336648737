import React, { useState } from 'react';
import styled from 'styled-components';
/* import Modal from 'widgets/Modal'; */
/* import ContactsForm from 'widgets/ContactsForm'; */
/* import ContactsForm from 'widgets/ContactsForm'; */
/* import { BlueInvertRippleButton } from 'widgets/RippleContainer';
import { ContactsFormNoBorder } from "widgets/Forms"; */
import { TextBlock1 } from "widgets/Typography";
/* import { HashLink } from "widgets/MyHashLink"; */

const Contattami = () => {
   const [modalOpen, setModalOpen] = useState(false);
   const modalShow = () => setModalOpen(true);
   const modalDismissed = () => setModalOpen(false);

   return (
      <>
         {/* <TextBlock1>
            <p>Se volete contattarmi, lasciate il vostro <strong>nome</strong> e <strong>cognome</strong>, <strong>telefono</strong>, <strong>e-mail</strong>, e un breve messaggio in cui, se lo desiderate, potete esporre la vostra richiesta, e soprattutto dirmi a che ora è possibile per me ricontattarvi.</p>
         </TextBlock1> */}
         <TextBlock1 verticalSpacing>
            <p>Per informazioni e richieste, scrivetemi a <a href="mailto:nadiasilistrini@gmail.com">nadia.silistrini@gmail.com</a> specificando nome e cognome. Se volete essere ricontattati per telefono, lasciate il vostro numero insieme a una fascia oraria di disponibilità.<div className="jump" /></p>
         </TextBlock1>
         {/* <ContactsContainer> */}
         {/* <ContactsBox>
               <ContactsInfo />
            </ContactsBox> */}
         {/* <ContactsButton>
               <BlueInvertRippleButton click={modalShow} text={"Contattami"} icon={"fas fa-paper-plane"} />
            </ContactsButton>
         </ContactsContainer> */}
         {/*
            modalOpen &&
            <Modal onDismiss={modalDismissed}>
               <ContactsFormNoBorder />
            </Modal>*/
         }
      </>
   );
}

const ContactsInfo = (props) => {
   return (
      <ContactsIcons onClick={props.click}>
         <ContactsIconBox>
            <ContactsIcon className="fas fa-clock" />
            <p>Dal Lunedì al Sabato</p>
            <p>10.00 - 19.30</p>
         </ContactsIconBox>
         <ContactsIconBox><ContactsIconLink href={"tel:+390222475932"}>
            <ContactsIcon className="fas fa-phone" />
            <p>+39 02 2247 5932</p>
            <p> </p>
         </ContactsIconLink></ContactsIconBox>
         <ContactsIconBox><ContactsIconLink href={"mailto:silistrini@tiscali.it"}>
            <ContactsIcon className="fas fa-envelope" />
            <p>silistrini@tiscali.it</p>
            <p> </p>
         </ContactsIconLink></ContactsIconBox >
         <ContactsIconBox><ContactsIconLink target="_blank" rel="noopener noreferrer" href="https://goo.gl/maps/EY3L4HKcg9nPTH8HA">
            <ContactsIcon className="fas fa-map-marker-alt" />
            <p>Via Fratelli Picardi, 67</p>
            <p>20099 Sesto San Giovanni MI</p>
         </ContactsIconLink></ContactsIconBox>
      </ContactsIcons >
   );
}

const ContactsContainer = styled.div`
   width: 100%;
   /* background-color: #F3F6FE; */
   padding: calc(10px + 1vw) 0 0 0;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-evenly;
   align-items: center;
   font-size: 1.5rem;
   @media only screen and (min-width: 1441px) {
      padding-top: 10px;
   }
`;

const ContactsBox = styled.div`
   width: 100%;
   position: relative;
   display: flex;
   flex-flow: row wrap;
   align-items: center;
   order: 0;
   @media only screen and (max-width: 1024px) {
      flex-direction: column;
      order: 0;
   }
`;

const ContactsIcons = styled.div`
   width: 100%;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-evenly;
   align-items: center;
   font-size: calc(12px + 0.3vw);
`;

const ContactsIconBox = styled.div`
   width: 20%;
   text-align: center;
   display: flex;
   flex-flow: column nowrap;
   justify-content: center;
   align-items: center;
   position: relative;
   color: #30a2ff90;
   font-weight: 600;
   margin: 20px 0;
   @media only screen and (max-width: 1024px) {width: 40%;}
   @media only screen and (max-width: 330px) {
      width: 80%;
      margin: 10px 0;
   }
`;

const ContactsIconLink = styled.a`
   transition: all 0.3s;
   text-decoration: none;
   cursor: pointer;
   color: #30a2ff90;
   padding: 0 1vw 1vw 1vw;
   border-bottom: 5px solid transparent;
   border-radius: 10%;
   @media(hover: hover) and (pointer: fine) {&:hover {color: #30a2ff;border-bottom: 5px solid #30a2ff;}}
`;

const ContactsIcon = styled.i`
   height: 8.6vh;
   font-size: 8.6vh;
   line-height: 8.6vh;
   margin-bottom: 0.5vmax;
   @media only screen and (min-height: 900px) {
      font-size: 80px;
   }
`;

const ContactsButton = styled.div`
   width: 100%;
   display: flex;
   flex-flow: row nowrap;
   justify-content: center;
   align-items: center;
   padding: calc(20px + 1vw) 0 calc(30px + 2vw) 0;
   order: 3;
   /* margin: 10px 0; */
   @media only screen and (max-width: 1024px) {order: 1;margin-top: 0px;}
   @media only screen and (min-width: 1441px) {padding: 30px 0 60px 0;}
`;

export default Contattami;
