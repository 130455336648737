import React, { useState } from 'react';
import styled from 'styled-components';
import { BlueInvertRippleButton } from "widgets/RippleContainer";
import headerItem0 from 'img/bg/header5.jpg';
import headerItem0b from 'img/bg/header6.jpg';
import headerItem1 from 'img/invecchiamento-attivo/laboratori-memoria.jpg';
import headerItem2 from 'img/bg/header3.jpg';
import headerItem3 from 'img/bg/header4.jpg';
import { useKey } from "react-use";
import debounce from "lodash.debounce";
import DotsNav from "widgets/DotsNav";
import { navBarFatHeight } from 'Config.js';
import { HashLink } from "widgets/MyHashLink";
/* import { Link } from "react-router-dom"; */
/* import headerItem1 from 'img/bg/header2.jpg'; */
/* import { theme0 } from "data/Config"; */
/* import headerItem0 from "img/illu0-850-700.png";
import headerItem1 from "img/illu1-850-700.png";
import headerItem2 from "img/illu2-850-700.png";
import headerItem3 from "img/illu3-850-700.png"; */

/* const headerItems = [
  { id: 0, img: bg1, title: "Psicoterapia cognitiva", subtitle: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur aliquet mollis velit, non rutrum dolor tempor vitae. Vivamus eu est interdum.", text: "", cta: "Scopri di più" },
  { id: 1, img: bg2, title: "Psicologia della salute", subtitle: "Integer semper turpis vel neque blandit, at laoreet mauris ultricies. Suspendisse convallis imperdiet accumsan.", text: "", cta: "Scopri di più" },
  { id: 2, img: bg3, title: "Invecchiamento attivo", subtitle: "Donec iaculis ligula quis nulla pellentesque, posuere tempor turpis laoreet. Proin turpis ipsum, molestie id tortor ac, placerat ultrices tortor.", text: "", cta: "Scopri di più" },
  { id: 3, img: bg4, title: "Cooperazione e volontariato", subtitle: "Integer ut mauris risus. Proin vestibulum tortor volutpat, congue nunc pulvinar, varius leo.", text: "", cta: "Scopri di più" }
]; */

const header2Items = [
  { title: 'Il più grande esploratore su questa terra non fa viaggi più lunghi di chi scende in fondo al proprio cuore', img: headerItem0, text: "J. Green", ctaTitle: "SCOPRI", ctaIcon: "fas fa-share", ctaLink: "#psicoterapia-cognitiva" },
  { title: 'Psicoterapia cognitivo-comportamentale', img: headerItem0b, text: "Scopri di più sul modello di psicoterapia a cui faccio riferimento.", ctaTitle: "SCOPRI", ctaIcon: "fas fa-share", ctaLink: "#psicoterapia-cognitiva" },
  { title: 'Anziani attivi', img: headerItem1, text: <p>{"È vero che \“quando il cervello invecchia, la mente migliora\”? (Gene Cohen)"}<br />{"Scopriamolo insieme"}</p>/* Approfondisci il termine invecchiamento attivo, l'approccio fondamentale di gran parte della mia pratica." */, ctaTitle: "LEGGI", ctaIcon: "fas fa-share", ctaLink: "#invecchiamento-attivo" },
  { title: 'Aiutare chi aiuta', img: headerItem2, text: "Esplora i servizi che offro per la pianificazione e la supervisione di progetti in ambito socio-sanitario, oltre che per l'ambito del sostegno alle famiglie.", ctaTitle: "ESPLORA", ctaIcon: "fas fa-share", ctaLink: "#assistenza" },
  { title: 'Ben-Essere', img: headerItem3, text: "Scopri le pratiche di sviluppo personale e benessere, dalla psicologia positiva, alla Mindfulness, allo yoga, alle tecniche di rilassamento, e il legame profondo che le unisce"/* "Scopri le pratiche di sviluppo personale e benessere nelle quali sono specializzata, dallo Yoga alla Priscologia Positiva." */, ctaTitle: "SCOPRI", ctaIcon: "fas fa-share", ctaLink: "#orizzonti" }
];
const Header2 = () => {
  const [item, setItem] = useState(0);
  const itemCount = header2Items.length;
  const swipeOn = debounce(() => { setItem(i => (i < (itemCount - 1)) ? (i + 1) : 0); }, 10);
  const swipeBack = debounce(() => { setItem(i => (i > 0) ? (i - 1) : (itemCount - 1)); }, 10);
  useKey("ArrowLeft", swipeBack);
  useKey("ArrowRight", swipeOn);
  const [boxedText, setBoxedText] = useState(false);
  /* useKey("ArrowRight", () => setBoxedText(state => !state)); */
  return (
    <Header2Box onClick={swipeOn}>
      {
        header2Items.map((cItem, cIndex) =>
          <Header2SubBox show={item === cIndex} imgFirst={cIndex % 2 !== 0}>
            <Header2TxtBoxt className="txtbox2" boxedText={boxedText} align={(cIndex % 2 !== 0) === true ? "left" : "right"}>
              {(cIndex === 0) ? <h4 onClick={e => e.stopPropagation()}>{cItem.title}</h4> : <h3 onClick={e => e.stopPropagation()}>{cItem.title}</h3>}
              <p onClick={e => e.stopPropagation()}>{cItem.text}</p>
              {(cIndex === 0) ?
                <Header3Dots onClick={(e) => e.stopPropagation()}>
                  <DotsNav marginPx={4} borderPx={0} dotsNumber={header2Items.length} sizePx={23} activeDot={((item > -1) && (item < header2Items.length)) ? item : 0} dotsCallback={(ind) => { setItem(ind); }} color="#0099cc60" colorActive="#0099cc" />
                </Header3Dots> :
                <HashLink smooth to={cItem.ctaLink}>
                  <BlueInvertRippleButton onMyClick={() => { }} text={cItem.ctaTitle} icon={cItem.ctaIcon} />
                </HashLink>
              }
            </Header2TxtBoxt>
            <Header2ImgBox src={cItem.img} className="imgbox2" imgFirst={cIndex % 2 !== 0}>
              <img src={cItem.img} alt={"BITSCUITS " + cItem.title} />
            </Header2ImgBox>
          </Header2SubBox>
        )
      }
      {(item !== 0) &&
        <Header2Dots onClick={(e) => e.stopPropagation()}>
          <DotsNav marginPx={4} borderPx={0} dotsNumber={header2Items.length} sizePx={23} activeDot={((item > -1) && (item < header2Items.length)) ? item : 0} dotsCallback={(ind) => { setItem(ind); }} color="#0099cc60" colorActive="#0099cc" />
        </Header2Dots>
      }
    </Header2Box>
  );
}

const Header2Dots = styled.div`
  position: absolute;
  left: 50%;
  bottom: 25px;
  transform: translate(-50%, 0%);
  padding: 5px 10px;
  z-index: 2;
  opacity: 1.0;
  transition: all 0.3s;
  /* background-color: #ffffffc0;
  border-radius: 22px;
  box-shadow: 4px 4px 4px #00000030; */
  @media only screen and (max-width: 800px) {
    /* bottom: unset;
    box-shadow: unset;
    background-color: unset;
    top: ${"calc(" + navBarFatHeight + " + 10px)"}; */
    background-color: #ffffffc0;
    border-radius: 22px;
    box-shadow: 4px 4px 4px #00000030;
  }
  @media only screen and (max-width: 330px) {
    bottom: 15px;
  }
`;

const Header3Dots = styled.div`
  position: relative;
  /* transform: translate(-50%, 0%); */
  /* margin: 20px 0; */
  padding: 5px 10px;
  z-index: 2;
  opacity: 1.0;
  transition: all 0.3s;
  /* background-color: #ffffffc0;
  border-radius: 22px;
  box-shadow: 4px 4px 4px #00000030; */
  @media only screen and (max-width: 800px) {
    /* bottom: unset;
    box-shadow: unset;
    background-color: unset;
    top: ${"calc(" + navBarFatHeight + " + 10px)"}; */
    background-color: #ffffffc0;
    border-radius: 22px;
    box-shadow: 4px 4px 4px #00000030;
  }
`;

const Header2Box = styled.div`
  user-select: none;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #ffffff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

const Header2SubBox = styled.div`
  position: absolute;
  top: ${navBarFatHeight};
  left: 5%;
  width: 90%;
  @media only screen and (max-width: 900px) {
    width: 100%;
    left: 0%;
  }
  transition: opacity 0.3s, display 1s;
  display: ${props => props.show === true ? "flex" : "none"};
  opacity: ${props => props.show === true ? "1.0" : "0.0"};
  .imgbox2 {
    transition: all 0.5s;
    transform: ${props => props.show === true ? "translate(0vw, 0)" : (props.imgFirst === false ? "translate(-100vw, 0)" : "translate(100vw, 0)")};
  }
  .txtbox2 {
    transition: all 0.5s;
    transform: ${props => props.show === true ? "translate(0, 0vh)" : (props.imgFirst === false ? "translate(0, 100vh)" : "translate(0, -100vh)")};
  }
  height: ${"calc(100% - " + navBarFatHeight + ")"};
  background-color: #ffffff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const Header2TxtBoxt = styled.div`
  position: relative;
  order: 1;
  width: calc(100% - (250px + 24vw));
  max-width: 700px;
  @media only screen and (min-width: 1441px) {
    width: calc(100% - 586px);
    max-width: 700px;
  }
  padding: calc(20px + 2vw);
  z-index: 1;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: ${props => props.align === "left" ? "flex-start" : (props.align === "right" ? "flex-end" : "center")};
  text-align: ${props => props.align === "left" ? "start" : (props.align === "right" ? "end" : "center")};
  cursor: initial;
  &>h3 {
    font-size: calc(20px + 1vw);
    font-weight: 500;
    line-height: 1;
    color: #0099cc;
    margin-bottom: calc(15px + 1.5vw);
  }
  &>h4 {
    font-size: calc(16px + 0.6vw);
    font-weight: 500;
    line-height: 1.4;
    text-align: justify;
    color: #909090;
    letter-spacing: 1px;
    margin-bottom: calc(10px + 0.4vw);
  }
  &>p {
    font-size: calc(13px + 0.4vw);
    font-weight: 400;
    line-height: 1.6;
    color: #505050;
    margin-bottom: calc(15px + 1.5vw);
  }
  @media only screen and (max-width: 800px) {
    width: ${props => props.boxedText === true ? "calc(400px + 20vw)" : "100%"};
    max-width: unset;
    margin: 0;
    border: none;
    align-items: center;
    text-align: center;
    /* background-color: #ffffffe8; */
    background-color: #ffffffc8;
    box-shadow: 0px 3px 6px #000000b0;
    padding: ${props => props.boxedText === true ? "calc(20px + 2vw)" : "calc(40px + 2vw) calc((100% - 500px ) / 2.0)"};
    border-radius: ${props => props.boxedText === true ? "50px" : "0"};
  }
  @media only screen and (max-width: 560px) {
    width: ${props => props.boxedText === true ? "90%" : "100%"};
    padding: calc(40px + 2vw) calc(20px + 2vw);
    &>h3 {font-size: 25px;}
    &>p {font-size: 14px;}
  }
`;

const Header2ImgBox = styled.div`
  position: relative;
  order: ${props => props.imgFirst === true ? "0" : "2"};
  width: calc(250px + 24vw);
  @media only screen and (min-width: 1441px) {
    width: 586px;
  }
  height: 100%;
  z-index: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  img {
    width: 90%;
    height: auto;
    border-radius: 50px;
  }
  @media only screen and (max-width: 800px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url(${props => props.src});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    /* opacity: 0.5; */
    img {
      display: none;
      /* position: absolute;
      height: 94%;
      width: auto;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.8; */
    }
  }
  /* @media only screen and (max-width: 560px) {} */
`;

export default Header2;
