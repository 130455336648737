import React from 'react';
import styled from 'styled-components';
import { HashLink } from "widgets/MyHashLink";

export const TextBlock1 = ({ children, verticalSpacing = false, outTitle, inTitle, paramWidth, maxWidth, textAlign, accentColor, linkHoverColor, ...moreProps }) => {
  return (
    <TextBlock1Box computedMargin={verticalSpacing ? (verticalSpacing === true ? "calc(30px + 2vw) 0" : ("" + verticalSpacing + " 0")) : "0"} paramWidth={paramWidth} maxWidth={maxWidth} textAlign={textAlign} inTitle={inTitle}
      accentColor={accentColor} linkHoverColor={linkHoverColor}
      {...moreProps}>
      {(outTitle && outTitle !== "") && <h3>{outTitle}</h3>}
      {(inTitle && inTitle !== "") && <h4>{inTitle}</h4>}
      {children}
    </TextBlock1Box>
  );
}

const TextBlock1Box = styled.div`
  position: relative;
  width: ${props => (props.paramWidth && props.paramWidth !== "") ? props.paramWidth : "90%"};
  max-width: ${props => (props.maxWidth && props.maxWidth !== "") ? props.maxWidth : "1100px"};
  text-align: ${props => (props.textAlign === "left" || props.textAlign === "start") ? "start" : ((props.textAlign === "right" || props.textAlign === "end") ? "end" : "center")};
  font-size: calc(13px + 0.6vw);
  line-height: 1.65;
  font-weight: 400;
  color: #404040;
  margin: ${props => props.computedMargin};
  /* display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center; */
  &>* {position: relative;}
  h3 {
    font-size: calc(20px + 1.0vw);
    line-height: 1.4;
    margin-bottom: 10px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #202020;
    &::first-letter {
      color: ${props => (props.linkColor && props.accentColor !== "") ? props.accentColor : "#0099cc"};
    }
  }
  h4 {
    display: inline-block;
    font-size: calc(18px + 0.8vw);
    color: #aaaaaa;
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 4px;
    &::first-letter {
      color: ${props => (props.linkColor && props.accentColor !== "") ? props.accentColor : "#0099cc"};
    }
  }
  p {display: inline;}
  strong {
    color: #202020;
    letter-spacing: 1px;
    font-weight: 600;
  }
  em {
    letter-spacing: 1px;
    color: #101010;
    font-weight: 400;
  }
  a {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      background-color: #0099cc;
      bottom: -2px;
      left: 50%;
      transition: all .25s ease-out;
    }
    color: ${props => (props.linkColor && props.accentColor !== "") ? props.accentColor : "#0099cc"};
    text-decoration: none;
    font-weight: 500;
    transition: color .25s ease-out;
    @media(hover: hover) and (pointer: fine) {
      &:hover {
        &::after {
          left: 0%;
          width: 100%;
        }
      }
    }
  }
  .muted {
    color: #aaaaaa;
    font-size: calc(12px + 0.5vw);
  }
  .jump {height: calc(1.65 * (13px + 0.4vw));}
  .jjump {height: calc(3 * (13px + 0.4vw));}
    @media only screen and (min-width: 1441px) {
      font-size: 24px;
      h3 {font-size: 34px;}
      h4 {font-size: 31px;}
      .muted { font-size: 22px; }
      .jump {height: calc(1.65 * (19px));}
      .jjump {height: calc(3 * (19px));}
    }
`;

export const MyReadMoreLink = ({ to = "/", text = "Scopri di più", color = "#0099cc", hoverColor = "#25D266" }) => {
  return (
    <MRMLink color={color} hoverColor={hoverColor} smooth to={to}>{text}</MRMLink>
  );
}

const MRMLink = styled(HashLink)`
  color: ${props => props.color};
  text-decoration: none;
  transition: color 0.4s;
  font-size: calc(16px + 0.5vw);
  line-height: calc(16px + 0.5vw);
  @media(hover: hover) and (pointer: fine) { &:hover { color: ${props => props.hoverColor}; } }
`;


export const TxtBlockInlineTitle = ({ color = "#0099cc", title = "Title #1", text = "Some content that precedes some more content and is subsequently followed by more content, eventually we have a last slab of content. Just kidding, here's some more content of some kind and some more content of a different kind. Content is what makes up this piece of content, along with the title of this content block and the text body it comprises." }) => {
  return (
    <TBITBox color={color}>
      <h3>{title}</h3>
      <p>{text}</p>
    </TBITBox>
  );
}

const TBITBox = styled.div`
  position: relative;
  width: 90%;
  max-width: 900px;
  line-height: calc(1.7 * (15px + 0.2vw));
  &::first-letter {color: ${props => props.color};}
  text-align: start;
  h3 {
    display: inline;
    font-size: calc(24px + 0.5vw);
    color: #a0a0a0;
    letter-spacing: 2px;
    font-weight: 600;
    margin-right: 10px;
  }
  p {
    display: inline;
    font-size: calc(13px + 0.3vw);
    color: #404040;
    font-weight: 400;
  }
`;
