import React, { useState } from 'react';
import styled from 'styled-components';
import ScrollContainer from 'react-indiana-drag-scroll';
import { SubHeadlineLine, SubHeadlineLineSubdued } from "widgets/Headlines";

export const CardScroller0 = ({ items, itemClick = (index) => console.log("clicked item number: ", index) }) => {
  return (
    <>{/* <SubHeadlineLine align="left" title="Pubblicazioni" /> */}
      <SubHeadlineLineSubdued align="left" title="Pubblicazioni" />
      <HGBox><HGBoxStart /><HGBoxEnd />
        <ScrollContainer vertical={false} className="scroll-container">
          <HGSubBox>
            {
              items.map((item, index) =>
                <HGItem key={"cscroll-" + item.title + index} onClick={() => { }/* itemClick(index) */}>
                  <div><p className="pubbl-title"><span>{item.title[0]}</span>{(item.title).substr(1)}</p>
                    <p className="pubbl-author">{item.authors}{(item.more && item.more !== "") && <span className="pubbl-more">{item.more}</span>}</p></div>
                  {/* <p className="pubbl-year">{item.editor + " - " + item.year}</p> */}
                  <div><p className="pubbl-year">{item.year}</p>
                    <p className="pubbl-editor">{item.editor}</p></div>
                </HGItem>
              )
            }
          </HGSubBox>
        </ScrollContainer>
      </HGBox></>
  );
}

const HGBox = styled.div`
/* background-color: red; */
/* border-top: 1px solid #999;
border-bottom: 1px solid #999; */
  position: relative;
  width: 100%;
  padding: calc(10px + 0.4vw) 0;
  display: grid;
  place-items: center;
  overflow: hidden;
  margin-bottom: 1vw;
  .scroll-container {
    position: relative;
    width: 100%;
    /* height: calc(118px + 6.4vw); */
    height: calc(300px + 0vw);
  }
`;

const HGItem = styled.div`
  /* background-image: linear-gradient(to bottom,
    #0099ccc0 0%, #0099ccc0 5%,
    #0099cc80 5%, #0099cc80 10%,
    #0099cc40 10%, #0099cc40 15%,
    #0099cc00 15%, #0099cc00 100%
    ); */
  /* &::first-letter {color: #0099cc;} */
  position: relative;
  width: calc(256px + 4vw);
  height: calc(226px + 0vw);
  margin: calc(8px + 0.4vw);
  padding: /* calc(30px + 0.4vw) calc(10px + 0.4vw) calc(10px + 0.4vw) */ calc(20px + 0.6vw);
  border-radius: 40px;
  box-shadow: 3px 3px 6px #404040;
  overflow: hidden;

  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;

  font-size: calc(13px + 0.3vw);
  font-weight: 400;
  color: #444444;
  text-align: center;
  &>div {
    /* max-height: 70%; */
    width: 94%;
    overflow-y: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    span {color: #0099cc;}
  }

  .pubbl-more {
    font-size: 13px;
    color: #888;
    font-weight: 400;
  }

  .pubbl-title {
    align-self: flex-start;
    text-align: start;
    /* justify-self: flex-start; */
    font-size: calc(13px + 0.1vw);
    color: #444;
    text-transform: uppercase;
    font-weight: 600;
    /* max-height: 30%; */
    max-width: 100%;
    overflow: hidden;
    /* white-space: wrap; */
    /* max-lines: 2; */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    &::first-letter {color: #0099cc;}
  }
  .pubbl-author {
    align-self: flex-start;
  text-align: start;
    /* color: #888; */
    color: #0099ccc0;
    font-weight: 500;
    font-size: calc(12px + 0.2vw);
  }
  .pubbl-year {
    font-size: calc(14px);
    color: #444;
    font-weight: 600;
    align-self: flex-end;
    text-align: end;
  }
  .pubbl-editor {
    align-self: flex-end;
    text-align: end;
    font-size: calc(14px);
    color: #666;
  }
  transform: scale(1.00);
  opacity: 0.8;
  transition: all 0.25s;

  /* cursor: pointer; */
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      transform: scale(1.1);
      opacity: 1.0;
    }
  }
`;

const HGSubBox = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  /* height: 100%; */
  padding: 0 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: stretch;
`;

const HGBoxStart = styled.div`
  pointer-events: none;
  width: calc(28px + 4vw);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: linear-gradient(to right, #ffffffff, #ffffff00);
  z-index: 1;
`;

const HGBoxEnd = styled(HGBoxStart)`
  left: unset;
  right: 0;
  background-image: linear-gradient(to left, #ffffffff, #ffffff00);
`;
