import React from 'react';
import styled from 'styled-components';
import Expandablist from "widgets/Expandablist";
import { /* MyReadMoreLink, */ TextBlock1 } from 'widgets/Typography';
import { HashLink } from "widgets/MyHashLink";
/* import Pubblicazioni from "sections/Pubblicazioni"; */
import { shortlistFAQ } from 'data/FAQs';

const SectFAQ = () => {
  return (
    <FAQBox>
      <TextBlock1>
        <p>Riporto di seguito alcune domande che mi vengono poste più di frequente. <HashLink to="/domande-frequenti">Qui</HashLink> trovate la lista completa, mentre per leggere articoli divulgativi ed esplorare argomenti di carattere più scientifico, c’è la pagina degli <HashLink to="/articoli">approfondimenti</HashLink>.</p><div className="jump" />
      </TextBlock1>
      <Expandablist itemList={shortlistFAQ} /><p>...</p>
      <TextBlock1>
        <HashLink to="/domande-frequenti">Leggi tutte le domande frequenti</HashLink>
      </TextBlock1>
      {/* <MyReadMoreLink text="Leggi tutte le domande frequenti" to="/domande-frequenti" /> */}
    </FAQBox>
  );
}

const FAQBox = styled.div`
  position: relative;
  width: auto;
  padding: 0 0 calc(20px + 2vw) 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  &>p {
    position: relative;
    width: 100%;
    text-align: end;
    padding: 0 17px;
    font-size: calc(24px + 1.4vw);
    line-height: calc(24px + 1.4vw);
    letter-spacing: 6px;
    font-weight: 600;
    color: #505050;
    margin-bottom: calc(14px + 1vw);
  }
`;

export default SectFAQ;
