import React from 'react';
import styled from 'styled-components';
import { PageHelmetTags } from 'utils/AppHelmetTags';
import { navBarFatHeight, footerHeight } from 'Config';
import { HeadlineLine } from "widgets/Headlines";

const Detail0 = ({ appMainRef, title, children }) => {
  return (
    <Detail0B0x ref={appMainRef}>
      <PageHelmetTags />
      {(title && title !== "") &&
        <HeadlineLine align="center" title={title} />
      }
      {children}
    </Detail0B0x>
  );
}

const Detail0B0x = styled.main`
  position: relative;
  padding-top: ${navBarFatHeight};
  padding-bottom: calc(40px + 2vw);
  width: 100%;
  min-height: ${"calc(100vh - 160px)"};
  @media only screen and (max-width: 1000px) { min-height: ${"calc(100vh - 300px)"}; }
  opacity: 0.0;
  animation: detail-page-reveal 1.0s ease-in 0.0s 1 forwards;
  @keyframes detail-page-reveal {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
  }
`;

export default Detail0;
