import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import { useKey } from 'react-use';

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000050;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  z-index: 999;
  animation: ${props => (props.dismissing === false) ? "modal-appear 0.3s ease-out 0.0s 1 forwards" :
    "modal-vanish 0.2s ease-out 0.0s 1 forwards"};
  @keyframes modal-appear { 0% { opacity: 0.0; } 100% { opacity: 1.0; } }
  @keyframes modal-vanish { 0% { opacity: 1.0; } 100% { opacity: 0.0; } }
`;

const ModalDialog = styled.div`
  position: relative;
  min-width: 300px;
  min-height: 300px;
  max-width: 90%;
  max-height: 90vh;
  padding: 4vw;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 3px 3px 6px #404040;
  display: grid;
  place-items: center;
  border-radius: 25px;
  animation: ${props => (props.dismissing === false) ? "modal-come 0.3s ease-out 0.0s 1 forwards" :
    "modal-go 0.2s ease-out 0.0s 1 forwards"};
  @keyframes modal-come {
    0% { transform: translateX(calc(-50vw - 50%)); opacity: 0.0; }
    100% { transform: translateX(0%); opacity: 1.0; }
  }
  @keyframes modal-go {
    0% { transform: translateX(0%); opacity: 1.0; }
    100% { transform: translateX(calc(50vw + 50%)); opacity: 0.0; }
  }
`;

const ModalClose = styled.i`
  position: absolute;
  right: calc(10px + 1vw);
  top: calc(10px + 1vw);
  cursor: pointer;
`;

const Modal = ({ children, onDismiss }) => {
  const [dismissing, setDismissing] = useState(false);
  const dismiss = () => setDismissing(true);
  const animEnd = (e) => { if (e.animationName === "modal-vanish") { onDismiss(); } }
  const clickOut = (e) => { e.stopPropagation(); dismiss(); }
  const clickIn = (e) => { e.stopPropagation(); }
  useKey("Escape", dismiss);

  return (
    createPortal(
      <ModalOverlay onAnimationEnd={animEnd} onMouseDown={clickOut} dismissing={dismissing}>
        <ModalDialog onMouseDown={clickIn} dismissing={dismissing}>
          <ModalClose onClick={dismiss} className="fas fa-minus" />
          {children}
        </ModalDialog>
      </ModalOverlay>
      , document.getElementById("modal-root")
    )
  );
}

export default Modal;
