import React from 'react';
import { Helmet } from 'react-helmet';

const metaDescription = "Nadia Silistrini - Psicologa psicoterapeuta - Sesto San Giovanni - Milano";
const metaKeywords = "nadia,silistrini,psicologa,psicologo,psicoterapeuta,sesto,san,giovanni,milano";

export const PageHelmetTags = ({ title = "Nadia Silistrini - Psicologa psicoterapeuta - Sesto San Giovanni - Milano" }) =>
  <Helmet>
    <title>{title}</title>
    <meta name="description" content={metaDescription} />
    <meta name="keywords" content={metaKeywords} />
  </Helmet>;
