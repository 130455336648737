import React, { useState } from 'react';
import styled from 'styled-components';
import { HashLink } from "widgets/MyHashLink";

// TODO: fix the double column stuff

const listItems0 = [
  {
    title: "Anziani fragili, familiari, assistenti",
    numberOfArticles: 6,
    content:
      <div>
        <HashLink to="/articoli/disturbi-cognitivi-e-riabilitazione">Disturbi cognitivi e riabilitazione</HashLink>
        <HashLink to="/articoli/l-assistenza-al-malato-di-alzheimer">L'assistenza al malato di Alzheimer</HashLink>
        <HashLink to="/articoli/il-contesto-relazionale">Il contesto relazionale</HashLink>
        <HashLink to="/articoli/l-auto-mutuo-aiuto">L'auto mutuo aiuto</HashLink>
        <HashLink to="/articoli/la-comunicazione-con-l-anziano-non-autosufficiente">La comunicazione tra anziano e operatori domiciliari</HashLink>
        <HashLink to="/articoli/la-relazione-di-aiuto">La relazione di aiuto</HashLink>
      </div>
  },
  {
    title: "Invecchiamento attivo",
    numberOfArticles: 4,
    content:
      <div>
        <HashLink to="/articoli/l-arte-di-invecchiare">L'arte di invecchiare</HashLink>
        <HashLink to="/articoli/l-autostima">L’autostima nella Terza Età</HashLink>
        <HashLink to="/articoli/la-gerascofobia">La gerascofobia</HashLink>
        <HashLink to="/articoli/la-solitudine">La solitudine</HashLink>
      </div>
  },
  {
    title: "La vita emotivo-affettiva",
    numberOfArticles: 2,
    content:
      <div>
        <HashLink to="/articoli/la-motivazione">La motivazione</HashLink>
        <HashLink to="/articoli/emozioni-e-motivazioni">Emozioni e motivazioni</HashLink>
      </div>
  },
  {
    title: "Psicologi, psicologia, psicoterapia",
    numberOfArticles: 11,
    content:
      <div>
        <HashLink to="/articoli/disturbi-d-ansia">I disturbi d'ansia</HashLink>
        <HashLink to="/articoli/disturbi-dell-umore">I disturbi dell'umore</HashLink>
        <HashLink to="/articoli/disturbi-di-personalita">I disturbi di personalità</HashLink>
        <HashLink to="/articoli/la-personalita">La personalità</HashLink>
        <HashLink to="/articoli/lo-psicologo-questo-sconosciuto">Lo psicologo, questo sconosciuto</HashLink>
        <HashLink to="/articoli/il-significato-psicologico-della-fiaba">Il significato psicologico della fiaba</HashLink>
        <HashLink to="/articoli/il-disturbo-da-accumulo">Il disturbo da accumulo</HashLink>
        <HashLink to="/articoli/la-resilienza">La resilienza</HashLink>
        <HashLink to="/articoli/il-modello-transteorico-di-cambiamento">Il Modello Transteorico di Cambiamento</HashLink>
        <HashLink to="/articoli/la-psicologia-della-salute-1">La Psicologia della Salute - parte I</HashLink>
        <HashLink to="/articoli/la-psicologia-della-salute-2">La Psicologia della Salute - parte II</HashLink>
      </div>
  }, {
    title: "Psicologia positiva e Ben-Essere",
    numberOfArticles: 4,
    content:
      <div>
        <HashLink to="/articoli/le-peak-experiences">Le Peak Experiences</HashLink>
        <HashLink to="/articoli/psicologia-e-yoga">Psicologia e Yoga</HashLink>
        <HashLink to="/articoli/psicologia-e-spiriitualita">Psicologia e spiritualità</HashLink>
        <HashLink to="/articoli/la-gratitudine">La gratitudine</HashLink>
      </div>
  }
];

export const Expandablist2 = ({ itemList = listItems0, title = "Domande frequenti" }) => {
  return (
    <>
      <ELBox>
        {itemList.map((item, index) => <ELItem2 key={"elitem" + index} maxCount={itemList.length} item={item} index={index} />)}
      </ELBox>
    </>
  );
}

const ELItem2 = ({ item, index, maxCount }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <ELItemWrap maxCount={maxCount} count={index}>
      <ELItemTitle expanded={expanded} onClick={() => setExpanded(!expanded)} count={index}>
        {item.title}
        <ELItemIcon count={index} className={expanded === true ? "fas fa-minus" : "fas fa-plus"} />
      </ELItemTitle>
      <ELItemContent numberOfArticles={item.numberOfArticles} count={index} expanded={expanded}>
        <div className="detail-faq-content-div">
          {item.content}
        </div>
      </ELItemContent>
    </ELItemWrap>
  );
}

const ELItemContent = styled.div`
  width: 100%;
  padding: 0 calc(12px + 3vw);
  color: #00000090;
  text-align: center;
  transition: max-height 0.4s;
  max-height: ${props => (props.expanded === true) ? ("calc(" + props.numberOfArticles + " * (2.5 * (12px + 0.4vw)))") : "0px"};
  overflow: hidden;
  font-size: calc(12px + 0.4vw);
  line-height: calc(2 * (12px + 0.4vw));
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  .detail-faq-content-div {
    width: 100%;
    transition: opacity 0.6s 0.1s;
    opacity: ${props => (props.expanded === true) ? "1.0" : "0.0"};
    padding-bottom: 20px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    a {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        background-color: #0099cc;
        bottom: -2px;
        left: 50%;
        transition: all .25s ease-out;
      }
      color: ${props => (props.linkColor && props.accentColor !== "") ? props.accentColor : "#0099cc"};
      text-decoration: none;
      font-weight: 500;
      transition: color .25s ease-out;
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          &::after {
            left: 0%;
            width: 100%;
          }
        }
      }
    }
    &>div {
      width: 100%;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }
`;

const Expandablist = ({ itemList = list, title = "Domande frequenti" }) => {
  return (
    <>
      {/* <ELTitle>{title}</ELTitle> */}
      <ELBox>
        {itemList.map((item, index) => <ELItem key={"elitem" + index} maxCount={itemList.length} item={item} index={index} />)}
      </ELBox>
    </>
  );
}

const ELItem = ({ item, index, maxCount }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <ELItemWrap maxCount={maxCount} count={index}>
      <ELItemTitle expanded={expanded} onClick={() => setExpanded(!expanded)} count={index}>
        {item.title}
        <ELItemIcon count={index} className={expanded === true ? "fas fa-minus" : "fas fa-plus"} />
      </ELItemTitle>
      <ELItemText count={index} expanded={expanded}>
        <div className="detail-faq-text-div"><p>{item.text}</p>
          {
            (item.image && item.image !== "") &&
            <><br /> <img alt={item.title} src={item.image} /></>
          }
        </div>
      </ELItemText>
    </ELItemWrap>
  );
}

const ELTitle = styled.h2`
  font-size: calc(20px + 1vw);
  font-weight: 500;
  color: #222;
`;

const ELBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  @media only screen and (min-width: 1000px) {max-width: 900px;}
  border-top: 1px solid #ddd;
  margin-top: 16px;
  &>div:nth-child(2n) {
    background-image: linear-gradient(to right, #0099cc00, #0099cc10, #0099cc00, #0099cc00, #0099cc00);
  }
  &>div:nth-child(2n+1) {
    background-image: linear-gradient(to right, #0099cc00, #0099cc00, #0099cc00, #0099cc10, #0099cc00);
  }
`;

const ELItemWrap = styled.div`
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid #ddd;
`;
const ELItemWrap2 = styled.div`
  overflow: hidden;
  border-bottom: 1px solid #ddd;
`;

const ELItemIcon = styled.i`
  position: absolute;
  top: 50%;
  left: 15px;
  color: #3E56AA;
  font-size: calc(14px + 0.3vw);
  transform: translateY(-50%);
`;

const ELItemTitle = styled.h2`
  position: relative;
  width: 100%;
  cursor: pointer;
  overflow: hidden;
  line-height: 1.5;
  font-size: calc(12px + 0.3vw);
  font-weight: 500;
  user-select: none;
  padding: calc(20px + 1vw) calc(40px + 1.2vw)/*  20px calc(32px + 1.2vw) */;
  color: ${props => (props.expanded === true) ? "#3E56AA" : "#666"};
  text-align: start;
  text-transform: uppercase;
`;

const ELItemText = styled.div`
a {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: 0%;
    height: 2px;
    background-color: #0099cc;
    bottom: -2px;
    left: 50%;
    transition: all .25s ease-out;
  }
  color: ${props => (props.linkColor && props.accentColor !== "") ? props.accentColor : "#0099cc"};
  text-decoration: none;
  font-weight: 500;
  transition: color .25s ease-out;
  @media(hover: hover) and (pointer: fine) {
    &:hover {
      &::after {
        left: 0%;
        width: 100%;
      }
    }
  }
}
  width: 100%;
  padding: 0 calc(12px + 3vw);
  color: #00000090;
  text-align: center;
  transition: max-height 0.4s;
  max-height: ${props => (props.expanded === true) ? "550px" : "0px"};
  overflow: hidden;
  font-size: calc(12px + 0.4vw);
  line-height: 1.5;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  .detail-faq-text-div {
    transition: opacity 0.6s 0.1s;
    opacity: ${props => (props.expanded === true) ? "1.0" : "0.0"};
    padding-bottom: 20px;
  }
  img {
    max-width: 300px;
    max-height: 300px;
    border-radius: 15px;
    margin: auto;
  }
  @media only screen and (max-width: 500px) {
    img {
      max-width: 75%;
      max-height: 300px;
    }
  }
`;

const list = [
  {
    title: "sample stuff 1",
    text: "ero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis",
    image: ""
  },
  {
    title: "sample stuff 2",
    text: "vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venearius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis"
  },
  {
    title: "sample stuff 3",
    text: "n ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermenus libero dui, enatis erat. Nunc quis efficitur ligula. Sed at lectus nec libero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit le",
    image: ""
  }
];

const list2 = [
  {
    title: "sample stuff 1",
    text: "ero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis",
    image: ""
  },
  {
    title: "sample stuff 2",
    text: "vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venearius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis"
  },
  {
    title: "sample stuff 3",
    text: "n ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermenus libero dui, enatis erat. Nunc quis efficitur ligula. Sed at lectus nec libero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit le",
    image: ""
  },
  {
    title: "sample stuff 4",
    text: "ero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis",
    image: ""
  },
  {
    title: "sample stuff 5",
    text: "vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venearius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis"
  },
  {
    title: "sample stuff 6",
    text: "n ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermenus libero dui, enatis erat. Nunc quis efficitur ligula. Sed at lectus nec libero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit le",
    image: ""
  },
  {
    title: "sample stuff 7",
    text: "ero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis",
    image: ""
  },
  {
    title: "sample stuff 8",
    text: "vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venearius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis"
  },
  {
    title: "sample stuff 9",
    text: "n ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermenus libero dui, enatis erat. Nunc quis efficitur ligula. Sed at lectus nec libero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit le",
    image: ""
  }
];

export const DoublExpandabList = ({ itemList = list2, title = "Domande frequenti" }) => {
  return (
    <DELBox>
      {itemList.map((item, index) => <DELItem key={"elitem" + index} maxCount={itemList.length} item={item} index={index} />)}
    </DELBox>
  );
}

const DELItem = ({ item, index, maxCount }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <DELItemWrap maxCount={maxCount} count={index}>
      <ELItemTitle expanded={expanded} onClick={() => setExpanded(!expanded)} count={index}>
        {item.title}
        <ELItemIcon count={index} className={expanded === true ? "fas fa-minus" : "fas fa-plus"} />
      </ELItemTitle>
      <ELItemText count={index} expanded={expanded}>
        <div className="detail-faq-text-div"><p>{item.text}</p>
          {
            (item.image && item.image !== "") &&
            <><br /> <img alt={item.title} src={item.image} /></>
          }
        </div>
      </ELItemText>
    </DELItemWrap>
  );
}

const DELBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: center;
  /* @media only screen and (min-width: 900px) {max-width: 800px;} */
  border-top: 1px solid #ddd;
  margin-top: 16px;
`;

const DELItemWrap = styled.div`
  overflow: hidden;
  /* border-top: 1px solid #ddd; */
  border-bottom: 1px solid #ddd;
  max-width: 500px;
`;

export default Expandablist;
