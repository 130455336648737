import React from 'react';
import styled from 'styled-components';
import { navBarHeight, navBarFatHeight, AppMaxWidthPx } from 'Config.js';
import logo512 from 'img/logo/logo512.png';
import SpreadMenu from "widgets/SpreadMenu";
import { HashLink } from "widgets/MyHashLink";

const Nav = ({ shrinked = false, menuToggleCallback, menuOpen }) => {
  return (
    <NavBox shrinked={shrinked}>
      <NavLogo shrinked={shrinked} smooth to="/#panoramica"><img src={logo512} alt="Nadia Silistrini" /></NavLogo>
      <NavTitle shrinked={shrinked}>
        <h1>NADIA SILISTRINI</h1>
        <h2>PSICOLOGA PSICOTERAPEUTA</h2>
      </NavTitle>
      <NavMenu shrinked={shrinked} menuOpen={menuOpen}>
        <SpreadMenu shrinked={shrinked} />
        <i onClick={(e) => { e.stopPropagation(); menuToggleCallback(); }} className="fas fa-bars" />
      </NavMenu>
    </NavBox>
  );
}

const NavBox = styled.div`
  height: ${props => (props.shrinked === true) ? navBarHeight : navBarFatHeight};
  width: 100%;
  padding: calc(6px + 0.2vw) calc(12px + 0.8vw);
  transition: 0.4s;

  box-shadow: 0px 3px 6px #000000b0;
  background-image: ${props => (props.shrinked === true) ?
    "linear-gradient(to right, #ffffffd8, #ffffffd8)" :
    "linear-gradient(to right, #ffffff90, #ffffffd8 25%, #ffffffd8 75%, #ffffff90)"};
  color: #202020;
  pointer-events: all;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
`;

const NavLogo = styled(HashLink)`
  position: relative;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  transition: 0.4s;
  img {
    transition: all 0.2s;
    position: relative;
    height: 70%;
    width: auto;
    @media only screen and (max-width: 600px) {
      height: 65%;
    }
  }
  @media only screen and (max-width: 500px) {
    position: absolute;
    top: 0;
    left: ${props => (props.shrinked === true) ? "50%" : "5%"};
    /* width: 100%; */
    width: auto;
    height: 100%;
    /* background-color: #ff000090; */
    opacity: ${props => (props.shrinked === true) ? "0.2" : "0.1"};
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    transform: ${props => (props.shrinked === true) ? "translateX(-50%)" : "translateX(5%)"};
    img {
      position: relative;
      /* top: ${props => (props.shrinked === true) ? "8px" : "8px"}; */
      /* left: 0; */
      height: ${props => (props.shrinked === true) ? "calc(100% - 16px)" : "calc(100% - 16px)"};
      width: auto;
      /* transform: ${props => (props.shrinked === true) ? "translate(-50%, -50%)" : "translateX(-50%)"}; */
    }
    /* position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${props => (props.shrinked === true) ? "0.3" : "0.2"};
    img {
      position: absolute;
      top: ${props => (props.shrinked === true) ? "50%" : "8px"};
      left: 25%;
      height: ${props => (props.shrinked === true) ? "80%" : "calc(100% - 16px)"};
      width: auto;
      transform: ${props => (props.shrinked === true) ? "translate(-50%, -50%)" : "translateX(-50%)"};
    } */
  }
`;

const NavTitle = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  width: calc(11 * (19px + 1.8vw));
  h1 {
    padding-top: ${props => (props.shrinked === true) ? "8px" : "0"};
    position: relative;
    font-size: calc(20px + 2vw);
    line-height: 0.85;
    font-weight: 500;
    color: #222222;
  }

  h2{
    position: relative;
    display: ${props => (props.shrinked === true) ? "none" : "initial"};
    font-size: calc(11px + 1.125vw);
    line-height: 0.85;
    font-weight: 500;
    color: #0099cca0;
  }
  @media only screen and (max-width: 500px) {
    /* background-color: #ff000090; */
    width: auto;
    margin-right: calc(8px + 0.4vw);
  }
`;

const NavMenu = styled.div`
  position: relative;
    padding-top: ${props => (props.shrinked === true) ? "8px" : "0"};
    &>i {
      padding-top: ${props => (props.shrinked === true) ? "7px" : "0"};
      color: #0099cc;
      cursor: pointer;
      font-size: calc(20px + 1vw);
      transition: 0.25s;
      transform: ${props => (props.menuOpen === true) ? "rotateZ(90deg)" : "rotateZ(0deg)"};
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          transform: scale(1.2);
        }
      }
    }
  @media only screen and (min-width: 1000px) {
    &>i {
      font-size: calc(20px + 1vw);
      display: none;
    }
  }
  @media only screen and (min-width: ${AppMaxWidthPx + 1}px) {
    &>i {
      font-size: calc(20px + 1vw);
      display: block;
    }
    &>div {
      display: none;
    }
  }
  @media only screen and (max-width: 999px) {
    &>div {
      display: none;
    }
  }
`;

export default Nav;
