import React, { useState } from 'react';
import styled from 'styled-components';
import { BlueInvertRippleButton, GreenInvertRippleButton } from "widgets/RippleContainer";
import { Headline00, SubHeadlineLineSubdued } from "widgets/Headlines";

const useSignUpForm = callback => {
  const [inputs, setInputs] = useState({});
  const handleSubmit = event => { if (event) { console.log("form submitted"); event.preventDefault(); } callback(); };
  const handleInputChange = event => {
    event.persist();
    setInputs(inputs => ({ ...inputs, [event.target.name]: event.target.value }));
  };
  return { handleSubmit, handleInputChange, inputs };
};

export const ContactsFormNoBorder = () => {
  const onSignUp = () => { console.log(`User Created! Name: ${inputs.firstName} ${inputs.lastName} Email: ${inputs.email}`); };
  const { inputs, handleInputChange, handleSubmit } = useSignUpForm(onSignUp);
  return (
    <FormBoxNoBorder onSubmit={handleSubmit}>
      {/* <Headline00>{getString("contactsform-title")}</Headline00> */}
      {/* <SubHeadlineLineSubdued color={"#128C7E"} align="center" title={getString("wa-modal-title")} /> */}
      <FInputFieldBox>
        <FInputLabel htmlFor="cform-name-id">Nome</FInputLabel>
        <FInputField type="text" id="cform-name-id" name="cformname" /* placeholder={"nome..."} */
          onChange={handleInputChange} value={inputs.cformname || ""} required />
      </FInputFieldBox>
      <FInputFieldBox>
        <FInputLabel htmlFor="cform-email-id">E-mail</FInputLabel>
        <FInputField type="email" id="cform-email-id" name="cformemail" /* placeholder={"e-mail..."} */
          onChange={handleInputChange} value={inputs.cformemail || ""} required />
      </FInputFieldBox>
      <FInputFieldBox>
        <FInputLabel htmlFor="cform-msg-id">Testo</FInputLabel>
        <FTextarea id="cform-msg-id" name="cformmsg" /* placeholder={"e-mail..."} */
          onChange={handleInputChange} value={inputs.cformmsg || ""} required />
      </FInputFieldBox>
      <FInputFieldBox2>
        <BlueInvertRippleButton style={{ margin: "0" }} text={"Invia"} icon="fas fa-paper-plane" click={(e) => { document.querySelector("#form-contacts-sumbit-id").click(); }} />
      </FInputFieldBox2>
      <button id="form-contacts-sumbit-id" type="submit">SUBMIT</button>
    </FormBoxNoBorder>
  );
}

export const WhatsappFormNoBorder = () => {
  const onSignUp = () => { console.log(`User Created! Name: ${inputs.firstName} ${inputs.lastName} Email: ${inputs.email}`); };
  const { inputs, handleInputChange, handleSubmit } = useSignUpForm(onSignUp);
  return (
    <FormBoxNoBorder onSubmit={handleSubmit}>
      <SubHeadlineLineSubdued color={"#128C7E"} align="left" title={"WhatsApp"} />
      {/* <Headline00>{getString("contactsform-title")}</Headline00> */}
      <FInputFieldBox>
        <FInputLabel2 htmlFor="cform-msg-id">Testo</FInputLabel2>
        <FTextarea2 id="cform-msg-id" name="cformmsg" /* placeholder={"e-mail..."} */
          onChange={handleInputChange} value={inputs.cformmsg || ""} required />
      </FInputFieldBox>
      <FInputFieldBox2>
        <GreenInvertRippleButton style={{ marginBottom: "calc(10px + 0.2vw)" }} text={"Invia"} icon="fas fa-paper-plane" click={(e) => { document.querySelector("#form-contacts-sumbit-id").click(); }} />
      </FInputFieldBox2>
      <button id="form-contacts-sumbit-id" type="submit">SUBMIT</button>
    </FormBoxNoBorder>
  );
}

const FormBoxNoBorder = styled.form`
  position: relative;
  width: 80vw;
  max-width: 900px;
  padding: /* calc(20px + 1vw) */0 calc(40px + 2vw) /* calc(20px + 1vw) */0 calc(30px + 2vw);
  background-color: #ffffffc0;
  /* background-color: red; */
  @media only screen and (max-width: 600px) {
    padding: /* calc(20px + 1vw) */0;
  }
  /* @media only screen and (max-width: 800px) {
    width: 90%;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    border-radius: 0;
  } */
  button {
    padding: 10px 20px;
    border-radius: 25px;
    background-color: #0099cc40;
    border: 3px solid #0099cc;
    box-shadow: 3px 3px 6px #404040;
    font-size: calc(14px + 0.4vw);
    cursor: pointer;
    transition: transform 0.3s;
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
    visibility: hidden;
    opacity: 0.0;
  }
`;

const FormBox = styled.form`
  position: relative;
  /* width: 80%; */
  width: 90%;
  max-width: 800px;
  padding: /* calc(20px + 1vw) */0 calc(30px + 2vw) /* calc(20px + 1vw) */0 calc(30px + 2vw);
  background-color: #ffffffc0;
  border-radius: 25px;
  box-shadow: 2px 2px 8px #000000a0;
  /* @media only screen and (max-width: 800px) {
    width: 90%;
  } */
  @media only screen and (max-width: 600px) {
    padding: 0;
    width: 100%;
    border-radius: 0;
    box-shadow: 2px 2px 6px #000000a0;
  }
  button {
    padding: 10px 20px;
    border-radius: 25px;
    background-color: #0099cc40;
    border: 3px solid #0099cc;
    box-shadow: 3px 3px 6px #404040;
    font-size: calc(14px + 0.4vw);
    cursor: pointer;
    transition: transform 0.3s;
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
    visibility: hidden;
    opacity: 0.0;
  }
`;

const FInputFieldBox = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  margin: calc(20px + 1vw) 0;
  @media only screen and (max-width: 600px) {
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    /* &>div {
      margin-right: 0 !important;
    } */
  }
`;

const FInputFieldBox2 = styled(FInputFieldBox)`
  margin: 0;
`;

const FInputLabel = styled.label`
  /* width: calc(12 * (14px + 0.4vw)); */
  /* color: #a0a0a0; */
  color: #3E56AA;
  font-size: calc(12px + 0.4vw);
  text-transform: uppercase;
  font-weight: 600;
  text-align: end;
  margin-right: calc(20px + 0.6vw);
  /* padding: 0.4vw 2vw 0 2vw; */
  flex-grow: 1;
  @media only screen and (max-width: 600px) {
    margin-right: 0;
    text-align: center;
    flex-grow: 0;
  }
`;

const FInputLabel2 = styled(FInputLabel)`
  color: #128C7E;
`;

const FTextarea = styled.textarea`
  position: relative;
  width: calc(100% - (5 * (14px + 0.4vw)));
  height: 180px;
  padding: calc(14px + 0.4vw);
  margin-bottom: calc(10px + 2vw);
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;

  /* border-radius: 0 35px 0 35px; */
  border-radius:  35px ;
  border-width: 0 1px 3px 0;
  border-style: solid;
  border-color: #3E56AA;
  /* padding: 0.4vw 2vw calc(0.4vw + 2px) 2vw; */
  font-size: calc(14px + 0.4vw);
  font-weight: 400;
  color: #3E56AA;

  transition: all 0.2s;
  &:focus { outline: none; /* border-bottom: 3px solid #3E56AA; padding: 0.4vw 2vw;  */background-image: linear-gradient(to bottom, #0099cc00, #0099cc20);}
  /* background-color: #3E56AA00; */
  /* @media only screen and (max-width: 600px) {
    width: 100%;
  } */
`;

const FTextarea2 = styled(FTextarea)`

  border-color: #128C7E;
  color: #128C7E;

  transition: all 0.2s;
  &:focus { background-image: linear-gradient(to bottom, #25D26600, #25D26620);}
`;

const FInputField = styled.input`
  width: calc(100% - (5 * (14px + 0.4vw)));
  padding: 0.4vw 2vw calc(0.4vw + 2px) 2vw;
  font-size: calc(14px + 0.4vw);
  /* border-radius: calc(1.4vw + 10px); */
  border: none;
  border-bottom: 1px solid #3E56AA;
  /* box-shadow: 2px 2px 4px #00000080; */
  color: #202020;
  transition: all 0.2s;
  &:focus { outline: none; border-bottom: 3px solid #3E56AA; padding: 0.4vw 2vw; background-color: #0099cc18;/* background-image: linear-gradient(to bottom, #3E56AA00, #3E56AA20 50%); */}
  /* background-color: #3E56AA00; */
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
