import React from 'react';
import styled from 'styled-components';
import { SubHeadlineLineSubdued } from "widgets/Headlines";
import { useRouteMatch, useParams } from "react-router-dom";
import Detail0 from "pages/Detail0";
import { HashLink } from "widgets/MyHashLink";
import { articlesArray } from 'data/Articles';

/*
https://wordhtml.com
don't clean the html, just use the "remove classes and IDs" feature once or twice
cleaninig would remove the useful empty newlines e.g. <p>&nbsp;</p>
*/

const articles = new Map(articlesArray);

const PageArticle = ({ appMainRef, article = "auto-mutuo-aiuto" }) => {
  let { articleTitle } = useParams();
  return (
    articles.has(articleTitle) ?
      <Detail0 title={""} appMainRef={appMainRef}>
        <ArticleBox>
          <Article>
            {articles.get(articleTitle)}
          </Article>
        </ArticleBox>
      </Detail0> :
      <Detail0 title="Articolo non trovato" appMainRef={appMainRef}>
        <p style={{ paddingTop: "calc(20px + 2vw)" }}>Articolo non trovato, torna all'<HashLink to="/articoli">indice</HashLink></p>
      </Detail0>
  );
}

const Article = ({ children }) => {
  return (
    <ABox>
      <p className="ap-goback"><HashLink to="/articoli"><i className="fas fa-undo-alt" /></HashLink>Torna all'<HashLink className="ap-goback-link" to="/articoli">indice</HashLink></p>
      <div>
        {children}
      </div>
      <p className="ap-goback2"><HashLink to="/articoli"><i className="fas fa-undo-alt" /></HashLink>Torna all'<HashLink className="ap-goback-link" to="/articoli">indice</HashLink></p>
    </ABox>
  );
}

const ABox = styled.div`
  user-select: none;
  &>div {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    a {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        background-color: #0099cc;
        bottom: -2px;
        left: 50%;
        transition: all .25s ease-out;
      }
      color: #0099cc;
      text-decoration: none;
      font-weight: 500;
      transition: color .25s ease-out;
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          &::after {
            left: 0%;
            width: 100%;
          }
        }
      }
    }
  }
  position: relative;
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: calc(20px + 2vw);
  font-size: calc(13px + 0.4vw);
  line-height: 1.65;
  font-weight: 400;
  color: #202020;
  text-align: left;
  @media only screen and (max-width: 400px) {
    font-size: 13px;
    padding: calc(20px + 2vw) 20px;
  }
  @media only screen and (max-width: 330px) {
    font-size: 12px;
    padding: calc(20px + 2vw) 16px;
  }
  strong {
    color: #202020;
    letter-spacing: 1px;
    font-weight: 600;
  }
  em {
    /* letter-spacing: 1px; */
    /* color: #404040; */
    color: hsl(195, 31%, 40%);
    font-weight: 400;
  }
  ol,ul,menu {
    list-style: initial;
  }
  li {
    margin-left: calc(20px + 2vw);
  }
  h1,h2,h3/* ,h4,h5,h6 */ {
    align-self: center;
    text-align: center;
  }
  .ap-goback, .ap-goback2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    align-self: flex-end;
    text-align: end;
    text-transform: uppercase;
    color: #808080;
    letter-spacing: calc(2px + 0.1vw);
    margin-bottom: calc(20px + 0.8vw);
    font-size: calc(12px + 0.2vw);
    @media only screen and (max-width: 330px) {
      font-size: 11px;
      letter-spacing: 1px;
      i {font-size: 14px;}
    }
    i {
      font-size: calc(16px + 0.8vw);
      color: #0099cc90;
      margin: 0 6px;
      transform: rotateZ(0deg);
      transition: all 0.5s ease;
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          transform: rotateZ(-360deg);
          color: #0099ccff;
        }
      }
    }
    .ap-goback-link {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 0%;
        height: 2px;
        background-color: #0099cc;
        bottom: -2px;
        left: 50%;
        transition: all .25s ease-out;
      }
      color: #0099cc;
      text-decoration: none;
      font-weight: 500;
      transition: color .25s ease-out;
      @media(hover: hover) and (pointer: fine) {
        &:hover {
          &::after {
            left: 0%;
            width: 100%;
          }
        }
      }
    }
  }
  .ap-goback2 {
    margin-top: calc(30px + 1vw);
    margin-bottom: 0;
  }
  @media only screen and (min-width: 1441px) {
    font-size: 24px;
    h3 {font-size: 34px;}
    h4 {font-size: 31px;}
    .muted { font-size: 22px; }
  }
`;

const ArticleBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export default PageArticle;
