import { useState, useRef, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
/*  window and document size issues for server side rendering SSR */

export const SomeHashTarget = styled.div`
  position: absolute;
  top: -100px;
  left: 0px;
  width: 0px;
  height: 0px;
`;

/* For any non-empty HTML document, documentElement will always be an <html> element */
/* actual app size, doesn't match css media query dimensions */
/* true 1vh as third item is commented out */
export function useInnerSizeResizeDebounced() {
    let [size, setSize] = useState(
        ((document &&
            document.documentElement &&
            [document.documentElement.clientWidth, document.documentElement.clientHeight/* ,
            document.documentElement.clientHeight * 0.01 */]) ||
            (window && [window.innerWidth, window.innerHeight/* , window.innerHeight * 0.01 */]) || [-1, -1])
    );
    useLayoutEffect(() => {
        function updateSize() {
            setSize(
                ((document &&
                    document.documentElement &&
                    [document.documentElement.clientWidth, document.documentElement.clientHeight/* ,
                    document.documentElement.clientHeight * 0.01 */]) ||
                    (window && [window.innerWidth, window.innerHeight/* , window.innerHeight * 0.01 */]) || [-1, -1])
            );
        }
        window.addEventListener('resize', debounce(updateSize, 250));
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

function useKey(key) {
    const [pressed, setPressed] = useState(false);
    const match = event => key.toLowerCase() === event.key.toLowerCase();
    const onDown = event => { if (match(event)) setPressed(true); }
    const onUp = event => { if (match(event)) setPressed(false); }
    useEffect(() => {
        window.addEventListener("keydown", onDown); window.addEventListener("keyup", onUp);
        return () => { window.removeEventListener("keydown", onDown); window.removeEventListener("keyup", onUp); }
    }, [key]);
    return pressed;
}

export function useEventListener(eventName, handler, element = window) {
    const savedHandler = useRef();
    useEffect(() => { savedHandler.current = handler; }, [handler]);
    useEffect(() => {
        const isSupported = element && element.addEventListener;
        if (!isSupported) { return; }
        const eventListener = event => savedHandler.current(event);
        element.addEventListener(eventName, eventListener);
        return () => { element.removeEventListener(eventName, eventListener); };
    },
        [eventName, element]
    );
};

export const useResizeEndCallback = (resizeCallback = (e) => { return console.log("end of resize"); }) => {
    const onResizeCallback = debounce((e) => {
        resizeCallback(e);
    }, 500);

    useEffect(() => {
        window.addEventListener('resize', onResizeCallback);
        return () => {
            window.removeEventListener('resize', onResizeCallback);
        }
    }, []);
}

/* body client size matches css media query dimensions */
export function useBodyClientSizeResizeDebounced() {
    let [size, setSize] = useState((document &&
        document.body && [document.body.clientWidth, document.body.clientHeight])
        || [-1.0, -1.0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize((document &&
                document.body && [document.body.clientWidth, document.body.clientHeight])
                || [-1.0, -1.0]);
        }
        window.addEventListener('resize', debounce(updateSize, 250));
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export const supportsWorkers = () => !!window.Worker;

export const isMobile = () => {
    let hasTouchScreen = false;
    if ("maxTouchPoints" in navigator) {
        hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ("msMaxTouchPoints" in navigator) {
        hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
        let mQ = window.matchMedia && matchMedia("(pointer:coarse)");
        if (mQ && mQ.media === "(pointer:coarse)") {
            hasTouchScreen = !!mQ.matches;
        } else if ('orientation' in window) {
            hasTouchScreen = true; // deprecated, but good fallback
        } else {
            // Only as a last resort, fall back to user agent sniffing
            let UA = navigator.userAgent;
            hasTouchScreen = (
                /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
                /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
            );
        }
    }
    let mQ2 = window.matchMedia && matchMedia("(max-width: 767px), (max-height: 767px)");
    return ((hasTouchScreen === true) && (mQ2.matches === true));
}
