import React from 'react';
import styled from 'styled-components';
import Expandablist from "widgets/Expandablist";
import { SubHeadlineLine } from "widgets/Headlines";
import { TextBlock1 } from 'widgets/Typography';
import { HashLink } from "widgets/MyHashLink";
import Pubblicazioni from "sections/Pubblicazioni";

/* const linkCV = "http://psicologi.psy.it/Lombardia/Milano/Silistrini-Nadia_Maria-88990.html" */
/* https://smagento.mdigitalia.com/article-docs/altro/nadiasilistrini-cv.pdf */
/* const linkCV = "/article-docs/altro/nadiasilistrini-cv.pdf" */
const linkCV = "/article-docs/altro/psicologa-nadia-silistrini-cv.pdf"

const PageNadia = () => {
  return (
    <PNBox>
      <TextBlock1><div className="jjump" /><p>Un cordiale benvenuto a voi che leggete, sono Nadia Maria Silistrini, vivo e lavoro a Sesto San
      Giovanni, in provincia di Milano. Sono nata nel 1960 e quindi ho un curriculum formativo e
professionale abbastanza lungo che, se volete potete <a href={linkCV}>scaricare in formato PDF</a></p><div className="jump" /></TextBlock1>
      {/* <SubHeadlineLine align="left" title="Formazione e orientamento" />
      <SubHeadlineLine align="right" title="Esperienze professionali" /> */}
      <Pubblicazioni />
    </PNBox>
  );
}

const PNBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export default PageNadia;
