import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import Nav from 'widgets/Nav';
import Footer from 'widgets/Footer';
import Menu from 'widgets/Menu';
import Modal from 'widgets/Modal';
import { AppMaxWidthPx } from 'Config.js';
/* import Home from 'pages/Home'; */
import Home2 from 'pages/Home2';
import Detail0 from 'pages/Detail0';
import AppError from "pages/AppError";
import CookieBanner from "widgets/CookieBanner";
import PageFAQ from "pages/PageFAQ";
import PageNadia from "pages/PageNadia";
import PageArticles from "pages/PageArticles";
import PagePolicy from "pages/PagePolicy";
import PageArticle from "pages/PageArticle";
import { WhatsappFormNoBorder } from "widgets/Forms";
import { useKey } from "react-use";

/* import ReactGA from 'react-ga';
ReactGA.initialize('UA-000000-01');
ReactGA.pageview(window.location.pathname + window.location.search); */
/* import FABMenu from 'widgets/FABMenu';


Ti lascio il mio IBAN
IT26H0306901767100000000156
intestato a
FREZZATO DANIELE ANTONIO
*/

/*
<IfModule mod_rewrite.c>
RewriteEngine on
RewriteCond %{HTTP_HOST} ^forestopg\.mdigitalia\.com$
RewriteRule (.*) http://www.forestopg.mdigitalia.com/$1 [R=301,L]
RewriteRule ^$ m232 [L]
</IfModule>
*/

// PRIMARY COLOR: #0099cc
/* z-indexes:
  header item: 0-1;
  header overlay: 2;
  nav: 3;
  fab: 1;
  map-container: 1;
  scroller-ends: 1;
  image-slider-box: 1;
  menu: 999;
  modal: 999;
  prefooter text: 1;
  side-menu appcontainer: 200;
  cookies box: 13;
*/

// TODO: add unique key to Gallery and ImageSlider map lists

function App() {
  const [home, setHome] = useState(false);
  /* useKey("ArrowUp", () => setHome(state => !state));
  useKey("ArrowDown", () => setHome(state => !state)); */

  const [navBorn, setNavBorn] = useState(false);
  setTimeout(() => { setNavBorn(true); }, 1500); // prevents navbar flash of unstyled content on page load

  /* const [modalOpen, setModalOpen] = useState(false);
  const modalShow = () => setModalOpen(true);
  const modalDismissed = () => setModalOpen(false); */

  const [menuOpen, setMenuOpen] = useState(false);
  const menuShow = () => setMenuOpen(true);
  const menuDismissed = () => setMenuOpen(false);

  const { pathname } = useLocation();
  // see if this closes the menu on link click
  useEffect(() => { menuOpen && setMenuOpen(false); }, [pathname]);

  const [headRef, headInView,] = useInView({ threshold: 0.3 });
  const [appMain, appMainInView] = useInView({ rootMargin: "-36px" });
  const [appFoot, appFootInView] = useInView({ threshold: 0.75 });
  const [fabVisible, setFabVisible] = useState(false);
  useEffect(() => { setFabVisible(appMainInView); }, [appMainInView]);

  return (
    <>
      <AppBackground>
        <AppBox>
          <Switch>
            <Route exact path="/" render={(props) => /* home ? <Home appHeadRef={headRef} appMainRef={appMain} /> : */ <Home2 appHeadRef={headRef} appMainRef={appMain} />} />
            <Route exact path="/nadia" render={(props) =>
              <Detail0 title="Il mio percorso" appMainRef={appMain}>
                <PageNadia />
              </Detail0>} />
            <Route exact path="/domande-frequenti" render={(props) =>
              <Detail0 title="Domande frequenti" appMainRef={appMain}>
                <PageFAQ />
              </Detail0>} />
            <Route exact path="/articoli" render={(props) =>
              <Detail0 title="Approfondimenti" appMainRef={appMain}>
                <PageArticles />
              </Detail0>} />
            <Route exact path="/privacy-policy" render={(props) =>
              <Detail0 title="Privacy Policy" appMainRef={appMain}>
                <PagePolicy />
              </Detail0>} />
            <Route exact path="/risorse-utili" render={(props) =>
              <Detail0 title="Risorse utili" appMainRef={appMain}>
                <PageFAQ />
              </Detail0>} />
            <Route exact path="/articoli/:articleTitle"/* auto-mutuo-aiuto" */ render={(props) =>
              <PageArticle appMainRef={appMain} /* article="auto-mutuo-aiuto" */ />} />
            <Route component={AppError} />
          </Switch>
          <AppNav id="app-nav-id">
            <Nav menuOpen={menuOpen} menuToggleCallback={menuShow} shrinked={((pathname.length < 2) && !headInView)} />
          </AppNav>
          <AppFooter ref={appFoot}>
            <Footer />
          </AppFooter>
        </AppBox>
        {/* fabVisible && <FABMenu waModalShow={modalShow} fabUp={appFootInView} /> */}
      </AppBackground>
      {/* <CookieBanner /> */}
      {
        /* modalOpen &&
        <Modal onDismiss={modalDismissed}>
          <WhatsappFormNoBorder />
        </Modal> */
      }
      {menuOpen && <Menu onDismiss={menuDismissed} />}
    </>
  );
}

const AppBackground = styled.div`
  position: relative;
  width: 100%;
  background-color: #ffffff;
`;

const AppBox = styled.div`
  position: relative;
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: #ffffff;
  text-align: center;
  @media only screen and (min-width: ${AppMaxWidthPx + 1}px) {
    max-width: ${AppMaxWidthPx}px;
    box-shadow: 4px 0px 10px #00000090;
  }
`;

const AppNav = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: grid;
  place-items: center;
  height: auto;
  transition: all 0.4s;
  z-index: 3;
  @media only screen and (min-width: ${AppMaxWidthPx + 1}px) {
    max-width: ${AppMaxWidthPx}px;
    left: 50%;
    transform: translate(-50%, -100%);
    animation: nav-box-reveal-overwide 0.8s ease-out 0.6s 1 forwards;
  }
  transform: translateY(-100%);
  animation: nav-box-reveal 0.8s ease-out 0.6s 1 forwards;
  @keyframes nav-box-reveal {
      0% { transform: translateY(-100%); }
      100% { transform: translateY(0%); }
  }
  @keyframes nav-box-reveal-overwide {
      0% { transform: translate(-50%, -100%); }
      100% { transform: translate(-50%, 0%); }
  }
`;

const AppFooter = styled.footer`
  position: relative;
  width: 100%;
  background-color: #444;
  color: white;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s;
`;

export default App;
