import React from 'react';
import styled from 'styled-components';
import Pubblicazioni from 'sections/Pubblicazioni';
import Nadia2 from 'sections/Nadia2';
import Contattami2 from 'sections/Contattami2';
import { PageHelmetTags } from 'utils/AppHelmetTags';
import Header2 from "widgets/Header2";
import SectFAQ from "sections/SectFAQ";
import Prefooter from "widgets/Prefooter";
import { HeadlineLine } from "widgets/Headlines";
/* import Header from "widgets/Header"; */
/* import Gallery from 'sections/Gallery'; */
/* import FormeTrattamento from 'sections/FormeTrattamento';
import Dove from 'sections/Dove'; */
/* import Interventi from 'sections/Interventi'; */

const Home2 = ({ appHeadRef, appMainRef }) => {
  return (
    <>
      <PageHelmetTags />
      <AppHeader ref={appHeadRef} id="panoramica">
        {/* <Header /> */}
        <Header2 />
      </AppHeader>
      <AppHomeMain ref={appMainRef}>
        <AppHomeSection title="Nadia" titleAlign="center" secId="nadia">
          <Nadia2 />
        </AppHomeSection>
        {/* <AppHomeSection title="Aree di intervento" titleAlign="right" secId="aree-di-intervento">
          <Interventi />
        </AppHomeSection> */}
        {/* <AppHomeSection title="Forme e percorsi" titleAlign="left">
          <FormeTrattamento />
        </AppHomeSection> */}
        <AppHomeSection title="Approfondimenti" titleAlign="right" secId="approfondisci">
          <SectFAQ />
          <Pubblicazioni />
        </AppHomeSection>
        {/* <AppHomeSection title="Lo studio" titleAlign="left">
          <Gallery />
          <Dove /><br />
        </AppHomeSection> */}
        <AppHomeSection title="Contattami" titleAlign="left" secId="contatti">
          <Contattami2 />
        </AppHomeSection>
        <Home3End />
        <Prefooter />
      </AppHomeMain>
    </>
  );
}

const Home3End = () => {
  return (
    <>
      <Home3EndBox2 colorDark="#004D66" colorLight="#99E5FF" color="#0099cc" />
      <Home3EndBox colorDark="#004D66" colorLight="#99E5FF" color="#0099cc" />
    </>
  );
}

const Home3EndBox = styled.div`
  width: 100%;
  position: relative;
  height: calc(9px + 0.6vw);
  background-image: ${props => ("linear-gradient(to right, " + props.color + ", " + props.colorLight + ", " + props.colorDark + ")")};
`;

const Home3EndBox2 = styled.div`
  width: 100%;
  position: relative;
  height: calc(4px + 0.3vw);
  background-image: ${props => ("linear-gradient(to left, " + props.color + ", " + props.colorLight + ", " + props.colorDark + ")")};
`;

const AppHeader = styled.header`
  position: relative;
  height: 100vh;
  max-height: 800px;
  overflow: hidden;
  background-color: #ffffff;
  width: 100%;
  display: grid;
  place-items: center;
  /* @media only screen and (min-height: 1081px) {height: 1080px; } */
`;

const AppHomeMain = styled.main`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-evenly;
  align-items: center;
  color: black;
  opacity: 0.0;
  animation: home-page-reveal 1.0s ease-in 0.0s 1 forwards;
  @keyframes home-page-reveal {
      0% { opacity: 0.0; }
      100% { opacity: 1.0; }
  }
`;

const AppHomeSection = ({ secId = "section-id", children, title = "Section Title", titleAlign = "left" }) => {
  return (
    <AHSBox>
      <HomeHashTarget id={secId} />
      <HeadlineLine align={titleAlign} title={title} />
      <br />
      {children}
    </AHSBox>
  );
}

const HomeHashTarget = styled.div`
  position: absolute;
  top: -100px;
  left: 0px;
  width: 0px;
  height: 0px;
`;

const AHSBox = styled.section`
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  margin: 0vh 0;
`;

export default Home2;
