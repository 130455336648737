import React from 'react';
import styled from 'styled-components';
import Expandablist from "widgets/Expandablist";
import { listFAQ } from 'data/FAQs';

/*
Uno psicologo è un medico?
Quali sono le differenze fra psicologo, psicanalista, psicoterapeuta e psichiatra?
Quando rivolgersi a ciascuna di queste figure?
Come si svolge e a cosa serve il primo colloquio?
Chi può accedere alla consulenza psicologica?
Quanto dura una psicoterapia?
Come faccio a sapere se sto migliorando?
Qual è il momento per concludere il percorso?
Quali sono le differenze sostanziali fra psicoterapia e gruppo di sostegno?
Quali sono le modalità e i tipi di psicoterapia?
Cosa devo raccontare di me allo psicologo?
Posso invitare familiari e conoscenti alla psicoterapia?
Quali sono i vincoli relativi alla terapia per minori?
Lo psicologo è tenuto al segreto professionale?
Cos'è il consenso informato all'intervento psicologico?

In cosa consiste la terapia di gruppo?
Quanto costa una psicoterapia?
Esiste un codice deontologicodegli psicologi/psicoterapeuti?

Quali benefici posso ottenere con la consulenza psicologica?
I risultati sono garantiti?
Come si può interrompere un percorso?
Quanto dura e quanto costa la singola seduta?

Come prendere un primo appuntamento?
Con quale frequenza si svolge il percorso?
Dovrò usare dei farmaci durante la psicoterapia?

Come posso capire se la terapia sta funzionando?
C'è il rischio di dipendenza da terapia?

Chiedere una consulenza mi vincola in qualche modo a un percorso più lungo?

Quali sono le possibilità e i limiti della psicoterapia?
Quali condizioni sono necessarie perchè la psicoterapia sia efficace?
Che differenze ci sono da una chiacchierata con un amico?

Solo i pazzi ricorrono alla psicoterapia?
La psicoterapia mi farà il lavaggio del cervello?

Fino a che età è possibile il cambiamento psicologico?
Soffro di ansia/depressione, è corretto che mi rivolga allo psicologo?
*/

const PageFAQ = () => {
  return (
    <FAQBox>
      <Expandablist itemList={listFAQ} />
    </FAQBox>
  );
}

const FAQBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export default PageFAQ;
