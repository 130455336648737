import React from 'react';
import styled from 'styled-components';
import logo512 from 'img/logo/logo512.png';
import { HashLink } from "widgets/MyHashLink";
import { Link } from "react-router-dom";

const linkOrdinePsicologi = "http://psicologi.psy.it/Lombardia/Milano/Silistrini-Nadia_Maria-88990.html"
const copyString = "© " + (new Date().getFullYear()) + " Nadia Maria Silistrini";

const Footer = () => {
  return (
    <FooterBox>
      <LogoBox className="fimgbox">
        <HashLink smooth to="/#panoramica">
          <img src={logo512} alt={copyString} />
        </HashLink>
      </LogoBox>
      <OrdineBox>
        <a target="_blank" rel="Noopener noreferrer" href={linkOrdinePsicologi}>Ordine degli Psicologi della Lombardia 03/89</a>
      </OrdineBox>
      <CopyBox>
        <p>{copyString}</p>
        <p>Psicologa psicoterapeuta</p>
        <p>P.IVA 02675140962</p>
        {/* <Link to="/privacy-policy">PRIVACY POLICY</Link> */}
      </CopyBox>
    </FooterBox>
  );
}

const LogoBox = styled.div`
  position: relative;
  @media only screen and (min-width: 1300px) {
    width: 300px;
  }
  /* background-color: red; */
`;

const OrdineBox = styled.div`
  position: relative;
  flex-grow: 10;
  @media only screen and (max-width: 1000px) {
    /* width: 100%; */
    order: 3;
    /* text-align: center; */
  }
`;

const CopyBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  text-align: end;
  p {line-height: 1.4;}
  a {
    font-size: 11px;
    opacity: 0.9;
    &::after { opacity: 0.8; }
  }
@media only screen and (min-width: 1300px) {
    width: 300px;
  }
  /* background-color: red; */
`;

const FooterBox = styled.div`
/* &>div {
  width: calc((100% - 120px) / 2.0);
  display: grid;
  place-items: center;
}
.fimgbox {
  width: 100px;
} */
  position: relative;
  width: 100%;
  height: 160px;
  background-color: #00171F;
  color: white;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 30px 30px 30px 50px;
  align-items: center;
  color: #808080;
  font-size: calc(14px + 0.4vw);
  @media only screen and (min-width: 1441px) {
    font-size: calc(20px);
  }
  @media only screen and (max-width: 1000px) {
    height: 300px;
    &>div {
    width: 100%;
    /* order: 3; */
    text-align: center;
    display: grid;
    place-items: center;
    }
    a {
      margin: calc(8px + 0.3vw) 0;
    }
    img {
      margin-bottom: calc(8px + 0.3vw);
    }
  }
  a {
    position: relative;
    color: #0099cc;
    &::after {
      content: "";
      position: absolute;
      width: 0%;
      height: 2px;
      background-color: #0099cc;
      bottom: -2px;
      left: 50%;
      transition: all .25s ease-out;
    }
    &:hover {
        &::after {
          left: 0%;
          width: 100%;
        }
      }
    /*
    height: 100%; */
  }
  img {
    position: relative;
    height: 70px;
    width: auto;
    /* margin: calc(8px + 0.3vw); */
  }
  p {
    position: relative;
    /* margin: calc(8px + 0.3vw); */
  }
`;

export default Footer;
