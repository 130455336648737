export const AppNavFullHeightStr = "30vh"; /* header top = expanded nav, if viewport height is > 500 px */
export const AppNavHeightStr = "100px";
export const AppFooterHeightPx = 160; /* mobile-first */
export const AppFooterHeightMin768Px = 100; /* for large screens */
export const AppMaxWidthPx = 1440; /* body is bounded, vw must be bounded too (see AppMaxVwPx) */
export const AppMaxWidthHalfPx = AppMaxWidthPx / 2; /* body is bounded, vw must be bounded too (see AppMaxVwPx) */
export const AppMaxVwPx = AppMaxWidthPx / 100; /* e.g. margin: 2vw becomes margin: ${2* AppMaxVwPx}px on screen width > 1440px */
export const AppFabSpacingStr = "calc(10px + 0.8vw)";
export const slideMenuSide = new Map([["top", 0], ["right", 1], ["bottom", 2], ["left", 3]]);

export const navBarHeight = "100px";
export const navBarFatHeight = "140px";
export const footerHeight = "120px";
export const footerMax1024ScreenHeight = "150px";
