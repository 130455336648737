import React from 'react';
import styled from 'styled-components';
import { /* TxtBlockInlineTitle, */ TextBlock1 } from "widgets/Typography";
import imgInvecch from 'img/invecchiamento-attivo/invecchiamento-attivo.jpg';
import imgCooperaz from 'img/psicoterapia/cooperazione-sociale.jpg';
import imgSonno from 'img/psicoterapia/disturbi-sonno-mindfulness.jpg';
import imgSonno2 from 'img/psicoterapia/disturbi-sonno-mindfulness-2.jpg';
import imgNadia from "img/nadia-2-1024.jpg";
import imgPsy from "img/psy-512-384.jpg";
import { HashLink } from "widgets/MyHashLink";
import { BlueInvertRippleButton, BlueInvertRippleButtonOutline } from "widgets/RippleContainer";
import { ImgText00, ImgFloatText01 } from "widgets/ImgText00";
import { MultiSnippet3, MultiSnippet4, MultiSnippet5 } from "widgets/MultiSnippet";
/* import nadiaimg from 'img/nadia512.png';
import Strings from 'data/Strings'; */
/* import imgNadia from "img/nadia-512-384.jpg"; */
/* import imgFamilia from 'img/invecchiamento-attivo/sostegno-familiari-anziani.jpg';
import imgMemoria from 'img/invecchiamento-attivo/laboratori-memoria.jpg'; */
/* import imgOld from "img/old-512-384.jpg";
import { ImgText5 } from "widgets/ImgTexts"; */
/* import ImgsText0 from "widgets/ImgsText0"; */
/* import { SomeHashTarget } from "utils/Utils"; */

const Nadia2 = () => {
  return (
    <NadiaBox>
      <ImgFloatText01 refId="nadia-psicologa" imgFloat="right" verticalSpacing="calc(10px + 1vw)" imgWidth="calc(256px + 10vw)" inTitle="Nadia" img={imgNadia}>
        <p>Un cordiale benvenuto a voi che leggete, sono Nadia Maria Silistrini, vivo e lavoro a Sesto San Giovanni, in provincia di Milano. Sono nata nel 1960 e quindi ho un curriculum formativo e professionale abbastanza lungo che, se lo desiderate, potete consultare nel mio <HashLink to="/nadia">percorso</HashLink>.</p><div className="jump" /><p>A sedici anni, mentre frequentavo l’istituto tecnico per Periti Aziendali e Corrispondenti in Lingue Estere, ho letto un libro che parlava di Psicologia e ho deciso che dopo la maturità non avrei scelto un corso universitario a indirizzo economico o linguistico, ma la Facoltà di Magistero a Padova, dove era stato attivato l’unico corso di Laurea in Psicologia del Nord Italia.</p><div className="jump" /><p>Da allora, la Psicologia è stata il mio terreno privilegiato di studio, di lavoro, di interesse</p>
      </ImgFloatText01>
      <ImgText00 refId="psicoterapia-cognitiva" /* verticalSpacing */ verticalSpacing="calc(20px + 1vw)" imgFirst /* columnLayoutThresholdPx={"800px"} */ imgWidth={400} outTitle="Psicoterapia cognitivo-comportamentale" img={imgPsy}>
        <p>{/* Mi occupo di <strong>invecchiamento attivo</strong>, corsi di psicologia nelle università per la Terza Età, e sostegno a familiari e operatori impegnati nell’assistenza a persone anziane non autosufficienti.  */}La mia attività di psicologa psicoterapeuta libero professionista si rivolge ad adulti e anziani che soffrono di disturbi d’ansia, disturbi di personalità e disturbi dell'umore; è individuale e fa riferimento alla <strong>psicologia cognitivo-comportamentale</strong>, che considera essenziale il legame tra quello che ci accade e i pensieri, le emozioni, i comportamenti che mettiamo in atto e che raccontano giorno per giorno chi siamo, cosa temiamo, cosa vogliamo, cosa speriamo. Questa è la punta dell’iceberg, la cui conoscenza ci permette di scoprire le modalità profonde del nostro pensare e agire</p>
      </ImgText00>
      <MultiSnippet3 /* color="#25D266" */ bgicons={false}
        icons={["fas fa-user-clock", "fas fa-user-shield", "fas fa-people-arrows"]}
        titles={["Disturbi d'ansia", "Disturbi dell'umore", "Disturbi di personalità"]}
        texts={[
          <>
            <p>La paura è un’emozione che dal punto di vista evolutivo si è sviluppata con lo scopo di proteggere la vita. Quando non svolge più il suo naturale compito di metterci in guardia...</p><HashLink className="more-link" to="/articoli/disturbi-d-ansia">Approfondisci</HashLink>
          </>,
          <>
            <p>Tutti sperimentiamo oscillazioni dell’umore, soprattutto come risposta a eventi che ci accadono, ma quando queste si strutturano in disturbi che compromettono la normale vita sociale e lavorativa...</p><HashLink className="more-link" to="/articoli/disturbi-dell-umore">Approfondisci</HashLink>
          </>,
          <>
            <p>La personalità è ciò che ci caratterizza, è il nostro modo di essere formato da tratti stabili ma capaci di evoluzione salvo quando alcune modalità diventano così rigide e inflessibili da...</p><HashLink className="more-link" to="/articoli/disturbi-di-personalita">Approfondisci</HashLink>
          </>]} />
      {/* <SomeHashTarget /> */}
      <ImgText00 refId="invecchiamento-attivo" verticalSpacing="calc(10px + 1vw)" imgWidth={400} outTitle="Invecchiamento attivo" img={imgInvecch}>
        <p>Mi occupo di corsi per l’apprendimento delle <strong>Skills for Life</strong> (Abilità per la Vita) focalizzati in particolare sull’invecchiamento attivo e la psicologia della salute, di laboratori per la memoria e di workshop per Raccoglitori di Storie, dove si lavora fianco a fianco per comprendere che davvero <em>“Ogni vita merita un romanzo”</em> (E. Polster).</p>
      </ImgText00>
      {/* <ImgsText0 refId="invecchiamento-attivo" imgsHeight={250} imgsWidth={340} verticalSpacing="calc(10px + 1vw)" outTitle="Invecchiamento attivo" labels={["Invecchiamento attivo", "Sostegno ai familiari", "Laboratori della memoria"]} imgs={[imgInvecch, imgFamilia, imgMemoria]}>
        <p>Mi occupo di Corsi per l’apprendimento delle <strong>Skills for Life</strong> (Abilità per la Vita) focalizzati in particolare sull’invecchiamento attivo e la psicologia della salute, laboratori per la memoria e di workshop per Raccoglitori di Storie, dove si lavora fianco a fianco per comprendere che davvero <em>“Ogni vita merita un romanzo”</em> (E. Polster).</p>
      </ImgsText0> */}
      <MultiSnippet4 color="#0099cc"
        icons={["fas fa-heartbeat", "fas fa-tools", "fas fa-feather", "fas fa-history"]}
        titles={["Psicologia della salute", "Skills for Life", "Laboratori per la memoria", "Raccoglitori di storie"]}
        texts={[
          "La Psicologia della Salute si propone di insegnare a essere sani, oltre che studiare come l’ambiente sociale e i processi psicologici influenzino la persona nelle scelte di cura.",
          "Le competenze per la vita (Skills for life) comprendono le abilità cognitive, emotive e relazionali che ci permettono di utilizzare bene le nostre risorse e partecipare positivamente alla vita della comunità.",
          "La memoria non è un semplice magazzino di ricordi, ma un processo dinamico che sostiene la nostra identità. Conoscerla, rafforzarla, valorizzarla fa di noi persone umanamente più ricche e mature.",
          "«Io sono una storia, tu sei una storia...», diceva la Piccola Principessa in un libro per l’infanzia. Raccogliere storie è dare importanza all’unicità e al valore di ogni vita."]} />
      {/* <ImgText00 verticalSpacing="calc(10px + 2vw)" imgWidth={400} outTitle="Assistenza agli anziani e sostegno alle famiglie" img={imgCooperaz}>
        <p>Mi occupo di formazione, supervisione, e aggiornamento per <strong>OSS e Assistenti Familiari</strong> (badanti); di consulenza scientifica per Progetti di sostegno rivolti a familiari di anziani non autosufficienti e di implementazione di Gruppi di auto mutuo, in collaborazione con Associazioni e Cooperative del Terzo Settore.</p><div className="jump" /><p>Come si dice spesso: <em>“La famiglia aiuta, ma chi aiuta la famiglia?”</em>. Chi si occupa di una persona fragile ha bisogno a sua volta di ricevere sostegno e consulenza, individualmente o in gruppo, che lo aiuti a fronteggiare le difficoltà e i sentimenti di solitudine.</p>
      </ImgText00> */}
      <ImgFloatText01 refId="assistenza" verticalSpacing="calc(10px + 2vw)" imgFloat="left" imgWidth="calc(300px + 10vw)" outTitle="Assistenza agli anziani e sostegno alle famiglie" /* inTitle="Nadia" */ img={imgCooperaz}>
        <p>Mi occupo di formazione, supervisione, e aggiornamento per <strong>OSS e Assistenti Familiari</strong> (badanti); di consulenza scientifica per Progetti di sostegno rivolti a familiari di anziani non autosufficienti e di implementazione di Gruppi di auto mutuo, in collaborazione con Associazioni e Cooperative del Terzo Settore.</p><div className="jump" /><p>Come si dice spesso: <em>“La famiglia aiuta, ma chi aiuta la famiglia?”</em></p><div className="jump" /><p>Chi si occupa di una persona fragile ha bisogno a sua volta di ricevere sostegno e consulenza, individualmente o in gruppo, che lo aiuti a fronteggiare le difficoltà e i sentimenti di solitudine.</p>
      </ImgFloatText01>
      <MultiSnippet3 color="#0099cc"
        icons={["fas fa-graduation-cap", "fas fa-people-carry", "fas fa-hands-helping"]}
        titles={["Formazione per operatori socio-sanitari e assistenti familiari", "Progetti per il sostegno ai familiari di anziani non autosufficienti", "Auto mutuo aiuto"]}
        texts={[
          "Chi si occupa di persone fragili svolge un compito gravoso e spesso poco considerato. La disponibilità umana non basta, occorre anche la competenza, che si sviluppa nel tempo e richiede sostegno e formazione continua.",
          "L’anziano non autosufficiente e i suoi familiari spesso condividono una condizione di isolamento. Chi offre aiuto deve ricevere a sua volta aiuto e sentire che non è solo nella scelta di curare l’anziano a casa sua.",
          "Nei gruppi di auto mutuo aiuto si esercitano il sostegno e la solidarietà fra persone che vogliono condividere le loro esperienze per affrontare meglio un problema comune, una difficoltà, un momento delicato della vita."]} />
      <ImgText00 refId="orizzonti" /* verticalSpacing */ verticalSpacing="calc(10px + 1vw)" /* columnLayoutThresholdPx={"800px"} */ imgWidth={400} outTitle="Orizzonti" img={imgSonno2}>
        <p>Pratico <strong>yoga</strong> e <strong>mindfulness</strong>, ho una formazione in <strong>Tecniche di Rilassamento</strong>, mi interesso alla Psicologia Positiva, alla Psicologia del viaggiatore e ai diari di viaggio, che nel loro significato di itinerario nello spazio e nel tempo danno conto dell’evoluzione che ciascuno di noi vive quotidianamente. Una ricerca appassionante alla scoperta di sé, del senso della vita, della promozione del proprio benessere e di quello altrui. Il miglior lavoro che si possa fare.</p>
      </ImgText00>
      <MultiSnippet5 five color="#25D266"
        icons={["fas fa-heartbeat", "fas fa-spa", "fas fa-sun", "fas fa-hiking", "fas fa-child"]}
        titles={["Psicologia della salute e Yoga", "Mindfulness", "Psicologia positiva", "Psicologia del viggiatore", "Tecniche di rilassamento"]}
        texts={[
          "La Psicologia della Salute ha in comune con lo yoga la ricerca dell’equilibrio interiore attraverso la consapevolezza piena e presente, realizzabile attraverso strade autonome che possono integrarsi e completarsi a vicenda.",
          "Tradotta con presenza mentale consapevole o con attenzione piena al momento presente è una pratica che aiuta le persone a non identificarsi con i propri pensieri – solitamente basati sull’autocritica e la colpevolizzazione – e ad assumere un atteggiamento più benevolo verso se stessi e gli altri.",
          "La Psicologia Positiva si concentra sulle potenzialità e le risorse individuali, sulla capacità di provare emozioni positive, fiducia e benessere, senza per questo negare le difficoltà e le sfide.",
          "Perché sentiamo il bisogno di muoverci nello spazio e nel tempo? Partiamo per scoprire il “fuori” o il “dentro” di noi? O per scoprire che la differenza è molto più sottile di quanto pensiamo?",
          "La mente manda messaggi al corpo e il corpo ne manda alla mente. Rilassando il corpo e riducendo l’eccitazione mentale, si instaura un circolo virtuoso che diminuisce lo stress e aumenta il benessere"]} />
      <TextBlock1 verticalSpacing="calc(10px + 1vw)">
        <p>{/* Per farvi un'idea dei temi che affronto abitualmente nella mia pratica, e capire se la mia esperienza possa venire incontro alle vostre esigenze, continuate esplorando le <HashLink smooth to="/#aree-di-intervento">aree</HashLink> di intervento.  */}Per orientarvi nel mondo della psicoterapia potete leggere le <HashLink smooth to="/#approfondisci">domande</HashLink> frequenti e gli approfondimenti presenti sul sito.{/* , oppure consultare le <HashLink to="https://google.com">risorse</HashLink> esterne che ho compilato<br /> */} Per parlarmi o chiarire qualche dubbio, lasciate un messaggio e mettiamoci in <HashLink smooth to="/#contatti">contatto</HashLink>
        </p>
      </TextBlock1>
      <FPButtonRow>
        <HashLink to="/articoli"><BlueInvertRippleButtonOutline style={{ margin: "calc(10px + 0.6vw)" }} icon="fas fa-book" text="Approfondisci" /></HashLink>
        <HashLink smooth to="/#contatti"><BlueInvertRippleButton style={{ margin: "calc(10px + 0.6vw)" }} icon="fas fa-paper-plane" text="Contattami" /></HashLink>
      </FPButtonRow>
      <TextBlock1><div className="jjump" /></TextBlock1>
      {/* <p className="type-fullwidth">{Strings.str0}</p>
      <NadiaImg alt="Nadia Silistrini" src={nadiaimg} /> */}
      {/* <TxtBlockInlineTitle title="NADIA" /> */}
      {/* <ImgText5 img={imgNadia} title="NADIA" text="Un cordiale benvenuto a voi che leggete, sono Nadia Maria Silistrini, vivo e lavoro a Sesto San Giovanni, in provincia di Milano. Sono nata nel 1960 e quindi ho un curriculum formativo e professionale abbastanza lungo che, se lo desiderate, potete consultare alla voce Il mio percorso" align="right" /> */}
      {/* <ImgText00 verticalSpacing="calc(10px + 1vw)" columnLayoutThresholdPx={"800px"} imgWidth={300} inTitle="NADIA" img={imgNadia}>
        <p>Un cordiale benvenuto a voi che leggete, sono Nadia Maria Silistrini, vivo e lavoro a Sesto San Giovanni, in provincia di Milano. Sono nata nel 1960 e quindi ho un curriculum formativo e professionale abbastanza lungo che, se lo desiderate, potete consultare nel mio <HashLink to="/nadia">percorso</HashLink>. Mi occupo di invecchiamento attivo, laboratori per la memoria, e sostegno a familiari e operatori impegnati nell’assistenza a persone anziane non autosufficienti.</p>
      </ImgText00> */}
      {/* <ImgText00 verticalSpacing="calc(20px + 2vw)" imgFirst columnLayoutThresholdPx={"800px"} imgWidth={300} inTitle="PSICOLOGIA COGNITIVA" img={imgPsy}>
        <p>La mia attività di psicologa psicoterapeuta libero professionista si rivolge in modo particolare a chi
        soffre di disturbi d’ansia, disturbi di personalità e disturbi depressivi e fa riferimento alla psicologia
        cognitiva, che considera essenziale il legame tra quello che ci accade e i pensieri, le emozioni, le
        valutazioni che ne derivano, e che raccontano giorno per giorno chi siamo, cosa temiamo, cosa
vogliamo, cosa speriamo.</p>
      </ImgText00> */}
      {/* <ImgText5 img={imgPsy} title="PSICOLOGIA COGNITIVA" text="Just kidding, here's some more content of some kind and some more content of a differen. Some content that precedes some more content and is subsequently followed by more content, eventually we have a last slab of content. Just kidding, here's some more content of some kind and some more content of a different kind. Content is what makes up this piece of content. " align="left" /> */}
      {/* <ImgText5 img={imgOld} title="INVECCHIAMENTO ATTIVO" text="Some content that precedes some more content and is subsequently followed by more content, eventually we have a last slab of content. Just kidding, here's some more content of some kind and some more content of a different kind. Content is what makes up this piece of content, along with the title of this content block and the text body it comprises." align="right" /> */}
    </NadiaBox>
  );
}

const FPButtonRow = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  /* @media only screen and (max-width: 1024px) {
    flex-flow: column nowrap;
    justify-content: space-evenly;
  } */
`;

const NadiaBox = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
`;

const NadiaImg = styled.img`
  width: 40%;
  @media only screen and (min-width: 768px) {width: 30%;}
  @media only screen and (min-width: 1280px) {width: 15%;}
  margin: 1vh 0;
  border-radius: 100%;
  box-shadow: 3px 3px 6px #404040;
  cursor: pointer;
  transition: all 0.2s;
  @media(hover: hover) and (pointer: fine) { &:hover { transform: scale(1.1); } }
`;

export default Nadia2;
