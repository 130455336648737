import React, { useState } from 'react';
import { HashLink } from "widgets/MyHashLink";

export const shortlistFAQ = [
  {
    title: "Che cosa fa lo psicologo?",
    text: <p>Lo psicologo è un esperto della mente, del comportamento e delle relazioni umane. Opera in diversi campi con la finalità di promuovere il benessere individuale e collettivo. Quando si occupa di disturbi mentali, utilizza varie tecniche, di cui la principale è il colloquio, per aiutare il paziente a mettere a fuoco le proprie difficoltà e iniziare un percorso di comprensione delle stesse. Puoi approfondire leggendo il mio <HashLink to="/articoli/lo-psicologo-questo-sconosciuto">articolo</HashLink> <em>Lo psicologo, questo sconosciuto</em></p>
  },
  {
    title: "Non siamo tutti, in fin dei conti, un po’ psicologi?",
    text: <p>Sarebbe come dire che siamo tutti un po’ medici, un po’ architetti, un po’ ingegneri... Chi si farebbe difendere in tribunale da uno che è un po’ avvocato? Tutti abbiamo uno stomaco, ma quando ci fa davvero male, non ci passa per la mente di fare da soli e andiamo dal medico. Tutti abbiamo una mente e anche se sapessimo molte cose sul suo funzionamento, potremmo non capire con chiarezza perché ci comportiamo in un certo modo, perché non riusciamo a venir fuori da una brutta situazione, perché ci sembra di non poter superare la paura, la tristezza, o la rabbia, perché le nostre relazioni non sono soddisfacenti, e così via.</p>
  },
  {
    title: "Chiedere un colloquio con uno psicologo mi obbliga a fare psicoterapia?",
    text: "Naturalmente no. Nel caso in cui lo psicologo suggerisca la necessità della psicoterapia, ne spiegherà al paziente i motivi. Spetta a quest’ultimo decidere. Una decisione meditata e convinta è la premessa per un’alleanza terapeuta–paziente indispensabile per la realizzazione di un lavoro proficuo e perché la psicoterapia sia efficace.",
    image: ""
  },
  {
    title: "Che cosa fa lo psicoterapeuta cognitivo?",
    text: <p>Lo psicoterapeuta cognitivo aiuta a comprendere che le basi del nostro vissuto sono il <em>che cosa</em> e il <em>come</em>. Usa spesso la metafora dei due scienziati: uno scienziato è il paziente, esperto del <em>che cosa</em>. Infatti, lui solo conosce i propri pensieri, l’intensità delle proprie emozioni, i comportamenti con cui reagisce agli eventi della vita. Lo psicoterapeuta è lo scienziato del <em>come</em>, cioè del modo in cui fatti, pensieri, emozioni, comportamenti formano un puzzle di cui alcune parti non sono facilmente accessibili (modalità profonde di funzionamento) e che, scoperte e riposizionate, permettono di comprendere il senso di quello che succede e di riprendere in mano la propria vita.</p>
  },
  {
    title: "Sono anziano, a che mi serve la psicoterapia?",
    text: "La capacità di apprendere, cambiare ed evolvere è presente a tutte le età. Anche una persona anziana ha il diritto di affrontare, mitigare, superare la sofferenza. Ha diritto alla cura, sia che si tratti di cure mediche, psicologiche, riabilitative, o assistenziali. Se è vero che il passato non può essere cambiato, è altrettanto vero che è possibile cambiare il proprio modo di vederlo e giudicarlo e fare pace con se stessi. Agli anziani che mi dicono: «A che serve? Tanto sono vecchio, devo morire», rispondo: «Non è detto che debba succedere adesso o domani». Tutti noi abbiamo il diritto di vivere più serenamente il nostro presente.",
    image: ""
  },
  {
    title: "Quando l’ansia o la depressione devono preoccupare?",
    text: "Generalmente si chiede aiuto a un professionista quando la situazione di sofferenza è giudicata troppo seria e invalidante per poterla gestire da soli o con l’aiuto della rete familiare e sociale, che pure continua a rivestire un ruolo significativo. A volte le persone aspettano molto tempo prima di fare questo passo, mentre sarebbe opportuno intervenire quando i sintomi non sono diventati così importanti.",
    image: ""
  }
];

export const listFAQ = [
  ...shortlistFAQ,
  {
    title: "Perché andare dallo psicologo? Non basta parlare in famiglia o con un amico?",
    text: "I familiari e gli amici non hanno le competenze dello psicologo, ma anche se fossero psicologi hanno un rapporto che non garantisce la necessaria distanza emotiva e obiettività nel vedere i problemi. Non è deontologicamente corretto occuparsi professionalmente di persone con cui si hanno rapporti nella sfera privata. La persona stessa difficilmente si aprirebbe senza riserve, sapendo che familiari e amici non possono restare emotivamente neutri: il timore del giudizio o l’idea di causare dolore, preoccupazione e sconcerto nelle persone amate sono molto condizionanti. Inoltre non si riconoscerebbe loro l’autorevolezza necessaria per accettare l’aiuto. Se un problema è affrontato e risolto positivamente grazie alla rete familiare e sociale, quasi sicuramente non c’era bisogno di un aiuto professionale."
  },
  {
    title: "È vero che dallo psicologo ci vanno i matti?",
    text: "Purtroppo questo pregiudizio impedisce a persone che potrebbero trarre beneficio dall’aiuto di uno psicologo, di affrontare la vita con meno sofferenza e più gratificazione. Il timore del giudizio, di essere considerati deboli e incapaci di farcela da soli, può essere un serio ostacolo. Ricordiamo che dallo psicologo ci vanno persone che riconoscono di attraversare un momento di difficoltà e responsabilmente chiedono di essere aiutati a superarlo. Domandare un supporto quando se ne sente il bisogno è un gesto di benevolenza e rispetto nei confronti di sé."
  },
  {
    title: "Possiamo considerare lo psicologo un medico?",
    text: "No, lo psicologo non è un medico e non può utilizzare atti tipici della professione medica, come la prescrizione di farmaci. Naturalmente non è proibito avere due lauree, quindi se uno psicologo è laureato anche in medicina e iscritto all’Albo dei Medici, può esercitare come medico. Sta al professionista informare correttamente e spiegare con termini comprensibili le proprie competenze."
  },
  {
    title: "Che differenza c’è fra psicologo, psicanalista, psicoterapeuta, psichiatra e neurologo?",
    text: <p>Spesso è difficile orientarsi tra i nomi che indicano figure professionali diverse ma che si occupano di mente e cervello. Ti invito a leggere il mio <HashLink to="/articoli/lo-psicologo-questo-sconosciuto">articolo</HashLink> <em>Lo psicologo, questo sconosciuto</em>, dove troverai le spiegazioni necessarie per comprendere le diverse competenze.</p>
  },
  {
    title: "Devo raccontare proprio tutto allo psicologo?",
    text: "Di solito il paziente inizia parlando dei problemi che lo disturbano, ma a volte il livello di ansia e di confusione è tale che la persona non sa bene da dove cominciare o addirittura non riesce ad aprire bocca, o al contrario parla di molte cose diverse. È compito dello psicologo aiutarlo con delicatezza a focalizzarsi sui motivi che lo hanno portato a chiedere il colloquio. Quando si instaura un rapporto di fiducia (ed è necessario un po’ di tempo) il paziente parla più liberamente di sé e di ciò che ritiene importante, con l’obiettivo di stare meglio e superare le difficoltà. Non sempre ciò accade. Se allo psicologo sono nascoste informazioni rilevanti, il lavoro con il paziente ne risentirà, anche seriamente."
  },
  {
    title: "Come funziona il primo colloquio?",
    text: "Il primo colloquio è utile innanzitutto per conoscersi e ascoltare le difficoltà che il paziente porta. Sulla base di questo colloquio, lo psicologo può capire se la persona necessita di una consulenza psicologica, di ulteriori colloqui psicodiagnostici approfonditi, che aiutino a comprendere, ad esempio, se c’è bisogno di un trattamento psicoterapico, oppure se è più opportuno l’invio ad altri professionisti. Lo psicologo pone delle domande e ascolta in modo empatico e non giudicante quello che il paziente dice."
  },
  {
    title: "Perché esistono tanti tipi di psicoterapia? Come faccio a scegliere?",
    text: <p>La storia della psicologia e della psicoterapia è complessa, ma è possibile comprenderla iniziando a focalizzare alcuni elementi di base. Puoi leggere il mio <HashLink to="/articoli/lo-psicologo-questo-sconosciuto">articolo</HashLink> Lo psicologo, questo sconosciuto, in cui troverai alcune informazioni. È opportuno che il professionista informi il paziente circa il proprio orientamento teorico e spieghi perché può essere utile nel suo caso. Un professionista serio non esiterà a consigliare un tipo di terapia diversa da quella che pratica, se ritiene che il paziente ne trarrà maggiori benefici (ad es. uno psicanalista può inviare il paziente a un terapeuta cognitivo–comportamentale, o a terapeuti sistemico–relazionali, e viceversa).</p>
  },
  {
    title: "Quanto può durare una psicoterapia? Quante sono le sedute a settimana?",
    text: "Dipende dall’orientamento (psicanalitico, cognitivo, comportamentale ecc.) dello psicoterapeuta, dal tipo di problema che il paziente porta, dai suoi obiettivi e dalla sua volontà e capacità di collaborare e impegnarsi. La franchezza, la correttezza e la trasparenza dello psicoterapeuta sono indispensabili per aiutare il paziente a scegliere se accettare il percorso psicoterapeutico consigliato e, in caso positivo, a comprendere i segnali di miglioramento, le criticità, i progressi e anche quando il lavoro può dirsi concluso."
  },
  {
    title: "I miei familiari possono partecipare alla psicoterapia?",
    text: "Se la terapia ha carattere individuale, i familiari non possono partecipare. In caso di necessità e se il paziente è d’accordo, possono essere coinvolti con modalità e limiti molto chiari e precisi."
  },
  {
    title: "Quanto costa una psicoterapia?",
    text: "Dipende dal professionista, il quale è tenuto a comunicare nel modello di Consenso Informato le tariffe praticate."
  },
  {
    title: "Si può interrompere un percorso di psicoterapia?",
    text: "Sì, anche perché per la sua buona riuscita sono indispensabili la collaborazione e la motivazione. È importante che terapista e paziente concordino una seduta in cui parlarne, in modo da verificare i benefici e le criticità del lavoro fatto."
  },
  {
    title: "Durante la psicoterapia si devono prendere farmaci?",
    text: "Non è detto. Se il disagio emotivo del paziente è tanto elevato da compromettere la vita quotidiana e la psicoterapia stessa, il professionista può consigliare l’invio al medico di famiglia o allo psichiatra, che a loro giudizio possono prescrivere i farmaci appropriati. In questo caso, il contatto e la collaborazione tra le diverse figure professionali, con il consenso del paziente, può essere molto utile."
  },
  {
    title: "Posso diventare dipendente dallo psicoterapeuta?",
    text: "In tutte le relazioni di aiuto si instaura un certo grado di dipendenza. È compito del professionista valutarla adeguatamente e impedire che diventi un ostacolo all’evoluzione e all’autonomia del paziente, cosa che sarebbe in contraddizione con il fine stesso della psicoterapia."
  }
];

/* [
  {
    title: "EXTRA-DOMANDA?",
    text: "ero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis"
  }
]; */

/* export const listFAQ = [
  {
    title: "Mi servono le lenti progressive?",
    text: "ero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis"
  },
  {
    title: "Ogni quanto è consigliabile ripetere la visita optometrica?",
    text: "vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venearius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis"
  },
  {
    title: "Bruciore agli occhi stando al PC, come mai?",
    text: "n ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermenus libero dui, enatis erat. Nunc quis efficitur ligula. Sed at lectus nec libero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit le",
    image: ""
  },
  {
    title: "Esistono materiali anallergici?",
    text: "vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venearius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis"
  },
  {
    title: "Devo rivolgermi all'optometrista, al ocntattologo o all'oftalmologo",
    text: "n ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermenus libero dui, enatis erat. Nunc quis efficitur ligula. Sed at lectus nec libero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit le",
    image: ""
  },
  {
    title: "Quali sono i trattamenti essenziali sulle lenti?",
    text: "vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venearius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis"
  },
  {
    title: "Esistono lenti a contatto progressive?",
    text: "n ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermenus libero dui, enatis erat. Nunc quis efficitur ligula. Sed at lectus nec libero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit le"
  },
  {
    title: "Quali lenti sono consigliabili per l'attività sportiva?",
    text: "vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venearius venenatis leo. Donec metus augue, fermentum et iaculis vel, aliquam eget magna. Vivamed at lectus nec libero elementum tempor iaculis"
  },
  {
    title: "Lenti a contatto dopo operazione laser?",
    text: "n ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermenus libero dui, enatis erat. Nunc quis efficitur ligula. Sed at lectus nec libero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit le",
    image: ""
  },
  {
    title: "Infezioni all'occhio impediscono l'utilizzo delle lenti a contatto?",
    text: "n ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermenus libero dui, enatis erat. Nunc quis efficitur ligula. Sed at lectus nec libero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit le"
  },
  {
    title: "Cosa indicano i diversi numeri nei modelli di lenti a contatto?",
    text: "n ut blandit leo. Nenatis erat. Nunc quis efficitur ligula. Sretium vitae magna. Proin quam augue, tristique nec egestas non, varius venenatis leo. Donec metus augue, fermenus libero dui, enatis erat. Nunc quis efficitur ligula. Sed at lectus nec libero elementum tempor iaculis nec dui. Suspendisse elit nibh, placerat ut suscipit non, pdapibus quis aliquet ac, vulputate at nunc. Etiam facilisis nisi et ipsum ultrices mattis. In hac habitasse platea dictumst. Duis sit amet nisl ornare mauris aliquet cursus. Proin ut blandit le",
    image: ""
  }
]; */
