import React from 'react';
import styled from 'styled-components';
import bg from 'img/bg/prefooter1.jpg';

const Prefooter = ({ /* quote = "“ Il più grande esploratore su questa terra non fa viaggi più lunghi di chi scende in fondo al proprio cuore ”" */quote = "“ Noi siamo quello che pensiamo di essere. Il nostro abituale modo di pensare determina le nostre tendenze, le nostre capacità e la nostra personalità. ”", by = /* "J. Green" */"Paramahansa Yogananda", img, children, ...moreProps }) => {
  return (
    <PFBox src={img ? img : bg} {...moreProps}>
      <PFOverlay />
      {
        children ? children
          :
          <>
            <PFQuote>{quote}</PFQuote>
            <PFAuthor>{by}</PFAuthor>
          </>
      }
      {children}
    </PFBox>
  );
}

const PFBox = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  padding: calc(8px + 6vw) 0 calc(12px + 9vw) 0;
  overflow: hidden;
  background-image: url(${props => props.src});
  background-position: center;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
`;

const PFOverlay = styled.div`
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background-image: linear-gradient(to bottom, #ffffffff, #ffffff80);
`;

const PFQuote = styled.em`
  position: relative;
  width: 90%;
  max-width: 900px;
  z-index: 1;
  color: #000000e0;
  font-size: calc(14px + 0.6vw);
  letter-spacing: 1px;
  font-weight: 400;
  text-align: center;
`;

const PFAuthor = styled.p`
  position: relative;
  width: 90%;
  max-width: 900px;
  padding-right: calc(40px + 4vw);
  z-index: 1;
  color: #00000088;
  font-size: calc(14px + 0.4vw);
  font-weight: 600;
  text-align: end;
`;

export default Prefooter;
