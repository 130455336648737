import React, { useState } from 'react';
import styled from 'styled-components';
/* import Modal from "widgets/Modal"; */
import { CardScroller0 } from "widgets/CardScrollers";
/*
in AA.VV Un cuore giovane per l’età anziana
in Fiori sotto Zero, a cura di D.Barbot
*/
const pubblicazioni = [
  { year: "2014", authors: "A cura di Nadia Silistrini", title: "Non mi ruberai la vita", info: "Vademecum per anziani vittime di reato e per i loro familiari", editor: "" },
  { year: "2011", authors: "Nadia Silistrini", title: "Le mille parole", info: "Vademecum per anziani vittime di reato e per i loro familiari", editor: "Aesse Comunicazione Roma" },
  { year: "2011", authors: "Nadia Silistrini", title: "Solidarietà e condivisione tra anziani", more: ", da Un cuore giovane per l’età anziana (AA.VV.)", info: "Vademecum per anziani vittime di reato e per i loro familiari", editor: "Coop. In dialogo Milano" },
  { year: "2004", authors: "Nadia Silistrini", title: "Assistenti e assistiti: dentro la malattia e oltre", more: ", da Fiori sotto Zero (a cura di D.Barbot)", info: "Vademecum per anziani vittime di reato e per i loro familiari", editor: "Franco Angeli Editore Milano" },
  { year: "2000", authors: "Nadia Silistrini", title: "Il mestiere di aiutare", info: "Vademecum per anziani vittime di reato e per i loro familiari", editor: "Franco Angeli Editore Milano" },
  { year: "1994", authors: "Nadia Silistrini", title: "Aspetti psicologici dell'assistenza domiciliare agli anziani", info: "Vademecum per anziani vittime di reato e per i loro familiari", editor: "Edizioni Lavoro Roma" },
  { year: "1993", authors: "A cura di Nadia Silistrini", title: "In casa con noi", info: "Vademecum per anziani vittime di reato e per i loro familiari", editor: "Edizioni CAF Milano" }
];

const Pubblicazioni = () => {
  /* const [img, setImg] = useState(-1);
  const [imgModalShowing, setImgModalShowing] = useState(false);
  const imgModalShow = () => setImgModalShowing(true);
  const imgModalDismiss = () => setImgModalShowing(false);
  const itemClick = (index) => { console.log("itemCLICK" + index); setImg(index); imgModalShow(); } */
  return (
    <>
      <CardScroller0 items={pubblicazioni} /* itemClick={itemClick} */ />
      {/*imgModalShowing &&
        <Modal onDismiss={imgModalDismiss}>
          <p>{pubblicazioni[img].title}</p>
        </Modal>*/
      }
    </>
  );
}

export default Pubblicazioni;
